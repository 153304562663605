import React, { Component } from 'react';
import Slider from 'react-slick';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Map, Marker, GoogleApiWrapper, GoogleMap } from 'google-maps-react';
import axios from "axios";
import { APIURL, COMMON } from '../config'
import * as Moment from 'moment'
import { history } from "../history"
import HTMLRenderer from 'react-html-renderer'
import stringsoflanguages from '../Stringsoflanguage'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import { generateRefreshToken } from '../serviceworker';
import { Redirect } from 'react-router-dom';
let error_log = require('../error_log');
export class Job_details extends Component {
    constructor(props) {
        super(props);
        this.state = {
            leadtype: 0,
            is_auth: true,
            showModal: false,
            modalMessage: '',
            showCallHRModal: false,
            ipaddress: '',
            employeecode: -1,
            languagecode: 0,
            employermobileno: '',
            employercode: '',
            jobcode: '',
            ipurl: APIURL,
            apikey_map: 'AIzaSyBUrzOsS992lNcSpt9TqD7b0CtBUCMCAks',
            photoIndex: 0,
            isOpen: false,
            jobrolename: '',
            daysleft: '',
            appliedstatus: '',
            locationname: '',
            companyname: '',
            jobdescription: '',
            jobtypes: [],
            minsalary: '',
            maxsalary: '',
            isfresher: '',
            experiencefrom: '',
            experienceto: '',
            industryname: '',
            noofopenings: '',
            address: '',
            emailid: '',
            skills: [],
            varcontact_address1: "",
            varcontact_address2: "",
            varcontact_address3: "",
            varcontact_address4: "",
            varcontact_address5: "",
            varcontact_address6: "",
            approveddate: '',
            qualification: [],
            schoolinglistval: [],
            educationval: [],
            afterschoolingisany: "",
            specializationany: '',
            isanydegree: false,
            category_list: [],
            contact_lat: 0.0,
            contact_lng: 0.0,
            view_contact_map_markers: [
                {
                    name: "Current position",
                    position: {
                        lat: 0.0,
                        lng: 0.0
                    }
                }
            ],
            maritalstatusval: [],
            maritalisanyval: '',
            genderlistval: [],
            genderisanyval: '',
            agecriteria_isanyal: '',
            agecriteria_fromval: '',
            agecriteria_toval: '',
            differentlyabledval: '',
            jobfunctioncodeval: [],
            profileurlval: '',
            skip: 0,
            limit: 5,
            isbestsalaryval: '',
            mobileno: '',
            jobcode: '',
            jobalertdays: ''
        };
    }
    componentDidMount() {
        try {
            if (!localStorage.getItem("mobileNumber") || localStorage.getItem("mobileNumber") == null) {
                this.setState({ is_auth: this.state.is_auth = false });
            }
            let empcode = localStorage.getItem('employeecode');
            this.setState({ employeecode: this.state.employeecode = empcode });
            let leadtype = localStorage.getItem('leadtype');
            this.setState({ leadtype: this.state.leadtype = leadtype });
            localStorage.getItem('ipaddress')
            this.setState({ ipaddress: this.state.ipaddress = localStorage.getItem('ipaddress') });
            stringsoflanguages.setLanguage(localStorage.getItem('languagecode_short'));
            this.setState({ languagecode: this.state.languagecode = localStorage.getItem('languagecode') })
            let query = new URLSearchParams(history.location.search)
            let jobcode = (query.get('jobcode'))
            if (jobcode != '') {
                this.setState({ jobcode: this.state.jobcode = jobcode })
            }
            if (leadtype == 0) {
                this.jobs_view()
            }
        }
        catch (error) {
            error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Job_details", "componentDidMount", 0, error.toString());
        }
    }
    indian_standard_amount(amt) {
        try {
            var x = parseInt(amt);
            x = x.toString();
            var lastThree = x.substring(x.length - 3);
            var otherNumbers = x.substring(0, x.length - 3);
            if (otherNumbers != '')
                lastThree = ',' + lastThree;
            var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
            return res;
        }
        catch (error) {
            error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Job_details", "indian_standard_amount", 0, error.toString());
        }
    }
    jobs_view() {
        try {
            var accessToken = localStorage.getItem('accessToken');
            window.fbq('track', 'Website', { fb_ad_function: "jobs_view", fb_ad_type: "Job Details", fb_ad_loginuser: COMMON.employee_name });
            axios({
                method: 'Post',
                url: this.state.ipurl + 'jobs/view',
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json'
                },
                params: {
                    languagecode: this.state.languagecode,
                    employeecode: this.state.employeecode,
                    ipaddress: this.state.ipaddress,
                    typecode: 2,
                    jobcode: this.state.jobcode
                }
            })
                .then(async response => {
                    if (response.data.status == 401) {
                        await generateRefreshToken().then((result) => {
                            if (result == 1) {
                                this.jobs_view();
                            }
                            else {
                                // alert('Unauthorized')
                            }
                        })
                    }
                    else {
                        if (response.data.hasOwnProperty('job_view_json_result')) {
                            if (response.data.job_view_json_result.varstatuscode === 4) {
                                let result = response.data.job_view_json_result
                                if (result.hasOwnProperty('job_details')) {
                                    if (result.job_details.length > 0) {
                                        this.setState({
                                            employercode: this.state.employercode = (result.job_details[0].hasOwnProperty('employerdetails')) ? result.job_details[0].employerdetails.employercode : '',
                                            employermobileno: this.state.employermobileno = (result.job_details[0].hasOwnProperty('employerdetails')) ? result.job_details[0].employerdetails.mobileno : '',
                                            jobrolename: this.state.jobrolename = (result.job_details[0].hasOwnProperty('jobrolename')) ? result.job_details[0].jobrolename : '',
                                            daysleft: this.state.daysleft = (result.job_details[0].hasOwnProperty('daysleft')) ? result.job_details[0].daysleft : '',
                                            appliedstatus: this.state.appliedstatus = (result.job_details[0].hasOwnProperty('appliedstatus')) ? result.job_details[0].appliedstatus : '',
                                            companyname: this.state.companyname = (result.job_details[0].employerdetails.hasOwnProperty('employername')) ? result.job_details[0].employerdetails.employername : '',
                                            jobdescription: this.state.jobdescription = (result.job_details[0].hasOwnProperty('jobdescription')) ? result.job_details[0].jobdescription : '',
                                            minsalary: this.state.minsalary = (result.job_details[0].salaryrange.hasOwnProperty('min')) ? result.job_details[0].salaryrange.min : '',
                                            maxsalary: this.state.maxsalary = (result.job_details[0].salaryrange.hasOwnProperty('max')) ? result.job_details[0].salaryrange.max : '',
                                            industryname: this.state.industryname = (result.job_details[0].hasOwnProperty('industryname')) ? result.job_details[0].industryname : '',
                                            noofopenings: this.state.noofopenings = (result.job_details[0].hasOwnProperty('noofopenings')) ? result.job_details[0].noofopenings : '',
                                            address: this.state.address = (result.job_details[0].contactdetails.hasOwnProperty('address')) ? result.job_details[0].contactdetails.address : '',
                                            emailid: this.state.emailid = (result.job_details[0].contactdetails.hasOwnProperty('address')) ? result.job_details[0].contactdetails.emailid : '',
                                            skills: this.state.skills = (result.job_details[0].hasOwnProperty('skills')) ? result.job_details[0].skills : [],
                                            jobtypes: this.state.jobtypes = (result.job_details[0].hasOwnProperty('jobtypes')) ? result.job_details[0].jobtypes : [],
                                            qualification: this.state.qualification = (result.job_details[0].hasOwnProperty('qualification')) ? result.job_details[0].qualification : [],
                                            agecriteria_isanyal: this.state.agecriteria_isanyal = (result.job_details[0].agecriteria.hasOwnProperty('isany')) ? result.job_details[0].agecriteria.isany : '',
                                            agecriteria_fromval: this.state.agecriteria_fromval = (result.job_details[0].agecriteria.hasOwnProperty('from')) ? result.job_details[0].agecriteria.from : '',
                                            agecriteria_toval: this.state.agecriteria_toval = (result.job_details[0].agecriteria.hasOwnProperty('to')) ? result.job_details[0].agecriteria.to : '',
                                            differentlyabledval: this.state.differentlyabledval = (result.job_details[0].hasOwnProperty('differentlyabled')) ? result.job_details[0].differentlyabled : '',
                                            profileurlval: this.state.profileurlval = (result.job_details[0].employerdetails.hasOwnProperty('profileurl')) ? result.job_details[0].employerdetails.profileurl : '',
                                        });
                                        let gen = result.job_details[0].genderlist.map(({ gendername }) => gendername)
                                        this.setState({
                                            genderlistval: this.state.genderlistval = gen,
                                            genderisanyval: this.state.genderisanyval = (result.job_details[0].hasOwnProperty('genderisany')) ? result.job_details[0].genderisany : ''
                                        })
                                        let merital = result.job_details[0].maritalstatus.map(({ maritalname }) => maritalname)
                                        this.setState({
                                            maritalstatusval: this.state.maritalstatusval = merital,
                                            maritalisanyval: this.state.maritalisanyval = (result.job_details[0].hasOwnProperty('maritalisany')) ? result.job_details[0].maritalisany : ''
                                        })
                                        let jfunarr = []
                                        jfunarr.push(result.job_details[0].jobfunctioncode)
                                        this.setState({ jobfunctioncodeval: this.state.jobfunctioncodeval = jfunarr })
                                        let locname = result.job_details[0].joblocationlist.map(({ locationname }) => locationname)
                                        this.setState({ locationname: this.state.locationname = locname })
                                        let expfresher = result.job_details[0].experience.map(({ isfresher }) => isfresher)
                                        this.setState({ isfresher: this.state.jobtypisfresherename = expfresher })
                                        let expfrom = result.job_details[0].experience.map(({ from }) => from)
                                        this.setState({ experiencefrom: this.state.experiencefrom = expfrom })
                                        let expto = result.job_details[0].experience.map(({ to }) => to)
                                        this.setState({ experienceto: this.state.experienceto = expto })
                                        var expired_on = new Date(result.job_details[0].approveddate);
                                        var approveddate = Moment(expired_on).format('DD-MM-YYYY');
                                        this.setState({ approveddate: this.state.approveddate = approveddate })
                                        if (result.job_details[0].contactdetails.address) {
                                            this.setState({ varcontact_address1: this.state.varcontact_address1 = result.job_details[0].contactdetails.address });
                                            var extension = this.state.varcontact_address1.split('\n');
                                            let str = extension[0] + "\n" + extension[1] + ' ' + 'Town / City' + "\n" + extension[2] + ' ' + 'Taluk' + "\n" + extension[3] + "\n" + extension[4];
                                            let text = str.split("-");
                                            if (text && text.length > 0)
                                                str = text[0] + ' ' + 'District' + "-" + text[1];
                                            var extension1 = str.split('\n');
                                            this.setState({ varcontact_address2: this.state.varcontact_address2 = extension1[0] })
                                            this.setState({ varcontact_address3: this.state.varcontact_address3 = extension1[1] })
                                            this.setState({ varcontact_address4: this.state.varcontact_address4 = extension1[2] })
                                            this.setState({ varcontact_address5: this.state.varcontact_address5 = extension1[3] })
                                            this.setState({ varcontact_address6: this.state.varcontact_address6 = extension1[4] })
                                        }
                                        var schoolinglist = result.job_details[0].schooling
                                        if (schoolinglist) {
                                            this.setState({ schoolinglistval: this.state.schoolinglistval = schoolinglist });
                                        }
                                        var val_afterschool = []
                                        if (result.job_details[0].afterschooling && result.job_details[0].afterschooling.length > 0)
                                            val_afterschool = result.job_details[0].afterschooling[0]
                                        if (val_afterschool.hasOwnProperty('specializationlist')) {
                                            this.setState({ educationval: this.state.educationval = val_afterschool.specializationlist });
                                        }
                                        if (val_afterschool.qualisany) {
                                            this.setState({ afterschoolingisany: this.state.afterschoolingisany = val_afterschool.qualisany });
                                        }
                                        if (val_afterschool.afterschoolingisany) {
                                            this.setState({ specializationany: this.state.specializationany = val_afterschool.afterschoolingisany });
                                        }
                                        if (result.job_details[0].afterschooling.length == 0) {
                                            this.setState({ isanydegree: this.state.isanydegree = true });
                                        }
                                        if ((this.state.afterschoolingisany == false || this.state.afterschoolingisany == "false") &&
                                            this.state.specializationany == true || this.state.specializationany == "true") {
                                            let qualification_list = val_afterschool.hasOwnProperty('qualificationlist') ? val_afterschool.qualificationlist : []
                                            this.setState({ qualification_list: this.state.qualification_list = qualification_list })
                                        }
                                        if (this.state.afterschoolingisany == true || this.state.afterschoolingisany == "true") {
                                            let category_list = val_afterschool.hasOwnProperty('categorylist') ? val_afterschool.categorylist : []
                                            this.setState({ category_list: this.state.category_list = category_list })
                                        }
                                        if (result.job_details[0].employerdetails) {
                                            let lng = result.job_details[0].employerdetails.longitude;
                                            let lat = result.job_details[0].employerdetails.latitude;
                                            if (lat && lng) {
                                                let index = 0;
                                                const markers = [...this.state.view_contact_map_markers];
                                                markers[index] = { ...markers[index], position: { lat, lng } };
                                                this.setState({ view_contact_map_markers: this.state.view_contact_map_markers = markers });
                                                this.setState({ contact_lat: this.state.contact_lat = lat });
                                                this.setState({ contact_lng: this.state.contact_lng = lng });
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        this.job_list()
                    }
                })
                .catch(error => {
                    error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Job_details", "jobs_view", 0, error.toString());
                });
        }
        catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "Job_details", "jobs_view", 0, error.toString());
        }
    }
    category_list_view() {
        try {
            if (this.state.category_list && this.state.category_list.length > 0) {
                return this.state.category_list.map((education, index) => {
                    const { educationcategoryname } = education
                    return (
                        <div className="d-flex user-info-pd" key={index}>
                            <div className="width-contact-employee">  </div>
                            <div>
                                <span> {educationcategoryname} </span>
                            </div>
                        </div>
                    )
                })
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Job_Details", "category_list_view", 0, error.toString());
        }
    }
    education_list() {
        try {
            if (this.state.educationval && this.state.educationval.length > 0) {
                return this.state.educationval.map((education, index) => {
                    const { qualificationname, specializationname } = education
                    return (
                        <span key={index}>
                            {qualificationname} - {specializationname}
                        </span>
                    )
                })
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "Job_Details", "education_list", 0, error.toString());
        }
    }
    schooleducation_list() {
        try {
            if (this.state.schoolinglistval && this.state.schoolinglistval.length > 0) {
                return this.state.schoolinglistval.map((school, index) => {
                    const { qualificationname } = school
                    return (
                        <span key={index}>
                            {qualificationname},
                        </span>
                    )
                })
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "Job_Details", "schooleducation_list", 0, error.toString());
        }
    }
    qualification_list_view() {
        try {
            if (this.state.qualification_list && this.state.qualification_list.length > 0) {
                return this.state.qualification_list.map((education, index) => {
                    const { qualificationname } = education
                    return (
                        <span key={index}>
                            {qualificationname},
                        </span>
                    )
                })
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Job_Details", "qualification_list_view", 0, error.toString());
        }
    }
    job_list() {
        var accessToken = localStorage.getItem('accessToken');
        window.fbq('track', 'Website', { fb_ad_function: "job_list", fb_ad_type: "Job Details", fb_ad_loginuser: COMMON.employee_name });
        axios({
            method: 'Post',
            url: this.state.ipurl + 'jobs/jobslist',
            crossdomain: true,
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json'
            },
            params: {
                employeecode: this.state.employeecode,
                ipaddress: this.state.ipaddress,
                languagecode: this.state.languagecode,
                type: 6,
                skip: this.state.skip,
                limit: this.state.limit
            },
            data: {
                skillcode: [],
                locationcode: [], jobfunctioncode: this.state.jobfunctioncodeval, jobrolecode: [], jobtypecode: [], schoolqualcode: [], afterschoolcatecode: [], anydegree: "true", anyqualification: "true", anyspec: "true", afterschoolqualcode: [], afterschoolspeccode: [], experiencecode: 0, industrycode: [], employertypecode: [], companytypecode: [], maritalcode: [], gendercode: [], differentlyabled: -1, salaryfrom: 0, salaryto: 0, agefrom: 0, ageto: 0, anyage: "true"
            }
        })
            .then(async response => {
                if (response.data.status == 401) {
                    await generateRefreshToken().then((result) => {
                        if (result == 1) {
                            this.job_list();
                        }
                        else {
                            // alert('Unauthorized')
                        }
                    })
                }
                else {
                    if (response.data.hasOwnProperty('job_list_json_result')) {
                        if (response.data.job_list_json_result.varstatuscode === 4) {
                            this.setState({ jobalertdays: this.state.jobalertdays = response.data.job_list_json_result.jobalertdays })
                            // let flash_job_list = []
                            let flash_job_list = response.data.job_list_json_result.hasOwnProperty('flash_job_list') ? response.data.job_list_json_result.flash_job_list : []
                            let recommended_list = response.data.job_list_json_result.hasOwnProperty('job_list') ? response.data.job_list_json_result.job_list : []
                            this.setState({
                                flash_job_list: this.state.flash_job_list = flash_job_list,
                                employermobileno: this.state.employermobileno = (response.data.job_list_json_result.job_list[0].hasOwnProperty('employermobileno')) ? response.data.job_list_json_result.job_list[0].employermobileno : '',
                            });
                            for (let i = 0; i < recommended_list.length; i++) {
                                recommended_list[i].flash = 0;
                                if (recommended_list[i].hasOwnProperty('locationname'))
                                    recommended_list[i].locationname.sort();
                            }
                            for (let i = 0; i < flash_job_list.length; i++) {
                                flash_job_list[i].flash = 1;
                                if (flash_job_list[i].hasOwnProperty('joblocationname'))
                                    flash_job_list[i].joblocationname.sort();
                            }
                            let arr = [];
                            let flashlist = flash_job_list;
                            if ((recommended_list && recommended_list.length > 0) && (flash_job_list && flash_job_list.length > 0)) {
                                for (let i = 0; i < recommended_list.length; i++) {
                                    if (i != 0 && i % 4 == 0) {
                                        if (flashlist && flashlist.length > 0) {
                                            arr.push(flashlist[0]);
                                            flashlist.shift();
                                        }
                                        arr.push(recommended_list[i]);
                                    } else {
                                        arr.push(recommended_list[i]);
                                    }
                                }
                                if (flashlist && flashlist.length > 0) {
                                    flashlist.forEach(element => {
                                        arr.push(element);
                                    });
                                }
                            } else {
                                if ((recommended_list && recommended_list.length > 0)) {
                                    arr = recommended_list;
                                }
                                if ((flash_job_list && flash_job_list.length > 0)) {
                                    arr = flash_job_list;
                                }
                            }
                            this.setState({ recommended_list: this.state.recommended_list = arr });
                            let rowData = arr;
                            JSON.stringify(rowData);
                            this.setState({ rowData: this.state.rowData = rowData });
                        }
                    }
                }
            })
            .catch(error => {
                error_log.ERRORLOG(this.state.usercode, this.state.version, "Job_details", "job_list", 0, error.toString());
            });
    }
    openCallHRModal = () => {
        this.setState({
            showCallHRModal: true,
        });
    };
    openModal = (message) => {
        this.setState({
            showModal: true,
            modalMessage: message,
        });
        setTimeout(() => {
            this.setState({ showModal: this.state.showModal = false })
            this.updatecallcount()
            this.jobs_view();
            window.open('tel:' + this.state.employermobileno);
        }, 3000);
    }
    closeModal = () => {
        window.location.reload();
        this.setState({ showModal: this.state.showModal = false });
        this.jobs_view();
    }
    handleClick = (e) => {
        this.inputElement.click();
    }
    updatecallcount = async () => {
        try {
            var accessToken = localStorage.getItem('accessToken');
            axios({
                method: 'Post',
                url: this.state.ipurl + 'jobs/updateCallCount/',
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json'
                },
                params: {
                    jobcode: this.state.jobcode,
                    employeecode: this.state.employeecode,
                    ipaddress: this.state.ipaddress,
                    type: 1,
                    isleadtype: 0
                }
            })
                .then(async response => {
                    this.setState({ animating: this.state.animating = false });
                    if (response.data.status == 401) {
                        await generateRefreshToken().then((result) => {
                            if (result == 1) {
                                this.updatecallcount();
                            } else {
                                // alert('Unauthorized')
                            }
                        });
                    }
                    else {
                        if (response.data.hasOwnProperty('job_json_result')) {
                            this.state.varstatuscode = response.data.job_json_result.hasOwnProperty('varstatuscode') ? response.data.job_json_result.varstatuscode : 0
                            this.state.responsestring = response.data.job_json_result.hasOwnProperty('responsestring') ? response.data.job_json_result.responsestring : ''
                            if (this.state.varstatuscode == 2) {
                            }
                            else if (this.state.varstatuscode == 7) {
                                toast(stringsoflanguages.user_not_found, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            }
                            else {
                                toast(this.state.responsestring, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            }
                        }
                    }
                })
                .catch((error) => {
                    error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Job_details", "updatecallcount", 0, error.toString());
                });
        }
        catch (error) {
            error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Job_details", "updatecallcount", 0, error.toString());
        }
    }
    closeHRModal = () => {
        window.location.reload();
        this.setState({ showCallHRModal: this.state.showCallHRModal = false });
    }
    applynow() {
        var accessToken = localStorage.getItem('accessToken');
        try {
            axios({
                method: 'Post',
                url: this.state.ipurl + 'jobs/jobapply/?',
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json'
                },
                params: {
                    employeecode: this.state.employeecode,
                    languagecode: this.state.languagecode,
                    ipaddress: this.state.ipaddress,
                    jobcode: this.state.jobcode,
                    employercode: this.state.employercode,
                },
            })
                .then(async response => {
                    if (response.data.status == 401) {
                        await generateRefreshToken().then((result) => {
                            if (result == 1) {
                                this.applynow();
                            }
                            else {
                                // alert('Unauthorized')
                            }
                        })
                    }
                    else {
                        if (response.data.hasOwnProperty('job_json_result')) {
                            this.state.varstatuscode = response.data.job_json_result.hasOwnProperty('varstatuscode') ? response.data.job_json_result.varstatuscode : 0;
                            if (this.state.varstatuscode === 27) {
                                const message = `Job applied successfully!`;
                                this.openModal(message);
                            } else if (this.state.varstatuscode === 31) {
                                toast("not_eligible", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            } else if (this.state.varstatuscode === 77) {
                                toast("apply_limit", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            } else if (this.state.varstatuscode === 83) {
                                toast("alrdy_invited", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            } else if (this.state.varstatuscode === 62) {
                                toast("invite_then_reject", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            } else if (this.state.varstatuscode === 59) {
                                toast("apply_then_reject", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            } else if (this.state.varstatuscode === 7) {
                                toast("user_not_found", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            } else {
                                toast.error(this.state.responsestring, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            }
                        }
                    }
                })
                .catch((error) => {
                    error_log.ERRORLOG(this.state.usercode, this.state.version, "Job_details", "applynow", 0, error.toString());
                });
        } catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "Job_details", "applynow", 0, error.toString());
        }
    }
    render() {
        var slick_slider = {
            dots: false,
            arrow: false,
            autoplay: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 4,
            slidesToScroll: 1,
            rows: 1,
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        };
        return (
            <div className="site-main">
                {!this.state.is_auth && <Redirect push to="/login" />}
                <ToastContainer autoClose={10000} toastStyle={{ backgroundColor: "#ed9d3b", color: 'white' }} progressStyle={{ background: 'white' }} />
                <Header />
                {/* PageHeader */}
                {/* <PageHeader
                    title={stringsoflanguages.employer_post_job_details}
                    breadcrumb={stringsoflanguages.employer_post_job_details}
                /> */}
                {/* PageHeader end */}
                {this.state.leadtype == 0 ?
                    <div className="ttm-row sidebar job-sidebar clearfix">
                        <div className="container">
                            {/* row */}
                            <div className="row">
                                <div className="col-lg-12 content-area">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="showing-result-block d-sm-flex align-items-center justify-content-between" style={{ display: 'flex' }}>
                                                <span className="showing-result">{stringsoflanguages.employer_post_job_details}</span>
                                                {this.state.appliedstatus == 11 && window.innerWidth >= 768 ?
                                                    <button className="apply-button ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" style={{ backgroundColor: 'green', border: 'none' }}
                                                        onClick={() => { this.setState({ showCallHRModal: true }); this.updatecallcount() }}>
                                                        Call HR</button>
                                                    : this.state.appliedstatus == 11 && window.innerWidth < 768 ?
                                                        <div onClick={this.handleClick()}> <a ref={input => this.inputElement = input} href={'tel:' + this.state.employermobileno} >
                                                            <button onClick={() => { this.setState({ showCallHRModal: true }); this.updatecallcount() }} className="apply-button ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" style={{ backgroundColor: 'green', borderColor: 'transparent' }}>
                                                                Call HR</button></a></div>
                                                        : null}
                                            </div>
                                        </div>
                                    </div>
                                    <Modal isOpen={this.state.showCallHRModal} className="JobList_Popup">
                                        <ModalHeader style={{ borderColor: 'white', justifyContent: 'flex-end', textAlign: 'flex-end', alignContent: 'flex-end', alignSelf: 'flex-end', alignItems: 'flex-end' }} toggle={this.closeHRModal} >
                                        </ModalHeader>
                                        <ModalBody>
                                            <div className="content-tab">
                                                <div className="text-center" style={{ marginTop: "-5px", textAlign: 'center', alignContent: 'center', alignSelf: 'center', alignItems: 'center' }}>
                                                    <center>
                                                        <img src="images/footer-logo.png" alt="Your Logo" />
                                                        <p style={{ fontSize: 12, color: 'black' }}>User Friendly & Trusted HR Manager</p></center>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: 'green',
                                                fontSize: '48px'
                                            }}>
                                                <span><img src="images/svg/phone-call.png" className="dz-img rounded-circle cls-load" alt="" /></span>
                                            </div>
                                            <p style={{
                                                textAlign: 'center',
                                                fontSize: '18px',
                                                fontWeight: 'bold',
                                            }}>{this.state.modalMessage}</p>
                                            <p style={{ textAlign: 'center' }}>Now you can call this employer @ <b><a href={'tel:' + this.state.employermobileno} >{this.state.employermobileno}</a></b></p>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <a href={'tel:' + this.state.employermobileno} >
                                                    <Button color="green" onClick={this.closeHRModal}>Call HR</Button></a>
                                            </div>
                                        </ModalBody>
                                    </Modal>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                            {/* featured-imagebox */}
                                            <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor m-0">
                                                <div className="featured-thumbnail" style={{ textAlign: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center', verticalAlign: 'top' }}>
                                                    {this.state.profileurlval ?
                                                        <img src={this.state.profileurlval} />
                                                        :
                                                        <img src="images/joblist_logo.jpg" />
                                                    }
                                                    <div className="job-time">
                                                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                                            {/* <span style={{ backgroundColor:'transparent',color:'red',fontSize:'11px' }}>{this.state.daysleft} days left</span> */}
                                                            {this.state.daysleft > this.state.jobalertdays && (
                                                                <span style={{ backgroundColor: 'transparent', color: 'red', fontSize: '12px' }}>{this.state.daysleft} days left</span>
                                                            )}
                                                            {this.state.daysleft <= this.state.jobalertdays && this.state.daysleft > 0 ?
                                                                <span style={{ backgroundColor: 'transparent', color: 'red', fontSize: '12px' }}>{this.state.daysleft} days left</span>
                                                                : this.state.daysleft == 0 ?
                                                                    <span style={{ backgroundColor: 'transparent', color: 'red', fontSize: '12px' }} >Expiring Today</span>
                                                                    : this.state.daysleft < 0 ?
                                                                        <span style={{ backgroundColor: 'transparent', color: 'red', fontSize: '12px' }} >Expired</span>
                                                                        :
                                                                        null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">
                                                        <h3><a href={'/Job_details'}>{this.state.jobrolename}</a></h3>
                                                    </div>
                                                    <div className="featured-details">
                                                        <div className="job-meta">
                                                            <div>
                                                                <span><i className="fa fa-map-marker-alt"></i>{this.state.locationname}</span>
                                                            </div>
                                                            <div>
                                                                <span><i className="fa fa-building"></i>{this.state.companyname}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            <div className="overview-box">
                                                <div className="title">
                                                    <h5>{stringsoflanguages.inform_txt_key}</h5>
                                                </div>
                                                <div className="desc">
                                                    <ul>
                                                        <li className="d-flex">
                                                            <div className='d-md-flex'>
                                                                <div>
                                                                    <b className="mr-5">{stringsoflanguages.home_jobtype} :</b>
                                                                </div>
                                                                {this.state.jobtypes.map((jobtypes, index) => {
                                                                    return (
                                                                        <span>{jobtypes.jobtypename} {index == this.state.jobtypes.length - 1 ? '' : ', '}</span>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                        </li>
                                                        <li className="d-flex">
                                                            <div className='d-md-flex'>
                                                                <div>
                                                                    <b className="mr-5">{stringsoflanguages.edu_loc}:</b>
                                                                </div>
                                                                <div>{this.state.locationname}</div>
                                                            </div>
                                                        </li>
                                                        <li className="d-flex">
                                                            <div className='d-md-flex'>
                                                                <div>
                                                                    <b className="mr-5">{stringsoflanguages.off_txt}:</b>
                                                                </div>
                                                                {this.state.isbestsalaryval == 1 ? 'Best Salary in the Industry' : this.state.minsalary + '-' + this.state.maxsalary}
                                                            </div>
                                                        </li>
                                                        <li className="d-flex">
                                                            <div className='d-md-flex'>
                                                                <div>
                                                                    <b className="mr-5"> {stringsoflanguages.employer_sub_posted}:</b>
                                                                </div>
                                                                {this.state.approveddate}
                                                            </div>
                                                        </li>
                                                        <li className="d-flex">
                                                            <div className='d-md-flex'>
                                                                <div>
                                                                    <b className="mr-5">{stringsoflanguages.employer_post_job_exp}:</b>
                                                                </div>
                                                                {this.state.isfresher == true || this.state.isfresher == 'true' ?
                                                                    'Fresher ' : ''
                                                                }
                                                                {this.state.experiencefrom >= 0 && this.state.experienceto > 0 ?
                                                                    this.state.experiencefrom + '-' + this.state.experienceto + ' Years' : ''}
                                                            </div>
                                                        </li>
                                                        <li className="d-flex">
                                                            <div className='d-md-flex'>
                                                                <div>
                                                                    <b className="mr-5"> {stringsoflanguages.employer_filter_industry}:</b>
                                                                </div>
                                                                {this.state.industryname}
                                                            </div>
                                                        </li>
                                                        <li className="d-flex">
                                                            <div className='d-md-flex'>
                                                                <div>
                                                                    <b className="mr-5">{stringsoflanguages.edu_qualification}:</b> {/* // Schooling   */}
                                                                </div>
                                                                {this.state.isanydegree == true || this.state.isanydegree == "true" ?
                                                                    <span>{stringsoflanguages.employer_any_Degree}</span>
                                                                    : ""
                                                                }
                                                                {this.state.schoolinglistval && this.state.schoolinglistval.length > 0 ?
                                                                    <span>{this.schooleducation_list()} </span> : null}
                                                                {/* // After Schooling with category with any qual   */}
                                                                {(this.state.afterschoolingisany === "true") ?
                                                                    <span>{this.category_list_view()}
                                                                        <span style={{ marginLeft: "10%" }}>Any Qualification ,</span>
                                                                    </span> :
                                                                    (this.state.afterschoolingisany === "false" && this.state.specializationany === "false") ?
                                                                        <span>{this.education_list()}</span> :
                                                                        this.state.afterschoolingisany === "false" && this.state.specializationany === "true" ?
                                                                            <span>{this.qualification_list_view()}  Any Specialization
                                                                            </span> : null
                                                                }
                                                            </div>
                                                        </li>
                                                        <li className="d-flex"><b className="mr-5">{stringsoflanguages.rl_no_of_opening} :</b>{this.state.noofopenings}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            {this.state.jobdescription != '' ?
                                                <div className="overview-box">
                                                    <div className="title">
                                                        <h5> {stringsoflanguages.gn_desc_job_desc}</h5>
                                                    </div>
                                                    <div className="desc">
                                                        <HTMLRenderer
                                                            html={this.state.jobdescription}
                                                        />
                                                    </div>
                                                </div>
                                                : ''
                                            }
                                            {this.state.skills != '' ?
                                                <div className="overview-box">
                                                    <div className="title">
                                                        <h5>{stringsoflanguages.txt_ski}</h5>
                                                    </div>
                                                    <div className="desc">
                                                        <ul className="ttm-list ttm-list-style-icon ttm-textcolor-darkgrey">
                                                            {this.state.skills.map((skills, index) => {
                                                                return (
                                                                    <li>
                                                                        <i className="ti ti-check-box"></i>
                                                                        <div className="ttm-list-li-content">{skills.skillname}</div>
                                                                    </li>
                                                                )
                                                            })}
                                                            {/* <li>
                                                        <i className="ti ti-check-box"></i>
                                                        <div className="ttm-list-li-content">HTML, CSS & Scss</div>
                                                    </li>
                                                    <li><i className="ti ti-check-box"></i>
                                                        <div className="ttm-list-li-content">Javascript</div>
                                                    </li>
                                                    <li><i className="ti ti-check-box"></i>
                                                        <div className="ttm-list-li-content">PHP</div>
                                                    </li>
                                                    <li><i className="ti ti-check-box"></i>
                                                        <div className="ttm-list-li-content">Photoshop</div>
                                                    </li>
                                                    <li><i className="ti ti-check-box"></i>
                                                        <div className="ttm-list-li-content">Illustrator</div>
                                                    </li>
                                                    <li>
                                                        <i className="ti ti-check-box"></i>
                                                        <div className="ttm-list-li-content">Wordpress & elementor</div>
                                                    </li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                                :
                                                ''
                                            }
                                            <div className="overview-box">
                                                <div className="title">
                                                    <h5>{stringsoflanguages.r1_group}</h5>
                                                </div>
                                                <div className="desc">
                                                    <ul className="ttm-list ttm-list-style-icon ttm-textcolor-darkgrey">
                                                        <li className="d-flex"><b className="mr-5"> {stringsoflanguages.employer_personal_marital}:</b>{this.state.maritalisanyval == true || this.state.maritalisanyval == 'true' ? 'Any' : this.state.maritalstatusval}</li>
                                                        <li className="d-flex"><b className="mr-5">{stringsoflanguages.personal_gender}:</b>{this.state.genderisanyval == true || this.state.genderisanyval == 'true' ? 'Any' : this.state.genderlistval}</li>
                                                        <li className="d-flex"><b className="mr-5">{stringsoflanguages.rl_age}:</b>{this.state.agecriteria_isanyal == true || this.state.agecriteria_isanyal == 'true' ? 'Any' : this.state.agecriteria_fromval + '-' + this.state.agecriteria_toval}</li>
                                                        <li className="d-flex"><b className="mr-5">{stringsoflanguages.personal_diff}:</b>{this.state.differentlyabledval && this.state.differentlyabledval === 1 ? 'Only Differently Abled' : this.state.vardifferently_abled === -1 ? 'Include' : 'No'}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* {this.state.employeecode == 0 && (
                                     <div className="overview-box" style={{ textAlign: 'right' }}>
                                 <div className="title">
                         {this.state.appliedstatus == 0 ? (
                            <button
                      className="apply-button ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                         onClick={() => this.applynow()}
                             >
                             Apply Now
                              </button>
                             ) : null}
                              </div>
                                 </div>
                                          )}
                                           */}
                                            {this.state.appliedstatus == 0 ?
                                                <div className="overview-box stickyButton" style={{ textAlign: 'right' }}>
                                                    <div className="title">
                                                        <button
                                                            className="apply-button ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                                            onClick={() => this.applynow()}
                                                        >
                                                            {stringsoflanguages.rl_apply_now}
                                                        </button></div></div> : null}
                                            <Modal isOpen={this.state.showModal} className='JobList_Popup'>
                                                <ModalHeader style={{ borderColor: 'white', display: 'flex', justifyContent: 'center' }} toggle={this.closeModal} >
                                                    <div className="content-tab">
                                                        <div className="text-center" style={{ marginTop: "-5px", marginLeft: "75px" }}>
                                                            <img src="images/footer-logo.png" alt="Your Logo" />
                                                            <p style={{ fontSize: 12, color: 'black', marginLeft: "73px" }}>User Friendly & Trusted HR Manager</p>
                                                        </div>
                                                    </div>
                                                </ModalHeader>
                                                <ModalBody>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        color: 'green',
                                                        fontSize: '48px'
                                                    }}>
                                                        <span><img src="images/svg/accept.png" className="dz-img rounded-circle cls-load" alt="" /></span>
                                                    </div>
                                                    <p style={{
                                                        textAlign: 'center',
                                                        fontSize: '18px',
                                                        fontWeight: 'bold',
                                                    }}>{this.state.modalMessage}</p>
                                                    <p style={{ textAlign: 'center' }}>Now you can call this employer @ <b> <a onClick={() => this.updatecallcount()} href={'tel:' + this.state.employermobileno} >{this.state.employermobileno}</a></b></p>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <a href={'tel:' + this.state.employermobileno} >
                                                            <Button onClick={() => this.updatecallcount()} color="green">Call HR</Button></a>
                                                    </div>
                                                </ModalBody>
                                            </Modal>
                                            <div className="overview-box mb-60">
                                                {/* <div className="title">
                                                <div className="d-sm-flex justify-content-between mb_10">
                                                    <h5 className="mb-10">Share Job :</h5>
                                                    <div className="social-icons circle mb-10">
                                                        <ul className="list-inline">
                                                            <li><a href="#facebook" rel="noopener" aria-label="facebook"><i className="ti ti-facebook"></i></a></li>
                                                            <li><a href="#twitter" rel="noopener" aria-label="twitter"><i className="ti ti-twitter-alt"></i></a></li>
                                                            <li><a href="#instagram" rel="noopener" aria-label="instagram"><i className="ti ti-instagram"></i></a></li>
                                                            <li><a href="#pinterest" rel="noopener" aria-label="pinterest"><i className="ti ti-pinterest"></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                    <div className="col-lg-12">
                                        <h5>Related Jobs :</h5>
                                    </div>
                                </div>
                                <Slider className="row slick_slider slick-arrows-style2 mb_15" {...slick_slider} vertical={true} slidesToShow={1} rows={2} arrows={true}>
                                    {this.jobpost_list_view()}
                                </Slider>row end */}
                                </div>
                            </div>{/* row end */}
                        </div>
                    </div>
                    : null}
                {/* <Footer /> */}
            </div>
        )
    }
}
export default GoogleApiWrapper({
    apiKey: 'AIzaSyAcNVIX0ZSTMYUOGG7rgFZhKc0AWQ8xpt4'
})(Job_details)
// export default Job_details;
import React, { Component, Fragment } from 'react';
import Header from '../components/layout/Header';
import axios from 'axios';
import { APIURL, COMMON } from '../config';
import stringsoflanguages from '../Stringsoflanguage'
import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Button } from "reactstrap"
import { ChipSet, Chip } from "@material/react-chips";
import { Redirect } from 'react-router-dom';
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import avatar1 from '../assets/img/gender/Female.jpg';
import avatar2 from '../assets/img/gender/Male.jpg';
import avatar3 from '../assets/img/gender/Others.jpg';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import LoadingOverlay from 'react-loading-overlay'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { generateRefreshToken } from "../serviceworker";
import Checkbox from "../components/@vuexy/checkbox/CheckboxesVuexy";
import Resizer from "react-image-file-resizer";
import Cropper from 'react-easy-crop'
import getCroppedImg from './cropImage'
let error_log = require('../error_log');
export class Candidate_details extends Component {
  constructor() {
    super();
    this.state = {
      uploadfilename: '',
      imageSrc: '',
      isupload: false,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1,
      croppedAreaPixels: {},
      deletealert: false,
      leadtype: 0,
      currentjobrolecode: 0,
      tempjobrolecode: 0,
      currentjobroleflag: false,
      is_auth: true,
      name: "React",
      designation: '',
      minsalary: '',
      ipurl: APIURL,
      employeecode: 0,
      ipaddress: '',
      filter_json_result: {},
      editlanguage: [],
      experience: [],
      maxDateofbirth: new Date("01-01-" + (new Date().getFullYear() - 18)),
      languagecode: 0,
      statecode: 0,
      profileData: {},
      showModal: false,
      showJobRoleModal: false,
      gender: '',
      genderID: 0,
      maritalname: '',
      languagename: '',
      maritalID: 0,
      languageID: 0,
      genderChips: [],
      languageChips: [],
      maritalChips: [],
      educationChips: [],
      workexperienceChips: [],
      dpFilterdJobFunction: [],
      experienceChips: [],
      selectedRoleSkilsArray: [],
      selectedJobRoleIndex: 0,
      showJobFunctionDiv: false,
      showSkillsDiv: false,
      age: '',
      contact: '',
      MAX_IMAGE_SIZE: 1000000,
      location: '',
      showLocation: '',
      jobTypeArray: [],
      showCandidateLocation: "",
      updatedGender: '',
      updatedMarital: '',
      updatedLanguage: '',
      updatedname: '',
      updatedsalary: '',
      updatedminsalary: '',
      updatedAge: '',
      uploadImage: '',
      locationID: 0,
      updatedContact: '',
      updatedLocation: '',
      selectedGender: [],
      selectedMarital: [],
      candidateImage: '',
      selectJobRoleArray: [],
      selectedEducation: [],
      selectedWorkExperience: [],
      selectedLanguageKnown: [],
      filteredJobType: [],
      selectedJobType: [],
      GetJobSkill: [],
      dpdistrict: [],
      selectedJobRoles: [],
      totalexperience: [],
      allJobroleArray: [],
      selectedDistrict: null,
      dpdistrictValue: '',
      handleJobRoleSelected: [],
      dpstateValue: '',
      districtCode: '',
      candidateDistrictValue: "0",
      candidateStateValue: 0,
      skillList: [],
      dpjobfunction: [],
      frestatus: '',
      dpjobrole: [],
      enableEditIcon: false,
      Rejected: 9,
      totalPercentage: 0,
      showDeletePopup: false,
      txtJobFunction: "",
      dob: '',
      invitealert: false,
      deleteJobRole: [],
      isAddJobRole: false,
      showjobFunction: ""
    };
    this.setState({
      maxDateofbirth: this.state.maxDateofbirth = (new Date().getFullYear() - 18) + "-01-01"
    })
    this.onChangeValue = this.onChangeValue.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.editcloseModal = this.editcloseModal.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    if (!localStorage.getItem("mobileNumber") || localStorage.getItem("mobileNumber") == null) {
      this.setState({ is_auth: this.state.is_auth = false });
    }
  }
  number_only = (event) => {
    try {
      const regex = /^[0-9]*$/;
      const input = event.target.value + event.key;
      if (!regex.test(input) || input.length > 5) {
        event.preventDefault();
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "number_only", 0, error.toString());
    }
  };
  drawImage = () => {
    try {
      const { croppedAreaPixels } = this.state;
      this.ctx.drawImage(this.image, croppedAreaPixels.x, croppedAreaPixels.y, croppedAreaPixels.width, croppedAreaPixels.height, 0, 0, croppedAreaPixels.width, croppedAreaPixels.height);
      this.setState({ candidateImage: this.state.candidateImage = this.state.image })
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "drawImage", 0, error.toString());
    }
  }
  onCropChange = crop => {
    this.setState({ crop: this.state.crop = crop })
  }
  onZoomChange = zoom => {
    this.setState({ zoom: this.state.zoom = zoom })
  }
  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedAreaPixels
    })
  }
  showCroppedImage = async () => {
    this.setState({ isActive: this.state.isActive = true })
    try {
      const croppedImage = await getCroppedImg(
        this.state.imageSrc,
        this.state.croppedAreaPixels,
        0,
        0
      )
      this.setState({ candidateImage: this.state.candidateImage = croppedImage })
      this.createImage();
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "showCroppedImage", 0, error.toString());
    }
  }
  onChangeValue(event) {
  }
  openjobRoleModal(comeFor = "") {
    try {
      this.setState({
        showJobRoleModal: true,
        showJobFunctionDiv: true,
        showSkillsDiv: false,
        selectedJobRoles: this.state.selectedJobRoles = [],
        isAddJobRole: this.state.isAddJobRole = true,
        selectedRoleSkilsArray: this.state.selectedRoleSkilsArray = [],
        txtJobFunction: this.state.txtJobFunction = "",
        selectJobRoleArray: this.state.selectJobRoleArray = [],
        showjobFunction: this.state.showjobFunction = "",
        dpFilterdJobFunction: this.state.dpFilterdJobFunction = []
      });
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "openjobRoleModal", 0, error.toString());
    }
  }
  closejobRoleModal(comeFor = "") {
    try {
      this.setState({
        showJobRoleModal: false,
        showJobFunctionDiv: false,
        showSkillsDiv: false,
        isAddJobRole: this.state.isAddJobRole = false,
        showjobFunction: this.state.showjobFunction = ""
      });
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "closejobRoleModal", 0, error.toString());
    }
  }
  editJobTitle(event) {
    try {
      if (event.jobrolecode != 0) {
        this.setState({
          showJobFunctionDiv: this.state.showJobFunctionDiv = false,
          isAddJobRole: this.state.isAddJobRole = false
        });
        if (event.jobrolecode != 0) {
          let SelectedJobRolesArray = event.jobrolecode
          var jobrole_list = localStorage.getItem("filter_jobrole_list") != null ? JSON.parse(localStorage.getItem("filter_jobrole_list")) : [];
          let jobRoleFind = jobrole_list.find(ele => ele.jobrolecode == SelectedJobRolesArray)
          if (jobRoleFind) {
            this.setState({
              selectedJobRoles: this.state.selectedJobRoles = [jobRoleFind]
            })
          }
        }
        this.state.selectedJobRoles.map(e => {
          var skills = this.state.skillList.filter((el) => el.jobfunctioncode == e.jobfunctioncode && el.jobrolecode == e.jobrolecode)
          return e['skills'] = skills;
        })
        this.setState({ showSkillsDiv: this.state.showSkillsDiv = true });
        this.setState({
          selectedJobRoleIndex: this.state.selectedJobRoleIndex = 0,
        });
      }
      this.state.selectedJobRoles.map(e => {
        if (e.jobrolecode == event.jobrolecode) {
          return e['selectedSkills'] = event.skillcode
        }
      })
      // Set Existing skill in job title popup 
      this.setState({
        selectedRoleSkilsArray:
          event.skillcode.length > 0 ? event.skillcode : []
      })
      //set Experience in Job Title popup
      this.state.selectedJobRoles.map(e => {
        if (e.jobrolecode == event.jobrolecode) {
          var experiencechip = this.state.experienceChips.find((val) => val.value == event.expvalue)
          e['currentworkstatus'] = event.currentworkstatus
          return e['selectedExperience'] = [experiencechip.experiencecode]
        }
      })
      this.setState({
        selectedJobRoles:
          this.state.selectedJobRoles
      })
      this.setState({
        showJobRoleModal: true,
        showJobFunctionDiv: false
      })
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "editJobTitle", 0, error.toString());
    }
  }
  indian_standard_amount(amt) {
    try {
      var x = parseInt(amt);
      x = x.toString();
      var lastThree = x.substring(x.length - 3);
      var otherNumbers = x.substring(0, x.length - 3);
      if (otherNumbers != '')
        lastThree = ',' + lastThree;
      var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
      return res;
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "indian_standard_amount", 0, error.toString());
    }
  }
  deleteJobTitle(DeleteData) {
    try {
      if (DeleteData.currentworkstatus == 1) {
        this.setState({ deletealert: true })
      }
      else {
        this.setState({ invitealert: true, deleteJobRole: this.state.deleteJobRole = DeleteData })
      }
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "deleteJobTitle", 0, error.toString());
    }
  }
  selectJobRoleSkills(item, selectedChipIds) {
    try {
      if (selectedChipIds && selectedChipIds.length > 6) {
        toast.error(stringsoflanguages.YouCanselectSkills, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo-bar'
        });
        return false;
      } else {
        this.state.selectedJobRoles.map(e => {
          if (e.jobrolecode == item.jobrolecode) {
            return e['selectedSkills'] = selectedChipIds
          }
        })
        this.setState({
          selectedJobRoles:
            this.state.selectedJobRoles
        })
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "selectJobRoleSkills", 0, error.toString());
    }
  }
  openModal() {
    try {
      const { genderChips, gender, educationChips, education, workexperienceChips, workexperience, maritalChips, maritalname, languageChips, languagename } = this.state;
      const selectedGenderObj = genderChips.find((chip) => chip.gendername === gender);
      const selectedMaritalObj = maritalChips.find((chip) => chip.maritalname === maritalname);
      const selectedLanguageObj = languageChips.find((chip) => chip.languagename === languagename);
      const selectedEducationObj = educationChips.find((chip) => chip.educationcategorycode === this.state.education);
      const selectedWorkExperienceObj = workexperienceChips.find((chip) => chip.id === String(this.state.frestatus));
      const dateOfBirthMillis = this.state.dob;
      const dateOfBirth = dateOfBirthMillis == '' ? '' : new Date(dateOfBirthMillis).toISOString().split('T')[0];
      const date_Birth = new Date(dateOfBirth)
      const stateCodeval = this.state.statecode
      this.setState({
        showModal: true,
        updateddate: date_Birth,
        updatedGender: gender,
        updatedMarital: maritalname,
        updatedLanguage: languagename,
        updatedAge: this.state.employeeage,
        updatedname: this.state.profilename,
        updatedsalary: this.state.salary,
        updatedminsalary: this.state.minsalary,
        selectedGender: selectedGenderObj ? [selectedGenderObj.gendercode] : [],
        selectedMarital: selectedMaritalObj ? [selectedMaritalObj.maritalcode] : [],
        selectedLanguageKnown: selectedLanguageObj ? [selectedLanguageObj.languagecode] : [],
        selectedEducation: selectedEducationObj ? [selectedEducationObj.educationcategorycode] : [],
        selectedWorkExperience: selectedWorkExperienceObj ? [selectedWorkExperienceObj.id] : [],
        location: this.state.location = `${this.state.locationID}-${stateCodeval}`,
        dpdistrictValue: Number(this.state.locationID),
        dpstateValue: Number(stateCodeval),
      });
      this.setState({ showModal: true })
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "openModal", 0, error.toString());
    }
  }
  selectJobRole(e, isCreate = false) {
    try {
      if (!isCreate) {
        this.setState({
          selectJobRoleArray:
            (this.state.selectJobRoleArray =
              this.state.selectJobRoleArray.filter(el => el != e))
        })
      }
      if (this.state.selectJobRoleArray.length < 4 && isCreate) {
        if (this.state.dpjobrole && this.state.dpjobrole.findIndex(f => f['jobrolecode'] == e) == -1) {
          let jobTitle = this.state.allJobroleArray.find(f => f['jobrolecode'] == e);
          if (jobTitle && jobTitle['jobrolecode']) {
            this.state.dpjobrole.push(jobTitle);
            this.setState({
              dpjobrole: this.state.dpjobrole
            })
          }
        }
        let jobRoleArray = this.state.selectJobRoleArray
        jobRoleArray.push(e)
        this.setState({
          selectJobRoleArray:
            (this.state.selectJobRoleArray = jobRoleArray)
        })
      } else if (isCreate) {
        toast.error(stringsoflanguages.YouCanSelectJobRoles, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo-bar'
        });
        return false;
      } else {
        return false;
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "selectJobRole", 0, error.toString());
    }
  }
  goToSkill(e) {
    try {
      e.preventDefault();
      if (this.state.selectJobRoleArray.length > 0) {
        this.setState({
          showJobFunctionDiv:
            this.state.showJobFunctionDiv = false,
        });
        if (this.state.selectedJobRoles.length > 0) {
          let SelectedJobRolesArray = this.state.selectJobRoleArray
          for (var i = 0; i < SelectedJobRolesArray.length; i++) {
            if (!this.state.selectedJobRoles.find((ele => ele.jobrolecode == SelectedJobRolesArray[i]))) {
              let jobRoleFind = this.state.dpjobrole.find(ele => ele.jobrolecode == SelectedJobRolesArray[i])
              if (jobRoleFind) {
                let currentRoles = this.state.selectedJobRoles;
                currentRoles.push(jobRoleFind)
                this.setState({
                  selectedJobRoles: (this.state.selectedJobRoles = currentRoles)
                })
              }
            }
          }
          let result = [];
          for (var j = 0; j < this.state.selectedJobRoles.length; j++) {
            if (this.state.selectJobRoleArray.find((ele => ele == this.state.selectedJobRoles[j].jobrolecode))) {
              result.push(this.state.selectedJobRoles[j])
            }
          }
          this.setState({
            selectedJobRoles: (this.state.selectedJobRoles = result)
          })
        } else {
          let SelectedRoles = this.state.selectJobRoleArray.map(e => {
            let jobRoleFind = this.state.dpjobrole.find(ele => ele.jobrolecode == e)
            return e = jobRoleFind;
          })
          this.setState({
            selectedJobRoles: (this.state.selectedJobRoles = SelectedRoles),
            selectedRoleSkilsArray: (this.state.selectedRoleSkilsArray = [])
          })
        }
        if (this.state.selectedJobRoles.length > 0) {
          this.setState({
            selectedJobRoleIndex: (this.state.selectedJobRoleIndex = 0),
          });
          this.state.selectedJobRoles.map(e => {
            var skills = this.state.skillList.filter((el) => el.jobfunctioncode == e.jobfunctioncode && el.jobrolecode == e.jobrolecode)
            return e['skills'] = skills;
          })
          this.setState({
            selectedRoleSkilsArray: (this.state.selectedRoleSkilsArray = this.state.selectedJobRoles && this.state.selectedJobRoles[0] && this.state.selectedJobRoles[0]['selectedSkills'] ? this.state.selectedJobRoles[0]['selectedSkills'] : [])
          })
        }
        this.setState({ showSkillsDiv: this.state.showSkillsDiv = true });
        this.setState({
          selectedJobRoleIndex: (this.state.selectedJobRoleIndex = 0),
        });
      }
      else {
        toast.error(stringsoflanguages.err_preference_jobrole_required, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo-bar'
        });
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "goToSkill", 0, error.toString());
    }
  }
  gotoPrevious() {
    this.setState({
      showJobFunctionDiv: (this.state.showJobFunctionDiv = true),
      showSkillsDiv: (this.state.showJobFunctionDiv = false)
    });
  }
  updateJobRole(item) {
    try {
      if (item && item[0]['selectedExperience'] == null || item[0]['selectedExperience'].length == 0) {
        toast.error(stringsoflanguages.ChooseUrExp, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo-bar'
        });
        return false
      } else if (item && item[0]['selectedSkills'] == null || item[0]['selectedSkills'].length == 0) {
        toast.error(stringsoflanguages.chooseUrSkill, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo-bar'
        });
        return false
      } else {
        var totalexp = 0;
        var selJobRoles = this.state.selectedJobRoles;
        var getJobSkills = this.state.GetJobSkill;
        var experienceArr = [], jobFunctionRoleArr = [];
        var jobRoleArr = [];
        var skillsArr = [];
        var currentjobrolecodetemp = 0;
        for (var i = 0; i < selJobRoles.length; i++) {
          var selExp = selJobRoles[i]['selectedExperience'] != null && selJobRoles[i]['selectedExperience'].length > 0 ? selJobRoles[i]['selectedExperience'][0] : 0;
          if (selExp) {
            var expJson = this.state.experienceChips.filter(ex => ex.experiencecode == selExp);
            if (expJson.length > 0) {
              currentjobrolecodetemp = selJobRoles[i]['jobrolecode']
              var skillArr = [];
              for (var j = 0; j < selJobRoles[i].selectedSkills.length; j++) {
                var data = { "skillcode": selJobRoles[i].selectedSkills[j] }
                skillArr.push(data)
              }
              var exp = { "experiencecode": i + 1, 'skills': skillArr, "designationname": selJobRoles[i]['jobrolename'], "jobrolecode": selJobRoles[i]['jobrolecode'], "jobfunctioncode": selJobRoles[i]['jobfunctioncode'], "expid": expJson[0]['experiencecode'], "currentworkstatus": (this.state.tempjobrolecode == selJobRoles[i]['jobrolecode']) ? 1 : 0 }
              experienceArr.push(exp);
            }
          }
          if (selJobRoles[i]['jobfunctioncode'])
            jobFunctionRoleArr.push({ "jobfunctioncode": selJobRoles[i]['jobfunctioncode'] })
          var jobRoleJs = { "jobrolecode": selJobRoles[i]['jobrolecode'] };
          jobRoleArr.push(jobRoleJs);
          skillsArr = [];
          var selSkills = selJobRoles[i]['selectedSkills']
          if (selSkills.length > 0) {
            for (var j = 0; j < selSkills.length; j++) {
              var skillJs = selJobRoles[i]['skills'].filter(e => e.skillcode == selSkills[j]);
              if (skillJs.length > 0) {
                skillJs[0]['currentjobfunction'] = (this.state.tempjobrolecode == selJobRoles[i]['jobrolecode']) ? 1 : 0;
                skillsArr.push(skillJs[0]);
              }
            }
          }
        }
        for (var j = 0; j < getJobSkills.length; j++) {
          if (!this.state.isAddJobRole && getJobSkills[j]['jobrolecode'] == selJobRoles[0]['jobrolecode']) {
            continue;
          }
          var selExp = getJobSkills[j]['expvalue'] != null ? getJobSkills[j]['expvalue'] : 0;
          if (selExp) {
            var expJson = this.state.experienceChips.filter(ex => ex.value == selExp);
            var skillsArr1 = [];
            for (var k = 0; k < getJobSkills[j]['skillcode'].length; k++) {
              var data = { "skillcode": getJobSkills[j]['skillcode'][k] }
              skillsArr1.push(data)
              skillsArr.push(data)
            }
            if (expJson.length > 0) {
              var exp = { "experiencecode": experienceArr.length + 1, "jobrolecode": getJobSkills[j]['jobrolecode'], "jobfunctioncode": getJobSkills[j]['jobfunctioncode'], "designationname": getJobSkills[j]['jobrolename'], "expid": expJson[0]['experiencecode'], 'skills': skillsArr1, "currentworkstatus": (this.state.tempjobrolecode == getJobSkills[j]['jobrolecode']) ? 1 : 0 }
              experienceArr.push(exp);
            }
          }
          if (getJobSkills[j]['jobfunctioncode'])
            jobFunctionRoleArr.push({ "jobfunctioncode": getJobSkills[j]['jobfunctioncode'] })
          var jobRoleJs = { "jobrolecode": getJobSkills[j]['jobrolecode'] };
          jobRoleArr.push(jobRoleJs);
          var selSkills = getJobSkills[j]['skillcode']
          if (selSkills.length > 0) {
            for (var k = 0; k < selSkills.length; k++) {
              var skillJs = this.state.skillList.filter(e => e.skillcode == selSkills[k] && e.jobrolecode == getJobSkills[j]['jobrolecode']);
              if (skillJs.length > 0) {
                skillJs[0]['currentjobfunction'] = (this.state.tempjobrolecode == skillJs[0]['jobrolecode']) ? 1 : 0;
                skillsArr.push(skillJs[0]);
              }
            }
          }
        }
        var candidateSkill = {
          "mobileno": this.state.contact,
          "jobfunction": jobFunctionRoleArr,
          "jobrole": jobRoleArr,
          "experienceinfo": experienceArr,
          "skills": skillsArr,
        }
        this.CandidateSkillUpdates(candidateSkill);
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "updateJobRole", 0, error.toString());
    }
  }
  CandidateSkillUpdates(candidate_skill) {
    var accessToken = localStorage.getItem('accessToken');
    try {
      this.setState({
        isActive: (this.state.isActive = true)
      })
      axios({
        method: 'Post',
        url: this.state.ipurl + 'employeeportal/jobroleupdate/',
        params: {
          employeecode: this.state.employeecode,
          deviceip: this.state.ipaddress,
          languagecode: this.state.languagecode,
          isleadtype: 0
        },
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        data: candidate_skill,
      }).then(async response => {
        if (response.data.status == 401) {
          await generateRefreshToken().then((result) => {
            if (result == 1) {
              this.CandidateSkillUpdates(candidate_skill);
            }
            else {
              // alert('Unauthorized')
            }
          })
        }
        else {
          this.setState({ isActive: this.state.isActive = false })
          if (response.data.hasOwnProperty('employee_json_result')) {
            this.state.varstatuscode = response.data.employee_json_result.hasOwnProperty('varstatuscode') ? response.data.employee_json_result.varstatuscode : 13;
            if (this.state.varstatuscode == 2) {
              if (this.state.isAddJobRole)
                toast.success(stringsoflanguages.employer_saved_success, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: 'toast-success-container toast-success-container-after'
                });
              else
                toast.success(stringsoflanguages.updated_success, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: 'toast-success-container toast-success-container-after'
                });
              this.closejobRoleModal();
              this.loadProfileData();
            } else {
              toast.error(response.data.employee_json_result.responsestring, {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo-bar'
              });
            }
          }
          else {
            toast.error(response.data.employee_json_result.responsestring, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'foo-bar'
            });
          }
        }
      })
    }
    catch (error) {
      this.setState({ isActive: this.state.isActive = false })
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "CandidateSkillUpdates", 0, error.toString());
    }
  }
  selectJobRoleExperience(item, selectedChipIds) {
    try {
      this.state.selectedJobRoles.map(e => {
        if (e.jobrolecode == item.jobrolecode) {
          return e['selectedExperience'] = selectedChipIds
        }
      })
      this.setState({
        selectedJobRoles:
          this.state.selectedJobRoles
      })
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "selectJobRoleExperience", 0, error.toString());
    }
  }
  closeModal() {
    this.setState({
      showModal: false,
    });
  }
  handleUpdate() {
    try {
      if (this.state.contact != '' && this.state.updatedname != '' && this.state.selectedGender != [] && this.state.updateddate != '' &&
        this.state.selectedWorkExperience.length > 0 && this.state.updatedsalary != '' && this.state.selectedEducation.length > 0 && this.state.selectedJobType.length > 0 && this.state.candidateDistrictValue !== "0" && this.state.dpdistrictValue !== "0") {
        this.saveCandidateProfile()
      }
      else {
        toast.error(stringsoflanguages.PleaseSelectAllField, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo-bar'
        });
      }
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "handleUpdate", 0, error.toString());
    }
  }
  saveCandidateProfile() {
    try {
      var accessToken = localStorage.getItem('accessToken');
      var newDate = new Date(this.state.updateddate);
      var getLocationCode = this.state.location == '' ? '' : this.state.location;
      var getLocateCode = this.state.location.split('-')
      getLocationCode = Number(getLocateCode[0]);
      var getSchooling = this.state.educationChips.find((chip) => chip.educationcategorycode === this.state.selectedEducation[0]);
      var schooling = getSchooling.typecode == 1 ? this.setSchoolingArray(getSchooling) : [];
      var afterschoolArray = getSchooling.typecode == 2 ? this.setAfterSchoolingArray(getSchooling) : [];
      var jobTypeArr = []
      if (this.state.selectedJobType.length > 0) {
        var jobType = this.state.selectedJobType
        for (var i = 0; i < jobType.length; i++) {
          jobTypeArr.push({
            employementtypecode: jobType[i]
          })
        }
      }
      var employeeProfileJson = {
        "mobileno": this.state.contact,
        "employeename": this.state.updatedname,
        "personalinfo":
        {
          "employeefullname": this.state.updatedname,
          "gender": this.state.selectedGender && this.state.selectedGender.length > 0 ? this.state.selectedGender[0] : 0,
          "dateofbirth": newDate.getTime(),
          "maritalstatus": this.state.selectedMarital && this.state.selectedMarital.length > 0 ? this.state.selectedMarital[0] : -1,
          "languagesknown": this.state.languagesknown && this.state.languagesknown.length > 0 ? this.state.languagesknown : -1
        },
        "contactinfo": {
          "mobileno": localStorage.getItem('mobileNumber'),
          "statecode": this.state.candidateStateValue,
          "districtcode": this.state.candidateDistrictValue,
        },
        "fresherstatus": Number(this.state.selectedWorkExperience[0]),
        "minsalary": this.state.updatedminsalary,
        "maxsalary": this.state.updatedsalary,
        "employementtype": jobTypeArr,
        "locationcode": getLocationCode,
        "schooling": schooling.schoolingcode == 1 ? [schooling] : [],
        "afterschooling": afterschoolArray.afterschoolingcode == 2 ? [afterschoolArray] : [],
      }
      try {
        this.setState({ isActive: this.state.isActive = true })
        axios({
          method: 'Post',
          url: this.state.ipurl + 'employeeportal/personalupdate/?',
          params: {
            employeecode: this.state.employeecode,
            deviceip: this.state.ipaddress,
            languagecode: this.state.languagecode,
            isleadtype: 0
          },
          headers: {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
          },
          data: employeeProfileJson,
        }).then(async response => {
          if (response.data.status == 401) {
            await generateRefreshToken().then((result) => {
              if (result == 1) {
                this.saveCandidateProfile();
              }
              else {
                // alert('Unauthorized')
              }
            })
          }
          else {
            this.setState({ isActive: this.state.isActive = false })
            if (response.data.hasOwnProperty('employee_json_result')) {
              this.state.varstatuscode = response.data.employee_json_result.hasOwnProperty('varstatuscode') ? response.data.employee_json_result.varstatuscode : 13;
              if (this.state.varstatuscode == 2) {
                this.loadProfileData();
                this.setState({
                  showModal: false
                });
                toast.success(stringsoflanguages.notifi_submit_toast, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: 'toast-success-container toast-success-container-after'
                });
              }
              else {
                toast.error(response.data.employee_json_result.responsestring, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: 'foo-bar'
                });
              }
            }
          }
        })
      }
      catch (e) {
        this.setState({ isActive: this.state.isActive = false })
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "saveCandidateProfile", 0, error.toString());
    }
  }
  setAfterSchoolingArray(value) {
    var data = { "afterschoolingcode": value.typecode, "educationcategorycode": value.educationcategorycode };
    return data
  }
  setSchoolingArray(values) {
    var sData = { "schoolingcode": values.typecode, "qualificationcode": values.educationcategorycode };
    return sData
  }
  getUploadedProfileImage = async e => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        this.setState({ uploadfilename: this.state.uploadfilename = e.target.files[0].name })
        const imageDataUrl = await this.readFile(e.target.files[0])
        this.setState({
          imageSrc: imageDataUrl,
          crop: { x: 0, y: 0 },
          zoom: 1,
        })
        this.setState({ isupload: this.state.isupload = true })
      }
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "getUploadedProfileImage", 0, error.toString());
    }
  }
  readFile(file) {
    try {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
      })
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "readFile", 0, error.toString());
    }
  }
  resizeFile = (file) => new Promise(resolve => {
    try {
      Resizer.imageFileResizer(file, 200, 200, 'JPEG', 100, 0,
        uri => {
          resolve(uri);
        }, 'base64');
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "resizeFile", 0, error.toString());
    }
  });
  async createImage() {
    try {
      this.setState({ isActive: this.state.isActive = true })
      const croppedImage = await getCroppedImg(
        this.state.imageSrc,
        this.state.croppedAreaPixels,
        0,
        0
      )
      let binary = atob(croppedImage.split(',')[1])
      let array = []
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }
      let blobData = new Blob([new Uint8Array(array)], { type: 'image/jpeg' })
      var varfilename = this.state.uploadfilename;
      var cur_time = new Date().getTime();
      var file_name = 'profile_pic_' + this.state.employeecode + '_' + cur_time + '.' + varfilename.split('.').pop()
      var data_body = {
        "bucketName": process.env.REACT_APP_PROFILE_IMAGE_UPLOAD,
        "fileName": process.env.REACT_APP_PROFILE_IMAGE_FOLDER_UPLOAD + '/' + file_name
      }
      var accessToken = localStorage.getItem('accessToken');
      axios({
        method: 'Post',
        url: this.state.ipurl + 'getLamdaUrlDetails',
        crossdomain: true,
        data: data_body,
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
      })
        .then(async (URLResponse) => {
          this.setState({ isActive: this.state.isActive = false })
          if (URLResponse.data.status == 401) {
            await generateRefreshToken().then((result) => {
              if (result == 1) {
                this.createImage()
              }
              else {
                // alert('Unauthorized')
              }
            })
          }
          else {
            const result = await fetch(URLResponse.data?.urlresponse?.uploadURL, {
              method: 'PUT',
              body: blobData
            }).then(resp => {
              const uploadUrl = resp.url.split('?');
              if (uploadUrl[0]) {
                let dataparams = {
                  employeecode: this.state.employeecode,
                  ipaddress: this.state.ipaddress,
                  imageurl: uploadUrl[0]
                }
                this.setState({ isActive: this.state.isActive = true })
                axios({
                  method: "Post",
                  url: this.state.ipurl + "employee/imagesavevialambda/",
                  crossdomain: true,
                  params: dataparams,
                  headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json'
                  },
                })
                  .then(async (response) => {
                    this.setState({ isActive: this.state.isActive = false })
                    if (response.data.status == 401) {
                      await generateRefreshToken().then((result) => {
                        if (result == 1) {
                          this.createImage()
                        }
                        else {
                          // alert('Unauthorized')
                        }
                      })
                    }
                    else {
                      var result = response.data.employee_json_result.varstatuscode;
                      if (result === 2) {
                        toast.success(stringsoflanguages.employer_updated_success, {
                          position: toast.POSITION.TOP_RIGHT,
                          className: 'toast-success-container toast-success-container-after'
                        });
                        this.setState({ isupload: this.state.isupload = false });
                        this.loadProfileData()
                      }
                      else {
                        if (response.data.employee_json_result['responsestring'])
                          toast.error(stringsoflanguages.image_upload_size_msg, {
                            position: toast.POSITION.TOP_RIGHT,
                            className: 'foo-bar'
                          });
                        this.setState({ isActive: this.state.isActive = false });
                      }
                    }
                  })
                  .catch((error) => {
                    this.setState({ isActive: this.state.isActive = false });
                    error_log.ERRORLOG(
                      this.state.usercode,
                      this.state.version,
                      "Candidate_details",
                      "getUploadedProfileImage",
                      0,
                      error.toString()
                    );
                  });
              }
            }).catch((error) => {
              this.setState({ isActive: this.state.isActive = false });
              error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "createImage", 0, error.toString());
            });
          }
        }).catch((error) => {
          this.setState({ isActive: this.state.isActive = false });
          error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "createImage", 0, error.toString());
        });
    } catch (error) {
      this.setState({ isActive: this.state.isActive = false });
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "createImage", 0, error.toString());
    }
  }
  call_job_role_function() {
    try {
      window.fbq("track", "Website", {
        fb_ad_function: "call_job_role_function",
        fb_ad_type: "Job list",
        fb_ad_loginuser: COMMON.employee_name,
      });
      var accessToken = localStorage.getItem('accessToken');
      if (this.state.dpjobrole.length == 0) {
        this.setState({
          isSmallLoaderJobFunctionActive:
            (this.state.isSmallLoaderJobFunctionActive = true),
        });
        axios({
          method: "Post",
          url: this.state.ipurl + "employee/filterbind/",
          crossdomain: true,
          headers: {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
          },
          params: {
            deviceip: this.state.ipaddress,
            type: 12,
            languagecode: this.state.languagecode || COMMON.default_language_code,
            employeecode: this.state.employeecode,
          },
        })
          .then(async (response) => {
            if (response.data.status == 401) {
              await generateRefreshToken().then((result) => {
                if (result == 1) {
                  this.call_job_role_function();
                }
                else {
                  // alert('Unauthorized')
                }
              })
            }
            else {
              if (response.data.jobrole_filter_json_result.varstatuscode === 4) {
                this.state.dpjobrole = response.data.jobrole_filter_json_result.jobrolelist;
                this.setState({ dpjobrole: this.state.dpjobrole });
              } else if (response.data.filter_json_result.varstatuscode === 7) {
              } else {
              }
            }
          })
          .catch((error) => {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "call_job_role_function", 0, error.toString());
          });
      }
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "call_job_role_function", 0, error.toString());
    }
  }
  componentDidMount() {
    try {
      let empcode = localStorage.getItem('employeecode');
      let leadtype = localStorage.getItem('leadtype');
      if (empcode == null || empcode == undefined || empcode == 0 || empcode == '') {
        empcode = -1;
      }
      else {
        empcode = empcode;
      }
      this.setState({ employeecode: this.state.employeecode = empcode });
      this.setState({ leadtype: this.state.leadtype = leadtype });
      localStorage.getItem('ipaddress')
      this.setState({ ipaddress: this.state.ipaddress = localStorage.getItem('ipaddress') });
      stringsoflanguages.setLanguage(localStorage.getItem('languagecode_short'));
      this.setState({ languagecode: this.state.languagecode = localStorage.getItem('languagecode') });
      if (leadtype == 0) {
        if (localStorage.getItem('languageFilter') == null || localStorage.getItem('languageFilter') == 'null' || localStorage.getItem('languageFilter') == undefined || localStorage.getItem('languageFilter') == 'undefined') {
          this.checkFilterBind()
        } else {
          this.loadProfileData();
          this.call_job_function()
        }
      }
      window.addEventListener('popstate', (event) => {
        if (event.state) {
          this.setState({
            showModal: false,
            showJobRoleModal: false,
            showJobFunctionDiv: false,
            showSkillsDiv: false,
            isAddJobRole: this.state.isAddJobRole = false,
            showjobFunction: this.state.showjobFunction = ""
          });
        }
      }, false);
      window.history.pushState({ name: "browserBack" }, "on browser back click", window.location.href);
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "componentDidMount", 0, error.toString());
    }
  }
  languageBind() {
    try {
      var langCode = "1";
      if (localStorage.getItem('languagecode') != null && localStorage.getItem('languagecode') != 'null' && localStorage.getItem('languagecode') != undefined && localStorage.getItem('languagecode') != 'undefined') {
        langCode = localStorage.getItem('languagecode');
      }
      var accessToken = localStorage.getItem('accessToken');
      axios({
        method: 'Post',
        url: this.state.ipurl + 'employee/personalinfoload/',
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        crossdomain: true,
        params: {
          deviceip: localStorage.getItem('ipaddress'),
          languagecode: langCode,
          usercode: 5,
          employeecode: 0,
        }
      })
        .then(async response => {
          this.setState({ isActive: this.state.isActive = false })
          if (response.data.status == 401) {
            await generateRefreshToken().then((result) => {
              if (result == 1) {
                this.languageBind();
              }
              else {
                // alert('Unauthorized')
              }
            })
          }
          else {
            if (response.data.employee_json_result.varstatuscode === 4) {
              let varlanguageOptions = response.data.employee_json_result.languagelist
              let languageOptions = [];
              if (varlanguageOptions && varlanguageOptions.length > 0) {
                let languagecode = localStorage.getItem('languagecode');
                varlanguageOptions.forEach(lg => {
                  let lg_displayname = lg.languagename;
                  if (lg.displayname && lg.displayname.length > 0) {
                    let dis_name = lg.displayname.find(d => d.code === Number(languagecode));
                    if (dis_name && dis_name.name) {
                      lg_displayname = dis_name.name;
                    }
                  }
                  languageOptions.push({ languagecode: lg.languagecode, languagename: lg_displayname, status: lg.status, knowntype: lg.knowntype, displayname: lg.displayname })
                });
                this.setState({ languagesknown: this.state.languagesknown = languageOptions })
              }
            }
          }
        })
        .catch(error => {
          this.setState({ isActive: this.state.isActive = false })
        });
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "languageBind", 0, error.toString());
    }
  }
  checkFilterBind() {
    try {
      if (localStorage.getItem('languageFilter') != null && localStorage.getItem('languageFilter') != 'null' && localStorage.getItem('languageFilter') != undefined && localStorage.getItem('languageFilter') != 'undefined') {
        this.call_job_function();
        this.loadProfileData();
      } else {
        setTimeout(() => {
          this.checkFilterBind()
        }, 500)
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "checkFilterBind", 0, error.toString());
    }
  }
  call_job_function() {
    try {
      var storedGenderResult = JSON.parse(localStorage.getItem('filter_gender_list'));
      var storedEducationResult = JSON.parse(localStorage.getItem('filter_education_category'));
      var storedExperienceResult = JSON.parse(localStorage.getItem('filter_experience_list'));
      var storedDistrictResult = JSON.parse(localStorage.getItem('filter_district_list'));
      var stored_skill_list = JSON.parse(localStorage.getItem('filter_skill_list'));
      var stored_marital_list = JSON.parse(localStorage.getItem('filter_maritalstatus_list'));
      var job_type = localStorage.getItem("filter_jobtypelist") != null ? JSON.parse(localStorage.getItem("filter_jobtypelist")) : [];
      var jobfunctionlist = localStorage.getItem("filter_job_function_list") != null ? JSON.parse(localStorage.getItem("filter_job_function_list")) : [];
      this.state.dpjobfunction = jobfunctionlist;
      this.state.dpjobrole = localStorage.getItem("filter_jobrole_list") != null ? JSON.parse(localStorage.getItem("filter_jobrole_list")) : [];
      this.setState({ dpjobrole: this.state.dpjobrole });
      this.setState({ dpjobfunction: this.state.dpjobfunction });
      const genderList = storedGenderResult ? storedGenderResult : [];
      this.setState({ genderChips: this.state.genderChips = genderList });
      const maritalList = stored_marital_list ? stored_marital_list : [];
      this.setState({ maritalChips: this.state.maritalChips = maritalList })
      const educationcategorylist = storedEducationResult ? storedEducationResult : [];
      this.setState({ educationChips: this.state.educationChips = educationcategorylist });
      this.setState({ skillList: this.state.skillList = stored_skill_list });
      this.setState({ filteredJobType: this.state.filteredJobType = job_type });
      var jobrole_list = localStorage.getItem("filter_jobrole_list") != null ? JSON.parse(localStorage.getItem("filter_jobrole_list")) : [];
      this.state.allJobroleArray = jobrole_list;
      const experiencelist = storedExperienceResult ? storedExperienceResult : [];
      this.setState({ experienceChips: this.state.experienceChips = experiencelist });
      this.setState({
        workexperienceChips: this.state.workexperienceChips = [
          {
            id: "0",
            value: stringsoflanguages.work_exp_experienced || "Experienced",
          },
          {
            id: "1",
            value: stringsoflanguages.work_exp_fresher || "Fresher",
          },
        ]
      });
      const district = storedDistrictResult ? storedDistrictResult : [];
      this.setState({ dpdistrict: this.state.dpdistrict = district });
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "call_job_function", 0, error.toString());
    }
  }
  loadProfileData() {
    try {
      this.setState({
        isActive: this.state.isActive = true
      })
      var accessToken = localStorage.getItem('accessToken');
      axios({
        method: 'Post',
        url: this.state.ipurl + 'employee/portalprofileload/?',
        params: {
          employeecode: this.state.employeecode,
          deviceip: this.state.ipaddress,
          languagecode: this.state.languagecode,
          isleadtype: 0
        },
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
      }).then(async (response) => {
        if (response.data.status == 401) {
          await generateRefreshToken().then((result) => {
            if (result == 1) {
              this.loadProfileData();
            }
            else {
              // alert('Unauthorized')
            }
          })
        }
        else {
          this.setState({
            isActive: this.state.isActive = false
          })
          if (response.data.hasOwnProperty('employee_json_result')) {
            const { employee_json_result } = response.data;
            if (employee_json_result.varstatuscode === 4) {
              //Hanlde edit icon based on Login on App or Portal
              const regVia = employee_json_result.personalinfo.registervia;
              this.setState({ enableEditIcon: regVia == 1 ? true : false });
              //name
              const fullName = employee_json_result.personalinfo.employeefullname;
              this.setState({ profilename: fullName });
              //age
              const emage = employee_json_result.personalinfo.age;
              this.setState({ employeeage: emage });
              //date of birth
              const candidImage = employee_json_result.personalinfo.imageurl || "";
              this.setState({ candidateImage: candidImage });
              const date = employee_json_result.personalinfo.dateofbirth;
              this.setState({ dob: this.state.dob = date });
              //phonenumber
              const mobileno = employee_json_result.contactinfo.mobileno;
              this.setState({ contact: mobileno });
              //experience
              const totalexperience = employee_json_result.totalexperience;
              this.setState({ experience: totalexperience });
              //fresherstatus
              const fresherstatus = employee_json_result.fresherstatus;
              this.setState({
                frestatus: fresherstatus
              });
              //job skills list
              const skillsList = employee_json_result.skilllist;
              const experienceDetails = employee_json_result && employee_json_result.experience;
              this.setState({ GetJobSkill: skillsList });
              this.state.GetJobSkill.map(e => {
                var skillsVal = experienceDetails.filter((el) => el.jobrolecode == e.jobrolecode)
                e.currentjobfunction = skillsVal[0].currentworkstatus;
                e.currentworkstatus = skillsVal[0].currentworkstatus;
                if (skillsVal[0].currentworkstatus != null && skillsVal[0].currentworkstatus != undefined && skillsVal[0].currentworkstatus != 'undefined' && skillsVal[0].currentworkstatus == 1) {
                  this.setState({ currentjobrolecode: this.state.currentjobrolecode = e.jobrolecode });
                  this.setState({ tempjobrolecode: this.state.tempjobrolecode = e.jobrolecode });
                  this.setState({ currentjobroleflag: this.state.currentjobroleflag = true });
                }
                return e['expvalue'] = skillsVal && skillsVal.length > 0 ? skillsVal[0].expvalue : 0;
              })
              this.setState({ GetJobSkill: this.state.GetJobSkill });
              let jobSkills = this.state.GetJobSkill || [];
              for (let k = 0; k < jobSkills.length; k++) {
                let index = this.state.dpjobrole.findIndex(ele => ele.jobrolecode == jobSkills[k]['jobrolecode']);
                if (index != -1)
                  this.state.dpjobrole.splice(index, 1);
                if (this.state.allJobroleArray.find(ele => ele.jobrolecode == jobSkills[k]['jobrolecode'])) {
                  let currentJobList = this.state.handleJobRoleSelected;
                  currentJobList.push(this.state.allJobroleArray.find(ele => ele.jobrolecode == jobSkills[k]['jobrolecode']));
                  this.setState({
                    handleJobRoleSelected: this.state.handleJobRoleSelected = currentJobList
                  });
                }
                let jobRoleIndex = this.state.allJobroleArray.findIndex(ele => ele.jobrolecode == jobSkills[k]['jobrolecode']);
                if (jobRoleIndex != -1)
                  this.state.allJobroleArray.splice(jobRoleIndex, 1);
              }
              //education Level
              let Schooling = employee_json_result.schooling;
              let AfterSchooling = employee_json_result.afterschooling;
              const educationStatus = AfterSchooling.length > 0 ? AfterSchooling[0].qualificationcode : Schooling.length > 0 ? Schooling[0].qualificationcode : [];
              this.setState({
                education: educationStatus
              });
              //Total percentage
              const totalPer = employee_json_result.profilestatus && employee_json_result.profilestatus.totalpercentage;
              this.setState({ totalPercentage: totalPer });
              //salary 
              const maxsalary = employee_json_result.preferences == undefined ? '' : employee_json_result.preferences.maxsalary;
              this.setState({ salary: maxsalary });
              const minsalary = employee_json_result.preferences == undefined ? COMMON.minsalary : employee_json_result.preferences.minsalary;
              this.setState({ minsalary: minsalary });
              //job Type 
              const emptypelist = employee_json_result.preferences == undefined ? '' : employee_json_result.preferences.emptypelist;
              let job_type = emptypelist.flatMap(el => el.employementtypecode);
              this.setState({ selectedJobType: job_type });
              //marital status
              const maritalstatus = employee_json_result.personalinfo.maritalstatus;
              this.setState({ status: maritalstatus });
              //locations
              const locationname = employee_json_result.contactinfo && employee_json_result.contactinfo.districtname;
              const locationID = employee_json_result.preferences.locationlist.length > 0 ? employee_json_result.preferences.locationlist[0].locationcode : '';
              const statename = employee_json_result.contactinfo && employee_json_result.contactinfo.statename;
              const locationVal = locationname == undefined && statename == undefined ? '-' : locationname + ' - ' + statename;
              this.setState({ showLocation: locationVal, locationID: locationID });
              //Candidate locations
              const candidLocationname = employee_json_result.contactinfo ? employee_json_result.contactinfo.districtcode : '';
              const candidStatename = employee_json_result.contactinfo.statecode;
              const candidDistrictname = employee_json_result.contactinfo.districtcode;
              this.setState({ showCandidateLocation: candidLocationname + '-' + candidStatename, candidateDistrictValue: candidDistrictname });
              //State
              const stateVal = employee_json_result.preferences.statecode;
              this.setState({ statecode: stateVal });
              //job type
              const jobTypeVal = employee_json_result.preferences && employee_json_result.preferences.emptypelist;
              var jobArray = [];
              for (let emp = 0; emp < jobTypeVal.length; emp++) {
                jobArray.push(jobTypeVal[emp].employementtypename)
              }
              this.setState({ jobTypeArray: jobArray });
              //gender
              const gendername = employee_json_result.personalinfo.gendername;
              this.setState({ gender: gendername, genderID: employee_json_result.personalinfo.gender });
              var selGender = [];
              selGender.push(employee_json_result.personalinfo.gendername);
              this.setState({
                selectedGender: selGender
              })
              if (this.state.gender && this.state.gender == stringsoflanguages.female) {
                this.setState({ image: this.state.candidateImage != '' ? this.state.candidateImage : avatar1 })
              }
              else if (this.state.gender && this.state.gender == stringsoflanguages.male) {
                this.setState({ image: this.state.candidateImage != '' ? this.state.candidateImage : avatar2 })
              }
              else if (this.state.gender && this.state.gender == stringsoflanguages.transgender) {
                this.setState({ image: this.state.candidateImage != '' ? this.state.candidateImage : avatar3 })
              }
              const maritalname = employee_json_result.personalinfo.maritalstatus;
              this.setState({ maritalname: maritalname, maritalID: employee_json_result.personalinfo.maritalstatuscode })
              var selMarital = [];
              selMarital.push(employee_json_result.personalinfo.maritalstatuscode)
              this.setState({
                selectedMarital: selMarital
              })
              this.setState({ languagesknown: this.state.languagesknown = employee_json_result.personalinfo.languagesknown });
              this.setState({ editlanguage: this.state.editlanguage = employee_json_result.personalinfo.editlanguage })
              if (this.state.languagesknown.length == 0) {
                this.languageBind();
              }
              const experienceData = employee_json_result.experience || [];
              this.setState({
                experience: experienceData,
                designation: experienceData.map((exp) => exp.designationname).join(', '),
              });
            } else if (employee_json_result.varstatuscode === 7) {
              localStorage.clear();
            } else {
            }
          }
        }
      })
        .catch((error) => {
          error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "loadProfileData", 0, error.toString());
        });
    } catch (error) {
      this.setState({
        isActive: this.state.isActive = false
      })
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "loadProfileData", 0, error.toString());
    }
  }
  editcloseModal() {
    this.setState({
      showJobRoleModal: false,
    });
  }
  getExperienceValue(val) {
    try {
      var accessToken = localStorage.getItem('accessToken');
      this.setState({
        isActive: this.state.isActive = true
      })
      axios({
        method: 'Post',
        url: this.state.ipurl + 'employee/portalprofileload/?',
        params: {
          employeecode: this.state.employeecode,
          deviceip: this.state.ipaddress,
          languagecode: 2,
          isleadtype: 0,
        },
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
      })
        .then(async (response) => {
          if (response.data.status == 401) {
            await generateRefreshToken().then((result) => {
              if (result == 1) {
                this.getExperienceValue();
              }
              else {
                // alert('Unauthorized')
              }
            })
          }
          else {
            this.setState({
              isActive: this.state.isActive = false
            })
            if (response.data.hasOwnProperty('employee_json_result')) {
              const { employee_json_result } = response.data;
              if (employee_json_result.varstatuscode === 4) {
                //job skills list
                const skillsList = employee_json_result.skilllist;
                const experienceDetails = employee_json_result && employee_json_result.experience;
                skillsList.map(e => {
                  var skillsVal = experienceDetails.filter((el) => el.designationname == e.jobrolename)
                  return e['expvalue'] = skillsVal && skillsVal.length > 0 ? skillsVal[0].expvalue : 0;
                })
                this.state.GetJobSkill.map(val => {
                  var skillsVal = skillsList.filter((el) => el.jobrolecode == val.jobrolecode)
                  return val['expvalue'] = skillsVal && skillsVal.length > 0 ? skillsVal[0].expvalue : 0;
                })
                this.setState({ GetJobSkill: this.state.GetJobSkill });
              } else if (employee_json_result.varstatuscode === 7) {
                localStorage.clear();
              } else {
              }
            }
          }
        })
        .catch((error) => {
          error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "getExperienceValue", 0, error.toString());
        });
    } catch (error) {
      this.setState({
        isActive: this.state.isActive = false
      })
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "getExperienceValue", 0, error.toString());
    }
  }
  filterJobFunction(searchValue) {
    try {
      this.setState({ txtJobFunction: this.state.txtJobFunction = searchValue })
      if (searchValue && searchValue != "" && searchValue.length >= 3) {
        var filteredJobFunctionName = this.state.allJobroleArray.filter(e => e.jobrolename.toLowerCase().startsWith(searchValue.toLowerCase()));
        this.setState({ dpFilterdJobFunction: this.state.dpFilterdJobFunction = filteredJobFunctionName });
      }
      else {
        this.setState({ dpFilterdJobFunction: this.state.dpFilterdJobFunction = this.state.dpjobrole });
      }
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "filterJobFunction", 0, error.toString());
    }
  }
  getJobFunction(array) {
    try {
      var jobData = []
      for (let a = 0; a < array.length; a++) {
        var jobDataArray = {
          'jobfunctioncode': array[a].jobfunctioncode
        }
        jobData.push(jobDataArray)
      }
      return jobData
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "getJobFunction", 0, error.toString());
    }
  }
  getJobRole(array) {
    try {
      var jobRoleData = []
      for (let a = 0; a < array.length; a++) {
        var jobRoleArray = {
          'jobrolecode': array[a].jobrolecode
        }
        jobRoleData.push(jobRoleArray)
      }
      return jobRoleData
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "getJobRole", 0, error.toString());
    }
  }
  getExperience(array) {
    try {
      var jobRoleSkillsData = [], skillArr = []
      for (let a = 0; a < array.length; a++) {
        skillArr = [];
        var expJson = this.state.experienceChips.filter(ex => ex.value == array[a].expvalue);
        for (let j = 0; j < array[a].skillcode.length; j++) {
          var data = { "skillcode": array[a].skillcode[j] }
          skillArr.push(data)
        }
        if (expJson.length > 0) {
          var exp = { "experiencecode": a + 1, "designationname": array[a].jobrolename, "expid": expJson[0]['experiencecode'], "jobfunctioncode": array[a].jobfunctioncode, "jobrolecode": array[a].jobrolecode, "skills": skillArr, currentworkstatus: array[a].currentworkstatus }
          jobRoleSkillsData.push(exp);
        }
      }
      return jobRoleSkillsData
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "getExperience", 0, error.toString());
    }
  }
  getJobSkills(array) {
    try {
      var jobskillname = [];
      for (let a = 0; a < array.length; a++) {
        for (var j = 0; j < array[a].skillcode.length; j++) {
          var skillJs = this.state.skillList.filter(e => e.skillcode == array[a].skillcode[j] && e.jobrolecode == array[a].jobrolecode);
          if (skillJs.length > 0) {
            skillJs[0]['currentjobfunction'] = 0;
            jobskillname.push(skillJs[0]);
          }
        }
      }
      return jobskillname
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "getJobSkills", 0, error.toString());
    }
  }
  shortliststatus(appliedshortstatus) {
    try {
      var accessToken = localStorage.getItem('accessToken');
      this.state.GetJobSkill = this.state.GetJobSkill.filter((val) => val.jobrolecode !== this.state.deleteJobRole.jobrolecode);
      this.setState({
        GetJobSkill: this.state.GetJobSkill, invitealert: false
      })
      var jobfunction = this.getJobFunction(this.state.GetJobSkill)
      var jobRole = this.getJobRole(this.state.GetJobSkill)
      var jobSkills = this.getJobSkills(this.state.GetJobSkill)
      var experienceData = this.getExperience(this.state.GetJobSkill)
      var employeeJobRoleJson = {
        "mobileno": this.state.contact,
        "jobfunction": jobfunction,
        "jobrole": jobRole,
        "experienceinfo": experienceData,
        "skills": jobSkills
      }
      try {
        this.setState({
          isActive: this.state.isActive = true
        })
        axios({
          method: 'Post',
          url: this.state.ipurl + 'employeeportal/jobroleupdate/?',
          params: {
            employeecode: this.state.employeecode,
            deviceip: this.state.ipaddress,
            languagecode: this.state.languagecode,
            isleadtype: 0
          },
          headers: {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
          },
          data: employeeJobRoleJson,
        }).then(async response => {
          if (response.data.status == 401) {
            await generateRefreshToken().then((result) => {
              if (result == 1) {
                this.shortliststatus(appliedshortstatus);
              }
              else {
                // alert('Unauthorized')
              }
            })
          }
          else {
            this.setState({ isActive: this.state.isActive = false })
            if (response.data.hasOwnProperty('employee_json_result')) {
              this.state.varstatuscode = response.data.employee_json_result.hasOwnProperty('varstatuscode') ? response.data.employee_json_result.varstatuscode : 13;
              if (this.state.varstatuscode == 2) {
                toast.success(stringsoflanguages.deleted_success, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: 'toast-success-container toast-success-container-after'
                });
                let filterRole = this.state.allJobroleArray;
                if (this.state.handleJobRoleSelected.find(el => el.jobrolecode == this.state.deleteJobRole.jobrolecode)) {
                  filterRole.push(this.state.handleJobRoleSelected.find(el => el.jobrolecode == this.state.deleteJobRole.jobrolecode))
                  this.setState({
                    allJobroleArray: this.state.allJobroleArray = filterRole,
                  })
                }
              }
              else {
                toast.error(response.data.employee_json_result.responsestring, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: 'foo-bar'
                });
              }
            }
          }
        })
      }
      catch (error) {
        this.setState({ isActive: this.state.isActive = false })
        error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "shortliststatus", 0, error.toString());
      }
    }
    catch (error) {
      this.setState({ isActive: this.state.isActive = false })
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Candidate_details", "shortliststatus", 0, error.toString());
    }
  }
  render() {
    const { profileData } = this.state;
    const { image, croppedAreaPixels, crop, zoom, aspect } = this.state;
    return (
      <div className="site-main">
        <ToastContainer autoClose={10000} toastStyle={{ backgroundColor: "#ed9d3b", color: 'white' }} progressStyle={{ background: 'white' }} />
        <Header />
        <LoadingOverlay
          active={this.state.isActive}
          spinner={
            <img src="images/svg/loading.gif" className="dz-img rounded-circle" alt="" />}
        ></LoadingOverlay>
        {this.state.leadtype == 0 ?
          <div>
            <SweetAlert
              title={<div style={{ fontSize: '15px', fontWeight: '400' }}>{stringsoflanguages.deleteJobTitle}</div>}
              custom
              show={this.state.invitealert}
              showCancel
              reverseButtons
              confirmBtnText={stringsoflanguages.employer_personal_yes}
              cancelBtnText={stringsoflanguages.employer_personal_no}
              cancelBtnCssClass="btn-cancelClr"
              confirmBtnCssClass="btn-dangerclr"
              customIconCssClass="imgWidth"
              customIcon={<img src='/images/jobanya-logo.png' style={{ width: '43%', marginLeft: '27%', marginBottom: '18px' }} alt='' />}
              onConfirm={() => {
                this.shortliststatus(this.state.Rejected)
              }}
              onCancel={() => {
                this.setState({ invitealert: this.state.invitealert = false })
              }}
            >
            </SweetAlert>
            <SweetAlert
              title={<div style={{ fontSize: '15px', fontWeight: '400' }}>{stringsoflanguages.deleteactiveJobTitle}</div>}
              custom
              show={this.state.deletealert}
              reverseButtons
              confirmBtnText={stringsoflanguages.prefer_ok}
              cancelBtnCssClass="btn-cancelClr"
              confirmBtnCssClass="btn-dangerclr"
              customIconCssClass="imgWidth"
              customIcon={<img src='/images/jobanya-logo.png' style={{ width: '43%', marginLeft: '27%', marginBottom: '18px' }} alt='' />}
              onConfirm={() => {
                this.setState({ deletealert: this.state.deletealert = false })
              }}
            >
            </SweetAlert>
            {!this.state.is_auth && <Redirect push to="/login" />}
            <div className="ttm-row sidebar job-sidebar clearfix">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 content-area">
                    <div className="row">
                      <div className="col-md-12">
                        {window.innerWidth > 850 &&
                          <div className="candidate-data">
                            <div className="candidate-img">
                              <label
                                className=""
                                style={{ cursor: 'pointer' }}
                                htmlFor="imgorganization_logo"
                                color="primary" onChange={this.getUploadedProfileImage}>
                                <i className="fa fa-camera upload_camera_style dz-img fa-3x" hidden aria-hidden="true"></i>
                                <input
                                  type="file"
                                  id="imgorganization_logo" hidden
                                />
                                {this.state.gender && this.state.gender == stringsoflanguages.female && <img src={this.state.candidateImage != '' ? this.state.candidateImage : avatar1} />}
                                {this.state.gender && this.state.gender == stringsoflanguages.male && <img src={this.state.candidateImage != '' ? this.state.candidateImage : avatar2} />}
                                {this.state.gender && this.state.gender == stringsoflanguages.transgender && <img src={this.state.candidateImage != '' ? this.state.candidateImage : avatar3} />}
                              </label>
                            </div>
                            <div className="candidate-caption" style={{ width: '78%' }}>
                              <h5>{this.state.profilename}</h5>
                              <div className="meta-line">
                                <div>
                                  <span style={{ marginRight: '5px' }}><i className="ti-user"></i></span>{this.state.gender} |
                                  {this.state.employeeage} {stringsoflanguages.employer_r1_years} |
                                  {this.state.maritalname} |
                                  <span style={{ marginRight: '5px' }}><i className="ti-mobile"></i></span>{this.state.contact}
                                </div>
                                <div>
                                  <span style={{ marginRight: '5px' }}><i className="ti-briefcase"></i></span>{this.state.frestatus === 1 ? stringsoflanguages.work_exp_fresher : stringsoflanguages.employer_exp_heading} | <span style={{ marginRight: '3px' }}><i className="fas fa-rupee-sign"></i></span>{stringsoflanguages.SalaryExpected} : {this.indian_standard_amount(this.state.minsalary)} {' - '} {this.indian_standard_amount(this.state.salary)}
                                </div>
                                <div>
                                  <span style={{ marginRight: '5px' }}><i className="ti-location-pin"></i></span>{this.state.showLocation}
                                </div>
                                <div>
                                  <span style={{ marginRight: '3px' }}><i className="fas fa-business-time"></i></span>{stringsoflanguages.filter_job_type} : {this.state.jobTypeArray.length > 0 ? this.state.jobTypeArray.toString() : '-'}
                                </div>
                                {(Array.isArray(this.state.editlanguage) && this.state.editlanguage != null && this.state.editlanguage.length > 0) ?
                                  <div>
                                    <span style={{ marginRight: '3px' }}>
                                      <i className="fas fa-language"></i>
                                    </span>{stringsoflanguages.personal_lg}
                                    {Array.isArray(this.state.editlanguage) && this.state.editlanguage.map((lang) => <li>{lang.languagename} :
                                      {lang.knownto.filter(t => t == 'read').map(e => stringsoflanguages.lang_read_text)}
                                      {lang.knownto.filter(t => t == 'write').map(e => ' ' + stringsoflanguages.lang_write_text)}
                                      {lang.knownto.filter(t => t == 'speak').map(e => ' ' + stringsoflanguages.lang_speak_text)}
                                    </li>
                                    )}
                                  </div>
                                  : ""
                                }
                              </div>
                            </div>
                            <div style={{ width: '17%' }}>
                              <div style={{ width: '30%' }} ><CircularProgressbar styles={buildStyles({
                                pathColor: '#ff8d00',
                                textColor: 'black',
                                trailColor: '#d6d6d6'
                              })} value={this.state.totalPercentage} text={`${this.state.totalPercentage}%`} /></div>
                              <span className='progress_style'>{stringsoflanguages.ProfileCompletion}</span>
                            </div>
                            {this.state.enableEditIcon == true && <div style={{ width: '12%', textAlign: 'end', marginTop: '-13%' }}>
                              <div className="edit">
                                <button style={{ cursor: 'pointer' }} onClick={this.openModal}><i className="ti-pencil-alt edit" style={{ color: 'blue', fontSize: '18px' }} ></i></button>
                              </div>
                            </div>}
                          </div>
                        }
                        {/* mobile view */}
                        {window.innerWidth < 850 &&
                          <div className="candidate-data flex-column d-flex">
                            <div className="candidate-img" style={{ marginRight: '0px' }}>
                              <label
                                className=""
                                style={{ cursor: 'pointer' }}
                                htmlFor="imgorganization_logo"
                                color="primary" onChange={this.getUploadedProfileImage}>
                                <i className="fa fa-camera upload_camera_style dz-img fa-3x" hidden aria-hidden="true"></i>
                                <input
                                  type="file"
                                  id="imgorganization_logo" hidden
                                />
                                {this.state.gender && this.state.gender == stringsoflanguages.female && <img src={this.state.candidateImage != '' ? this.state.candidateImage : avatar1} />}
                                {this.state.gender && this.state.gender == stringsoflanguages.male && <img src={this.state.candidateImage != '' ? this.state.candidateImage : avatar2} />}
                                {this.state.gender && this.state.gender == stringsoflanguages.transgender && <img src={this.state.candidateImage != '' ? this.state.candidateImage : avatar3} />}
                              </label>
                            </div>
                            <div className="candidate-caption">
                              <div className='d-flex disp-center' style={{ marginTop: '10px' }}>
                                <h5>{this.state.profilename}</h5>
                                <div className="batch_mbl_style"><CircularProgressbar value={this.state.totalPercentage} styles={buildStyles({
                                  pathColor: '#ff8d00',
                                  textColor: 'black',
                                  trailColor: '#d6d6d6'
                                })} text={`${this.state.totalPercentage}%`} /></div>
                              </div>
                              <div className="meta-line">
                                <div className="disp-center disp-align" style={{ marginTop: "-8px" }}>
                                  <span style={{ fontSize: "13px", marginRight: '10px' }}><i className="ti-user"></i>{this.state.gender} |  {this.state.employeeage} {stringsoflanguages.employer_r1_years}</span>
                                </div>
                                <div>
                                  <span style={{ fontSize: "13px", marginRight: '5px' }}><i className="ti-mobile"></i></span>{this.state.contact}
                                </div>
                                <div >
                                  <span style={{ fontSize: "13px", marginRight: '5px' }}><i className="ti-briefcase"></i></span>{this.state.frestatus === 1 ? stringsoflanguages.employer_rl_fresher : stringsoflanguages.employer_exp_heading}
                                </div>
                                <div >
                                  <span style={{ fontSize: "13px", marginRight: '9px' }}><i className="fas fa-rupee-sign" style={{ fontSize: "14px" }}></i></span>{stringsoflanguages.SalaryExpected} : {this.indian_standard_amount(this.state.minsalary)} {' - '} {this.indian_standard_amount(this.state.salary)}
                                </div>
                                <div >
                                  <span style={{ fontSize: "13px", marginRight: '5px' }}><i className="ti-location-pin"></i></span>{this.state.showLocation}
                                </div>
                                <div>
                                  <span style={{ fontSize: "13px", marginRight: '5px' }}><i className="fas fa-business-time"></i></span>{stringsoflanguages.filter_job_type} <ol style={{ listStyleType: 'disc' }}>
                                    {this.state.jobTypeArray.map((experienceItem, index) => (
                                      <li style={{ fontSize: "12px" }} key={index}>{experienceItem}</li>
                                    ))}
                                  </ol>
                                </div>
                                {this.state.enableEditIcon == true &&
                                  <div className="edit">
                                    <button style={{ cursor: 'pointer' }} onClick={this.openModal}><i className="ti-pencil-alt edit" style={{ color: 'blue' }}></i></button>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ttm-row sidebar job-sidebar clearfix">
              <div className="container">
                <div className="row">
                  {((this.state.GetJobSkill && this.state.GetJobSkill.length <= 3 && this.state.frestatus == 0) || (this.state.frestatus == 1 && this.state.GetJobSkill.length < 1)) &&
                    <div className="edit txt-end">
                      <span className={this.state.enableEditIcon == true ? 'enabelExp' : 'disableExp'}>
                        <span className='des_btn cursor' onClick={() => this.openjobRoleModal("add")}>
                          <button><i className="fa fa-plus-circle edit txt-orange" style={{ color: "#ff8d00" }}></i></button>
                          <span className='add-txt'>{stringsoflanguages.add_job_title}</span>
                        </span>
                      </span>
                    </div>
                  }
                  <div className="col-lg-12 widget-area sidebar-left job_list-widget-area">
                    <div className="job_list-widget">
                      {Array.isArray(this.state.GetJobSkill) && this.state.GetJobSkill.map((experienceItem, index) => (
                        <aside className="widget candidate-widget" key={index}>
                          <h3 className="widget-title">
                            <div style={window.innerWidth > 850 ? { width: '100%' } : { width: '100%' }}>{experienceItem.jobrolename}</div>
                            <li className="d-flex" style={window.innerWidth > 850 ? { width: '20%' } : { width: '50%', marginRight: '-60px' }}>
                              <div className='md-flex flex-row d-flex' style={{ marginLeft: '30px' }}>
                                {this.state.enableEditIcon == true &&
                                  <div className="edit">
                                    <button style={{ cursor: 'pointer' }} onClick={(e) => { this.editJobTitle(experienceItem) }}><i className="ti-pencil-alt edit" style={{ fontSize: '15px', color: 'blue' }}></i></button>
                                  </div>
                                }
                                {this.state.GetJobSkill && this.state.GetJobSkill.length > 1 && this.state.enableEditIcon == true && <div className="edit">
                                  <button onClick={(e) => { this.deleteJobTitle(experienceItem) }}><i className="ti-trash edit" style={{ fontSize: '18px', color: 'red' }}></i></button>
                                </div>}
                              </div>
                            </li>
                          </h3>
                          <div>
                          </div>
                          <div className="d-flex  d-md-flex" style={window.innerWidth > 850 ? { flexDirection: 'row' } : { flexDirection: 'column' }}>
                            <li className="d-flex" style={{ width: '80%', flexDirection: 'row' }}>
                              <div className="d-flex" style={window.innerWidth > 850 ? { flexDirection: 'row' } : { flexDirection: 'row' }}>
                                <div>
                                  <b className="mr-5">{stringsoflanguages.rl_experience}:</b>
                                </div>
                                <div>{experienceItem.expvalue == 0 ? '' : experienceItem.expvalue} {experienceItem.expvalue == 0 ? stringsoflanguages.employer_rl_fresher : experienceItem.expvalue == 1 ? stringsoflanguages.exp_year_str1 : stringsoflanguages.employer_r1_years}</div>
                              </div>
                            </li>
                            <li className="d-flex" style={{ width: '100%', flexDirection: 'column' }}>
                              <div className='d-md-flex'>
                                <div style={{ display: 'flex' }}>
                                  <div style={{ fontWeight: '700' }}>{stringsoflanguages.rl_skills}</div> {window.innerWidth > 850 && <div style={{ marginRight: '4px' }}> : </div>}
                                </div>
                                {window.innerWidth > 850 &&
                                  <div className="d-flex" style={{ flexDirection: 'row' }}>
                                    <div style={{ fontSize: "12px" }}>{(experienceItem.skillname).toString()}</div>
                                  </div>
                                }
                                {window.innerWidth < 850 &&
                                  <div>
                                    <ol style={{ listStyleType: 'disc' }}>
                                      {experienceItem.skillname.map((experienceItem, index) => (
                                        <li style={{ fontSize: "12px" }} key={index}>{experienceItem}</li>
                                      ))}
                                    </ol>
                                  </div>
                                }
                              </div>
                            </li>
                          </div>
                        </aside>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal isOpen={this.state.isupload == true} className='JobList_Popup' style={{ width: '100%', height: '100%' }}>
              <ModalHeader style={{ width: "100%", display: 'block', height: '10%' }}>
                <div className='row'>
                  <div className={window.innerWidth > 850 ? 'col-11' : 'col-11'} style={{ display: 'inline-block' }}>
                    <h6 style={window.innerWidth > 850 ? { width: "95%", flex: 50 } : { width: "50%", flex: 50, display: 'inline-block' }}>{stringsoflanguages.file_upload_text}
                    </h6>
                  </div>
                  <div className='col-1' style={{ marginLeft: -20 }}>
                    <div >
                      <a className="close_btn" style={{ cursor: 'pointer' }} onClick={() => { this.setState({ isupload: this.state.isupload = false }) }}>
                        <i className="ti ti-close"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-10'>
                  </div>
                  <div className='col-lg-2'>
                    <button className='apply-button ttm-btn ttm-btn-size-md ttm-btn-shape-rounded applyBtn ttm-btn-style-fill ttm-btn-color-skincolor jobDetail'
                      onClick={this.showCroppedImage}
                      variant="contained"
                      color="primary"
                      style={{ alignContent: 'flex-end', alignItems: 'flex-end', alignSelf: 'flex-end', textAlign: 'center', width: '50%', display: 'inline-block' }}
                    >
                      {stringsoflanguages.rejection_save_text}
                    </button>
                  </div>
                </div>
              </ModalHeader>
              <ModalBody>
                <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                  <div style={{ width: '100%', height: '400px', position: 'relative' }}>
                    <div>
                      <Cropper
                        image={this.state.imageSrc}
                        crop={this.state.crop}
                        zoom={this.state.zoom}
                        aspect={this.state.aspect}
                        onCropChange={this.onCropChange}
                        onCropComplete={this.onCropComplete}
                        onZoomChange={this.onZoomChange}
                      /></div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
            <Modal isOpen={this.state.showJobRoleModal} className='JobList_Popup'>
              <ModalHeader style={{ display: 'none' }} >
              </ModalHeader>
              <ModalBody >
                {this.state.showJobFunctionDiv == true ? (
                  <div className="layer-content bg-theme-GreyColor ttm-col-bgcolor-yes p-3 ttm-bg border rounded p-lg-20 mt_70 mt-0">
                    <div className="mb-20 d-flex dialogTitle bg-theme-GreyColor">
                      <h6 style={window.innerWidth > 850 ? { width: "95%" } : { width: "100%" }}>{stringsoflanguages.add_job_title}*</h6>
                      <div>  <a className="close_btn" style={{ cursor: 'pointer' }} onClick={() => this.closejobRoleModal()}>
                        <i className="ti ti-close"></i>
                      </a> </div>
                    </div>
                    <div className="ttm-tabs ttm-tab-style-02">
                      <div className="content-tab">
                        {/* <TabPanel> */}
                        <form className="login_form wrap-form">
                          <div className="row">
                            <div className="col-lg-12">
                              <label>{stringsoflanguages.employer_home_jobfunction}*</label>
                              <label>
                                <i className="ti ti-search"></i>
                                <select
                                  tabIndex="2"
                                  value={this.state.showjobFunction}
                                  onChange={(e) => {
                                    if (e.target.value != "0") {
                                      var valueArrr = e.target.value;
                                      this.setState({ showjobFunction: this.state.showjobFunction = Number(valueArrr) })
                                      if (this.state.allJobroleArray.find(el => el.jobfunctioncode == valueArrr)) {
                                        this.setState({
                                          dpFilterdJobFunction: this.state.dpFilterdJobFunction = this.state.allJobroleArray.filter(el => el.jobfunctioncode == valueArrr) || [],
                                          selectJobRoleArray: this.state.selectJobRoleArray = []
                                        });
                                      } else {
                                        this.setState({
                                          dpFilterdJobFunction: this.state.dpFilterdJobFunction = [],
                                          selectJobRoleArray: this.state.selectJobRoleArray = []
                                        });
                                      }
                                    }
                                  }}
                                >
                                  <option value="0">{stringsoflanguages.Employee_known_text}</option>
                                  {this.state.dpjobfunction.map((o) => (
                                    <option
                                      key={o.jobfunctioncode}
                                      value={o.jobfunctioncode}
                                    >
                                      {o.jobfunctionname}
                                    </option>
                                  ))}
                                </select>
                              </label>
                            </div>
                            {this.state.dpFilterdJobFunction && this.state.dpFilterdJobFunction.length > 0 ?
                              <div className="col-lg-12">
                                <label>{stringsoflanguages.pre_jobrole}*</label>
                                <div className="col-lg-12">
                                  <FormGroup>
                                    <ChipSet
                                      choice
                                    >
                                      {this.state.dpFilterdJobFunction.map((chip) => (
                                        <Chip
                                          className={
                                            this.state.selectJobRoleArray.findIndex(f => f == chip.jobrolecode) > -1
                                              ? "selectedChipBg"
                                              : "chipBg"
                                          }
                                          onClick={() => {
                                            if (this.state.dpjobrole && this.state.dpjobrole.findIndex(f => f['jobrolecode'] == chip.jobrolecode) == -1) {
                                              let jobTitle = this.state.allJobroleArray.find(f => f['jobrolecode'] == chip.jobrolecode);
                                              if (jobTitle && jobTitle['jobrolecode']) {
                                                this.state.dpjobrole.push(jobTitle);
                                                this.setState({
                                                  dpjobrole: this.state.dpjobrole
                                                })
                                              }
                                            }
                                            this.setState({ selectJobRoleArray: [chip.jobrolecode] })
                                          }}
                                          leadingIcon={
                                            <img src={chip.imageurl}></img>
                                          }
                                          id={chip.jobrolecode}
                                          key={chip.jobrolecode}
                                          label={chip.jobrolename}
                                        />
                                      ))}
                                    </ChipSet>
                                  </FormGroup>
                                </div>
                              </div> : <></>}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>) : null}
                {this.state.selectedJobRoles.length > 0 &&
                  this.state.showSkillsDiv == true &&
                  this.state.selectedJobRoles.map((item, index) => (
                    <div className="layer-content" key={index}>
                      {this.state.selectedJobRoleIndex == index ? (
                        <div>
                          <div className="mb-20 d-flex">
                            <h6 style={window.innerWidth > 850 ? { width: "95%" } : { width: "100%" }}>{item.jobrolename}</h6>
                            <div>  <a className="close_btn" style={{ cursor: 'pointer' }} onClick={this.editcloseModal}>
                              <i className="ti ti-close"></i>
                            </a> </div>
                          </div>
                          {this.state.frestatus == 1 ? null :
                            <Checkbox className="d-flex flex-row justify-content-start"
                              id={item.jobrolecode}
                              icon=""
                              label={stringsoflanguages.job_currently}
                              disabled={this.state.currentjobrolecode == item.jobrolecode && this.state.currentjobroleflag == true && this.state.currentjobrolecode != 0}
                              checked={item.currentworkstatus == 1 || this.state.frestatus == 1}
                              onChange={(e) => {
                                if (item.currentworkstatus == 1) {
                                  this.setState({ tempjobrolecode: this.state.tempjobrolecode = 0 });
                                  item.currentworkstatus = 0;
                                }
                                else {
                                  this.setState({ tempjobrolecode: this.state.tempjobrolecode = item.jobrolecode });
                                  item.currentworkstatus = 1;
                                }
                                this.setState({ currentjobroleflag: this.state.currentjobroleflag = !this.state.currentjobroleflag });
                              }}
                            />}
                          <div className="ttm-tabs ttm-tab-style-02">
                            <div className="content-tab">
                              {/* <TabPanel> */}
                              <form className="login_form wrap-form">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <FormGroup>
                                      <label>{stringsoflanguages.employee_experience}</label>
                                      <ChipSet
                                        choice
                                        handleSelect={(selectedChipIds) => {
                                          this.selectJobRoleExperience(item, selectedChipIds)
                                        }
                                        }
                                      >
                                        {this.state.experienceChips.map(
                                          (chip) => (
                                            <Chip
                                              className={
                                                item['selectedExperience'] ? item['selectedExperience'][0] ==
                                                  chip.experiencecode
                                                  ? "selectedChipBg"
                                                  : "chipBg" : "chipBg"
                                              }
                                              id={chip.experiencecode}
                                              key={chip.experiencecode} // The chip's key cannot be its index, because its index may change.
                                              label={chip.experiencename}
                                            />
                                          )
                                        )}
                                      </ChipSet>
                                    </FormGroup>
                                  </div>
                                  <div className="col-lg-12">
                                    <FormGroup>
                                      <label>
                                        {stringsoflanguages.skills_known}
                                      </label>
                                      <ChipSet
                                        choice
                                      >
                                        {item.skills.map((chip) => (
                                          <Chip
                                            className={
                                              item['selectedSkills'] ? item['selectedSkills'].findIndex(f => f == chip.skillcode) > -1 ?
                                                "selectedChipBg"
                                                : "chipBg" : "chipBg"
                                            }
                                            onClick={() => {
                                              if (this.state.selectedRoleSkilsArray && this.state.selectedRoleSkilsArray.length == 6 && item['selectedSkills'].findIndex(f => f == chip.skillcode) == -1) {
                                                toast.error(stringsoflanguages.YouCanselectSkills, {
                                                  position: toast.POSITION.TOP_RIGHT,
                                                  className: 'foo-bar'
                                                });
                                                return;
                                              } else if (!item['selectedSkills'] || item['selectedSkills'].findIndex(f => f == chip.skillcode) == -1) {
                                                this.state.selectedRoleSkilsArray.push(chip.skillcode)
                                                this.setState({
                                                  selectedRoleSkilsArray: this.state.selectedRoleSkilsArray
                                                })
                                              } else {
                                                this.setState({
                                                  selectedRoleSkilsArray: this.state.selectedRoleSkilsArray = item['selectedSkills'].filter(f => f != chip.skillcode)
                                                })
                                              }
                                              this.selectJobRoleSkills(item, this.state.selectedRoleSkilsArray)
                                            }}
                                            id={chip.skillcode}
                                            key={chip.skillcode}
                                            label={chip.skillname}
                                          />
                                        )
                                        )}
                                      </ChipSet>
                                    </FormGroup>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ))}
              </ModalBody>
              <div className='stickyButton' style={{ bottom: '0px' }}>
                {this.state.showJobFunctionDiv == true && <><button color="orange" className="btnnext" style={{ margin: "8px 7px" }} onClick={(e) => { this.goToSkill(e) }}>{stringsoflanguages.langselectnext}</button>
                </>}
                {this.state.showSkillsDiv == true && <>
                  {this.state.isAddJobRole && <><button className="btnprevious" onClick={() => this.gotoPrevious()}>{stringsoflanguages.pre_me}</button> <button className="btnnext" onClick={() => this.updateJobRole(this.state.selectedJobRoles)}>{stringsoflanguages.ref_update}</button></>}
                  {!this.state.isAddJobRole && <><button className="btnnext" onClick={() => this.updateJobRole(this.state.selectedJobRoles)}>{stringsoflanguages.ref_update}</button>
                    <button className="btnprevious" onClick={this.editcloseModal}>{stringsoflanguages.Close}</button></>} </>}
              </div>
            </Modal>
            <Modal isOpen={this.state.showDeletePopup} className='JobList_Popup'>
              <ModalHeader style={{ display: 'none' }} >
              </ModalHeader>
              <ModalBody>
                <div className="mb-20 d-flex">
                  <h6>Are You Sure Want to delete this job role?</h6>
                  <div>  <a className="close_btn" style={{ cursor: 'pointer' }} onClick={() => this.closejobRoleModal()}>
                    <i className="ti ti-close"></i>
                  </a> </div>
                </div>
              </ModalBody>
            </Modal>
            <Modal isOpen={this.state.showModal} className='JobList_Popup modal-xl'>
              <ModalHeader style={{ display: 'none' }}></ModalHeader>
              <ModalBody>
                <div className="ttm-row sidebar job-sidebar clearfix" id="profileScreen">
                  <div className="">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg border rounded p-40 p-lg-20">
                          <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                          <div className="ttm-tabs ttm-tab-style-02">
                            <div className="content-tab">
                              <form className="login_form wrap-form">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="col-lg-12">
                                      <FormGroup>
                                        <label>{stringsoflanguages.ref_name}*</label>
                                        <label>
                                          <i className="ti ti-user"></i>
                                          <input
                                            type="text"
                                            id="txtempname"
                                            placeholder={stringsoflanguages.registration_user}
                                            tabIndex="1"
                                            value={this.state.updatedname}
                                            onChange={(e) => this.setState({ updatedname: e.target.value })}
                                          />
                                        </label>
                                      </FormGroup>
                                    </div>
                                    <div className="col-lg-12">
                                      <FormGroup>
                                        <label>{stringsoflanguages.rl_gender}*</label>
                                        <ChipSet
                                          choice
                                          handleSelect={(selectedChipIds) =>
                                            this.setState({ selectedGender: selectedChipIds })
                                          }
                                        >
                                          {Array.isArray(this.state.genderChips) &&
                                            this.state.genderChips.map((chip) => (
                                              <Chip
                                                className={
                                                  this.state.selectedGender.includes(chip.gendercode)
                                                    ? "selectedChipBg"
                                                    : "chipBg"
                                                }
                                                id={chip.gendercode}
                                                key={chip.gendercode}
                                                label={chip.gendername}
                                              />
                                            ))}
                                        </ChipSet>
                                      </FormGroup>
                                    </div>
                                    <div className="col-lg-12">
                                      <FormGroup>
                                        <label>{stringsoflanguages.rl_maritial}*</label>
                                        <ChipSet
                                          choice
                                          handleSelect={(selectedChipIds) =>
                                            this.setState({ selectedMarital: selectedChipIds })
                                          }
                                        >
                                          {Array.isArray(this.state.maritalChips) &&
                                            this.state.maritalChips.map((chip) => (
                                              <Chip
                                                className={
                                                  this.state.selectedMarital.includes(chip.maritalcode)
                                                    ? "selectedChipBg"
                                                    : "chipBg"
                                                }
                                                id={chip.maritalcode}
                                                key={chip.maritalcode}
                                                label={chip.maritalname}
                                              />
                                            ))}
                                        </ChipSet>
                                      </FormGroup>
                                    </div>
                                    <div className="col-lg-12">
                                      <FormGroup>
                                        <label>{stringsoflanguages.edu_trt}*</label>
                                        <ChipSet
                                          choice
                                          handleSelect={(selectedChipIds) =>
                                            this.setState({ selectedEducation: selectedChipIds })
                                          }
                                        >
                                          {Array.isArray(this.state.educationChips) &&
                                            this.state.educationChips.map((chip) => (
                                              <Chip
                                                className={
                                                  this.state.selectedEducation.includes(chip.educationcategorycode)
                                                    ? "selectedChipBg"
                                                    : "chipBg"
                                                }
                                                id={chip.educationcategorycode}
                                                key={chip.educationcategorycode}
                                                label={chip.educationcategoryname}
                                              />
                                            ))}
                                        </ChipSet>
                                      </FormGroup>
                                    </div>
                                    <div className="col-lg-12">
                                      <FormGroup>
                                        <label>{stringsoflanguages.candidate_job_type}*</label>
                                        <ChipSet
                                          choice
                                        >
                                          {this.state.filteredJobType.map((chip) => (
                                            <Chip
                                              className={
                                                this.state.selectedJobType.findIndex(f => f == chip.jobtypecode) > -1
                                                  ? "selectedChipBg"
                                                  : "chipBg"
                                              }
                                              onClick={() => {
                                                if (this.state.selectedJobType && this.state.selectedJobType.length == 4 && this.state.selectedJobType.findIndex(f => f == chip.jobtypecode) == -1) {
                                                  toast.error("You can select upto 4 Job Types", {
                                                    position: toast.POSITION.TOP_RIGHT,
                                                    className: 'foo-bar'
                                                  });
                                                  return;
                                                } else if (!this.state.selectedJobType || this.state.selectedJobType.findIndex(f => f == chip.jobtypecode) == -1) {
                                                  this.state.selectedJobType.push(chip.jobtypecode)
                                                  this.setState({
                                                    selectedJobType: this.state.selectedJobType
                                                  })
                                                } else {
                                                  this.setState({
                                                    selectedJobType: this.state.selectedJobType = this.state.selectedJobType.filter(f => f != chip.jobtypecode)
                                                  })
                                                }
                                              }}
                                              id={chip.jobtypecode}
                                              key={chip.jobtypecode} // The chip's key cannot be its index, because its index may change.
                                              label={chip.jobtypename}
                                            />
                                          ))}
                                        </ChipSet>
                                      </FormGroup>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="col-lg-12">
                                      <label>{stringsoflanguages.Candidate_location}*</label>
                                      <label>
                                        <i className="ti ti-location-pin"></i>
                                        <select
                                          tabIndex="2"
                                          value={this.state.showCandidateLocation}
                                          onChange={(e) => {
                                            if (e.target.value != "0") {
                                              var valueArrr = e.target.value.split("-");
                                              if (valueArrr.length > 0) {
                                                var stateCode = valueArrr[1];
                                                var districtCode = valueArrr[0];
                                                this.setState({ candidateDistrictValue: this.state.candidateDistrictValue = Number(districtCode) })
                                                this.setState({ candidateStateValue: this.state.candidateStateValue = Number(stateCode) })
                                                this.setState({ showCandidateLocation: this.state.showCandidateLocation = districtCode.toString() + "-" + stateCode.toString() })
                                              }
                                            }
                                            else {
                                              this.setState({ candidateDistrictValue: this.state.candidateDistrictValue = "0" })
                                              this.setState({ candidateStateValue: this.state.candidateStateValue = 0 })
                                              this.setState({ showCandidateLocation: this.state.showCandidateLocation = "" })
                                            }
                                          }}
                                        >
                                          <option value="0">{stringsoflanguages.Employee_known_text}</option>
                                          {this.state.dpdistrict.map((o) => (
                                            <option
                                              key={o.districtcode}
                                              value={o.districtcode.toString() + "-" + o.statecode.toString()}
                                            >
                                              {o.districtname}
                                            </option>
                                          ))}
                                        </select>
                                      </label>
                                    </div>
                                    <div className="col-lg-12">
                                      <label>{stringsoflanguages.preferedLocation}*</label>
                                      <label>
                                        <i className="ti ti-location-pin"></i>
                                        <select
                                          tabIndex="5"
                                          value={this.state.location}
                                          onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            if (selectedValue && selectedValue !== "0") {
                                              const valueArr = selectedValue.split("-");
                                              if (valueArr.length === 2) {
                                                const districtCode = valueArr[0];
                                                const stateCode = valueArr[1];
                                                this.setState({
                                                  dpdistrictValue: Number(districtCode),
                                                  dpstateValue: Number(stateCode)
                                                });
                                                this.setState({ location: this.state.location = districtCode.toString() + "-" + stateCode.toString() })
                                              }
                                            } else {
                                              this.setState({ dpdistrictValue: this.state.dpdistrictValue = "0" })
                                              this.setState({ dpstateValue: this.state.dpstateValue = 0 })
                                              this.setState({ location: this.state.location = "" })
                                            }
                                          }}
                                        >
                                          <option value="0">{stringsoflanguages.Employee_known_text}</option>
                                          {Array.isArray(this.state.dpdistrict) &&
                                            this.state.dpdistrict.map((o) => (
                                              <option
                                                key={o.districtcode}
                                                value={o.districtcode.toString() + "-" + o.statecode.toString()}
                                              >
                                                {o.districtname}
                                              </option>
                                            ))}
                                          <option value="-1">{stringsoflanguages.any_location}</option>
                                        </select>
                                      </label>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="col-lg-12">
                                        <FormGroup>
                                          <label>{stringsoflanguages.date_of_birth}*</label>
                                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker stmaxDateofbirthyle={{ background: 'none' }}
                                              className='form-control datepick'
                                              onChange={(e) => this.setState({ updateddate: e.$d })}
                                              defaultValue={dayjs(this.state.updateddate)}
                                              maxDate={dayjs(this.state.maxDateofbirth)}
                                              format="DD/MM/YYYY"
                                            />
                                          </LocalizationProvider>
                                        </FormGroup>
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <FormGroup>
                                        <label>{stringsoflanguages.wrk_pro}*</label>
                                        <ChipSet
                                          choice
                                          handleSelect={(selectedChipIds) =>
                                            this.setState({ selectedWorkExperience: selectedChipIds })
                                          }
                                        >
                                          {Array.isArray(this.state.workexperienceChips) &&
                                            this.state.workexperienceChips.map((chip) => (
                                              <Chip
                                                className={
                                                  this.state.selectedWorkExperience.includes(chip.id)
                                                    ? "selectedChipBg"
                                                    : "chipBg"
                                                }
                                                id={chip.id}
                                                key={chip.id}
                                                label={chip.value}
                                              />
                                            ))}
                                        </ChipSet>
                                      </FormGroup>
                                    </div>
                                    <div className="col-lg-12">
                                      <FormGroup>
                                        <label>{stringsoflanguages.personal_lg}*</label>
                                      </FormGroup>
                                      {Array.isArray(this.state.languagesknown) && this.state.languagesknown.map((lang) => (
                                        <FormGroup style={{ display: 'flex' }} className="col-lg-12 cls-lang-frm">
                                          <label className="col-lg-4 cls-lang-lbl col-md-12">{lang.languagename}</label>
                                          <ChipSet className="col-lg-8 col-md-12"
                                            choice
                                            handleSelect={(selectedChipIds) => {
                                              this.setState({
                                                selectedlanguage:
                                                  (this.state.selectedlanguage =
                                                    selectedChipIds),
                                              })
                                              lang.status = 1
                                              lang.knowntype.map(e => {
                                                if (selectedChipIds.includes(e.knownto)) {
                                                  if (lang.knowntype.findIndex(f => ((f.status == 0) && (f.knownto == this.state.selectedlanguage))) != -1) {
                                                    return e.status = 1;
                                                  }
                                                  else if (lang.knowntype.findIndex(f => ((f.status == 1) && (f.knownto == this.state.selectedlanguage))) != -1) {
                                                    return e.status = 0;
                                                  }
                                                }
                                              })
                                              return lang;
                                            }
                                            }
                                          >
                                            {lang.knowntype.map(
                                              (chip) => (
                                                <Chip
                                                  className={
                                                    chip.status == 1
                                                      ? "selectedChipBg"
                                                      : "chipBg"
                                                  }
                                                  id={chip.knownto}
                                                  key={chip.knownto} // The chip's key cannot be its index, because its index may change.
                                                  label={chip.knownto == 'read' ? stringsoflanguages.lang_read_text : chip.knownto == 'write' ? stringsoflanguages.lang_write_text : chip.knownto == 'speak' ? stringsoflanguages.lang_speak_text : ''}
                                                />
                                              )
                                            )}
                                          </ChipSet>
                                        </FormGroup>
                                      ))}
                                    </div>
                                    <div className="col-lg-12">
                                      <FormGroup>
                                        <label>{stringsoflanguages.cur_ex}*</label>
                                        <label>
                                          <div className="row">
                                            <div className="col-lg-4">
                                              <label className="col-lg-2">{stringsoflanguages.min_salary}</label>
                                              <input
                                                type="number"
                                                id="txtempname1"
                                                placeholder={stringsoflanguages.min_salary}
                                                autoComplete="off"
                                                tabIndex="5"
                                                className="clsInput"
                                                value={this.state.updatedminsalary}
                                                onKeyPress={(e) => this.number_only(e)}
                                                onChange={(e) => this.setState({ updatedminsalary: e.target.value })}
                                                onBlur={this.checkInputFieldEmpty}
                                              /></div>
                                            <div className="col-lg-4">
                                              <label className="col-lg-2">{stringsoflanguages.max_salary}</label>
                                              <input
                                                type="number"
                                                id="txtempname"
                                                placeholder={stringsoflanguages.pre_header_popup_sal}
                                                autoComplete="off"
                                                tabIndex="1"
                                                className="clsInput"
                                                value={this.state.updatedsalary}
                                                onKeyPress={(e) => this.number_only(e)}
                                                onChange={(e) => this.setState({ updatedsalary: e.target.value })}
                                              /></div>
                                          </div>
                                        </label>
                                      </FormGroup>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <div className="button-container stickyButton" style={{ textAlign: 'center' }}>
                <button className="btnnext" onClick={this.handleUpdate}>
                  {stringsoflanguages.employer_ref_update}
                </button>
                <button className='btnprevious' onClick={this.closeModal}>
                  {stringsoflanguages.Close}
                </button>
              </div>
            </Modal></div>
          : null}
      </div >
    )
  }
}
export default Candidate_details;
import React, { Component } from "react";
import Slider from 'react-slick';
import stringsoflanguages from '../../Stringsoflanguage'
import { IntlContext } from "../../utility/context/Internationalization"
import { Card, CardHeader, CardBody, CardTitle, Modal, ModalBody, ModalHeader, Button } from "reactstrap"
import { APIURL, COMMON } from '../../config'
import axios from "axios";
import OtpInput from 'react-otp-input';
import { history } from "../../history";
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import { generateRefreshToken } from '../../serviceworker';
let error_log = require('./../../error_log');
const notify = () => {


    toast("Custom Style Notification with css class!", {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
    });
}
// import { toast } from "react-toastify"
// import "react-toastify/dist/ReactToastify.css"
// import { ToastContainer } from "react-toastify"
const publicIp = require('public-ip');
//let history = useHistory();

export class Banner extends Component {
    constructor() {
        super();

        this.state = {
            show: false,
            search_flag: false,
            ipurl: APIURL,
            otpRequested: false,
            otp: '',
            minutes: 0,
            seconds: 30,
            miliSeconds: 0,

            isStarted: false,
            timer: [0, 0, 0],
            timerId: '',
            showMobileNumdiv: true,
            showOTPdiv: false,
            enableResend: false
        }

    }




    async componentDidMount() {   // stringsoflanguages.setLanguage('ta')
        try {
            const lastVisitTime = localStorage.getItem('lastVisitTime');
            // if (!lastVisitTime || (Date.now() - parseInt(lastVisitTime, 10)) >= 24 * 60 * 60 * 1000) {
            //     sessionStorage.clear()
            //     localStorage.setItem('lastVisitTime', Date.now());
            // }
            //context.switchLanguage('en')
            // alert(localStorage.getItem('languagecode'))
            let langcode = (localStorage.getItem('languagecode') == null || localStorage.getItem('languagecode') == undefined || localStorage.getItem('languagecode') == 0 || localStorage.getItem('languagecode') == '') ? 2 : localStorage.getItem('languagecode')
            let shortcode = (localStorage.getItem('languagecode_short') == null || localStorage.getItem('languagecode_short') == undefined || localStorage.getItem('languagecode_short') == 0 || localStorage.getItem('languagecode_short') == '') ? 'en' : localStorage.getItem('languagecode_short')
            // alert(shortcode);
            stringsoflanguages.setLanguage(shortcode)
            localStorage.setItem('languagecode', langcode)
            localStorage.setItem('languagecode_short', shortcode)
            // this.setState({ languagecode_color: this.state.languagecode_color = 2 })
            var my_ipaddress = await publicIp.v4();
            localStorage.setItem('ipaddress', my_ipaddress)
            //context.switchLanguage('en')localStorage.getItem('languagecode') == null
        }
        catch (error) { }
    }


    search() {

        let empcode = localStorage.getItem('employeecode');
        if (empcode == null || empcode == undefined || empcode == 0 || empcode == -1) {

            this.setState({ search_flag: this.state.search_flag = true })
        }
        else {
            history.push('/Job_list');
        }


    };
    // Checkmobnum() {
    //     if (this.state.mobnum.length === 0) {
    //         this.setState({
    //             errormobnum: this.state.errormobnum = 'Please enter mobile number',
    //             txtmobnum_invalid: this.state.txtmobnum_invalid = true
    //         })
    //     }
    //     else if (this.state.mobnum != 0) {
    //         var num = this.state.mobnum;
    //         if (!isNaN(num)) {
    //             var i = this.state.mobnum.length;
    //             if (i != 10) {
    //                 this.setState({
    //                     errormobnum: this.state.errormobnum = 'Please enter valid mobile number',
    //                     txtmobnum_invalid: this.state.txtmobnum_invalid = true
    //                 })
    //             }
    //             else if (i == 10) {
    //                 this.setState({
    //                     errormobnum: this.state.errormobnum = '',
    //                     txtmobnum_invalid: this.state.txtmobnum_invalid = false
    //                 })
    //             }
    //         }
    //         else {
    //             this.setState({
    //                 errormobnum: this.state.errormobnum = 'Please enter valid mobile number',
    //                 txtmobnum_invalid: this.state.txtmobnum_invalid = true
    //             })
    //         }
    //     }
    //     // if (this.state.errormobnum.length == 0) {
    //     //     try {
    //     //         axios({
    //     //             method: 'Post',
    //     //             url: this.state.ipurl + '/employee/registermobileno/?',
    //     //             params: {
    //     //                 mobileno: this.state.mobnum,
    //     //             },
    //     //         })
    //     //             .then(response => {
    //     //                 if (response.data.hasOwnProperty('employee_json_result')) {
    //     //                     this.state.varstatuscode = response.data.employee_json_result.hasOwnProperty('varstatuscode') ? response.data.employee_json_result.varstatuscode : 0;
    //     //                     if (this.state.varstatuscode == 12) {
    //     //                         this.setState({
    //     //                             errormobnum: this.state.errormobnum = 'Mobile number already exits',
    //     //                             txtmobnum_invalid: this.state.txtmobnum_invalid = true
    //     //                         })
    //     //                     }
    //     //                     else if (this.state.varstatuscode == 16) {
    //     //                         this.setState({
    //     //                             errormobnum: this.state.errormobnum = '',
    //     //                             txtmobnum_invalid: this.state.txtmobnum_invalid = false
    //     //                         })
    //     //                     }
    //     //                 }
    //     //             })
    //     //             .catch((error) => {

    //     //             });
    //     //     } catch (error) { }
    //     // }
    // }
    number_only = (event) => {
        try {
            const regex = /^[0-9 ]*$/;
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
            return true;
        }
        catch (error) {
            error_log.ERRORLOG(0, this.state.version, "Sign_Up", "number_only", 0, error.toString());
        }
    }

    Checkmobnum() {
        if (this.state.mobnum == "") {
            this.setState({
                errormobnum: 'Please enter valid mobile number',
                txtmobnum_invalid: true
            });

        } else {
            var num = this.state.mobnum;
            if (num.length == 10) {
                this.setState({
                    errormobnum: '',
                    txtmobnum_invalid: false
                });

                // try { 
                //   axios({ 
                //     method: 'Post', 
                //     url: this.state.ipurl + '/employee/registermobileno/?', 
                //     params: { 
                //       mobileno: this.state.mobnum, 
                //     }, 
                //   }) 
                //     .then(response => { 
                //       if (response.data.hasOwnProperty('employee_json_result')) { 
                //         this.state.varstatuscode = response.data.employee_json_result.hasOwnProperty('varstatuscode') ? response.data.employee_json_result.varstatuscode : 0; 
                //         if (this.state.varstatuscode == 12) { 
                //           this.setState({ 
                //             txtmobnum_invalid: true 
                //           }); 
                //         } else if (this.state.varstatuscode == 16) { 
                //           this.setState({ 
                //             errormobnum: '', 
                //             txtmobnum_invalid: false 
                //           }); 
                //         } 
                //       } 
                //     }) 
                //     .catch((error) => { 

                //     }); 
                // } catch (error) { } 
            } else {
                this.setState({
                    errormobnum: 'Please enter a valid 10-digit mobile number',
                    txtmobnum_invalid: true
                });
            }
        }
    }

    changemob() {
        this.setState({ showMobileNumdiv: this.state.showMobileNumdiv = true })
        this.setState({ showOTPdiv: this.state.showOTPdiv = false })
        this.setState({ mobnum: "" })
    }

    onStart() {

        this.setState({ enableResend: this.state.enableResend = false })
        if (!this.state.isStarted) return;
        if (this.state.seconds > 0) {
            //3rd
            this.setState({ seconds: this.state.seconds = this.state.seconds - 1 })
        }
        else {
            this.setState({ seconds: this.state.seconds = 0 })
            // this.setState({ timerId:this.state.timerId = timerId});
            this.setState({ isStarted: this.state.isStarted = false });
            this.setState({ enableResend: this.state.enableResend = true })
            clearInterval(this.state.timerId);
        }
    };

    onReset() {

        this.setState({ isStarted: this.state.isStarted = false })
        this.setState({ timer: this.state.timer = [0, 0, 0] });

    };


    onchangeOTP(e) {
        //alert(e.target.value);
        let value = e;
        if (this.state.otp != "" && this.state.otp.length == 5) {
            this.setState({ otp: this.state.otp = value });
            this.verifyOtp();
        }
        else {
            this.setState({ otp: this.state.otp = value });
        }
    }

    getOtp() {
        // const history = useHistory();
        // history.push('/Job_list');
        // return false;s
        // const history = useHistory();
        // history.push('/Job_list');
        // return false;
        var accessToken = localStorage.getItem('accessToken');
        if (this.state.mobnum != '' && this.state.txtmobnum_invalid == false) {
            try {
                axios({
                    method: 'Post',
                    url: this.state.ipurl + 'sendsms/getOTP/',
                    headers: {
                        'Authorization': 'Bearer ' + accessToken,
                        'Content-Type': 'application/json'
                      },
                    params: {
                        mobileno: this.state.mobnum,
                        ipaddress: localStorage.getItem('ipaddress'),
                        typecode: 9

                    },
                })
                    .then(async response => {
                        if (response.data.status == 401) {
                            await generateRefreshToken().then((result) => {
                              if (result == 1) {
                                this.getOtp()
                              }
                              else {
                                // alert('Unauthorized')
                              }
                            })
                  
                          }
                          else{
                        if (response.data.hasOwnProperty('sendotp_json_result')) {
                            this.state.varstatuscode = response.data.sendotp_json_result.hasOwnProperty('varstatuscode') ? response.data.sendotp_json_result.varstatuscode : 57;
                            if (this.state.varstatuscode == 57) {
                                // toast.success("OTP Sent Successfully!!! by Kavitha", {
                                //     autoClose: 2000
                                // });
                                localStorage.setItem('mobileNumber', this.state.mobnum);
                                this.setState({
                                    errorotp: 'Success! SMS code received',
                                    txtotpnum_invalid: false,
                                    otpRequested: true,
                                })
                                this.setState({ showMobileNumdiv: this.state.showMobileNumdiv = false })
                                this.setState({ showOTPdiv: this.state.showOTPdiv = true })
                                this.setState({ seconds: this.state.seconds = 30 })
                                this.setState({ isStarted: this.state.isStarted = true });
                                var timerId = setInterval(() => this.onStart(), 1000);
                                this.setState({ timerId: this.state.timerId = timerId });
                                //   alert("Success! SMS code received.");

                            }
                        }
                    }
                    })
                    .catch((error) => {

                    });
            } catch (error) { }
        }
        else {
            this.setState({
                errormobnum: this.state.errormobnum = 'Please enter valid mobile number',
                txtmobnum_invalid: this.state.txtmobnum_invalid = true
            })
        }
        this.setState({ enableResend: this.state.enableResend = false })

        // navigate('/job_list');

    }
    verifyOtp() {
        var accessToken = localStorage.getItem('accessToken');
        try {
            axios({
                method: 'Post',
                url: this.state.ipurl + 'sendsms/verifyOTP/?',
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json'
                  },
                params: {
                    mobileno: this.state.mobnum,
                    otpvalue: this.state.otp,
                    ipaddress: localStorage.getItem('ipaddress'),
                    typecode: 9

                },
            })
                .then(async response => {
                    if (response.data.status == 401) {
                        await generateRefreshToken().then((result) => {
                            if (result == 1) {
                                this.verifyOtp();
                            }
                            else {
                                // alert('Unauthorized')
                            }
                        })
    
                    }
                    else {
                    if (response.data.hasOwnProperty('employer_json_result')) {
                        this.state.varstatuscode = response.data.employer_json_result.hasOwnProperty('varstatuscode') ? response.data.employer_json_result.varstatuscode : 61;
                        if (this.state.varstatuscode == 67) {
                            let employeecode = response.data.employer_json_result.hasOwnProperty('employeecode') ? response.data.employer_json_result.employeecode : -1;
                            if (employeecode > 0) {

                                localStorage.setItem('enableLogin', "true");
                                localStorage.setItem('employeecode', employeecode);
                                //localStorage.setItem('languagecode', 1);
                                history.push('/job_list');
                                //window.location.reload();
                            } else if (employeecode == -1) {
                                history.push('/createProfile');
                                // window.location.reload();
                            }
                        }
                        else if (this.state.varstatuscode == 61) {
                            this.setState({
                                Expiredotp: 'Success! SMS code Expired OTP',
                                txtotpnum_invalid: false
                            })
                            toast.error("OTP Expired.", {
                                position: toast.POSITION.TOP_RIGHT,
                                className: 'foo-bar'
                            });
                            //window.location.href = "http://localhost:3000/job_list";

                        }
                        else {
                            this.setState({
                                Expiredotp: 'Success! SMS code Expired OTP',
                                txtotpnum_invalid: false
                            })
                            toast(response.data.employer_json_result.responsestring, {
                                position: toast.POSITION.TOP_RIGHT,
                                className: 'foo-bar'
                            });
                        }
                    }
                }
                })
                .catch((error) => {

                });
        } catch (error) { }
    }

    render() {
        var slick_slider = {
            dots: false,
            arrow: false,
            autoplay: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 1,

            responsive: [{

                breakpoint: 1199,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {

                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {

                breakpoint: 680,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        };

        return (
            <React.Fragment>
                <Slider className="slick_slider banner_slider banner_slider_3" {...slick_slider} slidesToShow={1} autoplay={false} fade="true" >
                    <div className="slide bg-layer">
                        <ToastContainer autoClose={10000} toastStyle={{ backgroundColor: "#ed9d3b", color: 'white' }} progressStyle={{ background: 'white' }} />
                        {/* <div className="slide_img" style={{ backgroundImage: 'url(https://via.placeholder.com/1920x730?text=slider-mainbg-005-1920x730.jpg)' }}></div> */}
                        <div className="slide_img" style={{ backgroundColor: "white" }}></div>
                        <div className="slide__content pb-50 pb-xl-0 pt-130 pt-xl-0">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 col-md-8">
                                        <div className="slide__content--headings ttm-textcolor-white">
                                            <h2 className="animated animate__fadeInDown">With<span className="text-theme-SkinColor fw-normal" style={{ textTransform: 'uppercase' }}> JOBANYA</span></h2>
                                            <h2 className="animated animate__fadeInDown"> You Can Easily Find Jobs And Employees In<span className="text-theme-SkinColor fw-normal"> Tamil Nadu & Puducherry</span></h2>
                                            <div className="mt-20 mb-30 mb-md-15">
                                                <p className="animated animate__fadeInDown d-block d-md-block">
                                                    2,000+ Jobs  &nbsp; &nbsp;75,000+ Employees</p>

                                            </div>
                                            <div className="animated animate__fadeInUp" data-delay="1.4">
                                                <a style={{ textTransform: 'none' }}
                                                >
                                                    <button className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor" onClick={e => { this.search(); }}> Want a Job?</button>
                                                </a>
                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" style={{ marginLeft: '20px', textTransform: 'none' }}
                                                    href="https://employer.jobanya.com/" target="_blank">Want to Hire?</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-none d-xl-block text-center position-relative">
                                            <div className="z-index-1 position-relative">
                                                <img className="rounded-circle box-shadow m-auto img-fuild" src="images/location-image/jobanya-img.png"></img>
                                            </div>
                                            <div className="cmp-logo one">
                                                <img className="img-fluid" src="images/location-image/chennai.png" />
                                            </div>
                                            <div className="cmp-logo two">
                                                <img className="img-fluid" src="images/location-image/madurai.png" />
                                            </div>
                                            <div className="cmp-logo three">
                                                <img className="img-fluid" src="images/location-image/salem.png" />
                                            </div>
                                            <div className="cmp-logo four">
                                                <img className="img-fluid" src="images/location-image/coimbatore.jpg" />
                                            </div>
                                            <div className="cmp-logo five">
                                                <img className="img-fluid" src="images/location-image/tiruchi.png" />
                                            </div>
                                            <div className="arrow-one">
                                                <img src="images/slides/arrow-1.png" />
                                            </div>
                                            <div className="arrow-two">
                                                <img src="images/slides/arrow-2.png" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div><ToastContainer /></div> */}
                    </div>
                </Slider>
                <Modal
                    style={{ width: '350px!important' }}
                    backdrop="static"
                    id="setting1"
                    isOpen={this.state.search_flag}

                >
                    <ModalHeader style={{ alignSelf: 'flex-end', alignItems: 'flex-end', alignContent: 'flex-end', borderColor: 'white' }} className="profile-modal-color bg-mycolortext">
                        <a className="close_btn" onClick={() => this.setState({ search_flag: false })}>
                            <i className="ti ti-close"></i>
                        </a>
                    </ModalHeader>
                    <ModalBody>
                        <div className="content-tab">
                            <div className="text-center" style={{ marginTop: "-50px" }}>
                                <img src="images/footer-logo.png" alt="Your Logo" />
                                <p style={{ fontSize: 12, color: 'black' }}>User Friendly & Trusted HR Manager</p>
                            </div>
                            <div id="login_form" className="login_form wrap-form">
                                <div className="row">
                                    <div className="col-lg-12 text-center mt-5">
                                        <p style={{ fontSize: 18 }}> Find local jobs with better salary! </p>
                                    </div>
                                    {this.state.showMobileNumdiv &&
                                        (<div className="row">
                                            <div className="col-lg-6">
                                                <label>
                                                    <i className="ti ti-mobile"></i>
                                                    <input type="number"
                                                        id="txtusername"
                                                        placeholder="Mobile No."
                                                        autoComplete="off"
                                                        maxLength={10}
                                                        className="clsInput"
                                                        value={this.state.mobnum}
                                                        onKeyPress={(e) => this.number_only(e)}
                                                        onChange={(e) => {
                                                            this.setState({ mobnum: e.target.value });
                                                            // const regex = /^[0-9,-]*$/;
                                                            // const key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
                                                            // if (!regex.test(key)) {
                                                            //     e.preventDefault();
                                                            //     return false;

                                                            // }
                                                            // else
                                                            // {
                                                            //     this.setState({ mobnum: inputValue }); 
                                                            //     return true;
                                                            // }

                                                            // const inputValue = e.target.value; 
                                                            // if (/^[0-9]*$/.test(inputValue)) { 
                                                            //   this.setState({ mobnum: inputValue }); 
                                                            // } 
                                                        }}
                                                        onBlur={() => this.Checkmobnum()}
                                                    />

                                                </label>
                                                {this.state.txtmobnum_invalid ? (
                                                    <span style={{ color: 'red' }}>{this.state.errormobnum}</span>
                                                ) : ('')}
                                            </div>
                                            <div className="featured-content btn-Align-Right col-lg-6" style={{ alignItems: 'flex-end', alignContent: 'flex-end', alignSelf: 'center' }}>
                                                <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" onClick={() => this.getOtp()}>
                                                    Get OTP
                                                </button>
                                            </div>

                                        </div>)}

                                    {this.state.otpRequested && this.state.showOTPdiv == true && (
                                        <div>
                                            <div className="row" style={{ marginTop: '16px' }}>

                                                <div className="col-lg-1" style={{ fontSize: '18px', color: '#000' }}> </div>
                                                <div className="col-lg-6" style={{ fontSize: '18px', color: '#000' }}>Please enter OTP</div>
                                            </div>
                                            <div className="row" style={{ marginBottom: "24px" }}>
                                                <div className="col-lg-1"></div>
                                                <div className="col-lg-11" style={{ fontSize: '14px' }}>{'We have sent an OTP on ' + this.state.mobnum}   <div onClick={() => this.changemob()} style={{ cursor: 'pointer', textDecorationLine: 'underline', color: 'green', fontSize: '10px' }}>Change Mobile Number</div></div>
                                            </div>

                                            <div className="row">

                                                <div className="col-lg-1"></div>
                                                <div className="col-lg-6 clsInput" style={{ marginBottom: "15px", fontSize: '14px' }}>

                                                    <OtpInput
                                                        value={this.state.otp}
                                                        inputType="number"

                                                        //onChange= {(e)=>{this.setState({otp:this.state.otp=e})}}
                                                        onChange={(e) => this.onchangeOTP(e)}
                                                        containerStyle={{ justifyContent: 'center' }}
                                                        inputStyle={{ width: '30px' }}

                                                        numInputs={6}
                                                        renderSeparator={<span>&nbsp;&nbsp;</span>}
                                                        renderInput={(props) => <input {...props} autoFocus />}
                                                    />
                                                    {!this.state.enableResend && (<div>Resend OTP in   {this.state.minutes < 10 && 0}
                                                        {this.state.minutes}:{this.state.seconds < 10 && 0}
                                                        {this.state.seconds}  </div>)}
                                                    {this.state.enableResend && (<div onClick={() => this.getOtp()} style={{ cursor: 'pointer', textDecorationLine: 'underline', color: 'green' }}>Resend OTP</div>)}
                                                </div>
                                                {/* <div className="col-lg-12">
                                                <label>
                                                    <i className="ti ti-eye"></i>
                                                    <input
                                                        type="text"
                                                        id="txtusername"
                                                        placeholder="OTP"
                                                        autoComplete="off"
                                                        value={this.state.username}
                                                        onChange={(e) => {
                                                            this.setState({ username: e.target.value });
                                                        }}
                                                    />
                                                </label>
                                            </div> */}

                                                <div className="featured-content btn-Align-Right col-lg-4" style={{ alignItems: 'flex-end', alignContent: 'flex-end' }}>
                                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" onClick={() => this.verifyOtp()}>
                                                        Verify OTP
                                                    </button>



                                                </div>
                                            </div>
                                            {/* <div className="col-lg-6 ">                                               
                                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" onClick={() => this.verifyOtp()}>Verify OTP</button>                                               
                                            </div> */}
                                            {/* <div className="col-lg-6 ">
                            <label className="mb-0">
                                <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" onClick={() => this.getOtp(9)}>Resend OTP</button>
                            </label>
                        </div> */}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal
                    backdrop="static"
                    id="setting1"

                >
                    <ModalHeader style={{ alignSelf: 'flex-end', alignItems: 'flex-end', alignContent: 'flex-end', borderColor: 'white' }} className="profile-modal-color bg-mycolortext">

                        <a className="close_btn" onClick={() => this.setState({ search_flag: this.state.search_flag = false })} >
                            <i class="ti ti-close"></i>
                        </a>
                    </ModalHeader>
                    <ModalBody >
                        <div className="content-tab">
                            <div className="text-center" style={{ marginTop: "-50px" }}>
                                <img src="images/footer-logo.png" alt="Your Logo" />
                                <p style={{ fontSize: 18, color: 'black' }}>Employee Registration</p>
                            </div>
                            <div id="login_form" className="login_form wrap-form">
                                <div className="row">

                                    <div className="col-lg-12">
                                        <label>
                                            <i className="ti ti-mobile"></i>
                                            <input type="text"
                                                id="txtusername"
                                                placeholder="Mobile No."
                                                autocomplete="off"
                                                value={this.state.mobnum}
                                                onChange={(e) => {
                                                    this.setState({ mobnum: e.target.value });
                                                }}
                                                onBlur={() => this.Checkmobnum()}
                                            />
                                            {this.state.txtmobnum_invalid ? (
                                                <span style={{ color: 'red' }}>{this.state.errormobnum}</span>
                                            ) : ('')}
                                        </label>
                                    </div>
                                    <div className="featured-content btn-Align-Right" style={{ alignItems: 'flex-end', alignContent: 'flex-end', alignSelf: 'flex-end' }}>

                                        <a className="btnapply " onClick={() => this.getOtp(10)} style={{ alignItems: 'flex-end', alignContent: 'flex-end', alignSelf: 'flex-end' }}>Get OTP</a>
                                        < div className="col-lg-12">
                                            <label>
                                                <i className="ti ti-eye"></i>
                                                <input type="text"
                                                    id="txtusername"
                                                    placeholder="OTP"
                                                    autocomplete="off"
                                                    value={this.state.username}
                                                    onChange={(e) => {
                                                        this.setState({ username: this.state.username = e.target.value });
                                                    }}
                                                />
                                            </label>
                                        </div>
                                        <div className="featured-content btn-Align-Right" style={{ alignItems: 'flex-end', alignContent: 'flex-end', alignSelf: 'flex-end' }}>

                                            <a className="btnapply" onClick={() => this.getOtp(10)} style={{ alignItems: 'flex-end', alignContent: 'flex-end', alignSelf: 'flex-end' }}>Resend OTP</a>
                                        </div>
                                    </div>

                                    <div className="ttm-list-li-content">
                                        <input
                                            type="checkbox"
                                            id="agreedToConditions"
                                            name="agreedToConditions"
                                            style={{ verticalAlign: 'middle', marginLeft: '23px' }}
                                        />
                                        <label
                                            htmlFor="agreedToConditions"
                                            style={{ display: 'inline', verticalAlign: 'middle', marginLeft: '18px' }}
                                        >
                                            I agree to the JOBANYA <a href="https://jobanya.com/terms-conditions/" style={{ textDecoration: 'underline', color: 'skyblue' }}>Terms and Conditions</a>
                                        </label>
                                    </div>
                                    <div className="col-lg-6 mx-auto">
                                        <label className="mb-0">
                                            <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" onClick={() => this.user_login()}>sign up</button>
                                        </label>
                                    </div>


                                </div></div>

                        </div>






                    </ModalBody>

                </Modal>
            </React.Fragment>
        )
    }
}

export default Banner;
import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <footer className="footer widget-footer bg-theme-DarkColor text-theme-WhiteColor clearfix">
                <div className="second-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 widget-area">
                                <div className="widget widget_text clearfix">
                                    <div className="footer-logo">
                                        <img id="footer-logo-img" className="img-fluid auto_size" height="70" width="162" src="images/footer-logo.png" alt="footer-logo" />
                                    </div>
                                    <p>Jobanya is the most suitable platform to find your job. Job seekers and employers are connected through advanced search engines, which makes finding the right match easy and fast.</p>
                                    <div className="d-flex align-items-center pt-15 pb-25">
                                        <h6 className="fw-normal">Social Share: </h6>
                                        <div className="social-icons">
                                            <ul className="social-icons list-inline">
                                                <li><a href="https://www.facebook.com/jobanya.official" target="_blank" rel="noopener" aria-label="facebook">
                                                    <i className="ti ti-facebook"></i></a>
                                                </li>

                                                <li><a href="https://www.youtube.com/channel/UCXTknuz12ghgr9RGn-ObPBA" target="_blank" rel="noopener" aria-label="youtube">
                                                    <i className="ti-youtube"></i></a>
                                                </li>
                                                <li><a href="https://www.instagram.com/jobanya_official/?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener" aria-label="instagram">
                                                    <i className="ti-instagram"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white"
                                        href={'/contact'}>Request A Free Quotes</a> */}
                                        <div style={{fontSize:13}}>
                                        <a href={'/terms-conditions'}>Terms & Conditions</a>&nbsp;|&nbsp;
                                        <a href={'/privacy-policy'}>Privacy Policy</a>&nbsp;|&nbsp;
                                        <a href={'/disclaimer_clause'}>Disclaimer Clause</a>
                                        </div>
                                        
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area">
                                {/* <div className="widget widget-recent-post clearfix">
                                    <h3 className="widget-title">Recent Posts</h3>
                                    <ul className="widget-post ttm-recent-post-list">
                                        <li>
                                            <div className="post-detail">
                                                <div className="cat-link"><a href={'/blog_details'}>business</a></div>
                                                <a href={'/blog_details'}>Are  meetings falling to flat? Try these 10 tips</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="post-detail">
                                                <div className="cat-link"><a href={'/blog_details'}>business</a></div>
                                                <a href={'/blog_details'}>Are You Moving Businesses To Another State?</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="post-detail">
                                                <div className="cat-link"><a href={'/blog_details'}>business</a></div>
                                                <a href={'/blog_details'}>Shame: Does It Fine to Fit In The Workplace?</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area">
                                <div className="widget widget-contact clearfix">
                                    <h3 className="widget-title">Quick Contacts</h3>
                                    <ul className="widget_contact_wrapper">
                                        <li>
                                            <i className="ttm-textcolor-skincolor flaticon-phone-call"></i>
                                            <h3>Call Us On:</h3>+91 93843 78052</li>
                                        <li>
                                            <i className="ttm-textcolor-skincolor flaticon-placeholder"></i>
                                            <h3>Address:</h3>VinMeen Infotech <br />20, Spencers Compound,<br /> 13th Cross Road, Thiruvalluvar Salai, <br />Dindigul – 624003, Tamil Nadu</li>
                                        <li>
                                            <i className="ttm-textcolor-skincolor flaticon-email"></i>
                                            <h3>Email:</h3><a href="mailto:support@jobanya.com">support@jobanya.com</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-footer-text">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-6">
                                <span className="copyright">Copyright © 2023 <a href={'/'}>Jobanya </a>All rights reserved.
                                </span>
                                {/* <ul className="footer-nav-menu">
                                    <li><a href={'/'}>Privacy & Policy</a></li>
                                    <li><a href={'/'}>Terms & Conditions</a></li>
                                </ul> */}
                            </div>
                            <div className="col-lg-4 col-md-6 d-lg-flex align-items-center justify-content-between">
                                <ul className="footer-nav-menu">
                                    {/* <li><a href={'/'}>Privacy Policy</a></li>
                                    <li><a href={'/'}>Terms & Conditions</a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}



import React, { Component } from 'react';
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap"
import stringsoflanguages from '../../Stringsoflanguage'
import { IntlContext } from "../../utility/context/Internationalization"
import axios from "axios";
import { APIURL, COMMON } from '../../config';
import { generateRefreshToken } from '../../serviceworker';
let error_log = require('./../../error_log');
class Logo extends Component {
  constructor() {
    super();
    this.state = {
      enableLogin: 'false',
      ipurl: APIURL,
      languageCode: 2,
      languageData: [],
    }
  }
  componentDidMount() { 
    try{
      let langcode = (localStorage.getItem('languagecode') == null || localStorage.getItem('languagecode') == undefined || localStorage.getItem('languagecode') == 0 || localStorage.getItem('languagecode') == '') ? 2 : localStorage.getItem('languagecode')
      let shortcode = (localStorage.getItem('languagecode_short') == null || localStorage.getItem('languagecode_short') == undefined || localStorage.getItem('languagecode_short') == 0 || localStorage.getItem('languagecode_short') == '') ? 'en' : localStorage.getItem('languagecode_short') 
      stringsoflanguages.setLanguage(shortcode)
      localStorage.setItem('languagecode', langcode)
      localStorage.setItem('languagecode_short', shortcode)
      this.setState({ languagecode_color: this.state.languagecode_color = 2 })
      window.addEventListener('scroll', this.isSticky);
      this.setState({ enableLogin: localStorage.getItem('enableLogin') })
      if (localStorage.getItem('language_json_result') == null || localStorage.getItem('language_json_result') == 'null' || localStorage.getItem('language_json_result') == undefined || localStorage.getItem('language_json_result') == 'undefined')
        this.fetchLanguageData();
      else {
        let language_list = localStorage.getItem('language_json_result').toString() != 'null' ? JSON.parse(localStorage.getItem('language_json_result')) : {}
        this.bindLanguage(language_list)
      }
    }catch (error) {
      error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Logo", "componentDidMount", 0, error.toString());
    } 
  }
  fetchLanguageData() {
    try {
      var accessToken = localStorage.getItem('accessToken');
      axios({
        method: 'Post',
        url: this.state.ipurl + 'languagebind/?',
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        params: {
          ipaddress: '103.65.1.203'
        },
      })
        .then(async (response) => {
          if (response.data.status == 401) {
            await generateRefreshToken().then((result) => {
              if (result == 1) {
                this.fetchLanguageData();
              }
              else {
                // alert('Unauthorized')
              }
            })
          }
          else {
            if (response.data) {
              localStorage.setItem("language_json_result", JSON.stringify(response.data))
              this.bindLanguage(response.data);
            }
          }
        })
        .catch((error) => { 
        });
    } catch (error) { 
      error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Logo", "fetchLanguageData", 0, error.toString());
    }
  }
  bindLanguage(response) {
    try{
      if (response.hasOwnProperty('language_json_result')) {
        let languageList = response.language_json_result.languagelist;
        this.setState({ languageData: this.state.languageData = languageList });
      }
    }catch (error) { 
      error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Logo", "bindLanguage", 0, error.toString());
    } 
  }
  render() {
    return (
      <div>
        <a className="home-link" href={'/'} title="Jobanya" rel="home">
          <img id="logo-img" height="80" width="250" className="img-fluid auto_size" src={process.env.PUBLIC_URL + "/images/jobanya-logo.png"} alt="logo-img" />
        </a>
      </div>
    );
  }
}
export default Logo;
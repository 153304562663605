import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';

export class Terms_conditions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
        };
    }
    render() {

        
        return (
            <div className="site-main">
                <Header />

                {/* PageHeader */}
                <PageHeader
                    title="Terms & Conditions"
                    breadcrumb="Terms & Conditions"
                />
                {/* PageHeader end */}


                <div className="ttm-row sidebar ttm-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-12 content-area order-lg-12" style={{ maxWidth: '100%' }}>
                                {/* ttm-service-single-content-area */}
                                <div className="ttm-service-single-content-area">
                                    {/* <div className="ttm_single_image-wrapper">
                                        <img className="img-fluid" src="images/Services.jpg" alt="services-img" />
                                    </div> */}
                                    <div className="ttm-service-description mt-40">
                                        {/* <h3 className="mb-20" style={{ textAlign: 'center' }}>TERMS & CONDITIONS OF USE</h3> */}
                                        <p>This document is an electronic record in terms of the Information Technology Act, 2000, and rules there under, as applicable, and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
                                        <p>This document is published in accordance with the provisions of Rule 3(1) of the Information Technology (Intermediaries guidelines) Rules, 2011, that require publishing the rules and regulations, Privacy Policy and user agreement for access or usage of Jobanya and its services.</p>
                                        <p>Jobanya welcomes you! We are a Job & Jobseeker information service provider connecting jobseekers and employers / placement consultants & agencies in the employment / recruitment market. Jobanya and its subsidiaries and affiliates (collectively "Jobanya") provide you with access to a variety of services, including but not limited to the <a href={'/'} target="_blank">www.jobanya.com</a> website (the "Website") and Jobanya mobile applications (the “App”) and all the products and services available through our website (collectively the "Services"). This Website and App is owned and operated by M/s VinMeen Infotech, a company incorporated under the Indian Companies Act, 2013.</p>
                                        <p>You are allowed to use our Services only if you can form a binding contract with us, and only in compliance with our Terms of Use and all applicable laws. The following terms and conditions apply to everyone (including Jobseeker, Employer, Recruiter, Placement Consultants and Manpower Agencies) who uses Jobanya services, and they govern the relationship between Jobanya and its users during their use of Jobanya services.</p>
                                        <p>Please read these Terms carefully before using our services. By accessing and using our services, you explicitly agree to comply with and be bound by our Terms of Use and Privacy Policy.</p>
                                        <h5>The Services We Provide</h5>
                                        <p>Our Services connect you with Job Seekers, Jobs, or Employers (as applicable), giving you the ability to search for, browse, and communicate with Job Seekers, Jobs, or Employers (as applicable). However, we also use automated matching processes facilitated by algorithms within the Services which seek to match you with the most appropriate Job Seekers, Jobs, or Employers (as applicable) by reference to the data you have provided to us along with any criteria specified by the Job Seeker or Employer (as applicable). This may result in decisions being made automatically. For example, we may limit what jobs are visible to Job Seekers based on the qualifications and expertise required for that job. In relation to this automated processing, we ensure there are safeguards in place to protect your rights and freedoms and legitimate interests and you have the right to contest any automated decisions made and obtain human intervention to alter decisions made, where possible. We may also use non-algorithmic methods to match you with Job Seekers or jobs who we believe would be suitable for candidates or Employers who we believe have a suitable role for you (as applicable).</p>
                                        <p>Our Services may contain links to third party services. If you click on a link to access any of those third-party services, please note that they have their own terms of use and that we do not accept any responsibility or liability for their terms of use or processing of your personal information. Please check these terms of use before you submit any personal information to such third-party websites. </p>
                                        <p>Our Services also include paid services. By using or purchasing our services, you agree to these Terms of Use.</p>
                                        <h5>Registration and Use</h5>
                                        <p>Jobanya may require that you register in order to use some or all of the features and functionalities of the Website and App. We may, in our sole discretion, permit or deny your registration. Once we permit you to register with the Website and App, you agree and undertake to access the Website and App only using the email id / mobile number followed by password, as amended by you from time to time, associated with your registration. You will be solely responsible for maintaining the confidentiality of your password and agree that the password is intended for use solely by you. We may permit you to register with the Website and App using your Facebook or Gmail account. If you complete your sign up or registration via Facebook or Gmail, all applicable Facebook and Gmail terms of use and any changes to the same shall apply to you in addition to these Terms of Use.</p>
                                        <p>You understand that for the purpose of effectively using the Website and App you may be required to provide accurate information including but not limited to your name, address, date of birth, email address, phone number, picture, video, audio, location, job title, work history, skills, school attended, references, salary, preferences, opinions, intentions, interests, likes, dislikes, life history, major events, social network, friends, connections, associations, Aadhar number, PAN number, driving license number and/or any other identification proof number and represent that they are true and accurate. </p>
                                        <p>You agree and understand that this Website, App and the contents are intended for people who are 18 years of age or above. You hereby represent that you are 18 years of age or above and competent to contract within the meaning of section 11 of the Indian Contract Act, 1872. </p>
                                        <p>If you are registering as a Placement Consultants and / or Placement Agencies, you represent that you are duly authorized to register and by accepting these Terms of Use you bind such underlying business entity to these Terms of Use. </p>
                                        <p>You will be solely responsible for your acts on the Website and App including those conducted under the username assigned to you. You understand and agree that Jobanya may, in our sole discretion, terminate your access to the Website and App without notice and you waive any right to claim access to the Website and App or any other claim that you may have. Any data of your usage may be retained or deleted at Jobanya sole discretion. You agree that the personal information provided by you can be used and viewed by other third parties who access the Website and App.</p>
                                        <p>By using our Services and providing us with your information, you agree that we can send you communications about our services and matters incidental thereto. By using our services or by filling out any forms/agreements on or through the services, providing us with information or making an inquiry, you acknowledge that we have an established business relationship with you and you expressly consent to being contacted by us or our service providers, whether by phone, mobile phone, text (SMS), email, postal mail or otherwise. From time to time, when you call us we may wish to record those phone calls for quality assurance and customer's / user's satisfaction purposes. In those jurisdictions where consent is required in order for a party to record any telephone call, you consent for us to record your call when you call us.</p>
                                        <p>All information provided by you will be used and processed in accordance with these Terms of Use and the Privacy Policy.</p>
                                        <p>You agree that you have read and understood the Privacy Policy.</p>
                                        <h5>Permitted Use</h5>
                                        <p>In relation to the use and access of the Website and App, its content and features, you agree and undertake not to:</p>
                                        <ul className="ttm-list ttm-list-style-square ttm-list-icon-color-skincolor mb-30">
                                            <li>
                                                <div className="ttm-list-li-content">Take passwords or personally identifiable information from other Users.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">To do probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Accessing data not intended for such user or logging into a server or account which the user is not authorized to access.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">To interfere with service to any user, host or network, including, without limitation, via means of submitting a virus to any Jobanya website, App, servers etc., overloading, flooding, spamming, mail bombing or crashing.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Forging any TCP/IP packet header or any part of the header information in any e-mail or newsgroup posting.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Defer any contact from an employer to any agent, agency, or other third party.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Violations of system or network security may result in civil or criminal liability. The Company will investigate occurrences which may involve such violations and may involve, and cooperate with, law enforcement authorities in prosecuting users who are involved in such violations.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Post or publish any job on any Jobanya website and App for any competitor of the Company or posting jobs or other content that contains links to any site competitive with Jobanya.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Use any Jobanya website and App for any purpose other than as an employer or recruiter seeking jobseekers or as an jobseeker seeking job including but not limited to using the information in the Resume Database to sell or promote any products or services.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Post or submit to any Jobanya website and App any incomplete, false or inaccurate biographical information or information which is not your own or any misleading information.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Post on any Jobanya website and App any franchise, pyramid scheme, "club membership", distributorship or sales representative agency arrangement or other business opportunity which requires an up-front or periodic payment, pays commissions only (except for postings that make clear that the available job pays commission only and clearly describes the product or service that the job seeker would be selling, in which case such postings are permissible), requires recruitment of other members, sub-distributors or sub-agents.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Post the names, logos or trademarks of unaffiliated companies other than those of your customer save where expressly agreed by the Company.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Post the names of colleges, cities, states, towns or countries that are unrelated to the posting.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Post more than one job or job description, more than one location, or more than one job category, unless the website so allows.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Post any material or links to material that exploits people in a sexual, violent or other manner, or solicits personal information from anyone who is of an age of less than 18 years.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Post jobs that require citizenship of any particular country or lawful permanent residence in a country as a condition of employment, unless otherwise required in order to comply with law, regulations, executive order, or federal, state or local government contract.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Post jobs that include any screening requirement or criterion in connection with a job posting where such requirement or criterion is not an actual and legal requirement of the posted job.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Sell, promote or advertise products or services, unless otherwise agreed to contractually agree by the Company.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Request the use of human body parts or the donation of human parts, including, without limitation, reproductive services such as egg donation and surrogacy;</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Endorse a particular political party, political agenda, political position or issue.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Promote a particular religion, faith or belief</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Send unsolicited mail or e-mail, making unsolicited phone calls or send unsolicited faxes regarding promotions and/or advertising of products or services to a user of any Jobanya.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Delete or revise any material posted by any other person or entity.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Take any action that imposes an unreasonable or disproportionately large load on any Jobanya website’s and App’s infrastructure.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Use any data mining, robots or similar data gathering or extraction methods. Notwithstanding anything to the contrary contained herein, use or attempt to use any engine, software, tool, agent or other device or mechanism (including without limitation browsers, spiders, robots, avatars or intelligent agents) to navigate or search any Jobanya website and App other than the search engine and search agents available from the Company on such Jobanya website and App.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Attempt to decipher, decompile, disassemble or reverse engineer any of the software comprising or in any way making up a part of any Jobanya website and App.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Aggregate, copy or duplicate in any manner any of the Jobanya content or information available from any Jobanya website and App including expired job postings, other than as permitted by these Terms.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Frame or link to any of Jobanya content or information available from any Jobanya website and App.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Use / insert any of the User's URL, web link, email address and website while posting any job on Jobanya and App.</div>
                                            </li>
                                        </ul>
                                        <p>The term "post" as used aforesaid shall mean information that you submit, publish or display on a Jobanya website and App. </p>
                                        <p>If any of your actions on the Website and App or materials posted by you on the Website or App are flagged as inappropriate, Jobanya reserves the right to review such actions or content to determine, in our sole and absolute discretion, whether it violates these Terms of Use. If Jobanya removes your content or disables your account and/or access to the Website and App, you may assume that such removal or disablement was purposeful on the part of Jobanya. Jobanya may additionally refuse to let you re-register and/or use the features and functionalities of the Website and App in our sole discretion.</p>
                                        <h5>Deal with Us in Good Faith</h5>
                                        <p>By creating an account, you agree that you are signing up for Jobanya Services in good faith, and that you mean to use them only for their intended purposes as job / employment tools and for no other reason</p>
                                        <h5>Security</h5>
                                        <p>While Jobanya works to protect the security of your content and account, we cannot guarantee that unauthorized third parties will not be able to circumvent our security measures. Please notify us immediately in the event of any compromise or unauthorized use of your account. You are also responsible for maintaining the confidentiality of your account information and for ensuring that only authorized individuals have access to your account. You are responsible for all actions taken or content added through your account.</p>
                                        <p>By using certain of our Services, you agree that Jobanya may create a user account for you. You can learn about our policies for data collected via user accounts in our privacy policy.</p>
                                        <h5>Our Rules about Your Data Privacy:</h5>
                                        <p>Jobanya privacy policy governs our collection, use, and disclosure of your data: </p>
                                        <p>Our privacy policy governs our collection, use, and disclosure of your personal information and is incorporated into these Terms of Use. Please read it carefully. It describes what information we collect from you and when, how and why we may create an account for you, whom we share your information with and when and how you can opt-out or delete your account. This is important information. By using our services, you consent to our privacy policy.</p>
                                        <h5>Our Rules about Your Content</h5>
                                        <p>Your content is your responsibility: You are solely responsible for any messages, reviews, text, photos, graphics, code, or other information, content or materials that you post, submit, publish, display or link to through the Services or send to other Jobanya users.</p>
                                        <p>Jobanya may choose to monitor user’s content: Jobanya does not approve, control, or endorse your or anyone else's User Content and has no obligation to do so. However, we reserve the right (but assume no obligation) to remove or modify User Content for any reason, at our sole discretion, including User Content that we believe violates our Terms of Use.</p>
                                        <p>Be truthful: You agree to provide accurate, complete, current, and truthful information when you add or edit facts about your candidature and requirements or otherwise provide content via the Services.</p>
                                        <p>Do not post illegal or harmful content: You agree not to post, submit, or link to any User Content or material that infringes, misappropriates, or violates the rights of any third party (including intellectual property rights) or that is in violation of any federal, state, or local law, rule, or regulation. You also agree not to post, submit or link to any User Content that is defamatory, obscene, pornographic, indecent, harassing, threatening, abusive, inflammatory, or fraudulent, purposely false or misleading or otherwise harmful.</p>
                                        <p>Do not violate others' rights: You agree not to post copyrighted material without permission from the owner of the copyright. This includes, for example, photographs, logo or other content you upload via the Services. You also agree not to disclose confidential or sensitive information.</p>
                                        <p>You grant Jobanya a license to use your content: When you provide User Content via our Services, you grant Jobanya a royalty-free, perpetual, irrevocable, and fully sub-licensable license to publish, reproduce, distribute, display, adapt, modify and otherwise use your User Content in connection with our Services. We can publish or promote your User Content on the social networking pages of Jobanya. We will not pay you or otherwise compensate you for the content you provide to us. </p>
                                        <p>Your content may be made public: You understand and agree that any User Content that you post or submit to Jobanya may be re-distributed through the Internet and other media channels, and may be viewed by the general public. </p>
                                        <p>We reserve the right in our sole discretion to edit or remove information or materials provided by you.</p>
                                        <h5>Rules for Placement Consultants / Agencies:</h5>
                                        <p>Information Provided by Placement Consultants / Agencies: </p>
                                        <p>If you are a Placement Consultant / Agency, you agree that you will not claim or submit job listings / requirements that do not belong to you. When you submit or claim a job listing, you hereby agree to abide by the User Terms and Conditions signed by you.</p>
                                        <h5>Jobanya Resume Database Provided to Placement Consultants / Agencies:</h5>
                                        <p>If you are a Placement Consultant / Agency, you agree that you will not use any information / Resume Database that is transferred to you during your use of Jobanya Website and App or Services (e.g., resume information) for any reason except to provide those jobseekers with placement / employment services. You further agree never to use such information for any illegal or harmful purpose.</p>
                                        <h5>Our Rules for Refund:</h5>
                                        <p>We issue refund only for plans mentioned on our Website and App on pro rata response basis.</p>
                                        <h5>Eligibility Criteria for Refund:</h5>
                                        <p>Response on your posted job should be less than our minimum guaranteed response mentioned on the plan while purchasing it and client is not able to hire a candidate from the response received.</p>
                                        <ul className="ttm-list ttm-list-style-square ttm-list-icon-color-skincolor mb-30">
                                            <li>
                                                <div className="ttm-list-li-content">Client should have valid invoice from Jobanya.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Claim should be made within 7 days from the date of purchase of the plan.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Refund request should be send in writing by the buyer from his registered e-mail ID on <a href="mailto: support@jabanya.com">support@jabanya.com</a></div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Refund policy is not applicable on the Job posted for other than Tamilnadu.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Refund policy is not applicable incase client has hired someone from the job application received on a job posted by client on Jobanya.</div>
                                            </li>
                                        </ul>
                                        <h5>Refund Process: </h5>
                                        <p>Once we receive the claim, our team will get in touch with you & validate the response provided. Post verification, we will notify you on the status of your refund whether approved or not approved. If your refund is approved, we will initiate a refund original method of payment. You will receive the credit within 5-7 business/working days. </p>
                                        <p>We do not refund to Placement Agencies and consultancies or who has opted for our customized plan other than standard plans mentioned over website / App.</p>
                                        <h5>Contact Us:</h5>
                                        <p>If you have any questions about our Refund Policy, please contact us at <a href="mailto: support@jabanya.com">support@jabanya.com</a></p>
                                        <h5>Intellectual Property and Licenses:</h5>
                                        <p>“Jobanya” Grants You a Limited License to Use Our Services:</p>
                                        <p>Subject to these Terms of Use and any other policies we create, we grant you a limited, non-sub licensable right to access the Website and App, use our Services, and print materials for your employment and hiring needs only. This license does not allow you to copy or sell our Services or Materials; scrape or mine our Website, App, Services, or Materials; or frame any part of our Website, App or Services. We reserve the right, without notice and in our sole discretion, to terminate your license to use the Website, App or Services, and to block or prevent future access to and use of the Website, App and Services.</p>
                                        <p>Except for the limited license granted to you, you are not conveyed any other right or license in any way. Any unauthorized use of our Website, App or Services, and any use that exceeds the license granted to you, will terminate the permission or license granted by these Terms of Use.</p>
                                        <h5>You agree and understand that</h5>
                                        <p>The contents of the Website and App including but not limited to the information, logos, designs, databases, arrangement of databases, user interfaces, response formats, videos, testimonials, software, audio, pictures, icons, are the sole property of Jobanya or our licensors. All intellectual property in and to the Website, App and its contents and functionalities shall vest solely with Jobanya or our licensors.</p>
                                        <ul className="ttm-list ttm-list-style-square ttm-list-icon-color-skincolor mb-30">
                                            <li>
                                                <div className="ttm-list-li-content">Save for the limited right to access and use the Website and App in accordance with the Terms of Use and other applicable agreements, on a non-exclusive and non-transferable basis, there are no other rights being granted to you in the Website, App or any of its content and functionalities.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">To the extent required, you grant Jobanya a non-exclusive, royalty free, worldwide, transferrable, sub-licensable right to host, display, demonstrate, publicly perform, use, reproduce, format, and distribute any materials, trademarks, trade names and other forms of your intellectual property which you have provided to Jobanya.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">You have no right to make any copies of the whole or part of the Website, App or any of the content therein.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">You have no rights to remove, modify (including removing any copyright notices or proprietary markings) any part of the Website and App except for any User Content that belongs to you.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">You have no right to use any search mechanisms other than that provided on the Website and App and you will not use any web-crawler or any data harvesting tools to harvest data of any sort from the Website and App.</div>
                                            </li>
                                        </ul>
                                        <p>If you are found to be in violation of this clause, Jobanya reserves the right to terminate your access to the Website and App immediately and take civil /criminal proceedings in court of law for said infringement.</p>
                                        <h5>Our Materials Are Protected:</h5>
                                        <p>All materials in Jobanya Website, App and Services, including our branding, trade dress, trade names, logos, design, text, search results, graphics, images, pictures, page headers, custom graphics, icons, scripts, sound files and other files, and the selection and arrangement and compilation of information thereof (collectively "Materials"), are proprietary property of Jobanya, our suppliers, our licensors, or users, and are protected by Indian and international intellectual property laws, including copyright and trademark laws. Our Materials cannot be copied, imitated, or used, in whole or in part, without our prior written permission. You may not use any meta-tags or any other "hidden text" utilizing “Jobanya” or any other name, trademark, or product name of Jobanya without our permission.</p>
                                        <p>Our Use of Third Parties' Marks Does Not Imply Endorsement: </p>
                                        <p>All other trademarks, registered trademarks, product names, and logos appearing on our Website and App are the property of their respective owners. Reference to any products, services, processes, or other information, by trade name, trademark, manufacturer, supplier, or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof by us.</p>
                                        <h5>Third-Party Links, Sites, and Services</h5>
                                        <p>Our Website, App and Services may contain links to third-party websites, Apps, advertisements, services, special offers, or other events, activities, or content (collectively "Third-Party Materials"). These Third-Party Materials are not under the control of Jobanya. We are providing these links to you only as a convenience, and the inclusion of any link does not imply affiliation, endorsement, or adoption by us of the website, App or any information contained therein. Jobanya is not responsible or liable for your use of or access to any Third-Party Materials. Whenever you leave Jobanya Website, App or Services be aware that your activity outside our Website, App or Services will not be governed by our Terms of Use or other agreements.</p>
                                        <h5>No Warranties</h5>
                                        <p>This website, App, materials, and the services are provided on an "as is" basis without warranties of any kind, either express or implied. All use of Jobanya website, App, materials, and services is at your sole risk.</p>
                                        <p>We specifically disclaim all warranties, express or implied, including implied warranties of merchantability, fitness for a particular purpose, title, Non-Infringement and any warranties arising out of the course of dealing or usage of trade.</p>
                                        <p>Jobanya takes no responsibility and assumes no liability for any User Content or other content, including links to web and App pages, that you or any other user or third party posts or transmits using our Website, App or Services. You understand and agree that you may be exposed to User Content that is inaccurate, objectionable and inappropriate for children or otherwise unsuited to your purpose.</p>
                                        <p>Jobanya is not responsible in any way for, does not monitor, and does not endorse or guarantee anything about any third-party content (including advertisements, offers, and promotions) that may appear in our Website, App or Services.</p>
                                        <h5>Jobanya does not represent in any manner that:</h5>
                                        <p>The information, data or contents of the Website and App are accurate.</p>
                                        <ul className="ttm-list ttm-list-style-square ttm-list-icon-color-skincolor mb-30">
                                            <li>
                                                <div className="ttm-list-li-content">The Website and App will be available at all times and will operate error free or that there will be uninterrupted access and service.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">The integrity of the information on the Website, App or information you upload will be maintained.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">We endorses any of the views of any of the users who may have posted content</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">We have verified or guarantee the quality of services or representations made by any user of the Website and App.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">We have verified the credit worthiness of any user.</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">We have screened or verified any of the information posted herein. And</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">The Website, App or any content is free from viruses or other malware.</div>
                                            </li>
                                        </ul>
                                        <p>Any resume of jobseekers prepared by Jobanya on behalf of such jobseeker over phone shall be limited to the capture, by an employee or agent of Jobanya, of such information of the jobseekers that is the subject matter for employment. The resumes are then listed / submitted on the Website & App and are available (may be in limitation) for viewing by all persons who visit the specific pages associated with a listing / submission. Please be aware that the resumes are specific to a particular time and that what you see in them may have subsequently been altered. We do not take any responsibility for the accuracy of the resumes or for keeping the resumes updated. The resumes are intended only as a preliminary guide and it shall be entirely your responsibility to screen, view and determine the suitability (for your needs) of any resume listed on the Website and App prior to entering into any contract with respect to the same.</p>
                                        <p>The Website and App is only a platform for the display of job / career information, jobseekers' resume etc. for facilitating recruitment process that two users of the Website or App may enter into outside of the auspices of the Website, App or the Services provided by Jobanya. Any contractual or commercial agreements are agreed to between users alone and neither the Website, App nor Jobanya shall be in any manner liable or responsible for these commercial arrangements. Jobanya is not responsible for any breach of any contract concluded by any of the user/s. </p>
                                        <p>The background verification services for the purpose of the screening the details of the jobseekers whose resumes are provided in the Website and App is outsourced to a third party service provider. In the event of any ambiguity, in your opinion, with respect to the details of the job seeker provided in regard hereto, you may contact the third party service provider directly, the details of which shall be provided by the Jobanya, upon request in writing. We do not take any responsibility / claim / loss pertaining to the accuracy of outcome highlighted through background verification services undertaken by third party.</p>
                                        <p>Your use of the Website, App and its functionalities shall not act to make Jobanya your agent in any form or manner. Any disputes between you and any other user/s are to be resolved between you and such user/s only and Jobanya is not responsible and shall not be required to mediate or resolve any disputes or disagreements between such users. You acknowledge and agree that you cannot and will not enjoin Jobanya or our affiliates, officers, employees, agents and professional advisors as a party to any such dispute.</p>
                                        <p>You expressly release Jobanya, our affiliates, officers, employees, agents and professional advisors from any cost, damage, liability or other consequence of any of the actions of the users of the Website and App.</p>
                                        <h5>Indemnification</h5>
                                        <p>You agree to defend, indemnify, and hold Jobanya harmless from and against any claims, damages, costs, liabilities, and expenses (including, but not limited to, reasonable attorneys' fees) arising out of or related to any User Content you post, store, or otherwise transmit on or through the Website, App or Services or your use of the Website, App and the Services, including, without limitation, any actual or threatened suit, demand, or claim made against Jobanya arising out of or relating to the User Content, your conduct, your violation of these Terms of Use, or your violation of the rights of any third party.</p>
                                        <h5>Limitation of Liability</h5>
                                        <p>In no event shall Jobanya, our officers, directors, agents, affiliates, employees, advertisers, or data providers be liable for any indirect, special, incidental, consequential or punitive damages (including but not limited to loss of use, loss of profits, or loss of data) whether in an action in contract, tort (including but not limited to negligence), equity or otherwise, arising out of or in any way connected with the use of this website, App, the materials, or our services. Some jurisdictions do not allow the exclusion or limitation of liability, so the above limitations may not apply to you.</p>
                                        <p>The aggregate liability of Jobanya to you or anyone, whether in contract, tort, negligence or otherwise, howsoever arising, whether in connection with these Terms of Use, your access and use of this Website, App and its contents and functionalities or for any reason related to the operation of the Website and App, shall not exceed INR 100 (Indian Rupees One Hundred only). In no event shall Jobanya be liable for any loss of profits (anticipated or real), loss of business, loss of reputation, loss of data, loss of goodwill, any business interruption or any direct, indirect, special, incidental, consequential, punitive, tort or other damages, however caused, whether or not we have been advised of the possibility of such damages.</p>
                                        <h5>General Terms</h5>
                                        <h6>Termination:</h6>
                                        <p>Jobanya reserves the right to terminate your access to the Website, App and services at any time, in our sole discretion. You acknowledge Jobanya right to do so and waive any claim that you may have arising from such termination. Notwithstanding the termination of access, all provisions, which by their nature are intended to survive, shall survive termination and continue to be applicable. </p>
                                        <h6>Force Majeure:</h6>
                                        <p>In no event shall Jobanya be liable for any acts beyond our control or for any acts of God. </p>
                                        <h6>Access:</h6>
                                        <p>Jobanya does not make any claim that the Website, App and its contents may be lawfully viewed or accessed in the jurisdiction you are viewing it in. You are solely responsible for complying with the laws applicable to you. </p>
                                        <h6>Complaints:</h6>
                                        <p>Should you have any complaints regarding the Website, App and services including but not limited to abuse and misuse of the Website, App and services copyright infringement and the like, or any issues related to data privacy please report the same to <a href="mailto: support@jabanya.com">support@jabanya.com</a></p>
                                        <p>Please provide your name, email address, physical address and contact numbers so that Jobanya may be in a position to verify details or check the authenticity of the complaints.</p>
                                        <h6>Modification of Jobanya Services:</h6>
                                        <p>Jobanya reserves the right to modify or discontinue (completely or in part) our Website, App and services, or any content appearing therein. Jobanya will not be liable to you or any third party if we exercise this right.</p>
                                        <h6>Modification of These Terms:</h6>
                                        <p>We reserve the right to change or modify these Terms of Use, or any policy or guideline of the Website, App or Services, at any time and in our sole discretion. Any changes or modifications will be effective immediately upon posting of the revisions to the Website, App or Services. You waive any right you may have to receive specific notice of such changes or modifications. Your continued use of the Website, App or services following the posting of changes or modifications will confirm your acceptance of such changes or modifications. Please review these terms and conditions periodically. Choice of Law:</p>
                                        <p>These Terms of Use are governed by the laws of India. The courts in Tamilnadu, India shall have exclusive jurisdiction over any claim or matter arising out of these Terms of Use.</p>
                                        <h5>No Waiver:</h5>
                                        <p>Jobanya failure to exercise or enforce any right or provision of the Terms of Use will not be deemed to be a waiver of such right or provision.</p>
                                        <h5>No Third-Party Beneficiaries or Rights: </h5>
                                        <p>These Terms of Use do not create any private right of action on the part of any third party or any reasonable expectation that the Website, App or services will not contain any content that is prohibited by these Terms of Use.</p>
                                        <h5>Entire Agreement and Severability:</h5>
                                        <p>These Terms of Use, the privacy policy and any amendments and additional agreements you might enter into with Jobanya in connection with our Website, App or services, shall constitute the entire agreement between you and Jobanya concerning the Website, App or services, and shall supersede any prior terms you had with Jobanya regarding the Website, App or Services.</p>
                                        <p>If any provision of these Terms of Use is deemed invalid, then that provision will be limited or eliminated to the minimum extent necessary and the other provisions of these Terms of Use remain in full force and effect.</p>
                                        <p>Assignment: You may not assign or otherwise transfer your rights or obligations under these Terms of Use. Jobanya may assign our rights and duties under these Terms without any such assignment being considered a change to the Term of Use and without any notice to you.</p>
                                        <h5>Notices:</h5>
                                        <p>Any notices or other communications that you wish to send to Jobanya may be addressed to the registered office - VinMeen Infotech, No.18 B, 1st Floor, Narayana Nagar, Pillayarpalayam, Dindigul - 624001, Tamilnadu.</p>
                                        <h5>Dispute Resolution: </h5>
                                        <p>Unless you have entered into an additional agreement with Jobanya that provides for an alternative dispute resolution mechanism, you agree that any dispute arising between you and Jobanya which cannot be resolved amicably, shall be conclusively resolved by a sole arbitrator. The Arbitration shall be conducted as per the Arbitration rules laid down under the Arbitration and Conciliation Act, 1996, and any modification/amendment effected thereafter from time to time. The arbitration shall be conducted in the English language and the award passed by the Arbitrator shall be final and binding on both parties. The cost of arbitration shall be borne by you, unless awarded otherwise.</p>
                                        <h5>Contact Information</h5>
                                        <p>If you have any questions or suggestions regarding our Terms of Use, please contact us at <a href="mailto: support@jabanya.com">support@jabanya.com</a></p>
                                        <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>

                                    </div>
                                </div>
                                {/* ttm-service-single-content-are end */}
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>

                <Footer />

            </div>
        )
    }
}


export default Terms_conditions;
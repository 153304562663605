import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
export class Disclaimer_clause extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photoIndex: 0,
            isOpen: false,
        };
    }
    render() {
        return (
            <div className="site-main">
                <Header />
                {/* PageHeader */}
                <PageHeader
                    title="Disclaimer Clause"
                    breadcrumb="Disclaimer Clause"
                />
                {/* PageHeader end */}
                <div className="ttm-row sidebar ttm-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-12 content-area order-lg-12" style={{ maxWidth: '100%' }}>
                                {/* ttm-service-single-content-area */}
                                <div className="ttm-service-single-content-area">
                                    {/* <div className="ttm_single_image-wrapper">
                                        <img className="img-fluid" src="images/Services.jpg" alt="services-img" />
                                    </div> */}
                                    <div className="ttm-service-description mt-40">
                                        {/* <h3 className="mb-20" style={{ textAlign: 'center' }}>PRIVACY POLICY</h3> */}
                                        <h5 className="mb-20">We do not represent any Government Entity</h5>
                                        <p>Jobanya.com updates, regarding Government Job listings are for the convenience of users. We explicitly make no representations or guarantees about job listings or the accuracy of the information provided by the employer.</p>
                                        <p>Jobanya.com is not a Government Portal and not affiliated to any Government Organization / Department / Institute / Ministries / Recruitment Boards / Educational Institutions / Central and State Educational Boards.</p>
                                        <p>Jobanya.com is not responsible for authenticity, wages, fraudulent practices or any other aspect of employment without limitation. It is the responsibility of users to perform due diligence in researching employers when applying for and thoroughly research the facts and reputation of each organization to which they are applying.</p>
                                        <p>Views and opinions expressed on this portal are strictly those of the author, which has been collected from various sources. The author has made considerable efforts to present accurate and reliable information on this web site. However, the author does not take any legal responsibility for the accuracy, completeness, or usefulness of the information herein. Information published here with noble intentions are not for academic purposes.</p>
                                        <p>Jobs, Results, Education updates and information posted in this portal are not having any guarantee that, the information provided in this portal is correct or authentic. Visitors must check the news / job notifications/ Results / Notifications / Education Updates / Other Information from the concerned official websites only.</p>
                                        <h5>Objectionable and Copyright Content will be removed ASAP</h5>
                                        <p>This portal will not publish objectionable content or others copyrighted content. However, if mistakenly, any content is published in this portal and any one have any objection about published content in this portal may contact us at <a href="mailto: admin@jabanya.com" target="_blank">admin@jobanya.com</a>. We will surely assure you that objectionable content or any others copyrighted content if posted in the portal will be removed as soon as possible.</p>
                                        <p>We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the portal or the information, services, or related graphics Contained on the portal for any purpose.</p>
                                        <p><b>Note:</b> Once the Last Date for any Government Vacancies is Completed then the post stands Expired or Invalid and the Links will not work. Any reliance you place on such information is therefore strictly at your own risk.</p>
                                        <h5>Source of information</h5>
                                        <p>The government job post information are sourced from websites of respective government organizations. The same has been mentioned in each and every government job post.</p>
                                        <p>All employment information communicated through Jobanya Employment App are verified by the Jobanya team. Users are requested to verify the quality of each company and authenticity of each job post.</p>
                                        <p>We humbly inform you that Jobanya Management will not be held responsible under any circumstances and in any manner for any damage or loss, caused by employment information provided in Jobanya Employment App.</p>
                                        <p>Share your thoughts and feedback with us - mail us at <a href="mailto: admin@jabanya.com" target="_blank">admin@jobanya.com</a></p>
                                        <p>Please do read our <a href={'/disclaimer_clause'} target="_blank">Disclaimer</a> and <a href={'/privacy-policy'} target="_blank">Privacy Policy</a>.</p>
                                        <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>
                                    </div>
                                </div>
                                {/* ttm-service-single-content-are end */}
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default Disclaimer_clause;
import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';

export class Privacy_policy extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
        };
    }
    render() {

        return (
            <div className="site-main">
                <Header />

                {/* PageHeader */}
                <PageHeader
                    title="Privacy Policy"
                    breadcrumb="Privacy Policy"
                />
                {/* PageHeader end */}


                <div className="ttm-row sidebar ttm-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-12 content-area order-lg-12" style={{ maxWidth: '100%' }}>
                                {/* ttm-service-single-content-area */}
                                <div className="ttm-service-single-content-area">
                                    {/* <div className="ttm_single_image-wrapper">
                                        <img className="img-fluid" src="images/Services.jpg" alt="services-img" />
                                    </div> */}
                                    <div className="ttm-service-description mt-40">
                                        {/* <h3 className="mb-20" style={{ textAlign: 'center' }}>PRIVACY POLICY</h3> */}
                                        <h5 className="mb-20">Please read this policy carefully before using Jobanya services</h5>
                                        <p>Jobanya welcomes you! We are a Job & Jobseeker information service provider connecting jobseekers and employers / placement consultants & agencies in the employment / recruitment market. Jobanya and its subsidiaries and affiliates (collectively "Jobanya") provide you with access to a variety of services, including but not limited to the <a href={'/'} target="_blank">www.jobanya.com</a> website (the "Website") and Jobanya mobile applications (the “App”) and all the products and services available through our website (collectively the "Services"). This Website and App is owned and operated by M/s VinMeen Infotech, a company incorporated under the Indian Companies Act, 2013.</p>
                                        <p>Our Privacy Policy is articulated as part of the Jobanya Terms of Use. Please read this Privacy Policy and our Terms of Use carefully before using our Services. By using Jobanya Services, you agree to the provisions of this Privacy Policy. If you do not want to be bound by our Privacy Policy, please do not use our Services.</p>
                                        <p>Protecting your data, privacy and personal information is very important to Jobanya (“JOBANYA”, “Jobanya”, “and our”, “us” or “we”).</p>
                                        <p>This policy (together with our terms of use at <a href={'/terms-conditions'} target="_blank">https://www.jobanya.com/terms-conditions</a> and any other documents referred to in it), sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read this privacy policy carefully to understand the types of information we collect from you, how we use that information, the circumstances under which we will share it with third parties, and your rights in relation to the personal data you provide to us.</p>
                                        <p>When visiting Jobanya website at <a href={'/'} target="_blank">www.jobanya.com</a> or any of its affiliated websites (our “Website”), using our application “Jobanya” (our “App”) or using any of the services offered via the Website or the App (the “Services”) or the Missed Call number, you acknowledge, and where applicable consent to, the practices described in this policy.</p>
                                        <p>Our Services may contain links to third party services. If you click on a link to access any of those third-party services, please note that they have their own privacy policies and that we do not accept any responsibility or liability for their policies or processing of your personal information. Please check these policies before you submit any personal information to such third-party websites.</p>
                                        <p>Our Services connect you with Job Seekers, Jobs, or Employers (as applicable), giving you the ability to search for, browse, and communicate with Job Seekers, Jobs, or Employers (as applicable). However, we also use automated matching processes facilitated by algorithms within the Services which seek to match you with the most appropriate Job Seekers, Jobs, or Employers (as applicable) by reference to the data you have provided to us along with any criteria specified by the Job Seeker or Employer (as applicable). This may result in decisions being made automatically. For example, we may limit what jobs are visible to Job Seekers based on the qualifications and expertise required for that job. In relation to this automated processing, we ensure there are safeguards in place to protect your rights and freedoms and legitimate interests and you have the right to contest any automated decisions made and obtain human intervention to alter decisions made, where possible. We may also use non-algorithmic methods to match you with Job Seekers or jobs who we believe would be suitable for candidates or Employers who we believe have a suitable role for you (as applicable).</p>
                                        <h5>Information we may collect</h5>
                                        <p>We may collect and process the following data about you:</p>
                                        <h5>Information that you provide to us:</h5>
                                        <p>We will ask you to provide us with your information when you:</p>
                                        <ul className="ttm-list ttm-list-style-square ttm-list-icon-color-skincolor mb-30">
                                            <li>
                                                <div className="ttm-list-li-content">fill in forms on our Website, App, through Profiling Agents or correspond with us by phone, email or otherwise;</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">register to use our Services;</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">use the Services;</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">report a problem with our Website, App or Services; or</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">complete any surveys we ask you to fill in for research purposes (although you do not have to respond to these if you do not want to).</div>
                                            </li>
                                        </ul>
                                        <p>For the avoidance of any doubt, any reference in this privacy policy to your data shall include data about other individuals that you have provided us with. You must have obtained clear permission from the individuals whose data you provide us with before sharing that data with us.</p>
                                        <p>The information you may be asked to provide to us for these purposes might include your name, address, date of birth, email address, phone number, picture, video, audio, location, job title, work history, skills, school attended, references, salary, preferences, opinions, intentions, interests, likes, dislikes, life history, major events, social network, friends, connections, associations, Aadhar number, driving license number and/or any other identification proof number. </p>
                                        <h5>Information we collect about you:</h5>
                                        <p>With regard to each of your visits to our Website or App we may automatically collect the following information:</p>
                                        <ul className="ttm-list ttm-list-style-square ttm-list-icon-color-skincolor mb-30">
                                            <li>
                                                <div className="ttm-list-li-content">device-specific information, such as your hardware model, operating system version, unique device identifiers, Universal Device Identifier (UDID), ID for Advertisers (IDFA) and mobile network information;</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">technical information about your computer, including where available, your IP address, operating system and browser type, and also latitude/longitude and related geo information of your whereabouts (i.e. city, pin code), this may be collected if you opt-in to share your location or inferred from your IP address, for system administration, analytical or Service delivery purposes; and</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">details of your visits to our Website, App, including your browsing trail, the full Uniform Resource Locators (URL) clickstream to, through and from our Website, App (including date and time), length of visits to certain pages, and page interaction information (such as scrolling, clicks, and mouse-overs).</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Jobanya adopts appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of Your personal information, username, password, and data stored on the App. Sensitive and private data exchange between the Application and its users happens over a SSL (Secure Socket Layer) secured communication channel. However, Jobanya cannot guarantee the security of any information obtained through unauthorized entry or use, hardware or software failure, and other factors that may compromise the security of user information at any time. It is important for the User to prevent unauthorized access to his/her account and personal information by limiting access to his/her device.</div>
                                            </li>
                                        </ul>
                                        <h5>Information we receive from other sources:</h5>
                                        <p>When using our Services, we will be in contact with third parties who may provide us with certain information about you in order to enable your use of the Services. This includes information from service providers such as Facebook or Google if you access our App through your Facebook or Google account. </p>
                                        <h5>Information we may collect about others:</h5>
                                        <p>We may collect and process data about others that you provide us with, including (but not limited to) information that you provide by filling in forms on our Website or App, or that you provide to us by Profiling Agents and email. This information might include, but is not limited to, opinions, ratings and reviews you provide us with on other users you have interacted with, whether Job Seekers or Employers.</p>
                                        <h5>How we use your information and justification of use:</h5>
                                        <p>Use of personal information under Indian data protection laws must be justified under legal “grounds” and we are required to set out the ground in respect of each use of your personal data in this policy. These are the principal grounds that justify our use of your information:</p>
                                        <ul className="ttm-list ttm-list-style-square ttm-list-icon-color-skincolor mb-30">
                                            <li>
                                                <div className="ttm-list-li-content">Consent: where you have consented to our use of your information (you are providing explicit, informed, freely given consent, in relation to any such use and may withdraw your consent in the circumstance detailed below by notifying us);</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Contract performance: where your information is necessary to enter into or perform our contract with you;</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Legal obligation: where we need to use your information to comply with our legal obligations;</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Legitimate interests: where we use your information to achieve a legitimate interest and our reasons for using it outweigh any prejudice to your data protection rights; and</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">Legal claims: where your information is necessary for us to defend, prosecute or make a claim against you or a third party</div>
                                            </li>
                                            <li>
                                                <div className="ttm-list-li-content">We does not sell, trade, or rent Your Personally Identifiable Information collected by us.</div>
                                            </li>
                                        </ul>
                                        <p>We use information held about you (and information about others that you have provided us with) in the following ways</p>
                                        <h5>Applicable to jobseekers</h5>
                                        <p>Jobanya and each Employer or Recruiter acts as a data controller in their own right, which means both independently determine the purpose and means of processing the personal data of the relevant Job Seeker. Where an Employer or Recruiter receive personal data relating to a Job Seeker it will be responsible for how it processes personal data.</p>
                                        <h5>Nature of Information Collected</h5>
                                        <p>Name, job role, mobile number, email address, City, Pin code & area, employment status, password, GPS coordinates, skill sets, salary, work history, academic and training history, language preferences, any pictures supplied by you, and what type of employment you are looking for (i.e. full time, part time, work from home, etc.). Social connections, (“Job Seeker Profile Data”) Aadhar number, driving license number.</p>
                                        <h5>Uses of the Collected Information</h5>
                                        <p>To provide you with access to the Services including profile updating or management and job searching allowing you to browse matching job opportunities and manage your job applications.</p>
                                        <h5>Justification for Use</h5>
                                        <p>Contract Performance</p>
                                        <p>Job Seeker Profile Data, your previously browsed job roles and previous job applications.</p>
                                        <p>Job Seeker Profile Data may be shared with users for this.</p>
                                        <p>To facilitate the finding of job opportunities for you and to provide you with matching job opportunities we believe you may be interested in.</p>
                                        <p>Legitimate Interest</p>
                                        <h5>Applicable to employers</h5>
                                        <p>Where an Employer or Recruiter receives personal data relating to a Job Seeker pursuant to using our Services, the Employer or recruiter will be responsible for how it processes such personal data and the Employer or recruiter will be a data controller in its own right and must comply with all applicable data protection legislation. Jobanya has notified Job Seekers that it will share personal data with Employers or Recruiters solely for the purpose of matching Job Seekers with Employers or Recruiters in accordance with this privacy policy. </p>
                                        <h5>Nature of Information Collected</h5>
                                        <p>Segment, Name, Company, Sector, Mobile number, Email address, City, Pin code& area, Address, Password, GPS coordinates, any pictures supplied by you. Social connections, (“Employer / Recruiter's Profile Data”). Job posting details: Job role, skills, City, Area &pin code, Salary & Benefits, Experience, Language, Job description, other job requirements.</p>
                                        <h5>Uses of the Collected Information</h5>
                                        <p>To provide you with access to the Services including the profile management, job seeker discovering, allowing you to browse potential Job Seekers’ profiles, job opportunities management, allowing you to create, edit, publish and promote job opportunities and applicant’s management allowing you to manage job applicants or matching candidates and review their profiles.</p>
                                        <h5>Justification for Use</h5>
                                        <p>Contract Performance</p>
                                        <p>We will not sell your personal data (or any other data you provide us with) to third-parties; however, we reserve the right to share any data which has been anonymised and/or aggregated. You acknowledge and accept that we own all rights, title and interest in and to any derived data or aggregated and/or anonymised data collected or created by us.</p>
                                        <h5>Marketing</h5>
                                        <p>We may use your information for marketing products and services to you. However, we will provide an option to unsubscribe or opt-out of communication on the Settings page.</p>
                                        <h5>Disclosure of your information</h5>
                                        <p>We may disclose your personal information to our service providers and business partners to assist us in performing any contract we enter into with them or you, including providing the Website, App and the Services it enables, analytics providers to assist us in the improvement and optimization of the Website and App.</p>
                                        <p>In addition, we may also disclose your personal information to third parties in the following circumstances: </p>
                                        <h5>Purpose of disclosure and third party(s) to which disclosure might be made</h5>
                                        <p>If we sell or buy any business or assets, we may disclose your personal information to the prospective seller or buyer of such business or assets.</p>
                                        <h5>Justification for Use</h5>
                                        <p>Legitimate interest (to sell our business or assets).</p>
                                        <p>If Jobanya or substantially all of its assets are acquired by a third party, personal information about our clients will be one of the transferred assets</p>
                                        <p>Legitimate interest (to sell our Company or assets).</p>
                                        <p>If we are under a duty to disclose or share your personal data in order to comply with any legal obligation or to protect the rights, property, or safety of Jobanya, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection.<br />Legal obligation.</p>
                                        <p>Fraud Prevention and other checks. We and other organizations may also access and use your personal information to conduct credit checks and checks to prevent fraud. If false or accurate information is provided and fraud is identified or suspected, details may be passed to appropriate agencies.<br />Legitimate interest (to assist with the prevention of fraud and to assess your risk profile).</p>
                                        <p>We may disclose your personal information to third parties, the court service and/or regulators or law enforcement agencies in connection with proceedings or investigations anywhere in the India where compelled to do so.</p>
                                        <p>Legal obligation (to cooperate with law enforcement and regulatory authorities).</p>
                                        <h5>Security over the internet</h5>
                                        <p>No data transmission over the internet can be guaranteed to be secure from intrusion. However, we maintain commercially reasonable physical, electronic and procedural safeguards to protect your information.</p>
                                        <p>All information you provide to us is stored on our or our subcontractors’ secure servers and accessed and used subject to our security policies and standards. </p>
                                        <p>We use hosted servers in the course of our business. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our site, you are responsible for keeping this password confidential, ensuring it is secure and strong, and for complying with any other security procedures that we notify you of. We ask you not to share your password with anyone.</p>
                                        <h5>Exports outside India</h5>
                                        <p>Your information may be accessed by staff or suppliers in, transferred to, and/or stored at, a destination outside the India in which data protection laws may be of a different standard than in the India. Regardless of location or whether the person is an employee or contractor, we will impose the same data protection safeguards that we deploy inside the India.</p>
                                        <p>Please contact us if you would like further details of the specific safeguards applied to the export of your information.</p>
                                        <h5>Your rights</h5>
                                        <p>You have various rights in relation to your personal data as per Indian data protection Laws. All of these rights can be exercised by contacting us at <a href="mailto: support@jabanya.com">support@jabanya.com</a></p>
                                        <p>In certain circumstances, you have the following rights in relation to your information / data:</p>
                                        <h5>Right</h5>
                                        <p>Right of Access</p>
                                        <h5>Details</h5>
                                        <p>You have the right to obtain from us information as to whether your personal data is being processed, and, where that is the case, access to such personal data.</p>
                                        <h5>Right to Rectification</h5>
                                        <p>We will use reasonable endeavors to ensure that your personal information is accurate. In order to assist us with this, you should notify us of any changes to the personal information that you have provided to us by sending us a request to rectify your personal data where you believe the personal data we have is inaccurate or incomplete. You may also change most of your personal details within our app or website directly.</p>
                                        <h5>Right to erasure / ‘Right to be forgotten’</h5>
                                        <p>Asking us to delete all of your personal data will result in Jobanya deleting your personal data without undue delay (unless there is a legitimate and legal reason why Jobanya is unable to delete certain of your personal data, in which case we will inform you).</p>
                                        <h5>Right to restriction of processing</h5>
                                        <p>You have the right to ask us to stop processing your personal data at any time.</p>
                                        <p>“Jobanya” will not ordinarily charge you in respect of any requests we receive to exercise any of your rights detailed above; however, if you make excessive, repetitive or manifestly unfounded requests, we may charge you an administration fee in order to process such requests or refuse to act on such requests. Asking us to stop processing your personal data or deleting your personal data will likely mean that you are no longer able to use Jobanya Services, or at least those aspects of the Services which require the processing of the types of personal data you have asked us to delete, which may result in you no longer being able to use the Services. </p>
                                        <p>Where you request Jobanya to rectify or erase your personal data or restrict any processing of such personal data, Jobanya may notify third parties to whom such personal data has been disclosed of such request. However, such third party may have the right to retain and continue to process such personal data in its own right.</p>
                                        <h5>Changes to this policy</h5>
                                        <p>Any changes we make to our privacy policy in the future will be posted on this page, and where appropriate, notified to you by email. We therefore encourage you to review it from time to time to stay informed of how we are processing your information.</p>
                                        <h5>Contact</h5>
                                        <p>Questions, comments and requests regarding this privacy policy are welcome and should be sent to <a href="mailto: support@jabanya.com">support@jabanya.com</a></p>
                                        <h5>Cookies</h5>
                                        <p>Jobanya uses cookies to distinguish you from other users. This helps us provide you with a good experience when you use our Website or App, and also allows us to improve our Services. Please note that it is possible to disable cookies being stored on your computer by changing your browser settings. However, our Website or App may not perform properly, or some features may not be available to you, if you disable cookies.</p>
                                        <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>

                                    </div>
                                </div>
                                {/* ttm-service-single-content-are end */}
                            </div>

                        </div>{/* row end */}
                    </div>
                </div>

                <Footer />

            </div>
        )
    }
}


export default Privacy_policy;
import React, { Component } from 'react';
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap"
import stringsoflanguages from '../Stringsoflanguage'
import { IntlContext } from "../utility/context/Internationalization"
import { APIURL, COMMON, checkIsLoggedIn } from '../config'
import Slider from 'react-slick';
import axios from "axios";
import OtpInput from 'react-otp-input';
import { history } from '../history';
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import { generateRefreshToken } from '../serviceworker';
let error_log = require('../error_log');
const publicIp = require('public-ip');
export class Login extends Component {
    constructor() {
        super();
        this.state = {
            selectedLanguage: null,
            show: false,
            search_flag: false,
            ipurl: APIURL,
            apptypecode: COMMON.APP_TYPE_CODE,
            otpRequested: false,
            otp: '',
            minutes: 0,
            seconds: 30,
            languagecode_color: 0,
            miliSeconds: 0,
            languageCode: 1,
            languageData: [],
            isStarted: false,
            timer: [0, 0, 0],
            timerId: '',
            ipaddress: '',
            showMobileNumdiv: true,
            showOTPdiv: false,
            enableResend: false
        };
    }
    async componentDidMount() {
        try {
            let loginCheck = checkIsLoggedIn();
            if (loginCheck) {
                if (localStorage.getItem('employeecode') && Number(localStorage.getItem('employeecode')) > 0 && Number(localStorage.getItem('leadtype')) == 1)
                    history.push('/createProfile');
                else if (localStorage.getItem('employeecode') && Number(localStorage.getItem('employeecode')) > 0 && Number(localStorage.getItem('leadtype')) == 0)
                    history.push('/job_list');
                return;
            } else {
                localStorage.clear();
                sessionStorage.clear();
                this.getToken();
            }
            let langcode = (localStorage.getItem('languagecode') == null || localStorage.getItem('languagecode') == undefined || localStorage.getItem('languagecode') == 0 || localStorage.getItem('languagecode') == '') ? COMMON.default_lang_code : localStorage.getItem('languagecode')
            let shortcode = (localStorage.getItem('languagecode_short') == null || localStorage.getItem('languagecode_short') == undefined || localStorage.getItem('languagecode_short') == 0 || localStorage.getItem('languagecode_short') == '') ? COMMON.default_lang_shortcode : localStorage.getItem('languagecode_short')
            stringsoflanguages.setLanguage(shortcode)
            localStorage.setItem('languagecode', langcode)
            localStorage.setItem('languagecode_short', shortcode)
            this.setState({ languagecode: (this.state.languagecode = langcode) })
            var my_ipaddress = await publicIp.v4();
            localStorage.setItem('ipaddress', my_ipaddress)
            this.setState({ ipaddress: this.state.ipaddress = localStorage.getItem('ipaddress') });
            this.setState({ languagecode_color: this.state.languagecode_color = 2 })
            this.setState({ selectedLanguage: shortcode });
            if (localStorage.getItem('language_json_result') == null || localStorage.getItem('language_json_result') == 'null' || localStorage.getItem('language_json_result') == undefined || localStorage.getItem('language_json_result') == 'undefined')
                this.fetchLanguageData();
            else {
                let language_list = localStorage.getItem('language_json_result').toString() != 'null' ? JSON.parse(localStorage.getItem('language_json_result')) : {}
                this.bindLanguage(language_list)
            }
            this.call_job_function();
            this.refs.txtmobileno.focus();
        }
        catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "login", "componentDidMount", 0, error.toString());
        }
    }
    bindLanguage(response) {
        try {
            if (response.hasOwnProperty('language_json_result')) {
                let languageList = response.language_json_result.languagelist;
                this.setState({ languageData: this.state.languageData = languageList });
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "login", "bindLanguage", 0, error.toString());
        }
    }
    getToken() {
        try {
            window.fbq('track', 'Website', { fb_ad_function: "call_job_function", fb_ad_type: "Job list", fb_ad_loginuser: COMMON.employee_name });
            axios({
                method: 'Post',
                url: this.state.ipurl + 'common_token_generation/',
                crossdomain: true,
                params: {
                    deviceip: this.state.ipaddress,
                    type: 0,
                    languagecode: this.state.languagecode,
                    employeecode: -1,
                }
            })
                .then(async response => {
                    if (response != null && response != undefined && response != '') {
                        localStorage.setItem('accessToken', response.data.common_token_json_result.accessToken)
                        localStorage.setItem('refreshToken', response.data.common_token_json_result.refreshToken)
                    }
                })
                .catch(error => {
                });
        }
        catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "login", "getToken", 0, error.toString());
        }
    }
    call_job_function() {
        try {
            window.fbq('track', 'Website', { fb_ad_function: "call_job_function", fb_ad_type: "Job list", fb_ad_loginuser: COMMON.employee_name });
            var accessToken = localStorage.getItem('accessToken');
            axios({
                method: 'Post',
                url: this.state.ipurl + 'employee/filterbind/',
                crossdomain: true,
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json'
                },
                params: {
                    deviceip: this.state.ipaddress,
                    type: 0,
                    languagecode: this.state.languagecode,
                    employeecode: -1,
                }
            })
                .then(async response => {
                    if (response.data.status == 401) {
                        await generateRefreshToken().then((result) => {
                            if (result == 1) {
                                this.call_job_function();
                            }
                            else {
                                // alert('Unauthorized')
                            }
                        })
                    }
                    else {
                        if (response.data.filter_json_result.varstatuscode === 4) {
                            var skillList = response.data.filter_json_result.skill_list || [];
                            localStorage.setItem('filter_skill_list', JSON.stringify(skillList))
                            var jobfunctionlist = response.data.filter_json_result.jobfunctionlist || [];
                            localStorage.setItem('filter_job_function_list', JSON.stringify(jobfunctionlist))
                            var genderlist = response.data.filter_json_result.genderlist || [];
                            localStorage.setItem('filter_gender_list', JSON.stringify(genderlist));
                            var state_list = response.data.filter_json_result.state_list || [];
                            localStorage.setItem('filter_state_list', JSON.stringify(state_list));
                            var district_list = response.data.filter_json_result.district_list || [];
                            localStorage.setItem('filter_district_list', JSON.stringify(district_list));
                            var jobrolelist = response.data.filter_json_result.jobrolelist || [];
                            localStorage.setItem('filter_jobrole_list', JSON.stringify(jobrolelist));
                            var profilecategoryresult = response.data.filter_json_result.profilecategoryresult || [];
                            localStorage.setItem('filter_profile_category', JSON.stringify(profilecategoryresult));
                            var experiencelist = response.data.filter_json_result.experiencelist || [];
                            localStorage.setItem('filter_experience_list', JSON.stringify(experiencelist));
                            var jobtypelist = response.data.filter_json_result.jobtypelist || [];
                            localStorage.setItem('filter_jobtypelist', JSON.stringify(jobtypelist));
                            var schoolinglist = response.data.filter_json_result.schoolinglist || [];
                            localStorage.setItem('filter_schooling_list', JSON.stringify(schoolinglist));
                            var educationcategorylist = response.data.filter_json_result.educationcategorylist || [];
                            localStorage.setItem('filter_education_category', JSON.stringify(educationcategorylist));
                            var maritalstatuslist = response.data.filter_json_result.maritalstatuslist || [];
                            localStorage.setItem('filter_maritalstatus_list', JSON.stringify(maritalstatuslist));
                            localStorage.setItem('languageFilter', this.state.selectedLanguage)
                        } else if (response.data.filter_json_result.varstatuscode === 7) {
                            localStorage.clear();
                        }
                    }
                })
                .catch(error => {
                });
        }
        catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "login", "calljobfunction", 0, error.toString());
        }
    }
    search() {
        try {
            let empcode = localStorage.getItem('employeecode');
            if (empcode == null || empcode == undefined || empcode == 0 || empcode == -1) {
                this.setState({ search_flag: this.state.search_flag = true })
            }
            else {
                history.push('/Job_list');
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "login", "search", 0, error.toString());
        }
    };
    fetchLanguageData() {
        try {
            var accessToken = localStorage.getItem('accessToken');
            axios({
                method: 'Post',
                url: this.state.ipurl + 'languagebind/?',
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json'
                },
                params: {
                    ipaddress: this.state.ipaddress
                },
            })
                .then(async (response) => {
                    if (response.data.status == 401) {
                        await generateRefreshToken().then((result) => {
                            if (result == 1) {
                                this.fetchLanguageData();
                            }
                            else {
                                // alert('Unauthorized')
                            }
                        })
                    }
                    else {
                        if (response.data) {
                            localStorage.setItem("language_json_result", JSON.stringify(response.data))
                            this.bindLanguage(response.data);
                        }
                    }
                })
                .catch((error) => {
                });
        } catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "login", "fetchLanguageData", 0, error.toString());
        }
    }
    number_only = (event) => {
        try {
            const regex = /^[0-9]*$/;
            const input = event.target.value + event.key;
            if (!regex.test(input) || input.length > 10) {
                event.preventDefault();
            }
        } catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "login", "number_only", 0, error.toString());
        }
    };
    Checkmobnum(txtFrom = "") {
        try {
            if (this.state.mobnum == "") {
                this.setState({
                    errormobnum: stringsoflanguages.mob_validate2,
                    txtmobnum_invalid: true
                });
            } else {
                var num = this.state.mobnum;
                if (num && num.length == 10) {
                    this.setState({
                        errormobnum: '',
                        txtmobnum_invalid: false
                    });
                    if (txtFrom == "")
                        this.getOtp();
                } else {
                    this.setState({
                        errormobnum: stringsoflanguages.vali_t,
                        txtmobnum_invalid: true
                    });
                }
            }
        } catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "login", "Checkmobnum", 0, error.toString());
        }
    }
    changemob() {
        try {
            this.setState({ showMobileNumdiv: this.state.showMobileNumdiv = true })
            this.setState({ showOTPdiv: this.state.showOTPdiv = false })
            this.setState({ mobnum: "" })
        } catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "login", "changemob", 0, error.toString());
        }
    }
    onStart() {
        try {
            if (!this.state.isStarted) return;
            if (this.state.seconds > 0) {
                this.setState({ enableResend: this.state.enableResend = false })
                //3rd
                this.setState({ seconds: this.state.seconds = this.state.seconds - 1 })
            }
            else {
                this.setState({ seconds: this.state.seconds = 0 })
                this.setState({ isStarted: this.state.isStarted = false });
                this.setState({ enableResend: this.state.enableResend = true })
                clearInterval(this.state.timerId);
            }
        } catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "login", "onStart", 0, error.toString());
        }
    };
    onReset() {
        this.setState({ isStarted: this.state.isStarted = false })
        this.setState({ timer: this.state.timer = [0, 0, 0] });
    };
    onchangeOTP(e) {
        try {
            let value = e;
            if (this.state.otp != "" && this.state.otp.length == 5 && value.length == 6) {
                this.setState({ otp: this.state.otp = value });
                this.verifyOtp();
            }
            else {
                this.setState({ otp: this.state.otp = value });
            }
        } catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "login", "onchangeOTP", 0, error.toString());
        }
    }
    getOtp() {
        try {
            var accessToken = localStorage.getItem('accessToken');
            if (this.state.mobnum != '') {
                try {
                    axios({
                        method: 'Post',
                        url: this.state.ipurl + 'sendsms/getOTP/',
                        headers: {
                            'Authorization': 'Bearer ' + accessToken,
                            'Content-Type': 'application/json'
                        },
                        params: {
                            mobileno: this.state.mobnum,
                            ipaddress: localStorage.getItem('ipaddress'),
                            typecode: 9
                        },
                    })
                        .then(async response => {
                            if (response.data.status == 401) {
                                await generateRefreshToken().then((result) => {
                                    if (result == 1) {
                                        this.getOtp();
                                    }
                                    else {
                                        // alert('Unauthorized')
                                    }
                                })
                            }
                            else {
                                if (response.data.hasOwnProperty('sendotp_json_result')) {
                                    this.state.varstatuscode = response.data.sendotp_json_result.hasOwnProperty('varstatuscode') ? response.data.sendotp_json_result.varstatuscode : 57;
                                    if (this.state.varstatuscode == 57) {
                                        localStorage.setItem('mobileNumber', this.state.mobnum);
                                        this.setState({
                                            errorotp: 'Success! SMS code received',
                                            txtotpnum_invalid: false,
                                            otpRequested: true,
                                        })
                                        this.setState({ showMobileNumdiv: this.state.showMobileNumdiv = false })
                                        this.setState({ showOTPdiv: this.state.showOTPdiv = true })
                                        this.setState({ seconds: this.state.seconds = 30 })
                                        this.setState({ isStarted: this.state.isStarted = true });
                                        var timerId = setInterval(() => this.onStart(), 1000);
                                        this.setState({ timerId: this.state.timerId = timerId });
                                    }
                                }
                            }
                        })
                        .catch((error) => {
                            error_log.ERRORLOG(this.state.usercode, this.state.version, "login", "getotp", 0, error.toString());
                        });
                } catch (error) {
                    error_log.ERRORLOG(this.state.usercode, this.state.version, "login", "getotp", 0, error.toString());
                }
            }
            else {
                this.setState({
                    errormobnum: this.state.errormobnum = stringsoflanguages.mob_validate2,
                    txtmobnum_invalid: this.state.txtmobnum_invalid = true
                })
            }
            this.setState({ enableResend: this.state.enableResend = false })
        } catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "login", "getotp", 0, error.toString());
        }
    }
    verifyOtp() {
        try {
            var accessToken = localStorage.getItem('accessToken');
            axios({
                method: 'Post',
                url: this.state.ipurl + 'sendsms/verifyOTP/?',
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json'
                },
                params: {
                    mobileno: this.state.mobnum,
                    otpvalue: this.state.otp,
                    ipaddress: localStorage.getItem('ipaddress'),
                    typecode: 9
                },
            })
                .then(async response => {
                    if (response.data.status == 401) {
                        await generateRefreshToken().then((result) => {
                            if (result == 1) {
                                this.verifyOtp();
                            }
                            else {
                                // alert('Unauthorized')
                            }
                        })
                    }
                    else {
                        if (response.data.hasOwnProperty('employer_json_result')) {
                            this.state.varstatuscode = response.data.employer_json_result.hasOwnProperty('varstatuscode') ? response.data.employer_json_result.varstatuscode : 61;
                            if (this.state.varstatuscode == 67) {
                                localStorage.setItem("isloggedIn", true)
                                localStorage.setItem('accessToken', response.data.employer_json_result.accessToken)
                                localStorage.setItem('refreshToken', response.data.employer_json_result.refreshToken)
                                let employeecode = response.data.employer_json_result.hasOwnProperty('employeecode') ? response.data.employer_json_result.employeecode : -1;
                                let employeename = response.data.employer_json_result.hasOwnProperty('employeename') ? response.data.employer_json_result.employeename : '';
                                let leadtype = response.data.employer_json_result.hasOwnProperty('leadtype') ? response.data.employer_json_result.leadtype : 1;
                                //get web Idle timer from backend stored in localstorage
                                if (response.data.employer_json_result['employee_pwa'])
                                    localStorage.setItem('employee_pwa_video', response.data.employer_json_result['employee_pwa']);
                                if (response.data.employer_json_result['webexpirylimit'])
                                    localStorage.setItem('webExpireTime', response.data.employer_json_result['webexpirylimit']);
                                localStorage.setItem('employeemobilenumber', this.state.mobnum);
                                if (employeecode > 0 && leadtype == 0) {
                                    localStorage.setItem('enableLogin', "true");
                                    localStorage.setItem('employeecode', employeecode);
                                    localStorage.setItem('employeeName', employeename);
                                    localStorage.setItem('leadtype', 0);
                                    let preferredLangCode = response.data.employer_json_result['preferredlanguagecode'] || this.state.languagecode;
                                    localStorage.setItem('languagecode', preferredLangCode);
                                    let shortCode = this.state.languageData.find(el => el.languagecode == preferredLangCode)
                                    if (shortCode && shortCode['shorttype'])
                                        localStorage.setItem('languagecode_short', shortCode['shorttype']);
                                    if (preferredLangCode != localStorage.getItem('languageFilter')) {
                                        localStorage.removeItem('languageFilter')
                                    }
                                    history.push('/job_list');
                                }
                                else if (employeecode > 0 && leadtype == 1) {
                                    localStorage.setItem('employeecode', employeecode);
                                    localStorage.setItem('leadtype', 1);
                                    history.push('/createProfile');
                                }
                                else if (employeecode == -1) {
                                    localStorage.setItem('employeecode', -1);
                                    localStorage.setItem('leadtype', 1);
                                    history.push('/createProfile');
                                }
                            }
                            else if (this.state.varstatuscode == 61) {
                                this.setState({
                                    Expiredotp: 'Error! Expired OTP',
                                    txtotpnum_invalid: false
                                })
                                this.setState({ otp: this.state.otp = '' });
                                toast("OTP Expired.", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            }
                            else {
                                this.setState({
                                    Expiredotp: 'Success! SMS code Expired OTP',
                                    txtotpnum_invalid: false
                                })
                                toast(response.data.employer_json_result.responsestring, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    style: { backgroundColor: 'red', color: 'white' }
                                });
                                this.setState({ otp: this.state.otp = '' });
                            }
                        }
                    }
                })
                .catch((error) => {
                    error_log.ERRORLOG(this.state.usercode, this.state.version, "login", "verifyotp", 0, error.toString());
                });
        } catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "login", "verifyotp", 0, error.toString());
        }
    }
    render() {
        var slick_slider = {
            dots: false,
            arrow: false,
            autoplay: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 1,
            responsive: [{
                breakpoint: 1199,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        };
        return (
            <React.Fragment>
                <Slider className="slick_slider banner_slider banner_slider_3" {...slick_slider} slidesToShow={1} autoplay={false} fade="true" >
                    <div className="slide bg-layer">
                        <ToastContainer autoClose={10000} toastStyle={{ backgroundColor: "#ed9d3b", color: 'white' }} progressStyle={{ background: 'white' }} />
                        <div className="slide_img" style={{ backgroundColor: "white" }}></div>
                        <div className="slide__content  pb-xl-0  pt-xl-0 mt-30 login_mrg_top">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 col-md-8">
                                        <div className="slide__content--headings ttm-textcolor-white">
                                            <div className="content-tab">
                                                <div className="text-center" style={{ display: "flex", justifyContent: "center" }}>
                                                    <img src="images/footer-logo.png" alt="Your Logo" />
                                                </div>
                                                <div id="login_form" className="login_form wrap-form ">
                                                    <div className="row">
                                                        <div className="col-lg-12 text-center mt-5">
                                                            <p style={{ fontSize: 12, color: 'black' }}>{stringsoflanguages.jobsummary_tag}</p>
                                                            <p style={{ fontSize: 18 }}>{stringsoflanguages.local_first} </p>
                                                        </div>
                                                        {this.state.showMobileNumdiv &&
                                                            (<div className="row mt-30" style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <div className="col-lg-6 column" style={{ justifyContent: 'center' }}>
                                                                    <label>
                                                                        <i className="ti ti-mobile"></i>
                                                                        <input type="number"
                                                                            id="txtusername"
                                                                            ref={'txtmobileno'}
                                                                            placeholder={stringsoflanguages.contact_mbleno}
                                                                            autoComplete="off"
                                                                            maxLength={10}
                                                                            style={{ backgroundColor: '#FEFEFE', border: "1px solid #ff8d00", borderRadius: '5px', padding: "10px 10px 10px 50px" }}
                                                                            className="clsInput logintxt"
                                                                            value={this.state.mobnum}
                                                                            onKeyPress={(e) => this.number_only(e)}
                                                                            onChange={(e) => {
                                                                                this.setState({ mobnum: e.target.value });
                                                                            }}
                                                                            onBlur={() => this.Checkmobnum("blur")}
                                                                        />
                                                                    </label>
                                                                    {this.state.txtmobnum_invalid ? (
                                                                        <div className="errMobNum">{this.state.errormobnum}</div>
                                                                    ) : ('')}
                                                                </div>
                                                                <div className="featured-content btn-Align-Right col-lg-6" style={{ alignSelf: 'center' }}>
                                                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" onClick={() =>
                                                                        this.Checkmobnum()}>
                                                                        {stringsoflanguages.resend_otp_account}
                                                                    </button>
                                                                </div>
                                                                <div>
                                                                    {this.state.languageData.length > 0 ? (
                                                                        <IntlContext.Consumer>
                                                                            {context => (
                                                                                <div style={{ paddingTop: '15%', display: 'flex', justifyContent: 'center' }}>
                                                                                    <div className="d-inline-block">
                                                                                        {this.state.languageData.map((language, index) => (
                                                                                            <button
                                                                                                key={index}
                                                                                                onClick={() => {
                                                                                                    stringsoflanguages.setLanguage(language.shorttype);
                                                                                                    localStorage.setItem('languagecode', language.languagecode);
                                                                                                    localStorage.setItem('languagecode_short', language.shorttype);
                                                                                                    this.setState({
                                                                                                        selectedLanguage: this.state.selectedLanguage = language.shorttype,
                                                                                                        languagecode: this.state.languagecode = language.languagecode
                                                                                                    });
                                                                                                    this.setState({
                                                                                                        errormobnum: stringsoflanguages.mob_validate2,
                                                                                                        txtmobnum_invalid: false
                                                                                                    });
                                                                                                    this.call_job_function();
                                                                                                }}
                                                                                                style={{
                                                                                                    padding: "15px 25px",
                                                                                                    fontWeight: this.state.selectedLanguage === language.shorttype ? 'bold' : 'normal',
                                                                                                    color: this.state.selectedLanguage === language.shorttype ? 'orange' : 'black',
                                                                                                    textDecoration: 'none',
                                                                                                }}
                                                                                            >
                                                                                                {language.languagename}
                                                                                            </button>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </IntlContext.Consumer>
                                                                    ) : null}
                                                                </div>
                                                            </div>)}
                                                        {this.state.otpRequested && this.state.showOTPdiv == true && (
                                                            <div>
                                                                <div className="row mt-10" style={{ display: "flex", justifyContent: "center", fontSize: "18px", color: '#000' }}>{stringsoflanguages.otp_chg}</div>
                                                                <div className="row" style={{ display: "flex", justifyContent: "center", fontSize: '14px' }}>{stringsoflanguages.sent + " " + this.state.mobnum}  </div>
                                                                <div onClick={() => this.changemob()} style={{ display: "flex", justifyContent: "center", cursor: 'pointer', textDecorationLine: 'underline', color: 'green', fontSize: '10px' }}>{stringsoflanguages.mobileNo_change_header}</div>
                                                                <div className="row mt-30" style={{ justifyContent: "center", display: "flex" }}>
                                                                    <div className="col-lg-6 clsInput" style={{ marginBottom: "15px", fontSize: '14px' }}>
                                                                        <OtpInput
                                                                            value={this.state.otp}
                                                                            inputType="number"
                                                                            //onChange= {(e)=>{this.setState({otp:this.state.otp=e})}}
                                                                            onChange={(e) => this.onchangeOTP(e)}
                                                                            autoFocusOnLoad={this.state.otp == ''}
                                                                            containerStyle={{ justifyContent: 'center' }}
                                                                            inputStyle={{ width: '30px' }}
                                                                            numInputs={6}
                                                                            renderSeparator={<span>&nbsp;&nbsp;</span>}
                                                                            renderInput={(props) => <input {...props} />}
                                                                            shouldAutoFocus={this.state.otp == ''} />
                                                                        {!this.state.enableResend && (<div style={{ display: "flex", justifyContent: "center" }}>{stringsoflanguages.otp_re}  {this.state.minutes < 10 && 0}
                                                                            {this.state.minutes}:{this.state.seconds < 10 && 0}
                                                                            {this.state.seconds}  </div>)}
                                                                        {this.state.enableResend && (
                                                                            <div onClick={() => {
                                                                                this.setState({ otp: '' });
                                                                                this.getOtp();
                                                                            }} style={{ cursor: 'pointer', display: "flex", justifyContent: "center", textDecorationLine: 'underline', color: 'green' }} >
                                                                                {stringsoflanguages.reotp}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="featured-content btn-Align-Right col-lg-6" style={{ alignItems: 'flex-end', alignContent: 'flex-end' }}>
                                                                        <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" onClick={() => this.verifyOtp()}>
                                                                            {stringsoflanguages.verifiedotptext}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-none d-xl-block text-center position-relative">
                                            <div className="z-index-1 position-relative">
                                                <img className="rounded-circle box-shadow m-auto img-fuild" src="images/location-image/jobanya-img.png"></img>
                                            </div>
                                            <div className="cmp-logo one">
                                                <img className="img-fluid" src="images/location-image/chennai.png" />
                                            </div>
                                            <div className="cmp-logo two">
                                                <img className="img-fluid" src="images/location-image/madurai.png" />
                                            </div>
                                            <div className="cmp-logo three">
                                                <img className="img-fluid" src="images/location-image/salem.png" />
                                            </div>
                                            <div className="cmp-logo four">
                                                <img className="img-fluid" src="images/location-image/coimbatore.jpg" />
                                            </div>
                                            <div className="cmp-logo five">
                                                <img className="img-fluid" src="images/location-image/tiruchi.png" />
                                            </div>
                                            <div className="arrow-one">
                                                <img src="images/slides/arrow-1.png" />
                                            </div>
                                            <div className="arrow-two">
                                                <img src="images/slides/arrow-2.png" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </React.Fragment>
        )
    }
}
export default Login;
import React, { Component } from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import { history } from "../../history"
import {  COMMON } from '../../config'
let error_log = require('./../../error_log');

export class Menu extends Component {
    constructor() {
        super();
        this.state = {
            show: false,
            employeecode: 0
        }
      }
    

      componentDidMount() {
        try{
          let empcode = localStorage.getItem('employeecode');
          if (empcode == null || empcode == undefined || empcode == 0 || empcode == -1)
          {
              empcode = -1;
          }
          this.setState({employeecode : this.state.employeecode =  empcode});
        }
        catch (error) {
            error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Job_list", "componentDidMount", 0, error.toString());
        }
      }
      logOut() {
        localStorage.clear();
        sessionStorage.clear();
      setTimeout(() => {
                            history.push('/');
                        }, 2000);
    }
    render() {
        return (
            <Router>
                <nav className="main-menu">   
                {this.state.employeecode > 0 ?
                  <ul className="menu">
                        {/* <li>
                        <a href={'/'}>Home</a>                           
                        </li> */}
                        {/* <li>
                        <a href={'/about_us'}>About Us</a>                          
                        </li>
                        
                        <li>
                        <a href={'/gnjoblist'} >Government Jobs</a>   
                        </li>
                        <li>
                        <a href={'/contact_us'}>Contact Us</a>
                        </li>      
                        <li>
                        <button onClick={() => this.logOut()}>Log Out</button>
                        </li>                 */}
                    </ul>
                :
                <ul className="menu">
                {/* <li>
                <a href={'/'}>Home</a>                           
                </li>
                <li>
                <a href={'/about_us'}>About Us</a>                          
                </li>
                <li>
                <a href={'/job_list'} >Private Jobs</a>   
                </li>
                <li>
                <a href={'/gnjoblist'} >Government Jobs</a>   
                </li>
                <li>
                <a href={'/contact_us'}>Contact Us</a>
                </li>                       */}
            </ul>
                }      
                  
                </nav>
            </Router>
        )
        
    }
}

export default Menu;
import LocalizedStrings from 'react-localization';
const strings = new LocalizedStrings({
  "en": {
    file_upload_text: "Upload Profile Image",
    min_salary: "Min Salary",
    max_salary: "Max Salary",
    welcome_text: "Welcome",
    welcome_text1: "Please complete your profile",
    image_text: "Upload Your Photo for Better Visibility",
    applied_jobs: "Applied Jobs",
    all_jobs: 'All Jobs',
    reotp: "Resend OTP",
    otp_re: "Resend OTP in ",
    sent: "We have sent an OTP on",
    otp_chg: "Please Enter OTP",
    local_first: " Find local jobs with better salary! ",
    vali_t: "Please enter a valid 10-digit mobile number",
    employee_experience: "What is your experience?",
    skills_known: "Which of these do you know?",
    recommended_jobs_label: "My Matching Jobs",
    filter_by: "Filter by",
    filter_job_type: "Job Type",
    filter_job_location: "Job Location",
    ma_ypro: "Make Your Profile",
    re_pro: "Register Your Profile",
    no_recommended_jobs: "No Recommended Jobs found",
    no_search_jobs: "No Jobs found in this Job Title",
    no_Filter_search_jobs: "No Jobs found",
    no_applied_jobs: "You have not yet applied",
    candidate_job_type: "Job Type",
    showing: "Showing",
    job_of: "Jobs of",
    cl_era: "Clear",
    pre_me: " Previous",
    chose_ji: "Choose your Job Title",
    add_job_title: "Add Experience",
    cur_ex: "Expected Salary Range",
    wrk_pro: "Work Experience",
    edu_trt: "Education Level",
    load_deng: "load more",
    txt_ski: "Skills Required",
    off_txt: "Offered Salary",
    inform_txt_key: "Job Information",
    expected_key: "Expected Minimum Salary",
    expect_ey: "Expected Maximum Salary",
    findlist_job: "Job List",
    findjobs_text: "Find Jobs",
    jobsummary_tag: "User Friendly & Trusted HR Manager",
    lang_read_text: "Read",
    lang_write_text: "Write",
    lang_speak_text: "Speak",
    persons_applied_text: " persons applied",
    recommended_jobs_text: "Recommended jobs based on your profile",
    matching_jobs_text: "My matching jobs based on your preferences",
    incomplete_text: "Incomplete",
    rejection_remarks_error: "Please enter reason for rejection",
    rejection_remarks_text: "Reason for Rejection",
    rejection_save_text: "Save",
    customercaremsg: "For Help, Please Call Our Customer Support Executive",
    customercaremsg1: " (Monday - Saturday / 10:00 am - 06:00 pm)",
    calls_made: "Calls Made",
    calls_received: "Calls Received",
    employer_job_expired: "Job Expired",
    apply_call: "Call Now",
    apply_viewjob: "View Job",
    invite_call: "Now you can call this employee @ ",
    download_resume_btntext: "View Resume",
    download_resume_icontext: "Download Your Resume",
    emp_legend: "Mandatory",
    checkout_button_text: "PROCEED",
    registration_fee: "Registration Fee",
    congratulations: "Congratulations !",
    offer_msg1: "Complete Your Profile 100% & GET ",
    offer_msg2: "% OFF",
    gstpercentage: "*GST 18% Applicable",
    checkout_msg1: "Complete Your Profile",
    checkout_msg2: "&",
    checkout_msg3: "GET",
    checkout_msg4: "OFF",
    checkout: "Pay Now",
    goback: "Go Back",
    thankyou: "Thank You",
    payment_success: "Your payment has been received successfully",
    proceed: "PROCEED",
    sorry: "Sorry",
    payment_failed: "Your payment has failed.Please try again.",
    retry: "RETRY",
    new_user: "NEW USER",
    registered_user: "REGISTERED USER",
    login: "Login",
    employee_search: "General Search",
    filesize_restriction: "File size must be less the 2MB",
    last_login: "Last Login",
    disclaimer_class: 'Please read our ',
    disclaimer_class1: 'Disclaimer Clause',
    disclaimer_class2: '',
    disclaimer_class3: 'Sourced from Websites of respective ',
    disclaimer_class4: 'Government Organizations.',
    any_state: " Any State",
    any_district: "Any District",
    filter_any_district: "Any District",
    filter_any_taluk: "Any Taluk",
    any_taluk: " Any Taluk",
    best_salary: 'Best Salary in the Industry',
    flash_jobs: 'Flash Jobs',
    flash_jobs_details: 'Flash Job Details',
    aadharAlphaNumeric: 'Enter valid Udyam/Udyog Aadhar number',
    Document_type: "Document Type",
    Additional_Documents: "Additional Documents",
    doument_error: "Please Upload atleast three documents",
    doument_error_one: "Please Upload atleast one document from GSTIN/PAN/Aadhar",
    Document_file: "Upload File",
    District: 'District',
    Taluk: 'Taluk',
    City: "Town / City",
    Reference_number: "Reference Number",
    File: "Options",
    Reference_number_error: "Please enter Reference No.",
    Reference_number_error_valid: "Please enter Valid Reference No.",
    Rejected_job: "This job was rejected",
    Telecallers: 'Telecallers',
    Referal: 'Referal',
    employer_usertype_type_errormsg: 'Please select telecallers',
    Employee_known_text: "Please select",
    employer_info_errormsg: 'Please provide info',
    sslc_first: 'Please add SSLC',
    text_viewed: 'Viewed',
    Match_pro: "Matching Profiles",
    employer_profile_txt: 'Upload Your company logo to make Your Profile Interesting !',
    employer_gallery_txt: 'Upload Your company Photo to make Your Profile Interesting !',
    employee_profile_View: 'Upload Your Photo to make Your Profile Interesting !',
    abuse_txt: 'Report Abuse',
    currently_pursuing_word: "Currently Pursuing",
    currently_pursuing: "Is Currently Pursuing",
    total_no_profile: "Total Profiles Count : ",
    total_job_list: "Total Jobs Count : ",
    exp_year_str: "Years",
    exp_year_str1: "Year",
    exp_month_str: "Months",
    exp_month_str1: "Month",
    experience_years1: "Year(s)",
    experience_mnt1: "Month(s)",
    full_complete_profile: "After Completing your profile 100%, ",
    full_complete_profile1: "you can download your Resume with current date & time for Your Interview",
    download_text: "Download Completed",
    subscriptions_buy_notes: 'KINDLY SUBSCRIBE THE PLANS AFTER SEEING SUFFICIENT MATCHING PROFILES In "My Preferred Candidates"',
    please_upload_profile: "Please upload Profile image",
    notification_on: "ON",
    notification_off: "OFF",
    share_gnjob1: "Government Job Notifications from JOBANYA",
    share_gnjob2: "To view full details log in to JOBANYA Android App",
    share_job1: "Job Notifications from JOBANYA",
    share_job2: "To view full details log in to JOBANYA Android App",
    share_open: "Openings",
    subscription_failed: "Subscription has failed",
    gst_applicable: "* GST as applicable",
    jobpost_posted_on: "Posted on : ",
    profilesummary_title: "Available Profile Summary",
    my_pref_cand: 'My Preferred Candidates',
    eligible_cand: 'Eligible Candidates',
    invited_cand: 'Invited Candidates',
    applied_cand: "Applied candidates",
    invited_now: "Invite Now",
    posted_jobs: "Total Posted Jobs",
    jobpost_approval_info: 'Please Note: A Job Post cannot be Edited, except for the HR Contact Details, once the Job Post is Approved & Posted',
    jobpost_tab_search_hint: 'Search Candidate’s Profiles by clicking the Search Icon',
    jobpostlist_hint: "Click your Listed Job Post, to view the Related Candidate Profiles",
    total_vacancies: 'Total Job Vacancies : ',
    incomplete_profile: "Your profile is incomplete.",
    lead_incomplete_profile_header: "Profile Status",
    lead_incomplete_profile: "Your profile is ",
    lead_incomplete_profile1: " completed. Fill ",
    lead_incomplete_profile2: " for best results.",
    lead_incomplete_profile3: " to apply for this job.",
    lead_incomplete_profile4: " completed. Fill ",
    keep_document_ready: "KEEP THESE DOCUMENT NUMBERS READY \n # Company GST   # Company PAN   # Udyam / Udyog aadhar \n in \n JPG/JPEG/PDF Format \n FOR REGISTRATION",
    incomplete_profile_employer: "Attract more employees by completing your profile 100%",
    employer_looking: "Looking for Desktop Version?",
    employer_visit_portal: "Please visit  ",
    upto: 'Max of ',
    as_per_settings: 'As Per Your Employee Preferences Settings',
    reset_filter: "RESET FILTERS",
    clear_filter: 'CLEAR FILTERS',
    reset_filter_inicaps: "Reset Filters",
    clear_filter_inicaps: 'Clear Filters',
    aadhar_note: '*TO ENSURE GENUINENESS OF YOUR PROFILE DURING INTERVIEW',
    from_age: 'From age',
    to_age: 'To age',
    from_sal: 'From Salary',
    to_sal: 'To Salary',
    from_exp: 'From Experience',
    to_exp: 'To Experience',
    Social_Media_link: "Social Media Links",
    linkedin_Media_link: "LinkedIn ",
    Twitter_Media_link: "Twitter ",
    Facebook_Media_link: "Facebook ",
    Instagram_Media_link: "Instagram ",
    Youtube_Media_link: "YouTube ",
    valid_link: "Please enter valid link",
    employer_preference_word: "While selecting Preferences, BE VERY SPECIFIC, so that, you will receive PERFECTLY MATCHING EMPLOYEE PROFILES",
    employee_preference_word: "While selecting Preferences, BE VERY SPECIFIC, so that, you will receive PERFECT JOB NOTIFICATIONS & JOB RECOMMENDATIONS",
    employer_gallery_word: "Choose images to upload",
    youtube: 'YouTube',
    facebook: 'Facebook',
    telegram: 'Telegram',
    employer_reg_msg: ' Your registration has been submitted successfully. Please verify your Email ID by clicking email verification link sent to your registered Email Id.',
    Search_here: 'Search here',
    dismissall_qus: 'Are you sure want to Dismiss All ?',
    deleteactiveJobTitle: 'You are try to delete your current job role. Please choose your current job role before deleting.',
    deleteJobTitle: 'Are you sure want to delete this experience?',
    placeholder_jobfunction: 'Search by Job Category',
    placeholder_jobrole: 'Search by Job Title',
    placeholder_employmenttype: 'Search by Employment Type',
    placeholder_district: 'Search by District',
    placeholder_taluk: 'Search by taluk',
    placeholder_faclities: 'Search by Facilities Offered',
    placeholder_skills: 'Search by Skills',
    placeholder_Education: 'Search by Education Category',
    placeholder_Qualification: 'Search by Qualification',
    placeholder_Specialization: 'Search by Specialization',
    textfont: 24,
    emp_footer_size: 16,
    Pending: 'Pending',
    not_auth: "You are not Authorized to view this content",
    app_share_employee: "Download India's No.1  employee searching app now \n",
    app_share_employer: "Download India's No.1  job searching app now \n",
    Select_district: 'Select district',
    Select_taluk: 'Select Taluk',
    select_education: 'Select Education Category',
    Select_facilities_offered: 'Select facilities offered',
    Select_Qualification: 'Select Qualification',
    Select_Specialization: 'Select Specialization',
    Exact_match: 'Exact Match',
    Similar_match: 'Similar Match',
    Invite: 'Invite',
    Invite_Now: 'Invite & Call',
    Invite_Now_qus: 'Are you sure want to invite ?',
    Mark: 'Mark',
    Latitude: "Latitude:",
    Longitude: "Longitude:",
    PDF_Viewer: 'PDF Viewer',
    Resume: 'Resume',
    PDF: "pdf",
    days: 'Days',
    search_similar: 'Search similar profiles',
    atleaseone_proof: 'Please upload atleast two proof',
    atleaseone_proof1: 'Upload File',
    split_Employee: "Employee",
    employer_Portal: "Employer Portal",
    split_Employer: 'Employer',
    update_available: "Update Available",
    update_msg: "There is an Update Available. Please Click Update Now.",
    force_update: "Update Now",
    home_update: 'Update Now for \n" Best Results "',
    Later: 'Later',
    male: 'Male',
    female: 'Female',
    transgender: 'Transgender',
    netinfo_heading: "Information",
    limit_cross_head_jobfunction: "Alert",
    limit_cross_head_joblocation: "Alert",
    limit_cross_head_jobfunction_error: "You can choose maximum of",
    limit_cross_head_joblocation_error: "You can choose maximum of",
    search_results: "Suggested Results",
    netinfo_heading_server: "Server not reachable",
    netinfo_subheading_server: "Server not reachable Please try again",
    fresher_status: "I am a fresher",
    netinfo_subheading: "Please Check Your Network Connection",
    netinfo_subheading_reachable: "Please Check Your Network Connection",
    schooling_no_record: "No Record Found",
    selectlangheadertag: "Preferred Language        ",
    experience_error: "Please Enter Valid Experience",
    experience_error1: "Please Enter Valid Experience Month",
    add_schooling: "Kindly add the schooling information first",
    selectedlanguage_heading: "Please Select Your Language ",
    Select: 'Select',
    Select_Skills: 'Select Skills',
    netinfo_subheading2: "Something Went Wrong",
    no_records: "No records found",
    no_notification: "There are no new notifications found",
    sslc_not_eligible: "You are not Eligible to add SSLC",
    hsc_not_eligible: "You are not Eligible to add HSC",
    exp_del_fresher_heading: "Confirmation",
    at: "at",
    since_experience: "Since",
    exit_cancel: "Cancel",
    personal_info_fill_first: "Please complete your personal info to proceed",
    not_eligible: "Not Eligible",
    apply_limit: "Applied limit exceeded",
    alrdy_invited: "Already Invited",
    invite_then_reject: "You already reject this job",
    apply_then_reject: "You are not selected for this job",
    education_exist: "Already Exist",
    exit_ok: "OK",
    any_locations: "Any locations",
    any_location: "Any Location",
    otp_message: "Invalid OTP",
    otp_sent: "OTP sent",
    err_checkismax: "Please enter valid Maximum salary",
    langselectnext: "Next",
    sliderfirsttitle: "Welcome to JOBANYA",
    slidersecondtitle: "",
    sliderthirdtitle: "",
    sliderfourtitle: "",
    getstarted: "Get Started",
    lang: "Language",
    loginhead: 'Employee',
    loginsubhead: "Login in to continue",
    mobileplaceholder: 'Mobile No. or User name',
    passwordplaceholder: 'Password',
    forgetpassword: 'Forgot Password?',
    signinbutton: 'Sign In',
    signupbutton: 'New User? Sign Up',
    forgotsubmit: 'Submit',
    otptext: 'OTP',
    getotptext: 'Get OTP',
    verifiedotpmessage: 'Please Verify OTP',
    verifiedotptext: 'Verify OTP',
    home_header_title1: 'JOBANYA',
    home_tab_home: 'Home',
    home_tab_recommended: 'Recommended',
    home_tab_applied: 'Applied',
    home_tab_invitedon: "Invited on",
    home_tab_invited: 'Invited',
    home_tab_wishlist: 'Shortlisted',
    home_jobtype: 'Job Type',
    home_joblocation: 'Job Location',
    home_joblocation1: 'Job Location District',
    job_joblocation1: 'Candidate Job Preference Location - District',
    home_industry: 'Industry',
    home_jobfunction: 'Job Category',
    home_govjobs: 'Government Jobs',
    home_eligiblejobs: 'My Matching Jobs',
    home_my: 'My',
    home_flashjobs: 'Flash Jobs',
    // clear_filter: 'Clear Filters',
    jobsummary_title: 'Available Job Summary',
    jobsummary_privatejobs: 'Private Jobs',
    jobsummary_fresherjobs: 'Freshers Jobs',
    jobsummary_appliedjobs: 'My Applied Jobs',
    jobsummary_eligiblejobs: 'My Matching Jobs',
    jobsummary_govtjobs: 'Govt Jobs',
    jobsummary_govtjobs: 'Govt Jobs',
    enter: 'Enter',
    footer_privatejobs: 'Total Jobs',
    footer_flashjobs: 'Flash Jobs',
    footer_govtjobs: 'Government Jobs',
    footer_jobs: 'Jobs',
    apply: "Applied",
    apply_filter: "Apply",
    salary_scale: "Salary Scale",
    invite_accept: "Accepted",
    invite_decline: "Declined",
    apply_accpt: "Accepted",
    apply_reject: "Rejected",
    Posted_on: "Posted on",
    invitation_accept: "Invitation Accepted Successfully",
    invitation_reject: "Invitation Rejected Successfully",
    education_plus: "Nothing is here yet! Add education by clicking the plus button below",
    skills_plus: "Nothing is here yet! Add skills by clicking the plus button below",
    no_record_found: "No record found",
    reference_plus: "Nothing is here yet! Add reference by clicking the plus button below",
    sidebar_home: "Home",
    sidebar_profile: "View My Profile",
    sidebar_resumebuilder: "Resume Builder",
    sidebar_news: "News",
    sidebar_events: "Events",
    sidebar_govjobs: "Government Jobs",
    sidebar_settings: "Settings",
    sidebar_contactus: "Contact Us / Feedback",
    sidebar_share: "Share with Friends",
    sidebar_rating: "Google Ratings & Reviews",
    sidebar_logout: "Logout",
    resume_heading: "Resume Builder",
    resume_personalinfo: "PERSONAL INFO",
    resume_contactinfo: "CONTACT INFO",
    resume_education: "EDUCATION",
    resume_skills: "JOB CATEGORY \nJOB TITLE & SKILLS",
    resume_exp: "EXPERIENCE",
    resume_ref: "REFERENCE",
    resume_recd: "(Recommended By / ",
    resume_recd1: "என்னை சிபாரிசு செய்பவர்கள்)",
    resume_preference: "MY JOB PREFERENCES",
    personal_header: "Personal Info",
    date: "Date",
    personal_fullname: "Full Name",
    personal_fathername: "Father Name",
    personal_spousename: "Spouse Name",
    personal_dob: "Date of Birth",
    personal_gender: "Gender",
    personal_marital: "Marital Status",
    personal_aadhar: "Aadhar No.",
    personal_lg: "Languages Known",
    personal_medical: "Any known medical history",
    personal_upload: "Upload Resume",
    personal_save: "Save",
    personal_diff: "Differently Abled",
    personal_yes: "Yes",
    personal_no: "No",
    personal_add: "Add",
    personal_format: "(doc,docx and PDF format only)",
    contact_header: "Contact Info",
    contact_door: "Door No. / Street",
    contact_area: "Area",
    contact_taluk: 'Job Taluk',
    contact_city: "Town / City Name ",
    contact_district: "District",
    contact_state: "State",
    contact_pincode: "Pincode",
    contact_emailid: "Email ID",
    contact_mbleno: "Mobile No. with WhatsApp",
    contact_altmbleno: "Alternative Mobile No. (if any)",
    contact_save: "Save",
    date_of_birth: "Date of birth",
    work_exp_fresher: "Fresher",
    work_exp_experienced: "Experienced",
    address: "Venue",
    contact_address: "Address",
    edu_header: "Education",
    edu_schooling: "School",
    edu_category: "Category",
    edu_qualification: "Qualification",
    edu_insti: "Institution",
    edu_loc: "Location",
    Candidate_location: "Candidate Location",
    edu_mark: "Percentage of mark",
    edu_yrofpassing: "Year of passing",
    edu_save: "Save",
    percentage_check: "Please enter valid percentage",
    ref_popup_header: "Relationship",
    ref: "Reference (Recommended By / என்னை சிபாரிசு செய்பவர்கள்)",
    ref_name: "Name",
    ref_desig: "Designation / Job Title",
    ref_others: "Mention your relationship",
    references_candidate_required: 'please select relationship',
    ref_org: "Company Name",
    ref_relationship: "Relationship to candidate",
    ref_remarks: "Remarks",
    ref_save: "Save",
    ref_del: "Delete",
    ref_update: "Update",
    per_view_header: "My Profile",
    per_view_marital: "Marital Status",
    per_view_father_name: "Father Name",
    per_view_spouse_name: "Spouse Name",
    per_view_dob: "Date of Birth",
    per_view_aadhar: "Aadhar No.",
    per_view_lang: "Languages Known",
    per_view_medical: "Any known medical history",
    per_view_differentlyabled: "Differently Abled",
    per_contact: "Contact Info",
    per_Details: "Personal Details",
    per_edu: "Education",
    per_school: "Schooling",
    per_after_school: "College",
    per_skills: "Job Category, Job Title & Skills",
    per_ref: "Reference",
    per_ref_recd: "(Recommended By / என்னை சிபாரிசு செய்பவர்கள்)",
    per_pre: "My Job Preferences",
    exp_totalexp: "Total Experience",
    exp_add: "Add your experience",
    exp_years: "Years",
    exp_heading: "Experience",
    exp_org: "Company Name",
    exp_desig: "Designation / Job Title",
    experience_from_month: "Please select from month",
    experience_to_month: "Please select to month",
    exp_location: "Location",
    exp_from: "From",
    exp_to_month: "To month",
    exp_from_month: "From month",
    exp_to: "To",
    shift_start: 'Start Time',
    shift_end: 'End Time',
    exp_month: "Month",
    exp_year: "Year",
    exp_currently: "Currently working here",
    job_currently: "Current job role",
    exp_save: "Save",
    prefer_ok: "OK",
    jobfunction_currently: "Current position",
    experience_yearoverlap: "Your current experience overlaps with another experience",
    experience_fromyear_required: "Please enter year",
    experience_fromyearvalid_required: "Please enter valid year",
    experience_tomonth_required: "Please enter month",
    experience_toyear_required: "Please enter year",
    experience_tomonthvalid_required: "Please enter valid month",
    experience_toyearvalid_required: "Please enter valid year",
    pre_emptype: "Employment Type",
    pre_timeforjg: "Time for Joining",
    pre_immediate: "Immediate",
    pre_within: "Within",
    pre_days: "Days",
    pre_day: 'Day',
    pre_expected: "Expected Salary Per Month",
    pre_permnth: "Per Month",
    pre_header_popup_type: "Employment Type",
    pre_header_popup_sal: "Salary",
    pre_to: "to",
    pre_jobrole: "Job Title",
    pre_fun: "Job Category",
    skills_heading: "Skills",
    skills_jobfunction: "Job Category",
    skills_Role: "Job Title",
    mobileNo_change_header: "Change Mobile Number",
    Mobileno_oldnum: "Old Mobile No.",
    mobileNo_change_number: "New Mobile No.",
    mobileNo_change_otp: "OTP",
    mobileNo_change_change_mobile_number_button: "Change Mobile Number",
    invited: "Invited",
    toast_mobile: "Mobile Number Updated Successfully",
    login_invisible: "Your Account is in Invisible Mode.",
    login_invisible_active: "Do you want to Reactivate Now?",
    accout_reactivate: "Account Reactivation",
    account_otp: "OTP is sent to your Registered Mobile Number",
    resend_otp_account: "Get OTP",
    resend_otp_verify: "Verify",
    login_yes: "Yes",
    login_no: "No",
    noti_head: "Notification",
    noti_jobs: "Jobs",
    noti_news: "News",
    noti_events: "Events",
    events_head: "Events",
    job_description: "Description",
    news_head: "News",
    link: "Link",
    filterby: "Filter By",
    govt_jobs: "Government Jobs",
    noti_button: "Dismiss All",
    noti_swipe: "(Swipe left to dismiss)",
    password_change_header: "Change Password",
    password_change_old_password: "Old Password",
    password_change_new_password: "New Password",
    password_change_change_password_button: "Change Password",
    toast_password: "Password Changed Successfully!",
    registration_heading: "Employee Registration",
    registration_heading1: "One Time Registration Fees",
    registration_user: "Name of the User",
    registration_name: "User Name",
    registered_name_msg: "(Name,Any number without any space)",
    registration_mobileno: "Mobile No.",
    registration_password: "Password",
    registration_otp: "OTP",
    registration_otpbutton: "Get OTP",
    registration_checkbox: "I agree to the JOBANYA",
    registration_submitbutton: "Sign Up",
    signup_button: "Sign Up & Continue",
    forgetpassword_mobileno: "Mobile No.",
    forgetpassword_otp: "OTP",
    forgetpassword_password: "Password",
    forgetpassword_submit: "Submit",
    Setting_heading: "Settings",
    Setting_changepassword: "Change Password",
    Setting_changemobileno: "Change Mobile No.",
    Setting_Notification: "Notification",
    Setting_Preferedlanguage: "Preferred Language",
    Setting_invisiblemode: "Invisible Mode",
    Notification_invisiblemode: "Invisible Mode",
    notifi_submit_toast: "Updated Successfully!",
    image_upload_size_msg: "Image size is too large",
    image_upload_format_msg: "File should be in '.jpg','.jpeg','.png' formats",
    OTP_Validation: "Please enter 6 digits OTP",
    Notification_msg1: "Do you want to change your Account to Invisible Mode?",
    Notification_msg2: "Employers will not be able to view your Account.",
    Notification_cancel: "Cancel",
    Notification_submit: "Submit",
    Notification_heading: "Notification",
    Notification_jobs: "Jobs",
    Notification_news: "News",
    Notification_events: "Events",
    Contactus_heading: "Contact Us",
    Contactus_website: "Website",
    Contactus_emailid: "Email Id",
    Contactus_mobileno: "Mobile No.",
    Contactus_subject: "Subject",
    Contactus__description: "Description",
    Contactus_button: "Submit",
    Setting_invisiblemode: "Invisible Mode",
    logout_heading: "Logout",
    logout_msg: "Are you sure, you want to Logout ?",
    logout_cancel: "Cancel",
    logout_logout: "Logout",
    Search_heading: "Search",
    Recent_Search: "Recent Search",
    Search_Something: 'Search Something',
    No_data_Available: 'No data Available',
    Placeholder: "Search by Location, Industry, Job Category, Job Title",
    toaster_job_title_error: "You can select upto 4 Job Titles",
    toaster_job_title_fresher_error: "You can select upto 1 Job Titles",
    toaster_job_type_error: "You can select upto 4 Job Types",
    already_Exist: "User Name already Exist",
    gn_desc_job_desc: "Job Description",
    gn_desc_link: "Source",
    gn_desc_downloads: "Downloads",
    gn_no_vacanies: "No. of Vacancies",
    gn_Expiry: "Last Date",
    rl_apply_now: "Call & Apply",
    rl_apply_now_one: "Are you sure want to apply ?",
    rl_freshers: "Freshers",
    rl_fresher: "Fresher",
    rl_sortby: "Sort By",
    rl_filterby: "Filter By",
    rl_applyfilter: "APPLY FILTERS",
    rl_filter: "APPLY FILTER",
    rl_jobtype: "Job Type",
    rl_experience: "Experience",
    rl_qualification: "Education",
    rl_skills: "Skills",
    Job: "Job",
    Description: 'Description',
    rl_description: "Job Description",
    rl_grp: "Job Group",
    rl_maritial: "Marital Status",
    rl_gender: "Gender",
    rl_age: "Age Criteria",
    rl_differently: "Differently Abled",
    rl_any: "Any",
    rl_no: "No",
    rl_preferred: "Preferred",
    rl_lastdate: "Last Date",
    rl_no_of_opening: "No. of Openings",
    rl_work: "Work Timings",
    rl_lang: "Languages Known",
    max_length_preference: "You can select maximum of",
    rl_aboutcompany: "About Company",
    rl_turnover: "Turn Over",
    rl_fac: "Facilities Offered",
    log_industry: "Industry/Sector",
    rl_activity: "Activity Type",
    rl_company: "Company Type",
    rl_employee: "No. of Employees",
    rl_contact: "Contact",
    rl_address: "Address",
    r1_email: "Email",
    alternative_mobileno: "Alternative Mobile No.",
    not_eligible_text: "You are not eligible to apply \n",
    not_eligible_text_1: "(Your Resume Preferences doesn't match)",
    rl_apply: "Apply & Call",
    rl_do_invitation: "Do you want to accept this invitation?",
    rl_decline: "Decline",
    accept_qus: 'Are you sure want to accept this invitation ?',
    decline_qus: "Are you sure want to decline this invitation ?",
    rl_decline_one: 'Are you sure want to decline ?',
    rl_accept: "Accept",
    rl_accept_one: 'Are you sure want to accept ?',
    r1_group: "Job Group",
    rl_Age: "Age Criteria",
    rl_school: "School",
    r1_daysleft: " days left",
    expired: "Expired",
    expired_todat: "Expiring Today",
    r1_to: "to",
    r1_years: "Years",
    contact_msg: "Message",
    assistent_call: 'For Assistance Call',
    job_head: "Report Abuse",
    job_msg: "Are you sure you want to report this job post?",
    job_cancel: "Cancel",
    job_report: "Report",
    edu_aft_schl_heading: "Education",
    edu_aft_schl_sub: "College",
    edu_aft_schl_qualification: "Qualification",
    edu_aft_schl_specialization: "Specialization",
    edu_aft_schl_institution: "Institution",
    edu_aft_schl_location: "Location",
    edu_aft_schl_mark: "CGPA / Percentage",
    edu_aft_schl_year: "Year of Passing",
    edu_aft_schl_button: "Save",
    contact_city: "Town / City Name",
    preference_heading: "My Job Preferences",
    preference_job: "Job Category",
    preference_Role: "Job Title",
    preference_Employement: "Employment Type",
    preference_taluk: "Job Taluk",
    preference_time: "Time for Joining",
    preference_time1: "Immediate",
    preference_time2: "Within",
    preference_day: "Days",
    preference_to: "to",
    preference_salary: "Expected Salary Per Month",
    preference_button: "Next",
    rate_ourapp: "RATE OUR APP",
    apprate_content: "How would you rate our app experience?",
    later_btn: "Later",
    ratenow_btn: "Rate Now",
    err_preferences_min_required: "Please enter minimum salary",
    add_schooling: "Kindly add the schooling information first",
    err_preferences_max_required: "Please enter the maximum salary",
    err_preferences_min_max_values: "Please enter valid max. salary",
    err_preferences_max_min_values: "Please enter valid min. salary",
    err_preferences_days_required: 'please enter days',
    err_preferences_min: "",
    err_preferences_emptype_required: 'please select  employment Type',
    err_job_function_required: 'please select the job category',
    err_preference_jobrole_required: 'Please select Job Title',
    err_preference_joblocation_required: 'please select job location',
    err_preference_jobtaluk_required: 'please select job taluk',
    err_preference_employementtype_required: "Please select employement type",
    registered_msg: "Successfully Registered",
    err_preference_employementtype_empty: "",
    err_preference_employementtype: "",
    err_job_function_empty: "",
    err_job_function: '',
    err_preference_joblocation: '',
    preference_type: "Job Type",
    err_preferences_days: '',
    err_preferences_days_empty: '',
    contact_msg: "Message",
    nameofuse_validate: '',
    nameofuse_validate1: "Name of the user should be atleast than 3 characters",
    nameofuse_validate2: 'Name of the user cannot exceed 50 characters',
    nameofuse_validate3: 'Please enter name',
    nameofuse_validate5: 'Please enter name',
    nameofuse_validate6: "Please enter valid name",
    user_validate1: "User name should be atleast 3 characters",
    user_validate2: 'User name cannot exceed 50 characters',
    user_validate3: 'Please enter user name',
    user_validate5: 'Please enter user name',
    user_validate6: "Please only use Alpha & Numeric & Do not use Space & Special Characters",
    user_validate7: "User name already exist",
    mob_validate1: 'Please enter mobile number',
    mob_validate2: "Please enter valid mobile number",
    mob_validate4: "Please enter valid mobile number",
    mob_validate5: "Please enter mobile number",
    mob_validate7: "Mobile number already exist",
    pass_validate1: "Please enter password",
    pass_validate2: "Password should be atleast 6 characters",
    pass_validate5: "Please enter password",
    pass_validate7: "Incorrect Password",
    pass_validate1_new: "Please enter password",
    pass_validate2_new: "Password should be atleast 6 characters",
    pass_validate5_new: "Please enter password",
    textValue: "Get OTP",
    textValue1: 'Resend OTP',
    check_value: "I agree to the JOBANYA",
    check_value1: " Terms and Conditions",
    password_message: "Please enter valid mobile Number",
    password_success: "Password Changed Successfully!",
    username_required: "Please enter mobile No. or user name",
    validate_username: "please enter valid user name",
    password_required: "Please enter password",
    password_limit: "Password should be below 20 characters",
    password_length: "Please enter valid login credentials",
    username_length: "Please enter valid login credentials",
    street_name_required: "Please enter street name",
    areaname_required: "Please enter area name",
    cityname_required: "Please enter Town / City name",
    emailid_required: "Please enter Email ID",
    emailid_already_exits: "Email id already exits",
    emailid_format: "please enter valid Email",
    mobileno_required: "Please enter mobile No. ",
    mobileno_max: "Please enter valid mobile number",
    same_alter_no: "The Alternate No. cannot be the Primary No.",
    pincode_required: "Please enter pincode ",
    pincode_max: "Please enter valid pincode",
    state_name_required: "Please enter state name",
    altmobileno_max: "Please enter valid alternate mobile number",
    state_required: 'Please select state',
    district_required: 'please select district',
    city_required: 'please select Town / City',
    reference_name_required: "Please enter name",
    reference_designation_required: "Please enter designation",
    reference_relationship_required: "Please enter relationship",
    reference_organization_required: "Please enter organization",
    reference_emailid_required: "Please enter the Email Id ",
    reference_emailid_format: "please enter valid Email",
    reference_mobileno_required: "Please enter mobile No.",
    reference_mobileno_max: "Please enter valid mobile No.",
    reference_mobileno_reference: "Your Contact No. cannot be a Reference No.",
    references_candidate_required: 'please select candidate',
    schooling_institution_required: "Please enter institution",
    schooling_qualification_required: "Please select qualification",
    after_schooling_institution_required: "Please enter institution",
    after_schooling_location_required: "Please enter location",
    after_schooling_mark_required: "Please enter percentage",
    after_schooling_category_required: "Please select category",
    after_schooling_qualification_required: "Please select qualification",
    after_schooling_year_required: "Please enter year of passing",
    after_schooling_year_validate: "Please enter valid year of passing",
    after_schooling_specialization_required: "Please select specialization",
    experience_organization_required: "Please enter organization",
    experience_designation_required: "Please enter designation",
    experience_location_required: "Please enter location",
    experience_fromyear_required: "Please enter year",
    experience_tomonth_required: "Please enter month",
    experience_toyear_required: "Please enter year",
    experience_years: "years",
    personalinfo_name_required: "Please enter full name",
    personalinfo_fathername_required: "Please enter father name",
    personalinfo_spousename_required: "Please enter spouse name",
    personalinfo_aadharno_required: "Please enter Aadhar No.",
    aadhar_exists: "Aadhar No. already exists",
    personalinfo_aadharno_max: "Aadhar No. should be 12 digits",
    personalinfo_lang_required: "Please enter language",
    personalinfo_ageerror_required: "Age should be atleast 18 years",
    personalinfo_resume_required: "File format should be doc,docx or Pdf only",
    personalinfo_gender_required: 'please select gender',
    personalinfo_Dob_required: 'please select Date of Birth',
    personalinfo_mar_required: 'please select marital status',
    preferences_min_required: "Please enter minsalary",
    preferences_max_required: "Please enter maxsalary",
    preferences_days_required: 'please enter days',
    preferences_emptype_required: 'please select employment type',
    job_function_required: 'please select the job category',
    preference_jobfunction_required: 'please select the job category',
    preference_jobrole_required: 'please select the job title',
    preference_joblocation_required: 'please select the job location',
    skills_required: 'please select skills',
    skill_catagory_required: 'Please select job category',
    skill_sub_catagory_required: 'Please select job title',
    sub: "Subject",
    user_not_found: 'Your account is not Active',
    abused_content: 'Account is abused',
    invalid_credentials: 'User Name or Password is incorrect',
    filter_skills: 'Skills',
    filter_location: 'Job Location',
    filter_jobfunction: 'Job Category',
    filter_jobtype: 'Job Type',
    filter_education: 'Education',
    filter_experience: 'Experience',
    filter_industry: 'Industry',
    filter_salaryrange: 'Salary Range Per Month',
    filter_employertype: 'Employer Type',
    filter_companytype: 'Company Type',
    filter_jobgroup: 'Job Group',
    login_success: "Login Successfully",
    pwd_changed_success: "Password Changed Successfully",
    document_download: "Document is Downloading",
    update_total_exp: "Please enter total years of experience",
    updated_success: "Updated Successfully",
    created_success: "Created Successfully",
    record_not_found: "Record Not Found",
    deleted_success: "Deleted Successfully",
    deleted_error: "Can't delete",
    deactivate_success: "Deactivated Successfully",
    active_success: "Activated Successfully",
    education_afterschool_year_overlap: "Please enter a valid year",
    saved_success: "Saved Successfully",
    abuse_error: "Already Abused",
    bellow_sslc_error: "Please check your schooling data",
    already_exist: "Already Exist",
    user_exist: "User Name already exist",
    user_not_exist: "Mobile No. or user name doesn't exist",
    mobile_no_exist: "Mobile No. already Exist",
    mobile_not_reg: "Mobile No. is not registered",
    invalid_credentials: "User Name or Password incorrect",
    register_success: "Registered Successfully",
    contactus_msg_error: "Please enter the Message",
    contactus_subject_error: "Please select the Subject",
    applied_success: "Job Applied Successfully",
    employer_Subscribed_Successfully: 'Subscribed Successfully',
    employer_email_changed: 'E Mail Changed Successfully',
    employer_email_exists: 'E Mail is Already exists',
    employer_otp_message_expired: 'Expired OTP',
    employer_seconds_ago: ' seconds ago',
    employer_minutes_ago: ' minutes ago',
    employer_hours_ago: ' hours ago',
    employer_days_ago: ' days ago',
    employer_months_ago: ' months ago',
    employer_years_ago: ' years ago',
    employer_update_available: "Update Available",
    employer_update_msg: "There is an Update Available. Please Click Update Now.",
    employer_force_update: "Force Update Now",
    employer_any_qualification: "Any Qualification",
    employer_any_specl: "Any Specialization",
    employer_any_Degree: " Any Education Category",
    employer_since_experience: "Since",
    employer_selectlangheadertag: "Preferred Language        ",
    preferedLocation: "Job Preferred Location",
    employer_date: "Date",
    Unlimited: 'Unlimited',
    employer_unlimited: '* UL-Unlimited',
    employer_unlimited_short: 'UL',
    employer_company_profile: "Company Profile",
    employer_no_records: "No records found",
    employer_no_notification: "There are no new notifications found",
    employer_job_accept: "Job Application Accepted",
    employer_job_accept_already: "Job application already accepted",
    employer_shortlist_limit_exceed: "Finalist limit is exceed",
    employer_job_reject_already: "Job application already rejected",
    employer_job_reject: "Job Application rejected",
    employer_no_jobpost: "No job post found",
    employer_no_active_subscription: "No active subscriptions found",
    employer_document_download: "Document is Downloading",
    employer_language_required: "Please select languages",
    employer_Contact_state_errormsg: "Please select state",
    employer_selectedlanguage_heading: "Please Select Your Language ",
    employer_langselectnext: "Next",
    employer_sliderfirsttitle: "Welcome to JOBANYA",
    employer_sliderfirstdesc: "Looking for career insipiration? Here's where you can find out about hundreds of  different jobs, from academic librarian to youth worker.",
    employer_slidersecondtitle: "",
    employer_sliderseconddesc: "Browse our job descriptions to find out about typical responsibilities and employers for each role, so you can  see what's involved and who might hire you.",
    employer_sliderthirdtitle: "",
    employer_sliderthirddesc: "Some roles are open to applicants from any degree background, while others require specific degrees of postgraduate qualifications.",
    employer_sliderfourtitle: "",
    employer_sliderfourdesc: "Filter by career sector to explore clusters of related job descriptions and hone your ideas about the kind of role that would suit you.",
    employer_getstarted: "Get Started",
    employer_pass_validate2: "Password should be atleast 6 characters",
    employer_pass_validate1: "Please enter password",
    employer_pass_validate5: "Please enter password",
    employer_pass_validate1_new: "Please enter password",
    employer_pass_validate5_new: "Please enter password",
    employer_contact_company_address_errormsg: "Please enter address",
    employer_pass_validate7: "Incorrect Password",
    employer_pass_validate2_new: "Password should be atleast 6 characters",
    employer_pwd_changed_success: "Password changed successfully",
    employer_loginhead: 'Employer',
    employer_textValue: "Get OTP",
    employer_textValue1: "Resend OTP",
    employer_loginsubhead: "Sign in to continue",
    employer_mobileplaceholder: 'Mobile No./User Name',
    employer_passwordplaceholder: 'Password',
    employer_forgetpassword: 'Forgot Password?',
    employer_signinbutton: 'Sign In',
    employer_signupbutton: 'New Employer? Sign Up',
    employer_emailid: "Email ID",
    employer_website_required: "Please enter website",
    employer_phone_num_check: "Please enter valid mobile number",
    employer_valid_salary: "Minimum salary should be minimum 4 digits",
    employer_email_required: "Please enter email",
    employer_forgotsubmit: 'Submit',
    employer_otptext: 'OTP',
    employer_err_preferences_min_max_values: "Please enter valid salary",
    employer_minsal_required: "Please enter minimum salary",
    employer_maxsal_required: "Please enter maximum salary",
    employer_minage_required: "Please enter minimum age",
    employer_maxage_required: "Please enter maximum age",
    employer_getotptext: 'Get OTP',
    employer_emailid_format: "Please enter a valid email id",
    employer_reg_email_error: "Please enter registered email",
    employer_mail_exists: "The email is already exists",
    employer_prefer_ok: "OK",
    employer_home_header_title: 'Shiva Software Solutions',
    employer_home_header_title1: 'Hi Suriya',
    employer_home_tab_post: 'My Job Posts',
    employer_home_tab_recommended: 'Recommended',
    employer_home_tab_applied: 'Applied',
    employer_home_tab_invited: 'Invited',
    employer_home_tab_wishlist: 'Shortlisted',
    employer_home_tab_Shortlisted: "Finalist",
    employer_home_tab_Description: "Description",
    employer_home_jobtype: 'Job Type',
    employer_home_industry: 'Industry',
    employer_home_jobfunction: 'Job Category',
    employer_home_govjobs: 'Government Jobs',
    employer_looking_more: "Are you looking for more number of job posts and profiles?",
    employer_sidebar_home: "Home",
    employer_sidebar_profile: "Company Profile",
    employer_sidebar_subscription: "Subscriptions",
    employer_sidebar_news: "News",
    employer_sidebar_events: "Events",
    employer_sidebar_settings: "Settings",
    employer_sidebar_contactus: "Contact Us",
    employer_sidebar_share: "Share",
    join_us: 'Join Us on Social Media',
    rate_us: 'Rate & Review Us',
    employer_sidebar_rating: "Ratings & Reviews",
    employer_sidebar_logout: "Logout",
    employer_resume_heading: "Resume Builder",
    employer_resume_personalinfo: "Personal Info",
    employer_resume_contactinfo: "Contact Info",
    employer_resume_education: "Education",
    education_category: "Education Category",
    employer_resume_skills: "Skills",
    employer_resume_exp: "Experience",
    employer_resume_ref: "Reference (Recommended By)",
    employer_resume_preference: "Notification Preferences",
    employer_personal_header: "Personal Info",
    employer_personal_fullname: "Full Name",
    employer_personal_fathername: "Father Name",
    employer_personal_spousename: "Spouse Name",
    employer_personal_dob: "Date of Birth",
    employer_personal_gender: "Gender",
    employer_personal_marital: "Marital Status",
    employer_personal_aadhar: "Udyam/Udyog Aadhar No.",
    employer_personal_lg: "Languages Known",
    employer_personal_medical: "Any known medical history",
    employer_personal_upload: "Upload Resume",
    employer_personal_save: "Save",
    employer_personal_diff: "Differently Abled",
    employer_personal_All: 'All',
    employer_personal_yes: "Yes",
    employer_personal_no: "No",
    employer_personal_include: "Include",
    employer_personal_only: "Only Differently Abled",
    employer_personal_add: "Add",
    employer_personal_format: "(doc,docx and PDF format only)",
    employer_contact_header: "Contact Info",
    employer_contact_door: "Door No. / Street",
    employer_contact_area: "Area",
    employer_contact_city: "Town / City Name ",
    employer_contact_district: "District",
    employer_contact_state: "State",
    employer_contact_pincode: "Pincode",
    employer_contact_emailid: "Email ID",
    employer_contact_mbleno: "Mobile No.",
    employer_contact_altmbleno: "Alternative Mobile No.",
    employer_contact_altmbleno_contact: "Alternative contact No.",
    employer_contact_save: "Save",
    employer_address: "Venue",
    employer_edu_header: "Education",
    employer_edu_schooling: "Schooling",
    employer_edu_qualification: "Qualification",
    employer_edu_insti: "Institution",
    employer_edu_loc: "Location",
    employer_edu_mark: "Percentage of mark",
    employer_edu_yrofpassing: "Year of Passing",
    employer_edu_save: "Save",
    employer_exit_cancel: "Cancel",
    employer_any_show: "Any",
    employer_ref_popup_header: "Relationship",
    employer_ref: "Reference",
    employer_ref_name: "Name",
    employer_ref_desig: "Designation",
    employer_branch_email: "Email ID",
    employer_branchname_required: "Please enter branch name",
    employer_person_name_required: "Please enter contact person name",
    employer_designation_required: "Please enter designation",
    employer_pincode_required: "Please enter pincode",
    employer_pincode_valid: "Please enter valid pincode",
    employer_companyaddress_required: "Please enter company address",
    employer_companyname_required: "Please enter company name",
    employer_mobnum_required: "Please enter mobile number",
    employer_mobnum_valid: "Please enter valid mobile number",
    employer_telenum_required: "Please enter telephone number",
    employer_telenum_valid: "Please enter valid telephone number",
    employer_ref_org: "Organization",
    employer_ref_relationship: "Relationship to candidate",
    employer_ref_remarks: "Remarks",
    // employer_ref_postjob:"Post Job",
    employer_ref_saveasdraft: "Save as Draft",
    employer_ref_save: "Save",
    employer_ref_del: "Delete",
    employer_ref_update: "Update",
    employer_later: 'Later',
    employer_per_view_header: "Profile",
    employer_per_view_marital: "Marital Status",
    employer_per_view_father_name: "Father Name",
    employer_per_view_spouse_name: "Spouse Name",
    employer_per_view_dob: "Date of Birth",
    employer_per_view_aadhar: "Udyam/Udyog Aadhar No.",
    employer_per_view_lang: "Languages Known",
    employer_per_view_medical: "Any known medical history",
    employer_per_view_differentlyabled: "Differently Abled",
    employer_per_contact: "Contact Info",
    employer_per_edu: "Education",
    employer_per_school: "Schooling",
    employer_per_after_school: "After Schooling",
    employer_per_skills: "Job Category, Job Title & Skills",
    employer_per_ref: "Reference (Recommended By)",
    employer_per_pre: "My Job Preferences",
    employer_pr_view_header: "Profile",
    employer_Pr_company: "Company Type",
    employer_pr_emp_type: "Employer Type",
    employer_pr_industry: "Industry / Sector",
    employer_pr_activity: "Activity Type",
    employer_pr_branch: "Head Office & Branch Details",
    employer_pr_abt_company: "About Company",
    employer_pr_pre: "My Employee Preferences",
    employer_pr_contact: "Contact Info",
    Employer_referredby: "Referred by",
    employer_pr_gn: "Government Identification",
    employer_pr_gstin1: "GSTIN",
    employer_pr_gstin: "GSTIN (include all pages)",
    employer_pr_pan: "PAN",
    employer_pr_aadhar: "Udyam/Udyog Aadhar No.",
    employer_pr_aadhar_no: "Aadhar No.",
    documentvalidate: 'Please Provide Three Valid Registration Numbers Without Attachments (or) Two Valid Registration Numbers with Attachments',
    employer_pr_facilities: "Facilities Offered",
    employer_pr_job_func: "Job Category",
    employer_pr_job_role: "Job Title",
    employer_pr_Loc: "Location",
    employer_pr_turnover: "Turn Over",
    employer_pr_no_emp: "No. of Employees",
    employer_exp_totalexp: "Total Experience",
    employer_exp_years: "Years",
    employer_rl_fresher: "Fresher",
    employer_exp_heading: "Experience",
    employer_exp_org: "Organization",
    employer_exp_desig: "Designation",
    employer_exp_location: "Location",
    employer_exp_from: "From",
    employer_exp_to: "To",
    employer_exp_month: "Month",
    employer_exp_year: "Year",
    employer_exp_currently: "Currently Working Here",
    employer_exp_save: "Save",
    employer_pre_emptype: "Employment Type",
    employer_pre_timeforjg: "Time for Joining",
    employer_pre_immediate: "Immediate",
    employer_pre_within: "Within",
    employer_pre_days: "Days",
    employer_pre_expected: "Expected Salary Per Month",
    employer_pre_permnth: "Per Month",
    employer_pre_header_popup_type: "Employee Type",
    employer_pre_header_popup_sal: "Salary",
    employer_pre_to: "to",
    employer_pre_jobrole: "Job Title",
    employer_pre_fun: "Job Category",
    employer_skills_heading: "Skills",
    employer_skills_jobfunction: "Job Category",
    employer_skills_Role: "Job Title",
    employer_mobileNo_change_header: "Change Email ID",
    employer_mobileNo_change_number: "Mobile No.",
    employer_email_change_current: "Current Email ID",
    employer_email_change_email: "Email ID",
    employer_mobileNo_change_otp: "OTP",
    employer_mobileNo_change_change_mobile_number_button: "Change Email ID",
    employer_noti_head: "Notification",
    employer_noti_jobs: "Job Related",
    employer_noti_news: "News",
    employer_noti_events: "Events",
    employer_events_head: "Events",
    employer_job_description: "Description",
    employer_news_head: "News",
    employer_link: "Link",
    employer_filterby: "Filter By",
    employer_govt_jobs: "Government Jobs",
    employer_noti_button: "Dismiss All",
    employer_noti_swipe: "(Swipe left to dismiss)",
    employer_password_change_header: "Change Password",
    employer_custom_plan: 'Custom plan',
    employer_password_change_old_password: "Current Password",
    employer_password_change_new_password: "New Password",
    employer_password_change_change_password_button: "Change Password",
    employer_registration_user: "Name of the User",
    employer_registration_mobileno: "Mobile No",
    employer_registration_password: "Password",
    employer_registration_otp: "OTP",
    employer_registration_otpbutton: "Get OTP",
    employer_registration_checkbox: "I agree to the JOBANYA",
    employer_registration_submitbutton: "Sign Up",
    employer_forgetpassword_mobileno: "Mobile No",
    employer_forgetpassword_otp: "OTP",
    employer_forgetpassword_password: "Password",
    employer_forgetpassword_submit: "Submit",
    employer_Setting_heading: "Settings",
    employer_Setting_changepassword: "Change Password",
    employer_Setting_email: "Change Email ID",
    employer_Setting_Notification: "Notification",
    employer_Setting_Preferedlanguage: "Preferred Language",
    employer_Setting_invisiblemode: "Invisible Mode",
    employer_Notification_invisiblemode: "Invisible Mode",
    employer_Notification_msg1: "Do you want to change your Account to Invisible Mode?",
    employer_Notification_msg2: "Employees will not be able to view your Account.",
    employer_Notification_cancel: "Cancel",
    employer_Notification_submit: "Submit",
    employer_Notification_heading: "Notification",
    employer_Notification_jobs: "Job Related",
    employer_Notification_news: "News",
    employer_Notification_events: "Events",
    employer_reg_faci: "Facilities Offered",
    employer_Contactus_heading: "Contact Us",
    employer_Contactus_website: "Website",
    employer_Contactus_emailid: "Email Id",
    employer_Contactus_mobileno: "Mobile No.",
    employer_Contactus_subject: "Subject",
    employer_Contactus__description: "Description",
    employer_Contactus_button: "Submit",
    employer_logout_heading: "Logout",
    employer_logout_msg: "Are you sure, you want to Logout ?",
    employer_logout_cancel: "Cancel",
    employer_logout_logout: "Logout",
    employer_Search_heading: "General Profile Search",
    employer_Recent_Search: "Recent Search",
    employer_Placeholder: "Search by Location, Job Category, Job Title",
    employer_Placeholder1: "Search by Location",
    employer_gn_desc_job_desc: "Job Description",
    employer_gn_desc_link: "Link",
    employer_gn_desc_downloads: "Downloads",
    employer_gn_no_vacanies: "No. of Vacancies",
    employer_gn_Expiry: "Last date",
    employer_rl_freshers: "Freshers",
    employer_rl_sortby: "Sort By",
    employer_rl_filterby: "Filter By",
    employer_rl_applyfilter: "APPLY FILTERS ",
    employer_rl_jobtype: "Job Type",
    employer_rl_experience: "Experience",
    employer_rl_qualification: "Education",
    employer_rl_skills: "Skills",
    employer_rl_description: "Job Description",
    employer_description: "To Avoid Job Post Rejection do not include contact information in Job Description",
    employer_rl_grp: "Job Group",
    employer_rl_maritial: "Martial Status",
    employer_rl_gender: "Gender",
    employer_rl_age: "Age Criteria",
    employer_rl_differently: "Differently Abled",
    employer_sal_range: "Salary Range Per Month",
    employer_rl_any: "Any",
    employer_rl_no: "No",
    employer_r1_to: "to",
    employer_r1_years: "Years",
    employer_rl_preferred: "Preferred",
    employer_rl_jobtaluk: "Job Taluk",
    job_rl_jobtaluk: "Candidate Job Preference Location - Taluk",
    employer_candidate_location: "Candidate Location",
    job_candidate_location: "Candidate Home Location - District",
    employer_candidate_taluk: "Candidate taluk",
    job_candidate_taluk: "Candidate Home Location - Taluk",
    employer_rl_lastdate: "Last Date",
    employer_rl_no_of_opening: "No. of Opening",
    employer_rl_work: "Work Timing",
    employer_rl_lang: "Languages",
    employer_rl_aboutcompany: "About Company",
    employer_rl_turnover: "Turn Over",
    employer_rl_fac: "Facilities Offered",
    employer_rl_activity: "Activity Type",
    employer_rl_employee: "No. of Employee",
    employer_rl_contact: "Contact",
    employer_rl_address: "Address",
    employer_rl_do_invitation: "Do You want to accept this Applicant Application?",
    employer_rl_decline: "Decline",
    employer_rl_accept: "Accept",
    employer_r1_group: "Job Group",
    employer_rl_Age: "Age Criteria",
    employer_rl_school: "School",
    employer_jp_repost: "Repost",
    employer_jp_clone: "Clone",
    employer_editable: "(Editable)",
    employer_noneditable: "(Non-Editable)",
    employer_jp_on: "On",
    employer_jp_off: "Off",
    employer_jp_filter: "Filter By Job Title",
    employer_search_results: "Suggested Results",
    employer_log_heading: "Employer Registration",
    employer_sliderfirsttitle1: "Company Basic Info",
    employer_slidersecondtitle2: "HR Contact Info",
    employer_sliderthirdtitle3: "Government Identification",
    employer_log_regcomname: "Registered Company Name",
    employer_log_companytype: "Company Type",
    employer_log_industry: "Industry/Sector",
    employer_log_activity: "Activity Type",
    employer_log_employertype: "Employer Type",
    employer_log_regmail: "Registered Email ID",
    employer_log_password: "Password",
    employer_log_state: "State",
    employer_log_district: "District",
    employer_log_pincode: "Pincode",
    employer_log_regaddress: "Street/ Road/ Area",
    Company_Address_Placeholder: 'Door Number, Street Name, Locality',
    employer_log_mobile: "Mobile No.",
    employer_log_telephone: "Office Number",
    employer_log_website: "Website",
    employer_log_check: "Contact address is same as the company registered address",
    employer_log_contactperson: "Contact Person",
    employer_log_designation: "Designation",
    employer_log_city: "Town / City Name ",
    employer_log_taluk: "Taluk",
    employer_log_address: "Company Address",
    employer_log_mobileno: "Mobile No.",
    employer_log_question: "How do you know about JOBANYA ?",
    employer_log_gstin: "Company GSTIN",
    employer_log_pan: "Company PAN",
    employer_log_pan_no: "PAN",
    employer_log_aadhar: "Udyam/Udyog Aadhaar Certificate",
    employer_log_aadhar_no: "Aadhaar No.",
    employer_company_address: 'Registered Company Address',
    employer_log_faci: "Facilities Offered",
    employer_contactinfo_header: "Edit Contact Info",
    employer_contactinfo_person: "Contact Person",
    employer_contactinfo_designation: "Designation",
    employer_contactinfo_address: "Company Address",
    employer_contactinfo_city: "Town / City Name ",
    employer_contactinfo_pincode: "Pincode",
    employer_contactinfo_mobile: "Mobile No.",
    employer_contactinfo_telephone: "Telephone",
    employer_contactinfo_email: "Email ID",
    employer_contactinfo_Website: "Website",
    employer_contactinfo_map: "Map Location",
    employer_contact_msg: "Message",
    employer_job_head: "Report Abuse",
    employer_at: "at",
    employer_job_msg: "Are you sure you want to report this Employee?",
    employer_job_cancel: "Cancel",
    employer_job_report: "Report",
    employer_edu_aft_schl_specialization: "Specialization",
    employer_logo: " Company Logo",
    employer_preference_heading: "My Employee Preferences",
    employer_preference_job: "Job Category",
    employer_preference_Role: "Job Title",
    employer_preference_type: "Job Type",
    employer_preference_time: "Time for Joining",
    employer_preference_to: "to",
    employer_preference_salary: "Expected Salary Per Month",
    employer_preference_button: "Next",
    employer_contact_us_post: "No. of Posts",
    employer_contact_us_head: "Contact Us",
    employer_contact_us_profile: "No. of profiles",
    employer_contact_us_vacancies: "No. of Vacancies",
    employer_contact_us_desc: "Description",
    employer_contact_us_submit: "Submit",
    employer_subscription_heading: "Subscriptions",
    employer_subscription_active: "Active",
    employer_subscription_history: "History",
    employer_subscription_subscribe: "Subscribe",
    employer_buy: "Buy Now",
    employer_sub_post: "No. of job posts",
    employer_sub_vacancy: 'No. of job Vacancies/Post',
    employer_sub_viewed: "No. of Profile who can  Viewed / Post",
    employer_sub_applied: "No. of Profiles who can Apply / Post",
    employer_sub_invited: "No. of Profiles who can be Invited / Post",
    employer_sub_shortlist: "No. of Profiles who can be Finalised / Post",
    employer_sub_total: "Total",
    employer_sub_posted: "Posted",
    employer_sub_available: "Available",
    employer_sub_active: "Active",
    employer_sub_max: "Maximum Validity per Job Post,From Date of Posting",
    employer_sub_exp: "Expires on",
    employer_sub_package_validity: "Package validity",
    employer_sub_choose_subscription: "Choose Your Subscription",
    employer_forgot_email: "Email ID",
    employer_abou_com_com: "About Company",
    employer_abou_com_turn: "Turn Over",
    employer_abou_com_No: "No. of Employees",
    employer_branch_head: "Branch Details",
    employer_branch_branch: "Branch Name",
    employer_branch_contact: "Contact Person",
    employer_branch_state: "State",
    employer_branch_district: "District",
    employer_branchtype: "Branch Type",
    employer_branch_taluk: "Taluk",
    employer_branch_city: "Town / City Name",
    employer_branch_pincode: "Pincode",
    employer_branch_address: "Company Address",
    employer_profile_edit: "Update profile details",
    employer_branch_mobile: "Mobile No.",
    employer_branch_telephone: "Telephone No.",
    employer_branch_head1: "Edit Branch Details",
    employer_branch_head2: "Add Branch Details",
    employer_abou_com_attach: "Attachment",
    employer_comapny_photos: "Company Photos",
    employer_abou_com_gallery: "Maximum of 3 photos allowed",
    employer_post_job_header: "Post the Job",
    employer_post_job_header1: "Post a Job",
    employer_post_job_details: "Job Details",
    employer_post_job_company: "Branch name",
    employer_post_job_hours: "Hours",
    employer_post_job_minutes: "Minutes",
    employer_Post_job_function: "Job Category",
    employer_post_job_Role: "Job Title",
    employer_post_job_skills: "Skills",
    employer_post_job_desc: "Description",
    employer_post_job_exp: "Experience",
    employer_post_job_quali: "Qualification",
    employer_post_job_jobtype: "Job Type",
    employer_post_job_noofopen: "No. of Openings ",
    shift_name: 'Shift Name',
    shift_txt: 'Shift',
    start_txt: 'Start',
    end_txt: 'End',
    shift_name1: 'Shift',
    start_txt1: 'Start Time',
    end_txt1: 'End Time',
    shift_error: 'Please select shift name',
    starthours_error: 'Please select start hours',
    startminutes_error: 'Please select start minutes',
    endhours_error: 'Please select end hours',
    endminutes_error: 'Please select end minutes',
    fromtimetext_error: 'Please select the start time',
    totimetext_error: 'please select the end time',
    sametime_error: 'Please select the valid time',
    shiftttime_exits: 'Time Already Exist',
    shiftname_exits: 'Shift Name Already Exist',
    employer_post_job_work: "Work Timing",
    add_post_job_work: "Add Work Timing",
    plaseadd_post_job_work: "Please add work timing",
    employer_post_job_factlities: "Facilities Offered",
    employer_post_job_validate: "Validity Date",
    employer_post_job_preferences: "My Employee Preferences",
    employer_job_details: "Job Details",
    employer_post_job_jobgroup: "Job Group",
    employer_post_job_martial: "Marital Status",
    employer_post_job_gender: "Gender",
    employer_post_job_age: "Age Criteria",
    employer_post_job_da: "Differently Abled",
    employer_post_job_salary: "Salary Range Per Month",
    employer_post_job_prefered: "Candidate Home Location",
    employer_post_job_prefered1: "Candidate Job Preference Location",
    employer_post_job_hr: "HR Contact Details",
    employer_post_job_email: "Email ID",
    employer_post_job_mn: "Mobile No.",
    employer_post_job_address: "Address",
    employer_post_job_specific: "Specific",
    employer_post_job_within: "Any",
    employer_post_job_contactperson: "Contact Person ",
    employer_post_job_desg: "Designation",
    employer_check_log: "Contact Info is same as Registered \n Office",
    employer_contactus_subject_error: "Please select the Subject",
    employer_contactus_post_error: "Please enter no of post",
    employer_contactus_profile_error: "Please enter no of profiles",
    employer_contactus_vacancies_error: "Please enter no. of vacancies",
    employer_contactus_desc_error: "Please enter the descriptions",
    employer_username_required: "Please enter Email ID",
    employer_password_required: "Please enter password",
    employer_limit_cross_head_jobfunction: "Alert",
    employer_limit_cross_head_joblocation: "Alert",
    employer_limit_cross_head_jobfunction_error: "You can choose maximum of",
    employer_limit_cross_head_joblocation_error: "You can choose maximum of",
    employer_abused_content: 'Your account has been blocked' + '\n' + 'please contact JOBANYA admin',
    employer_login_invisible: "Your Account is in Invisible Mode.",
    employer_login_invisible_active: "Do you want to Reactivate Now?",
    employer_login_yes: "Yes",
    employer_login_no: "No",
    employer_accout_reactivate: "Account Reactivation",
    employer_account_otp: "OTP is sent to your Registered E-Mail",
    employer_resend_otp_verify: "Verify",
    employer_otp_sent: "OTP sent",
    employer_Invalid_Mail: 'Invalid E Mail',
    employer_otp_message: "Invalid OTP",
    employer_otp_verified: 'Verified Success',
    employer_contactus_msg_error: "Please enter the Message",
    employer_saved_success: "Saved Successfully",
    thank_contactus: 'Thank you for Contacting Us',
    employer_deleted_success: "Deleted Successfully",
    employer_deleted_error: "Must have atleast one branch",
    employer_updated_success: "Updated Successfully",
    employer_already_aproved: "Job post already approved",
    employer_repost_success: "Reposted Successfully",
    employer_clone_success: "Cloned Successfully",
    employer_abouse_success: "Reported as abuse successfully",
    employer_login_success: "Log in Successfully",
    employer_abouse_error: "Already Abused",
    employer_invite_success: "Invited Successfully",
    employer_invite_error: "Already Invited",
    employer_alrdy_applied: "Already Applied",
    employer_invite_count_exceeds: "Invited limit is exceed",
    employer_already_reject_job: "Employee Rejected this job",
    employer_you_reject_this_job: "You Reject this Job",
    employer_repost: "Repost",
    employer_err_job_function_required: 'please select the job category',
    employer_err_preference_joblocation_required: 'please select  job location',
    employer_err_preference_employementtype_empty: "",
    employer_err_preference_jobrole_required: 'please select job title',
    employer_id_psw_incorrect: "Please enter valid email ID and password",
    employer_waiting_approval: "Your registration is waiting for approval.\nFor any clarification please contact JOBANYA.",
    employer_verification_pending: "Email id verification is in pending.\nPlease check your mail box.",
    employer_deactivated_acc: "Your Account is Deactivated",
    employer_employer_activated: "Activated Successfully",
    employer_password_old_check: "Old password is incorrect",
    employer_psw_changed: 'Password Changed Successfully',
    employer_post_edit_job_header: "Edit Job Post",
    employer_branch_required: "Please select branch name",
    employer_job_function_required: "Please select job category",
    employer_hours_required: "Please select Hours",
    employer_minutes_required: "Please select Minutes",
    employer_job_role_required: "Please select job title",
    employer_skills_error_required: "Please select skills",
    employer_skills_empty: '',
    employer_description_required: "Please enter job description",
    employer_education_required: "Please select education category",
    employer_qualification_required: "Please select qualification",
    employer_specialization_required: "Please select specialization",
    employer_education_empty: "",
    employer_experience_required: "Please enter from experience",
    employer_experiencefrom_valid: "Please enter valid from experience",
    employer_experienceTo_required: "Please enter to experience",
    employer_experienceTo_valid: "Please enter valid to experience",
    employer_experience_fresher_sepcific_required: "Please choose experience",
    employer_job_type_required: "Please select job type",
    select_job_type: "Select job type",
    employer_noofopening_required: "Please enter no. of opening",
    employer_worktime_required: "Please enter working time",
    employer_facility_error_required: "Please select facility",
    employer_contact_person_required: "Please enter contact person name",
    employer_marital_required: "Please choose marital status",
    employer_age_required: "Please choose age criteria",
    employer_gender_required: "Please choose gender",
    employer_contact_designation_required: "Please enter designation",
    employer_state_required: "Please choose state",
    employer_district_required: "Please choose district",
    employer_taluk_required: "Please choose taluk",
    employer_contact_email_required: "Please enter email",
    employer_contact_primary_mob_required: "Please enter mobile number",
    employer_contact_secondary_mob_required: "Please enter another mobile number",
    employer_contact_address_required: "Please enter contact address",
    employer_company_required: "Please enter description about company",
    employer_turn_over_required: "Please select turnover",
    employer_noof_employee_required: "Please enter number of employees",
    employer_industry_required: "Please select industry",
    employer_employertype_required: "Please select employertype",
    employer_companytypename_required: "Please select companytypename",
    employer_city_required: "Please select Town / City",
    employer_taluk_required: "Please select taluk",
    employer_applied_on: "Applied on ",
    employer_invited_on: "Invited on ",
    employer_accepted_on: "Accepted on ",
    employer_rejected_on: "Rejected on ",
    employer_company_name_errormsg: "Please enter company name",
    employer_company_name_errormsg_validate: "Company name should be less than 50 Characters",
    employer_company_type_errormsg: "Please select company type",
    employer_activity_type_errormsg: "Please select activity type",
    employer_industry_type_errormsg: "Please select industry type",
    employer_type_errormsg: "Please select employer type",
    employer_state_errormsg: "Please select state",
    employer_district_errormsg: "Please select district",
    employer_city_errormsg: "please select Town / City",
    employer_taluk_errormsg: "please select taluk",
    employer_registered_email_errormsg: "Please enter registered email",
    employer_valid_email_errormsg: "Please enter valid Email ID",
    employer_alreadyregistered_email_errormsg: "Email id is already registered",
    employer_passsword_errormsg: "Please enter password",
    employer_passsword_length_errormsg: "Please enter atleast 6 characters password",
    employer_pincode_errormsg: "Please enter pincode",
    employer_pincode_valid_errormsg: "Please enter valid pincode",
    employer_company_address_errormsg: "Please enter company address",
    employer_mobile_number_errormsg: "Please enter mobile number",
    employer_mobileno_exists_errormsg: "Mobile no. already exists",
    employer_mobile_numbervalid_errormsg: "Please enter valid mobile number",
    employer_telephone_number_errormsg: "Please enter telephone number",
    employer_telephone_number_Valid_errormsg: "Please enter valid office number",
    employer_website_address_errormsg: "Please enter website",
    employer_website_address_errormsg_valid: "Please enter valid website",
    employer_contact_person_errormsg: "Please enter contact person name",
    employer_designation_errormsg: "Please enter designation",
    employer_knowntype_errormsg: "Please select how do you know about JOBANYA",
    employer_log_others: "Please provide info",
    employer_gstin_errormsg: "Please enter GSTIN No.",
    employer_gstin_valid_errormsg: "Please enter valid GSTIN No.",
    employer_alreadygstin_errormsg: "GSTIN already exists",
    employer_alreadypan_errormsg: "PAN No. Already exists",
    employer_alreadyaadhar_errormsg: "Aadhar No. Already exists",
    employer_pan_errormsg: "Please enter PAN No.",
    employer_pan_valid_errormsg: "Please enter valid PAN No.",
    employer_aadhar_errormsg: "Please enter Udyam/Udyog Aadhar No.",
    employer_aadhar_prof_errormsg: "Please enter Aadhar No.",
    employer_aadhar_valid_errormsg: "Please enter valid Udyam/Udyog Aadhar No.",
    employer_aadhar_prof_valid_errormsg: "Please enter valid Aadhar No.",
    employer_OTP_valid_errormsg: "Please enter valid OTP.",
    employer_facilities_error: "Please choose facilities",
    employer_already_exist: "Already Exists",
    employer_register_success: "Registered Successfully",
    employer_register_name: "Registered Name",
    employer_filter_skills: 'Skills',
    employer_filter_location: 'Employee Location',
    employer_filter_taluk: 'Employee Taluk',
    employer_filter_jobfunction: 'Job Category',
    employer_filter_jobtype: 'Job Type',
    employer_filter_education: 'Education',
    employer_filter_experience: 'Experience',
    employer_filter_industry: 'Industry',
    employer_filter_salaryrange: 'Salary Range Per Month',
    employer_filter_employertype: 'Employer Type',
    employer_filter_companytype: 'Company Type',
    employer_filter_jobgroup: 'Job Group',
    employer_filter_jobrole: 'Job Title',
    user_not_found: 'Your account is not Active',
    netinfo_heading: "Information",
    netinfo_subheading_reachable: "Please Check Your Network Connection",
    netinfo_subheading: "Please Check Your Network Connection",
    netinfo_subheading2: "Something Went Wrong",
    netinfo_subheading_server: "Server not reachable Please try again",
    matching: "Matching",
    accountant: "Eg. Accountant, Driver, Telecaller",
    searchJobRole: "Search Result",
    SalaryExpected: 'Expected Salary Range',
    Close: 'Close',
    jobRole: 'Job Title',
    ProfileCompletion: 'Profile Completion',
    ClickhereMoreSearch: 'Click here for more search',
    YouCanselectSkills: 'You can select upto 6 Skills',
    ChooseUrExp: 'Please choose your experience',
    chooseUrSkill: 'Please choose your skills',
    YouCanSelectJobRoles: 'You can select upto 4 Job Title',
    PleaseSelectAllField: 'Please Select all Field'
  },
  "ta":
  {
    file_upload_text: "சுயவிவரப் படத்தைப் பதிவேற்றவும்",
    min_salary: "குறைந்தபட்ச சம்பளம்",
    max_salary: "அதிகபட்ச சம்பளம்",
    welcome_text: "வரவேற்கிறோம்",
    welcome_text1: "தயவு செய்து உங்கள் சுயவிவரத்தை நிரப்பவும்",
    image_text: "சிறந்த பார்வைக்கு உங்கள் புகைப்படத்தைப் பதிவேற்றவும்",
    applied_jobs: "விண்ணப்பித்த வேலைகள்",
    all_jobs: 'அனைத்து வேலைகள்',
    reotp: "OTP ஐ மீண்டும் அனுப்பவும்",
    otp_re: "OTP ஐ மீண்டும் அனுப்பவும்",
    sent: "நாங்கள் OTP அனுப்பியுள்ளோம்",
    otp_chg: "தயவுசெய்து otp ஐ உள்ளிடவும்",
    local_first: "சிறந்த சம்பளத்துடன் உள்ளூர் வேலைகளைத் தேடுங்கள்!",
    vali_t: "சரியான 10 இலக்க மொபைல் எண்ணை உள்ளிடவும்",
    recommended_jobs_label: "எனது பொருத்தமான வேலைகள்",
    filter_by: "வடிகட்டி வகைகள்",
    filter_job_type: "வேலை வகை",
    filter_job_location: "வேலை இடம்",
    no_recommended_jobs: "பரிந்துரைக்கப்பட்ட வேலைகள் எதுவும் இல்லை",
    no_search_jobs: "இந்த வேலைத் தலைப்பில் வேலைகள் இல்லை",
    no_Filter_search_jobs: "வேலைகள் இல்லை",
    no_applied_jobs: "நீங்கள் இன்னும் விண்ணப்பிக்கவில்லை",
    candidate_job_type: "வேலை வகை",
    showing: "காண்பிக்கும்",
    job_of: "வேலைகள்",
    employee_experience: "உங்கள் அனுபவம் என்ன?",
    skills_known: "இவற்றில் எது உங்களுக்குத் தெரியும்?",
    ma_ypro: "உங்கள் சுயவிவரத்தை உருவாக்கவும்",
    re_pro: "உங்கள் சுயவிவரத்தை பதிவு செய்யவும்",
    cl_era: "தெளிவு",
    pre_me: "முந்தைய",
    chose_ji: "உங்கள் வேலையின் தலைப்பைத் தேர்ந்தெடுக்கவும்",
    add_job_title: "அனுபவத்தைச் சேர்க்கவும்",
    cur_ex: "நீங்கள் எதிர்பார்க்கும் சம்பளம்",
    wrk_pro: "பணி அனுபவம்",
    edu_trt: "கல்வி நிலை",
    load_deng: "அதிகமாக ஏற்று",
    txt_ski: "திறன்கள் தேவை",
    off_txt: "வழங்கப்படும் சம்பளம்",
    inform_txt_key: "வேலை தகவல்",
    expect_ey: "எதிர்பார்க்கப்படும் அதிகபட்ச சம்பளம்",
    expected_key: "எதிர்பார்க்கப்படும் குறைந்தபட்ச சம்பளம்",
    findlist_job: "வேலை பட்டியல்",
    findjobs_text: "வேலைகளைத் தேடுங்கள்",
    jobsummary_tag: "User Friendly & Trusted HR Manager",
    lang_read_text: "படிப்பது",
    lang_write_text: "எழுதுவது",
    lang_speak_text: "பேசுவது",
    persons_applied_text: " பேர் விண்ணப்பித்துள்ளனர்",
    recommended_jobs_text: "பரிந்துரைக்கப்படும் வேலைகள் என்பது உங்கள் சுயவிவரத்தின் அடிப்படையில் இருக்கும்",
    matching_jobs_text: "எனது பொருத்தமான வேலைகள் என்பது உங்கள் விருப்பங்களை அடிப்படையாகக் கொண்டவை",
    incomplete_text: "முழுமையடையவில்லை",
    rejection_remarks_error: "நிராகரிப்பதற்கான காரணத்தை உள்ளிடவும்",
    rejection_remarks_text: "நிராகரிப்புக்கான காரணம்",
    rejection_save_text: "சேமி",
    customercaremsg: "உதவிக்கு, எங்கள் வாடிக்கையாளர் சேவை நிர்வாகியை அழைக்கவும்",
    customercaremsg1: " (திங்கள் - சனி / காலை 10:00 - மாலை 06:00 மணி)",
    calls_made: "அழைப்புகள் செய்யப்பட்டன",
    calls_received: "அழைப்புகள் பெறப்பட்டன",
    employer_job_expired: "வேலை காலாவதியானது",
    apply_call: "இப்போது அழைக்க",
    apply_viewjob: "வேலையைப் பார்க்க",
    invite_call: "இப்போது நீங்கள் இந்த ஊழியரை அழைக்கலாம் @",
    download_resume_btntext: "தற்குறிப்பு பார்க்க",
    download_resume_icontext: "உங்கள் தற்குறிப்பு பதிவிறக்கவும்",
    emp_legend: "கட்டாயமாகும்",
    checkout_button_text: "தொடரவும்",
    assistent_call: "உதவிக்கான அழைப்பு ",
    search_similar: 'ஒத்த சுயவிவரங்களைத் தேடுங்கள்',
    atleaseone_proof: 'தயவுசெய்து இரண்டு ஆதாரத்தையும் பதிவேற்றவும்',
    atleaseone_proof1: 'கோப்பை பதிவேற்றவும்',
    split_Employee: "வேலை தேடுவோர்",
    split_Employer: 'வேலையளிப்போர்',
    employer_Portal: "வேலையளிப்போர் போர்டல்",
    male: 'ஆண்',
    female: 'பெண்',
    transgender: 'திருநங்கை',
    netinfo_heading: "தகவல்",
    limit_cross_head_jobfunction: "எச்சரிக்கை",
    limit_cross_head_joblocation: "எச்சரிக்கை",
    limit_cross_head_jobfunction_error: "நீங்கள் அதிகபட்சம் தேர்வு செய்யும் எண்ணிக்கை",
    limit_cross_head_joblocation_error: "நீங்கள் அதிகபட்சம் தேர்வு செய்யும் எண்ணிக்கை",
    search_results: "பரிந்துரைக்கப்பட்ட முடிவுகள்",
    netinfo_heading_server: "சேவையகத்தை அடைய முடியவில்லை",
    netinfo_subheading_server: "சேவையகத்தை அடைய முடியவில்லை. தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    fresher_status: "நான் ஒரு புதியவன்",
    netinfo_subheading: "உங்கள் இணைய இணைப்பை சரிபார்க்கவும்",
    netinfo_subheading_reachable: "உங்கள் இணைய இணைப்பை சரிபார்க்கவும்",
    schooling_no_record: "எந்த பதிவுகளும் கண்டறியப்படவில்லை",
    selectlangheadertag: "விருப்பமான மொழி        ",
    experience_error: "செல்லுபடியாகும் அனுபவத்தை உள்ளிடவும்",
    experience_error1: "செல்லுபடியாகும் அனுபவத்தை உள்ளிடவும்",
    add_schooling: "முதலில் பள்ளிப்படிப்பை பூர்த்தி செய்யவும்",
    selectedlanguage_heading: "உங்கள் மொழியைத் தேர்ந்தெடுக்கவும்",
    Select: 'தேர்ந்தெடு',
    Select_Skills: 'திறன்களை தேர்ந்தெடுக்கவும்',
    netinfo_subheading2: "ஏதோ தவறு நடந்துவிட்டது",
    no_records: "எந்த பதிவுகளும் கண்டறியப்படவில்லை",
    no_notification: "புதிய அறிவிப்புகள் எதுவும் கிடைக்கவில்லை",
    sslc_not_eligible: "உங்களால் எஸ்.எஸ்.எல்.சி. தகுதியை சேர்க்கவியலாது ",
    hsc_not_eligible: "உங்களால் எச்.எஸ்.சி. தகுதியை சேர்க்கவியலாது",
    exp_del_fresher_heading: "உறுதிப்படுத்தல்",
    at: "இல்",
    since_experience: "முதல்",
    exit_cancel: "ரத்து செய்க",
    personal_info_fill_first: "தொடர உங்கள் தனிப்பட்ட தகவலை பூர்த்திசெய்யவும்",
    not_eligible: "தகுதி இல்லை",
    apply_limit: "விண்ணப்ப வரம்பு எண்ணிக்கை மீறப்பட்டுள்ளது",
    alrdy_invited: "ஏற்கனவே அழைக்கப்பட்டுள்ளீர்கள்",
    invite_then_reject: "ஏற்கனவே இந்த வேலை நிராகரிக்கப்பட்டது",
    apply_then_reject: "நீங்கள் இந்த வேலைக்கு தேர்ந்தெடுக்கப்படவில்லை",
    education_exist: "ஏற்கனவே உள்ளது",
    exit_ok: "சரி",
    any_locations: "ஏதேனும் இடம்",
    any_location: "ஏதேனும் இடம்",
    otp_message: "தவறான ஓடிபி",
    otp_sent: "ஓடிபி வெற்றிகரமாக அனுப்பபட்டது",
    err_checkismax: "செல்லுபடியாகும் அதிகபட்ச சம்பளத்தை உள்ளிடவும்",
    langselectnext: "அடுத்து",
    sliderfirsttitle: "ஜபான்யா உங்களை வரவேற்கிறது",
    slidersecondtitle: "",
    sliderthirdtitle: "",
    sliderfourtitle: "",
    getstarted: "தொடங்கவும்",
    lang: "மொழி",
    loginhead: 'வேலை தேடுவோர்',
    loginsubhead: "தொடர உள்நுழைக",
    mobileplaceholder: 'கைபேசி எண் அல்லது பயனர் பெயர்',
    passwordplaceholder: 'பாஸ்வேர்ட்',
    forgetpassword: 'பாஸ்வேர்ட் மறந்துவிட்டீர்களா?',
    signinbutton: 'உள்நுழைக',
    signupbutton: 'புதிய பயனர் - பதிவு செய்ய',
    forgotsubmit: 'சமர்ப்பிக்கவும்',
    otptext: 'ஒடிபி',
    getotptext: 'ஒடிபி பெறுக',
    verifiedotptext: 'OTP ஐ சரிபார்க்கவும்',
    verifiedotpmessage: 'OTP ஐ சரிபார்க்கவும்',
    home_header_title1: 'ஜபான்யா',
    home_tab_home: 'முகப்பு',
    home_tab_recommended: 'பரிந்துரைக்கப்படுபவை',
    home_tab_applied: 'விண்ணப்பித்தவை',
    home_tab_invitedon: "Invited on",
    home_tab_invited: 'அழைப்புகள்',
    home_tab_wishlist: 'விருப்பப்பட்டியல்',
    home_jobtype: 'வேலை வகை',
    home_joblocation: 'வேலை இடம்',
    home_joblocation1: 'வேலை இடம் மாவட்டம்',
    job_joblocation1: 'வேலை நாடுநரின் வேலை விருப்ப இடம் - மாவட்டம்',
    home_industry: 'தொழில்துறை',
    home_jobfunction: 'வேலை பிரிவு',
    home_govjobs: 'அரசு வேலைகள்',
    home_eligiblejobs: 'எனது பொருத்தமான வேலைகள்',
    home_my: 'எனது',
    home_flashjobs: 'ஃபிளாஷ் வேலைகள்',
    clear_filter: 'வடிகட்டிகளை அழி',
    reset_filter_inicaps: "வடிகட்டிகளை அழி",
    clear_filter_inicaps: 'வடிகட்டிகளை அழி',
    jobsummary_title: 'கிடைக்கும் வேலை சுருக்கம்',
    jobsummary_privatejobs: 'தனியார் வேலைகள்',
    jobsummary_fresherjobs: 'புதியவர்கள் வேலைகள்',
    jobsummary_appliedjobs: 'நான் விண்ணப்பித்த வேலைகள்',
    jobsummary_eligiblejobs: 'எனது பொருத்தமான வேலைகள்',
    jobsummary_govtjobs: 'அரசு வேலைகள்',
    enter: 'உள்ளிட',
    footer_privatejobs: 'மொத்த வேலைகள்',
    footer_flashjobs: 'ஃபிளாஷ் வேலைகள்',
    footer_govtjobs: 'அரசு வேலைகள்',
    footer_jobs: 'வேலைகள்',
    apply_filter: "விண்ணப்பிக்கவும்",
    salary_scale: "சம்பள அளவு",
    best_salary: 'தகுதிக்கேற்ற சம்பளம்',
    flash_jobs_details: 'ஃபிளாஷ் வேலை விவரங்கள்',
    apply: "விண்ணப்பித்தவை",
    invite_accept: "ஏற்றுக்கொள்ளப்பட்டது",
    invite_decline: "மறுக்கப்பட்டது",
    apply_accpt: "ஏற்றுக்கொள்ளப்பட்டது",
    apply_reject: "நிராகரிக்கப்பட்டது",
    Posted_on: "தேதி வெளியிடப்பட்டது",
    invitation_accept: "வேலை அழைப்பு ஏற்றுக்கொள்ளப்பட்டது",
    invitation_reject: "வேலை அழைப்பு நிராகரிக்கப்பட்டது",
    education_plus: "இதுவரை எதுவும் இங்கு இல்லை! கீழே உள்ள பிளஸ் பொத்தானை கிளிக் செய்வதன் மூலம் கல்வியை சேர்க்கவும்",
    skills_plus: "இதுவரை எதுவும் இங்கு இல்லை!  கீழே உள்ள பிளஸ் பொத்தானைக் கிளிக் செய்வதன் மூலம் திறன்களை சேர்க்கவும்",
    no_record_found: "எந்த பதிவுகளும் கண்டறியப்படவில்லை",
    reference_plus: "இதுவரை எதுவும் இங்கு இல்லை!  கீழே உள்ள பிளஸ் பொத்தானைக் கிளிக் செய்வதன் மூலம் பரிந்துரை சேர்க்கவும்",
    sidebar_home: "முகப்பு",
    sidebar_profile: "எனது சுயவிவரத்தைக் காண்க",
    sidebar_resumebuilder: "சுயவிவரத்தை உருவாக்க",
    sidebar_news: "செய்தி",
    sidebar_events: "நிகழ்வுகள்",
    sidebar_govjobs: "அரசு வேலைகள்",
    sidebar_settings: "அமைப்புகள்",
    sidebar_contactus: "எங்களை தொடர்பு கொள்ள / கருத்து",
    sidebar_share: "நண்பர்களுடன் பகிர்",
    sidebar_rating: "Google மதிப்பீடுகள் மற்றும் மதிப்புரைகள்",
    sidebar_logout: "வெளியேறு",
    resume_heading: "சுயவிவரத்தை உருவாக்க",
    resume_personalinfo: "தனிப்பட்ட தகவல்",
    resume_contactinfo: "தொடர்பு கொள்வதற்கான தகவல்",
    resume_education: "கல்வி",
    resume_skills: "வேலை பிரிவு,வேலை தலைப்பு & திறன்கள்",
    resume_exp: "அனுபவம்",
    resume_ref: "என்னை பரிந்துரைக்கும் நபர்கள்",
    resume_recd: "",
    resume_recd1: "(என்னை சிபாரிசு செய்பவர்கள்)",
    resume_preference: "என் வேலை விருப்பத்தேர்வுகள்",
    date: "தேதி",
    personal_header: "தனிப்பட்ட தகவல்",
    personal_fullname: "முழு பெயர்",
    personal_fathername: "தந்தையின் பெயர்",
    personal_spousename: "கணவன் அல்லது மனைவியின் பெயர்",
    personal_dob: "பிறந்த தேதி",
    personal_gender: "பாலினம்",
    personal_marital: "திருமண நிலை",
    personal_aadhar: "ஆதார் எண்",
    personal_lg: "தெரிந்த மொழிகள்",
    personal_medical: "அறியப்பட்ட மருத்துவ வரலாறு",
    personal_upload: "தற்குறிப்பு பதிவேற்றவும்",
    personal_save: "சேமி",
    personal_diff: "மாற்றுத்திறனாளி",
    personal_yes: "ஆம்",
    personal_no: "இல்லை",
    personal_add: "சேர்க்கவும்",
    personal_format: "(டாக், டாக்ஸ் மற்றும் பிடிஎப் வடிவம் மட்டும்)",
    contact_header: "தொடர்பு கொள்வதற்கான தகவல்",
    contact_door: "கதவு எண் / தெரு",
    contact_area: "இடம்",
    contact_taluk: 'வேலை தாலுகா',
    contact_city: "டவுன் / நகரத்தின் பெயர்",
    contact_district: "மாவட்டம்",
    contact_state: "மாநிலம்",
    contact_pincode: "அஞ்சல் குறியீடு",
    contact_emailid: "மின்னஞ்சல் முகவரி",
    contact_mbleno: "கைபேசி எண் (வாட்ஸ்அப் உடன்)",
    contact_altmbleno: "மாற்று கைபேசி எண் (ஏதேனும் இருந்தால்)",
    contact_save: "சேமி",
    date_of_birth: "பிறந்த தேதி",
    work_exp_fresher: "ஃப்ரெஷ்ஷர்",
    work_exp_experienced: "அனுபவம் வாய்ந்தவர்",
    address: "இடம்",
    contact_address: "முகவரி",
    edu_header: "கல்வி",
    edu_schooling: "பள்ளிப்படிப்பு",
    edu_category: "வகை",
    edu_qualification: "தகுதி",
    edu_insti: "கல்வி நிறுவனம்",
    edu_loc: "இடம்",
    Candidate_location: "உங்களின் சொந்த ஊர்",
    edu_mark: "மதிப்பெண் சதவீதம்",
    edu_yrofpassing: "தேர்ச்சி பெற்ற ஆண்டு",
    edu_save: "சேமி",
    percentage_check: "சரியான சதவீதத்தை உள்ளிடவும்",
    ref_popup_header: "உறவுமுறை",
    ref: "என்னை பரிந்துரைக்கும் நபர்கள்",
    ref_name: "பெயர்",
    ref_desig: "பதவி",
    ref_others: "உங்கள் உறவுமுறையை குறிப்பிடுங்கள்",
    references_candidate_required: 'உறவுமுறையை தேர்ந்தெடுக்கவும்',
    ref_org: "நிறுவனத்தின் பெயர்",
    ref_relationship: "பரிந்துரைக்கும் நபர் உறவு",
    ref_remarks: "குறிப்புகள்",
    ref_save: "சேமி",
    ref_del: "அழி",
    ref_update: "சேமி",
    per_view_header: "என் சுயவிவரம்",
    per_view_marital: "திருமண நிலை",
    per_view_father_name: "தந்தையின் பெயர்",
    per_view_spouse_name: "கணவன் அல்லது மனைவியின் பெயர்",
    per_view_dob: "பிறந்த தேதி",
    per_view_aadhar: "ஆதார் எண்",
    per_view_lang: "தெரிந்த மொழிகள்",
    per_view_medical: "அறியப்பட்ட மருத்துவ வரலாறு",
    per_view_differentlyabled: "மாற்றுத்திறனாளி",
    per_contact: "தொடர்பு கொள்வதற்கான தகவல்",
    per_Details: "தனிப்பட்ட விவரங்கள்",
    per_edu: "கல்வி",
    per_school: "பள்ளிப்படிப்பு",
    per_after_school: "கல்லூரி",
    per_skills: "வேலை பிரிவு,வேலை தலைப்பு & திறன்கள்",
    per_ref_recd: "(Recommended By / என்னை சிபாரிசு செய்பவர்கள்)",
    per_ref: "என்னை பரிந்துரைக்கும் நபர்கள்",
    per_pre: "என் வேலை விருப்பத்தேர்வுகள்",
    exp_totalexp: "மொத்த அனுபவம்",
    exp_add: "உங்கள் அனுபவத்தைச் சேர்க்கவும்",
    exp_years: "ஆண்டுகள்",
    exp_heading: "அனுபவம்",
    exp_org: "நிறுவனத்தின் பெயர்",
    exp_desig: "பதவி",
    experience_from_month: "மாதத்திலிருந்து தேர்ந்தெடுக்கவும்",
    experience_to_month: "மாதத்திற்குத் தேர்ந்தெடுக்கவும்",
    exp_location: "இடம்",
    exp_from_month: "மாதத்திலிருந்து",
    exp_to_month: "மாதம்வரை",
    exp_from: "முதல்",
    exp_to: "வரை",
    shift_start: 'தொடக்கம் நேரம்',
    shift_end: 'முடிவு நேரம்',
    exp_month: "மாதம்",
    exp_year: "ஆண்டு",
    exp_currently: "தற்போது இங்கு பணிபுரிகிறேன்",
    job_currently: "தற்போதைய வேலை பங்கு",
    exp_save: "சேமி",
    prefer_ok: "சரி",
    jobfunction_currently: "தற்போதைய பணி",
    experience_yearoverlap: "உங்கள் தற்போதைய அனுபவம் மற்றொரு அனுபவத்துடன் மேலணைகிரது",
    experience_fromyear_required: "ஆண்டை உள்ளிடவும்",
    experience_fromyearvalid_required: "செல்லுபடியாகும் ஆண்டை உள்ளிடவும்",
    experience_tomonth_required: "மாதத்தை உள்ளிடவும்",
    experience_toyear_required: "ஆண்டை உள்ளிடவும்",
    experience_tomonthvalid_required: "சரியான மாதத்தை உள்ளிடவும்",
    experience_toyearvalid_required: "செல்லுபடியாகும் ஆண்டை உள்ளிடவும்",
    pre_emptype: "வேலைவாய்ப்பு வகை",
    pre_timeforjg: "சேருவதற்கான கால அவகாசம்",
    pre_immediate: "உடனடியாக",
    pre_within: "உள்ளே",
    pre_days: "நாட்களில்",
    pre_day: 'நாளில்',
    pre_expected: "எதிர்பார்க்கப்படும் மாத சம்பளம்",
    pre_permnth: "ஒரு மாதத்திற்கு",
    pre_header_popup_type: "வேலைவாய்ப்பு வகை",
    pre_header_popup_sal: "சம்பளம்",
    pre_to: "முதல்",
    pre_jobrole: "வேலை தலைப்பு",
    pre_fun: "வேலை பிரிவு",
    skills_heading: "திறன்கள்",
    skills_jobfunction: "வேலை பிரிவு",
    skills_Role: "வேலை தலைப்பு",
    applied_success: "வேலை வெற்றிகரமாக விண்ணப்பிக்கப்பட்டது",
    mobileNo_change_header: "கைபேசி எண்ணை மாற்றவும்",
    Mobileno_oldnum: "பழைய கைபேசி எண்",
    mobileNo_change_number: "புதிய கைபேசி எண்",
    mobileNo_change_otp: "ஒடிபி",
    mobileNo_change_change_mobile_number_button: "கைபேசி எண்ணை மாற்றவும்",
    invited: "அழைப்புகள்",
    toast_mobile: "கைபேசி எண் வெற்றிகரமாக புதுப்பிக்கப்பட்டது",
    login_invisible: "உங்கள் கணக்கு கண்ணுக்கு தெரியாத பயன்முறையில் உள்ளது.",
    login_invisible_active: "இப்போது மீண்டும் செயல்படுத்த விரும்புகிறீர்களா?",
    accout_reactivate: "கணக்கை மீண்டும் செயல்படுத்து",
    account_otp: "உங்கள் பதிவுசெய்யப்பட்ட கைபேசி எண்ணுக்கு ஒடிபி அனுப்பப்பட்டது",
    resend_otp_account: "ஒடிபி பெறுக",
    resend_otp_verify: "சரிபார்க்கவும்",
    login_yes: "ஆம்",
    login_no: "இல்லை",
    noti_head: "அறிவிப்பு",
    noti_jobs: "வேலைகள்",
    noti_news: "செய்தி",
    noti_events: "நிகழ்வுகள்",
    events_head: "நிகழ்வுகள்",
    job_description: "விளக்கம்",
    news_head: "செய்தி",
    link: "இணைப்பு",
    filterby: "வடிகட்டவும்",
    govt_jobs: "அரசு வேலைகள்",
    noti_button: "அனைத்தையும் நிராகரி",
    noti_swipe: "(தள்ளுபடி செய்ய இடதுபுறமாக ஸ்வைப் செய்யவும்)",
    password_change_header: "பாஸ்வேர்டை மாற்று",
    password_change_old_password: "பழைய பாஸ்வேர்ட்",
    password_change_new_password: "புதிய பாஸ்வேர்ட்",
    password_change_change_password_button: "பாஸ்வேர்டை மாற்று",
    toast_password: "பாஸ்வேர்ட் வெற்றிகரமாக மாற்றப்பட்டுள்ளது !",
    registration_heading: "வேலை தேடுவோர் பதிவு செய்தல்",
    registration_heading1: "ஒரு முறை பதிவுக் கட்டணம்",
    registration_user: "பயனரின் பெயர்",
    registered_name_msg: "பயனர் பெயர்",
    registered_name_msg: "(பெயர்,ஏதேனும் எண்ணை இடைவெளி இல்லாமல் உள்ளிடவும்)",
    registration_mobileno: "கைபேசி எண்",
    registration_password: "பாஸ்வேர்ட்",
    registration_otp: "ஒடிபி",
    registration_otpbutton: "ஒடிபி பெறுக",
    registration_checkbox: "ஜபான்யா விதிமுறைகளையும் நிபந்தனையையும் ஒப்புக்கொள்கிறேன்",
    registration_submitbutton: "பதிவு செய்ய",
    signup_button: "பதிவு செய்ய & தொடரவும்",
    forgetpassword_mobileno: "கைபேசி எண்",
    forgetpassword_otp: "ஒடிபி",
    forgetpassword_password: "பாஸ்வேர்ட்",
    forgetpassword_submit: "சமர்ப்பிக்கவும்",
    Setting_heading: "அமைப்புகள்",
    Setting_changepassword: "பாஸ்வேர்டை மாற்று",
    Setting_changemobileno: "மொபைல் எண்ணை மாற்றவும்.",
    Setting_Notification: "அறிவிப்பு",
    Setting_Preferedlanguage: "விருப்பமான மொழி",
    Setting_invisiblemode: "கண்ணுக்கு தெரியாத பயன்முறை",
    Notification_invisiblemode: "கண்ணுக்கு தெரியாத பயன்முறை",
    notifi_submit_toast: "வெற்றிகரமாக புதுப்பிக்கப்பட்டது!",
    image_upload_size_msg: "படத்தின் அளவு மிகவும் பெரியது",
    image_upload_format_msg: "கோப்பு '.jpg','.jpeg','.png' வடிவங்களில் இருக்க வேண்டும்",
    OTP_Validation: "6  இலக்க ஒடிபியை உள்ளிடவும்",
    Notification_msg1: "உங்கள் கணக்கை கண்ணுக்கு தெரியாத பயன்முறையில் மாற்ற விரும்புகிறீர்களா?",
    Notification_msg2: "உங்கள் கணக்கை வேலையளிப்போர் பார்க்க முடியாது",
    Notification_cancel: "ரத்து செய்க",
    Notification_submit: "சமர்ப்பிக்கவும்",
    Notification_heading: "அறிவிப்பு",
    Notification_jobs: "வேலைகள்",
    Notification_news: "செய்தி",
    Notification_events: "நிகழ்வுகள்",
    Contactus_heading: "எங்களை தொடர்பு கொள்ள",
    Contactus_website: "இணையதளம்",
    Contactus_emailid: "மின்னஞ்சல் முகவரி",
    Contactus_mobileno: "கைபேசி எண்",
    Contactus_subject: "பொருள்",
    Contactus__description: "விளக்கம்",
    Contactus_button: "சமர்ப்பிக்கவும்",
    Setting_invisiblemode: "கண்ணுக்கு தெரியாத பயன்முறை",
    logout_heading: "வெளியேறு",
    logout_msg: "நீங்கள் நிச்சயமாக வெளியேற விரும்புகிறீர்களா ?",
    logout_cancel: "ரத்து செய்க",
    logout_logout: "வெளியேறு",
    Search_heading: "தேடுங்கள்",
    Recent_Search: "சமீபத்திய தேடல்",
    Search_Something: 'ஏதாவது தேடுங்கள்',
    No_data_Available: 'தரவு எதுவும் கிடைக்கவில்லை',
    Placeholder: "இருப்பிடம், வேலை பிரிவு, வேலை தலைப்பு ஆகியவற்றின் அடிப்படையில் தேடுங்கள்",
    gn_desc_job_desc: "வேலை விளக்கம்",
    toaster_job_title_error: "நீங்கள் 4 வேலை தலைப்புகள் வரை தேர்ந்தெடுக்கலாம்",
    toaster_job_title_fresher_error: "நீங்கள் 1 வேலை தலைப்புகள் வரை தேர்ந்தெடுக்கலாம்",
    toaster_job_type_error: "நீங்கள் 4 வேலை வகைகள் வரை தேர்ந்தெடுக்கலாம்",
    already_Exist: "பயனர் பெயர் ஏற்கனவே உள்ளது",
    gn_desc_link: "ஆதாரம்",
    gn_desc_downloads: "பதிவிறக்கங்கள்",
    gn_no_vacanies: "காலியிடங்களின் எண்ணிக்கை",
    gn_Expiry: "கடைசி தேதி",
    rl_apply_now: "அழைப்பு & விண்ணப்பிக்கவும் ",
    rl_apply_now_one: "நீங்கள் நிச்சயமாக விண்ணப்பிக்க விரும்புகிறீர்களா?",
    rl_freshers: "புதியவர்கள்",
    rl_fresher: "ஃப்ரெஷ்ஷர்",
    rl_sortby: "வரிசைப்படுத்து",
    rl_filterby: "வடிகட்டவும்",
    rl_applyfilter: "வடிகட்டு",
    rl_filter: "வடிகட்டு",
    rl_jobtype: "வேலை வகை",
    rl_experience: "அனுபவம்",
    rl_qualification: "கல்வி",
    rl_skills: "திறன்கள்",
    Job: "வேலை",
    Description: 'விளக்கம்',
    rl_description: "வேலை விளக்கம்",
    rl_grp: "வேலை குழு",
    rl_maritial: "திருமண நிலை",
    rl_gender: "பாலினம்",
    rl_age: "வயது அளவுகோல்",
    rl_differently: "மாற்றுத்திறனாளி",
    rl_any: "ஏதேனும்",
    rl_no: "இல்லை",
    rl_preferred: "விருப்பமான",
    rl_lastdate: "கடைசி தேதி",
    rl_no_of_opening: "காலியிடங்கள்",
    rl_work: "வேலை நேரம்",
    rl_lang: "தெரிந்த மொழிகள்",
    max_length_preference: "நீங்கள் அதிகபட்சம் தேர்ந்தெடுக்கும் எண்ணிக்கை",
    rl_aboutcompany: "நிறுவனத்தை பற்றி",
    rl_turnover: "ஆண்டு விற்பனை",
    rl_fac: "வழங்கப்படும் வசதிகள்",
    log_industry: "தொழில்துறை",
    rl_company: "நிறுவனத்தின் வகை",
    rl_activity: "செயல்பாட்டு வகை",
    rl_employee: "வேலையாட்களின் எண்ணிக்கை",
    rl_contact: "தொடர்புக்கு",
    rl_address: "முகவரி",
    r1_email: "மின்னஞ்சல்",
    alternative_mobileno: "மாற்று கைபேசி எண்",
    not_eligible_text: "நீங்கள் விண்ணப்பிக்க இயலாது\n",
    not_eligible_text_1: "(உங்கள் தற்குறிப்பு விருப்பத்தேர்வுகள் பொருந்தவில்லை)",
    rl_apply: "விண்ணப்பிக்க மற்றும் அழைக்க",
    rl_do_invitation: "இந்த அழைப்பை ஏற்க விரும்புகிறீர்களா?",
    rl_decline: "மறுக்க",
    accept_qus: 'இந்த அழைப்பை நிச்சயமாக ஏற்க விரும்புகிறீர்களா?',
    decline_qus: "இந்த அழைப்பை நிச்சயமாக நிராகரிக்க விரும்புகிறீர்களா?",
    rl_decline_one: 'நீங்கள் நிச்சயமாக மறுக்க விரும்புகிறீர்களா?',
    rl_accept_one: 'நீங்கள் நிச்சயமாக ஏற்றுக்கொள்ள விரும்புகிறீர்களா?',
    rl_accept: "ஏற்றுக்கொள்",
    r1_group: "வேலை குழு",
    rl_Age: "வயது அளவுகோல்",
    rl_school: "பள்ளி",
    r1_daysleft: " நாட்கள் மீதமுள்ளது",
    expired: "காலாவதியானது",
    expired_todat: "இன்று காலாவதியாகிறது",
    r1_to: "முதல்",
    r1_years: "ஆண்டுகள்",
    contact_msg: "தகவல்",
    job_head: "! புகார் செய்",
    job_msg: "இந்த வேலை இடுகையை புகாரளிக்க விரும்புகிறீர்களா?",
    job_cancel: "ரத்து செய்க",
    job_report: "புகார் செய்",
    edu_aft_schl_heading: "கல்வி",
    edu_aft_schl_sub: "கல்லூரி",
    edu_aft_schl_qualification: "தகுதி",
    edu_aft_schl_specialization: "நிபுணத்துவம்",
    edu_aft_schl_institution: "கல்வி நிறுவனம்",
    edu_aft_schl_location: "இடம்",
    edu_aft_schl_mark: "சிஜிபிஏ / சதவீதம்",
    edu_aft_schl_year: "தேர்ச்சி பெற்ற ஆண்டு",
    edu_aft_schl_button: "சேமி",
    contact_city: "டவுன் / நகரத்தின் பெயர்",
    preference_heading: "என் வேலை விருப்பத்தேர்வுகள்",
    preference_job: "வேலை பிரிவு",
    preference_Role: "வேலை தலைப்பு",
    preference_Employement: "வேலைவாய்ப்பு வகை",
    preference_taluk: "வேலை தாலுகா",
    preference_time: "சேருவதற்கான கால அவகாசம்",
    preference_time1: "உடனடியாக",
    preference_time2: "",
    preference_day: "நாட்களில்",
    preference_to: "முதல்",
    preference_salary: "எதிர்பார்க்கப்படும் மாத சம்பளம்",
    preference_button: "அடுத்து",
    rate_ourapp: "எங்கள் செயலி பயன்பாட்டை மதிப்பிடவும்",
    apprate_content: "எங்கள் செயலி பயன்பாட்டு அனுபவத்தை எப்படி மதிப்பிடுவீர்கள் ?",
    later_btn: "பின்னர்",
    ratenow_btn: "இப்போது மதிப்பிடவும்",
    err_preferences_min_required: "குறைந்தபட்ச சம்பளத்தை உள்ளிடவும்",
    add_schooling: "முதலில் பள்ளிப்படிப்பை பூர்த்தி செய்யவும்",
    err_preferences_max_required: "அதிகபட்ச சம்பளத்தை உள்ளிடவும்",
    err_preferences_min_max_values: "செல்லுபடியாகும் அதிகபட்ச சம்பளத்தை உள்ளிடவும்",
    err_preferences_max_min_values: "செல்லுபடியாகும் குறைந்தபட்ச சம்பளத்தை உள்ளிடவும்",
    err_preferences_days_required: 'நாட்களை உள்ளிடவும்',
    err_preferences_min: "",
    err_preferences_emptype_required: 'வேலைவாய்ப்பு வகையைத் தேர்ந்தெடுக்கவும்',
    err_job_function_required: 'வேலை பிரிவை தேர்ந்தெடுக்கவும்',
    err_preference_jobrole_required: 'தயவுசெய்து வேலை தலைப்புத் தேர்ந்தெடுக்கவும்',
    err_preference_joblocation_required: 'தயவுசெய்து வேலை இருப்பிடத்தைத் தேர்ந்தெடுக்கவும்',
    err_preference_jobtaluk_required: 'தயவுசெய்து வேலை தாலுக்கைத் தேர்ந்தெடுக்கவும்',
    err_preference_employementtype_required: "வேலைவாய்ப்பு வகையைத் தேர்ந்தெடுக்கவும்",
    registered_msg: "வெற்றிகரமாகப் பதிவுசெய்யப்பட்டது",
    err_preference_employementtype_empty: "",
    err_preference_employementtype: "",
    err_job_function_empty: "",
    err_job_function: '',
    err_preference_joblocation: '',
    preference_type: "வேலை வகை",
    err_preferences_days: '', err_preferences_days_empty: '',
    contact_msg: "தகவல்",
    nameofuse_validate: '',
    nameofuse_validate1: "பயனரின் பெயர் குறைந்தபட்சம் 3 எழுத்துகளாக இருக்க வேண்டும்",
    nameofuse_validate2: 'பயனரின் பெயர் 50 எழுத்துக்களை தாண்டக்கூடாது',
    nameofuse_validate3: 'பெயரை உள்ளிடவும்',
    nameofuse_validate5: 'பெயரை உள்ளிடவும்',
    nameofuse_validate6: "சரியான பெயரை உள்ளிடவும்",
    user_validate1: "பயனர் பெயர் குறைந்தபட்சம் 3 எழுத்துகளாக இருக்க வேண்டும்",
    user_validate2: 'பயனர் பெயர் 50 எழுத்துக்களை தாண்டக்கூடாது',
    user_validate3: 'பயனர் பெயரை உள்ளிடவும்',
    user_validate5: 'பயனர் பெயரை உள்ளிடவும்',
    user_validate6: "தயவு செய்து ஆல்பா & எண்களை மட்டும் பயன்படுத்தவும் & ஸ்பேஸ் & சிறப்பு எழுத்துக்களைப் பயன்படுத்த வேண்டாம்",
    user_validate7: "பயனர் பெயர் ஏற்கனவே உள்ளது",
    mob_validate1: 'கைபேசி எண்ணை உள்ளிடவும்',
    mob_validate2: "செல்லுபடியாகும் கைபேசி எண்ணை உள்ளிடவும்",
    mob_validate4: "செல்லுபடியாகும் கைபேசி எண்ணை உள்ளிடவும்",
    mob_validate5: "கைபேசி எண்ணை உள்ளிடவும்",
    mob_validate7: "கைபேசி எண் ஏற்கனவே உள்ளது",
    pass_validate1: "பாஸ்வேர்டை உள்ளிடவும்",
    pass_validate2: "பாஸ்வேர்டை  குறைந்தபட்சம் 6 எழுத்துகளாக இருக்க வேண்டும்",
    pass_validate5: "பாஸ்வேர்டை  உள்ளிடவும்",
    pass_validate7: "தவறான பாஸ்வேர்ட்",
    pass_validate1_new: "பாஸ்வேர்டை உள்ளிடவும்",
    pass_validate2_new: "பாஸ்வேர்ட் குறைந்தபட்சம் 6 எழுத்துகளாக இருக்க வேண்டும்",
    pass_validate5_new: "பாஸ்வேர்டை உள்ளிடவும்",
    textValue: "ஒடிபி பெறுக",
    textValue1: 'ஒடிபிஐ மீண்டும் அனுப்பவும்',
    check_value: "ஜபான்யா விதிமுறைகளையும் நிபந்தனையையும் ஒப்புக்கொள்கிறேன்",
    check_value1: "விதிமுறைகள் மற்றும் நிபந்தனைகள்",
    password_message: "செல்லுபடியாகும் கைபேசி எண்ணை உள்ளிடவும்",
    password_success: "பாஸ்வேர்ட் வெற்றிகரமாக மாற்றப்பட்டுள்ளது !",
    username_required: "கைபேசி எண் அல்லது பயனர் பெயரை உள்ளிடவும்",
    validate_username: "சரியான பயனர் பெயரை உள்ளிடவும்",
    password_required: "பாஸ்வேர்டை உள்ளிடவும்",
    password_limit: "பாஸ்வேர்ட் 20 எழுத்துகளுக்குள் இருக்க வேண்டும்",
    password_length: "செல்லுபடியாகும் உள்நுழைவு சான்றுகளை உள்ளிடவும்",
    username_length: "செல்லுபடியாகும் உள்நுழைவு சான்றுகளை உள்ளிடவும்",
    street_name_required: "தெரு பெயரை உள்ளிடவும்",
    areaname_required: "இடம் பெயரை உள்ளிடவும்",
    cityname_required: "டவுன் / நகரத்தின் பெயரை உள்ளிடவும்",
    emailid_required: "மின்னஞ்சலை உள்ளிடவும்",
    emailid_already_exits: "மின்னஞ்சலை ஏற்கனவே இருக்கிறது",
    emailid_format: "செல்லுபடியாகும் மின்னஞ்சலை உள்ளிடவும்",
    mobileno_required: "கைபேசி எண்ணை உள்ளிடவும்",
    mobileno_max: "செல்லுபடியாகும் கைபேசி எண்ணை உள்ளிடவும்",
    same_alter_no: "மாற்று எண் முதன்மை எண்ணாக இருக்க முடியாது",
    pincode_required: "அஞ்சல் குறியீடை உள்ளிடவும்",
    pincode_max: "செல்லுபடியாகும் அஞ்சல் குறியீடை உள்ளிடவும்",
    state_name_required: "மாநில பெயரை உள்ளிடவும்",
    altmobileno_max: "செல்லுபடியாகும் மாற்று கைபேசி எண்ணை உள்ளிடவும்",
    state_required: 'மாநிலத்தை தேர்ந்தெடுக்கவும்',
    district_required: 'மாவட்டத்தை தேர்ந்தெடுக்கவும்',
    city_required: 'டவுன் / நகரத்தின் பெயரை தேர்ந்தெடுக்கவும்',
    reference_name_required: "பெயரை உள்ளிடவும்",
    reference_designation_required: "பதவியை உள்ளிடவும்",
    reference_relationship_required: "உறவுமுறையை உள்ளிடவும்",
    reference_organization_required: "நிறுவனத்தை உள்ளிடவும்",
    reference_emailid_required: "மின்னஞ்சல் ஐடியை உள்ளிடவும் ",
    reference_emailid_format: "செல்லுபடியாகும் மின்னஞ்சலை உள்ளிடவும்",
    reference_mobileno_required: "கைபேசி எண்ணை உள்ளிடவும்",
    reference_mobileno_max: "செல்லுபடியாகும் கைபேசி எண்ணை உள்ளிடவும்",
    reference_mobileno_reference: "உங்கள் தொடர்புஎண் குறிப்பு எண்ணாக இருக்க முடியாது",
    references_candidate_required: 'நபரை தேர்ந்தெடுக்கவும்',
    schooling_institution_required: "கல்வி நிறுவனத்தை உள்ளிடவும்",
    schooling_qualification_required: "தகுதியை தேர்ந்தெடுக்கவும்",
    after_schooling_institution_required: "கல்வி நிறுவனத்தை உள்ளிடவும்",
    after_schooling_location_required: "இடத்தை உள்ளிடவும்",
    after_schooling_mark_required: "சதவீதத்தை உள்ளிடவும்",
    after_schooling_category_required: "வகையை தேர்ந்தெடுக்கவும்",
    after_schooling_qualification_required: "தகுதியை தேர்ந்தெடுக்கவும்",
    after_schooling_year_required: "தேர்ச்சிபெற்ற ஆண்டை உள்ளிடவும்",
    after_schooling_year_validate: "செல்லுபடியாகும் தேர்ச்சிபெற்ற ஆண்டை உள்ளிடவும்",
    after_schooling_specialization_required: "நிபுணத்துவத்தை தேர்ந்தெடுக்கவும்",
    experience_organization_required: "நிறுவனத்தை உள்ளிடவும்",
    experience_designation_required: "பதவியை உள்ளிடவும்",
    experience_location_required: "இடத்தை உள்ளிடவும்",
    experience_fromyear_required: "ஆண்டை உள்ளிடவும்",
    experience_tomonth_required: "மாதத்தை உள்ளிடவும்",
    experience_toyear_required: "ஆண்டை உள்ளிடவும்",
    experience_years: "ஆண்டுகள்",
    personalinfo_name_required: "முழு பெயரை உள்ளிடவும்",
    personalinfo_fathername_required: "தந்தையின் பெயரை உள்ளிடவும்",
    personalinfo_spousename_required: "கணவன் அல்லது மனைவியின் பெயரை உள்ளிடவும்",
    personalinfo_aadharno_required: "ஆதார் எண்ணை உள்ளிடவும்",
    aadhar_exists: "ஆதார் எண் ஏற்கனவே உள்ளது",
    personalinfo_aadharno_max: "ஆதார் எண் 12 இலக்கங்களாக இருக்க வேண்டும்",
    personalinfo_lang_required: "மொழியை உள்ளிடவும்",
    personalinfo_ageerror_required: "வயது குறைந்தபட்சம் 18 ஆக இருக்க வேண்டும்",
    personalinfo_resume_required: "கோப்பு வடிவம் டாக், டாக்ஸ் அல்லது பிடிஎஃப் மட்டுமே இருக்க வேண்டும்",
    personalinfo_gender_required: 'பாலினத்தை தேர்ந்தெடுக்கவும்',
    personalinfo_Dob_required: 'பிறந்த தேதியை தேர்ந்தெடுக்கவும்',
    personalinfo_mar_required: 'திருமண நிலையை தேர்ந்தெடுக்கவும்',
    preferences_min_required: "குறைந்தபட்ச சம்பளத்தை உள்ளிடவும்",
    preferences_max_required: "அதிகபட்ச சம்பளத்தை உள்ளிடவும்",
    preferences_days_required: 'நாட்களை உள்ளிடவும்',
    preferences_emptype_required: 'வேலைவாய்ப்பு வகையை தேர்ந்தெடுக்கவும்',
    job_function_required: 'வேலை பிரிவை தேர்ந்தெடுக்கவும்',
    preference_jobfunction_required: 'வேலை பிரிவை தேர்ந்தெடுக்கவும்',
    preference_jobrole_required: 'வேலை தலைப்பைத் தேர்ந்தெடுக்கவும்',
    preference_joblocation_required: 'வேலை இடத்தைத் தேர்ந்தெடுக்கவும்',
    skills_required: 'திறன்களை தேர்ந்தெடுக்கவும்',
    skill_catagory_required: 'வேலை பிரிவை தேர்ந்தெடுக்கவும்',
    skill_sub_catagory_required: 'வேலை தலைப்பை தேர்ந்தெடுக்கவும்',
    sub: "பொருள்",
    user_not_found: 'உங்கள் கணக்கு செயலில் இல்லை',
    abused_content: 'கணக்கு துஷ்பிரயோகம் செய்யப்பட்டுள்ளது',
    invalid_credentials: 'பயனர் பெயர் அல்லது பாஸ்வேர்ட் தவறானது',
    filter_skills: 'திறன்கள்',
    filter_location: 'வேலை இடம்',
    filter_jobfunction: 'வேலை பிரிவு',
    filter_jobtype: 'வேலை வகை',
    filter_education: 'கல்வி',
    filter_experience: 'அனுபவம்',
    filter_industry: 'தொழில்துறை',
    filter_salaryrange: 'ஊதிய வீதம்',
    filter_employertype: 'வேலையளிப்போர் வகை',
    filter_companytype: 'நிறுவனத்தின் வகை',
    filter_jobgroup: 'வேலை குழு',
    login_success: "வெற்றிகரமாக உள்நுழைக",
    pwd_changed_success: "பாஸ்வேர்ட் வெற்றிகரமாக மாற்றப்பட்டுள்ளது ",
    document_download: "ஆவணம் பதிவிறக்குகிறது",
    update_total_exp: "மொத்த அனுபவ வருடத்தை உள்ளிடவும்",
    updated_success: "வெற்றிகரமாக புதுப்பிக்கப்பட்டது",
    created_success: "வெற்றிகரமாக உருவாக்கப்பட்டது",
    record_not_found: "பதிவு கிடைக்கவில்லை",
    deleted_success: "வெற்றிகரமாக அழிக்கப்பட்டது",
    deleted_error: "அழிக்க முடியாது. ஏற்கனவே பயன்பட்டுள்ளது ",
    deactivate_success: "வெற்றிகரமாக செயலிழக்கபட்டது",
    active_success: "வெற்றிகரமாக செயல்படுத்தப்பட்டுள்ளது",
    education_afterschool_year_overlap: "செல்லுபடியாகும்  ஆண்டை உள்ளிடவும்",
    saved_success: "வெற்றிகரமாக சேமிக்கப்பட்டுள்ளது ",
    abuse_error: "ஏற்கனவே துஷ்பிரயோகம் செய்யப்பட்டுள்ளது",
    bellow_sslc_error: "உங்கள் பள்ளி தரவைச் சரிபார்க்கவும்",
    already_exist: "ஏற்கனவே உள்ளது",
    user_exist: "பயனர் பெயர் ஏற்கனவே உள்ளது",
    user_not_exist: "மொபைல் எண் அல்லது பயனர் பெயர் இல்லை",
    mobile_no_exist: "கைபேசி எண் ஏற்கனவே உள்ளது",
    invalid_credentials: "பயனர் பெயர் அல்லது பாஸ்வேர்ட் தவறானது",
    register_success: "வெற்றிகரமாக பதிவு செய்யப்பட்டுள்ளது ",
    contactus_msg_error: "தகவலை உள்ளிடவும்",
    contactus_subject_error: "தலைப்பை தேர்ந்தெடுக்கவும்",
    update_available: "புதுப்பதிப்பு கிடைக்கின்றது",
    update_msg: "செயலியின் புதிய பதிப்பு உள்ளது. தயவுசெய்து புதுப்பி என்பதைக் கிளிக் செய்யவும்",
    force_update: "இப்பொழுதே புதுப்பிக்க",
    home_update: 'சிறந்த முடிவுகளுக்கு இப்போதே புதுப்பிக்கவும்',
    Later: 'பின்னர்',
    employer_email_changed: 'மின்னஞ்சல் வெற்றிகரமாக மாற்றப்பட்டது ',
    employer_email_exists: 'மின்னஞ்சல் ஏற்கனவே உள்ளது',
    employer_Invalid_Mail: 'தவறான மின்னஞ்சல்',
    employer_otp_message_expired: 'காலாவதியான ஓடிபி',
    employer_textValue: "ஒடிபி பெறுக",
    employer_register_name: "பதிவு செய்யப்பட்ட பெயர்",
    employer_textValue1: 'ஒடிபிஐ மீண்டும் அனுப்பவும்',
    employer_already_exist: "ஏற்கனவே உள்ளது",
    employer_register_success: "வெற்றிகரமாக பதிவு செய்யப்பட்டுள்ளது ",
    employer_filter_skills: 'திறன்கள்',
    employer_filter_location: 'பணியாளர் இடம்',
    employer_filter_taluk: 'பணியாளர் தாலுகா',
    employer_filter_jobfunction: "வேலை பிரிவு",
    employer_filter_jobtype: 'வேலை வகை',
    employer_filter_education: 'கல்வி',
    employer_filter_experience: 'அனுபவம்',
    employer_filter_industry: 'தொழில்துறை',
    employer_filter_salaryrange: "ஊதிய வீதம்",
    employer_filter_employertype: 'வேலையளிப்போர் வகை',
    employer_filter_companytype: 'நிறுவனத்தின் வகை',
    employer_filter_jobgroup: "வேலை குழு",
    employer_filter_jobrole: "வேலை தலைப்பு",
    employer_update_available: "புதுப்பதிப்பு கிடைக்கின்றது",
    employer_seconds_ago: ' விநாடிகளுக்கு முன்பு',
    employer_minutes_ago: ' நிமிடங்களுக்கு முன்பு',
    employer_hours_ago: ' மணி நேரத்திற்கு முன்பு',
    employer_days_ago: ' நாட்களுக்கு முன்பு',
    employer_months_ago: ' மாதங்களுக்கு முன்பு',
    employer_years_ago: ' ஆண்டுகளுக்கு முன்பு',
    employer_update_msg: "செயலியின் புதிய பதிப்பு உள்ளது. தயவுசெய்து புதுப்பி என்பதைக் கிளிக் செய்யவும்",
    //employer_update_msg: "ஒரு புதுப்பிப்பு உள்ளது. நீங்கள் நிச்சயமாக புதுப்பிக்க விரும்புகிறீர்களா?",
    employer_force_update: "கட்டாயமாக இப்பொது புதுப்பதிப்பாக்கு",
    employer_any_qualification: "ஏதேனும் ஒரு தகுதி",
    employer_any_specl: "ஏதேனும் ஒரு நிபுணத்துவம்",
    employer_any_Degree: "ஏதேனும் ஒரு கல்வி வகை",
    employer_selectlangheadertag: "விருப்பமான மொழி    ",
    preferedLocation: "நீங்கள் வேலை தேடும் ஊர்",
    employer_date: "தேதி",
    employer_language_required: "மொழிகளை தேர்ந்தெடுக்கவும்",
    employer_Contact_state_errormsg: "மாநிலத்தை தேர்ந்தெடுக்கவும்",
    Unlimited: 'வரம்பு இல்லை',
    employer_unlimited: '* UL-வரம்பு இல்லை',
    employer_unlimited_short: 'UL',
    employer_company_profile: "நிறுவனத்தின் சுயவிவரம்",
    employer_no_records: "எந்த பதிவுகளும் கண்டறியப்படவில்லை",
    employer_no_notification: "புதிய அறிவிப்பு எதுவும் இல்லை",
    employer_job_accept: "வேலை விண்ணப்பம் ஏற்றுக்கொள்ளப்பட்டது",
    employer_job_accept_already: "வேலை விண்ணப்பம் ஏற்கனவே ஏற்றுக்கொள்ளப்பட்டுள்ளது",
    employer_shortlist_limit_exceed: "தேர்தெடுத்தார் பட்டியல் வரம்பு மீறப்பட்டுள்ளது",
    employer_job_reject_already: "வேலை விண்ணப்பம் ஏற்கனவே நிராகரிக்கப்பட்டுள்ளது",
    employer_job_reject: "வேலை விண்ணப்பம் நிராகரிக்கப்பட்டது",
    employer_no_jobpost: "வேலை இடுகைகள் எதுவும் கிடைக்கவில்லை",
    employer_no_active_subscription: "சந்தாக்கள் எதுவும் இல்லை",
    employer_document_download: "ஆவணம் பதிவிறக்குகிறது",
    employer_selectedlanguage_heading: "உங்கள் மொழியைத் தேர்ந்தெடுக்கவும்",
    employer_langselectnext: "அடுத்தது",
    employer_sliderfirsttitle: 'சிறந்த வேலைகளுக்கு வரவேற்கிறோம்',
    employer_sliderfirstdesc: "தொழில் தூண்டுதலைத் தேடுகிறீர்களா? இங்கே நீங்கள் " +
      "கல்விசார் நூலகர் முதல் இளைஞர் தொழிலாளி வரை வெவ்வேறு வேலைகளின் ஹண்ட்ரெட்டுகள் பற்றி.",
    employer_slidersecondtitle: "",
    employer_sliderseconddesc: "வழக்கமான பொறுப்புகளைப் பற்றி அறிய எங்கள் வேலை விளக்கங்களை உலாவுக " +
      "மற்றும் ஒவ்வொரு பாத்திரத்திற்கும் முதலாளிகள், எனவே என்ன சம்பந்தப்பட்டிருக்கிறது, யார் உங்களை வேலைக்கு அமர்த்தலாம் என்பதை நீங்கள் காணலாம்",
    employer_sliderthirdtitle: "",
    employer_sliderthirddesc: "சில பட்டங்கள் எந்தவொரு பட்டப் பின்னணியிலிருந்தும் விண்ணப்பதாரர்களுக்குத் திறந்திருக்கும், மற்றவர்களுக்கு " +
      "தேவைப்படுகிறது முதுகலை தகுதிகளின் குறிப்பிட்ட வீழ்ச்சி",
    employer_sliderfourtitle: "",
    employer_sliderfourdesc: "தொடர்புடைய வேலை விளக்கத்தின் கொத்துக்களை ஆராய தொழில் துறையால் வடிகட்டவும் மற்றும் " +
      "உங்களுக்குப் பொருத்தமான பாத்திரத்தைப் பற்றிய உங்கள் கருத்துக்களை வளர்த்துக் கொள்ளுங்கள்",
    employer_getstarted: "தொடங்கவும்",
    employer_pass_validate2: "பாஸ்வேர்ட் குறைந்தபட்சம் 6 எழுத்துகளாக இருக்க வேண்டும்",
    employer_loginhead: 'வேலையளிப்போர்',
    employer_loginsubhead: "தொடர உள்நுழைக",
    employer_mobileplaceholder: 'மொபைல் எண் / பயனர் பெயர்',
    employer_passwordplaceholder: 'பாஸ்வேர்ட்',
    employer_forgetpassword: 'பாஸ்வேர்ட் மறந்துவிட்டீர்களா ?',
    employer_signinbutton: 'உள்நுழைக',
    employer_signupbutton: 'புதிய பயனர்? பதிவு செய்ய',
    employer_emailid: "மின்னஞ்சல் முகவரி",
    employer_forgotsubmit: 'சமர்ப்பிக்கவும்',
    employer_otptext: 'ஒடிபி',
    employer_err_preferences_min_max_values: "செல்லுபடியாகும் சம்பளத்தை உள்ளிடவும்",
    employer_minsal_required: "குறைந்தபட்ச சம்பளத்தை உள்ளிடவும்",
    employer_maxsal_required: "அதிகபட்ச சம்பளத்தை உள்ளிடவும்",
    employer_minage_required: "குறைந்தபட்ச வயதை உள்ளிடவும்",
    employer_maxage_required: "அதிகபட்ச வயதை உள்ளிடவும்",
    employer_getotptext: 'ஒடிபி பெறுக',
    employer_home_header_title: 'Shiva Software Solutions',
    employer_home_header_title1: 'Hi Suriya',
    employer_home_tab_post: 'எனது வேலை இடுகைகள்',
    employer_home_tab_recommended: 'பரிந்துரைக்கப்படுகிறது',
    employer_home_tab_applied: 'விண்ணப்பிக்கப்பட்டது',
    employer_home_tab_invited: 'அழைக்கப்பட்டது',
    employer_home_tab_wishlist: 'தேர்ந்தெடுக்கப்பட்டது',
    employer_home_tab_Shortlisted: "இறுதித்தேர்வு",
    employer_home_tab_Description: "விளக்கம்",
    employer_home_jobtype: 'வேலை வகை',
    employer_home_industry: 'தொழில்துறை',
    employer_home_jobfunction: 'வேலை பிரிவு',
    employer_home_govjobs: 'அரசு வேலைகள்',
    employer_reg_faci: "வழங்கப்படும் அம்சங்கள்",
    employer_since_experience: "முதல்",
    employer_sidebar_home: "முகப்பு",
    employer_sidebar_profile: "நிறுவனத்தின் சுயவிவரம்",
    employer_sidebar_subscription: "சந்தாக்கள்",
    employer_sidebar_news: "செய்தி",
    employer_sidebar_events: "நிகழ்வுகள்",
    employer_sidebar_settings: "அமைப்புகள்",
    employer_sidebar_contactus: "எங்களை தொடர்பு கொள்ள",
    employer_sidebar_share: "பகிர்",
    join_us: 'சமூக ஊடகங்களில் எங்களுடன் சேர',
    rate_us: 'எங்களை மதிப்பிட & மதிப்பாய்வு செய்ய',
    employer_sidebar_rating: "மதிப்பீடுகள் மற்றும் மதிப்புரைகள்",
    employer_sidebar_logout: "வெளியேறு",
    employer_resume_heading: "தற்குறிப்பு உருவாக்க",
    employer_resume_personalinfo: "தனிப்பட்ட தகவல்",
    employer_resume_contactinfo: "தொடர்பு கொள்வதற்கான தகவல்",
    employer_resume_education: "கல்வி",
    education_category: "கல்வி வகை",
    employer_resume_skills: "திறன்கள்",
    employer_resume_exp: "அனுபவம்",
    employer_resume_ref: "என்னை பரிந்துரைக்கும் நபர்கள்",
    employer_resume_preference: "அறிவிப்பு விருப்பத்தேர்வுகள்",
    employer_personal_header: "தனிப்பட்ட தகவல்",
    employer_personal_fullname: "முழு பெயர்",
    employer_personal_fathername: "தந்தையின் பெயர்",
    employer_personal_spousename: "கணவன் அல்லது மனைவியின் பெயர்",
    employer_personal_diff: "மாற்றுத்திறனாளி",
    employer_personal_All: 'நிராகரி',
    employer_personal_yes: "ஆம்",
    employer_personal_no: "இல்லை",
    employer_personal_include: "சேர்க்கவும்",
    employer_personal_only: "மாற்றுத்திறனாளிகள் மட்டும்",
    employer_personal_dob: "பிறந்த தேதி",
    employer_personal_gender: "பாலினம்",
    employer_personal_marital: "திருமண நிலை",
    employer_personal_aadhar: "உதயம்/உத்யோக் ஆதார் எண்",
    employer_personal_lg: "தெரிந்த மொழிகள்",
    employer_personal_medical: "அறியப்பட்ட மருத்துவ வரலாறு",
    employer_personal_upload: "தற்குறிப்பு பதிவேற்றவும்",
    employer_personal_save: "சேமி",
    employer_personal_add: "சேர்க்கவும்",
    employer_personal_format: "(டாக், டாக்ஸ் மற்றும் பிடிஎப் வடிவம் மட்டும்)",
    employer_contact_header: "தொடர்பு கொள்வதற்கான தகவல்",
    employer_contact_door: "கதவு எண் / தெரு",
    employer_contact_area: "இடம்",
    employer_contact_city: "டவுன் / நகரத்தின் பெயர்",
    employer_contact_district: "மாவட்டம்",
    employer_contact_state: "மாநிலம்",
    employer_contact_pincode: "அஞ்சல் குறியீடு",
    employer_contact_emailid: "மின்னஞ்சல் முகவரி",
    employer_contact_mbleno: "தொலைபேசி எண்",
    employer_contact_altmbleno: "மாற்று கைபேசி எண்",
    employer_contact_altmbleno_contact: "மாற்று தொடர்பு எண்",
    employer_contact_save: "சேமி",
    employer_job_head: "! துஷ்பிரயோகம் புகார்செய்",
    employer_at: "இல்",
    employer_job_msg: "இந்த வேலை நாடுநரை புகாரளிக்க விரும்புகிறீர்களா?",
    employer_job_cancel: "ரத்துசெய்",
    employer_job_report: "புகார் செய்",
    employer_edu_aft_schl_specialization: "நிபுணத்துவம்",
    employer_edu_header: "கல்வி",
    employer_edu_schooling: "பள்ளிப்படிப்பு",
    employer_edu_qualification: "தகுதி",
    employer_edu_insti: "கல்வி நிறுவனம்",
    employer_edu_loc: "இடம்",
    employer_edu_mark: "மதிப்பெண் சதவீதம்",
    employer_edu_yrofpassing: "தேர்ச்சி பெற்ற ஆண்டு",
    employer_edu_save: "சேமி",
    employer_any_show: "ஏதேனும்",
    employer_ref_popup_header: "உறவுமுறை",
    employer_ref: "என்னை பரிந்துரைக்கும் நபர்கள்",
    employer_ref_name: "பெயர்",
    employer_ref_desig: "பதவி",
    employer_ref_org: "நிறுவனத்தின் பெயர்",
    employer_ref_relationship: "பரிந்துரைக்கும் நபருடனான உறவுமுறை",
    employer_ref_remarks: "குறிப்புகள்",
    employer_ref_save: "சேமி",
    employer_ref_saveasdraft: "வரைவாக சேமி",
    employer_ref_del: "அழி",
    employer_ref_update: "சேமி",
    employer_later: 'பின்னர்',
    employer_pr_view_header: "சுயவிவரம்",
    employer_Pr_company: "நிறுவனத்தின் வகை",
    employer_pr_emp_type: "வேலையளிப்போர் வகை",
    employer_pr_industry: "தொழில்துறை",
    employer_pr_activity: "செயல்பாட்டு வகை",
    employer_pr_branch: "தலைமை அலுவலகம் & கிளை விவரங்கள்",
    employer_pr_abt_company: "நிறுவனத்தை பற்றி",
    employer_pr_pre: "என் பணியாளர் விருப்பத்தேர்வுகள்",
    employer_pr_contact: "தொடர்பு கொள்வதற்கான தகவல்",
    Employer_referredby: "பரிந்துரைக்கும் நபர்",
    employer_pr_gn: "அரசு அடையாளம்",
    employer_pr_gstin1: "ஜிஎஸ்டி எண்",
    employer_pr_gstin: "ஜிஎஸ்டி எண் (எல்லா பக்கங்களையும் சேர்க்கவும்)",
    employer_pr_pan: "பான் எண்",
    employer_pr_aadhar: "உதயம்/உத்யோக் ஆதார் எண்",
    employer_pr_aadhar_no: "ஆதார் எண்",
    employer_pr_facilities: "வழங்கப்படும் அம்சங்கள்",
    employer_pr_job_func: "வேலை பிரிவு",
    employer_pr_job_role: "வேலை தலைப்பு",
    employer_pr_Loc: "இடம்",
    employer_pr_turnover: "ஆண்டு விற்பனை",
    employer_pr_no_emp: "வேலை ஆட்கள் எண்ணிக்கை",
    employer_exp_totalexp: "மொத்த அனுபவம்",
    employer_exp_years: "ஆண்டுகள்",
    employer_rl_fresher: "ஃப்ரெஷ்ஷர்",
    employer_exp_heading: "அனுபவம்",
    employer_exp_org: "நிறுவனத்தின் பெயர்",
    employer_exp_desig: "பதவி",
    employer_exp_location: "இடம்",
    employer_exp_from: "முதல்",
    employer_exp_to: "வரை",
    employer_exp_month: "மாதம்",
    employer_exp_year: "ஆண்டு",
    employer_exp_currently: "தற்போது இங்கே வேலை செய்கிறீர்கள்",
    employer_exp_save: "சேமி",
    employer_pre_emptype: "வேலைவாய்ப்பு வகை",
    employer_pre_timeforjg: "சேருவதற்கான கால அவகாசம்",
    employer_pre_immediate: "உடனடியாக",
    employer_pre_within: "இன்னும்",
    employer_pre_days: "நாட்களில்",
    employer_pre_expected: "எதிர்பார்க்கப்படும் மாத சம்பளம்",
    employer_pre_permnth: "ஒரு மாதத்திற்கு",
    employer_pre_header_popup_type: "வேலை நாடுநர் வகை",
    employer_pre_header_popup_sal: "சம்பளம்",
    employer_pre_to: "முதல்",
    employer_pre_jobrole: "வேலை தலைப்பு",
    employer_pre_fun: "வேலை பிரிவு",
    employer_skills_heading: "திறன்கள்",
    employer_skills_jobfunction: "வேலை பிரிவு",
    employer_skills_Role: "வேலை தலைப்பு",
    employer_forgot_email: "மின்னஞ்சல் முகவரி",
    employer_noti_head: "அறிவிப்பு",
    employer_noti_jobs: "வேலை தொடர்பான",
    employer_noti_news: "செய்தி",
    employer_noti_events: "நிகழ்வுகள்",
    employer_events_head: "நிகழ்வுகள்",
    employer_job_description: "விளக்கம்",
    employer_address: "இடம்",
    employer_news_head: "செய்தி",
    employer_link: "இணைப்பு",
    employer_filterby: "வடிகட்டவும்",
    employer_govt_jobs: "அரசு வேலைகள்",
    employer_noti_button: "அனைத்தையும் நிராகரி",
    employer_noti_swipe: "(தள்ளுபடி செய்ய இடதுபுறமாக ஸ்வைப் செய்யவும்)",
    employer_log_heading: "வேலையளிப்போர் பதிவு செய்தல்",
    employer_sliderfirsttitle1: "நிறுவனத்தின் அடிப்படை தகவல்",
    employer_slidersecondtitle2: "மனிதவள தொடர்பு தகவல்",
    employer_sliderthirdtitle3: "அரசு அடையாளம்",
    employer_log_regcomname: "பதிவு செய்யப்பட்ட நிறுவனத்தின் பெயர்",
    employer_log_companytype: "நிறுவனத்தின் வகை",
    employer_log_industry: "தொழில்துறை",
    employer_log_activity: "செயல்பாட்டு வகை",
    employer_log_employertype: "வேலையளிப்போர் வகை",
    employer_log_regmail: "பதிவுசெய்யப்பட்ட மின்னஞ்சல்",
    employer_log_password: "பாஸ்வேர்ட்",
    employer_log_state: "மாநிலம்",
    employer_log_district: "மாவட்டம்",
    employer_log_city: "டவுன் / நகரத்தின் பெயர்",
    employer_log_taluk: "தாலுகா",
    employer_log_pincode: "அஞ்சல் குறியீடு",
    employer_log_regaddress: "தெரு / சாலை / இடம் ",
    Company_Address_Placeholder: 'கதவு எண், தெருவின் பெயர், இடம்',
    employer_log_mobile: "கைபேசி எண்",
    //employer_log_telephone: "தொலைபேசி எண்",
    employer_log_telephone: "அலுவலக எண்",
    employer_log_website: "இணையதள முகவரி ",
    employer_log_check: "தொடர்பு முகவரி நிறுவனத்தின் பதிவு செய்யப்பட்ட முகவரி போன்றது",
    employer_log_contactperson: "தொடர்பு கொள்ள வேண்டிய நபர் ",
    employer_log_designation: "பதவி",
    employer_log_address: "நிறுவனத்தின் முகவரி",
    employer_log_mobileno: "தொலைபேசி எண்",
    employer_log_question: "ஜபான்யா பற்றி உங்களுக்கு எப்படி தெரியும் ?",
    employer_log_gstin: "ஜிஎஸ்டி எண் (எல்லா பக்கங்களையும் சேர்க்கவும்)",
    employer_log_pan: "பான் எண்",
    employer_log_pan_no: "பான் எண்",
    employer_log_aadhar: "உதயம்/உத்யோக் ஆதார் சான்றிதழ்",
    employer_log_aadhar_no: "ஆதார் எண்",
    employer_company_address: 'பதிவு செய்யப்பட்ட நிறுவனத்தின் முகவரி',
    employer_log_faci: "வழங்கப்படும் அம்சங்கள்",
    employer_contactinfo_header: "தொடர்பு தகவலை தொகுத்து அமை",
    employer_contactinfo_person: "தொடர்பு கொள்ள வேண்டிய நபர் ",
    employer_contactinfo_designation: "பதவி",
    employer_contactinfo_address: "நிறுவனத்தின் முகவரி",
    employer_contactinfo_city: "டவுன் / நகரத்தின் பெயர்",
    employer_contactinfo_pincode: "அஞ்சல் குறியீடு",
    employer_contactinfo_mobile: "தொலைபேசி எண்",
    employer_contactinfo_telephone: "தொலைபேசி எண்",
    employer_contactinfo_email: "மின்னஞ்சல் முகவரி",
    employer_contactinfo_Website: "இணையதள முகவரி ",
    employer_contactinfo_map: "வரைபடத்தில் இருப்பிடம்",
    employer_logo: " நிறுவனத்தின் சின்னம்",
    employer_mobileNo_change_header: "மின்னஞ்சல் முகவரி மாற்றவும்",
    employer_mobileNo_change_number: "தொலைபேசி எண்",
    employer_email_change_current: "தற்போதைய மின்னஞ்சல் முகவரி",
    employer_email_change_email: "மின்னஞ்சல் முகவரி",
    employer_branch_email: "மின்னஞ்சல் முகவரி",
    employer_branchname_required: "கிளை பெயரை உள்ளிடவும்",
    employer_person_name_required: "தொடர்பு கொள்ள வேண்டிய நபரின் பெயரை உள்ளிடவும்",
    employer_designation_required: "பதவியை உள்ளிடவும்",
    employer_pincode_required: "அஞ்சல் குறியீடை உள்ளிடவும்",
    employer_pincode_valid: "செல்லுபடியாகும் அஞ்சல் குறியீடை உள்ளிடவும்",
    employer_companyaddress_required: "நிறுவனத்தின் முகவரியை உள்ளிடவும்",
    employer_companyname_required: "நிறுவனத்தின் பெயரை உள்ளிடவும்",
    employer_mobnum_required: "கைபேசி எண்ணை உள்ளிடவும்",
    employer_mobnum_valid: "செல்லுபடியாகும் கைபேசி எண்ணை உள்ளிடவும்",
    employer_telenum_required: "தொலைபேசி எண்ணை உள்ளிடவும்",
    employer_telenum_valid: "செல்லுபடியாகும் தொலைபேசி எண்ணை உள்ளிடவும்",
    employer_mobileNo_change_otp: "ஒடிபி",
    employer_mobileNo_change_change_mobile_number_button: "மின்னஞ்சல் முகவரி மாற்றவும்",
    employer_website_required: "வலைத்தள முகவரியை உள்ளிடவும்",
    employer_phone_num_check: "செல்லுபடியாகும் கைபேசி எண்ணை உள்ளிடவும்",
    employer_valid_salary: "குறைந்தபட்ச சம்பளம் குறைந்தபட்சம் 4 இலக்கங்களாக இருக்க வேண்டும்",
    employer_email_required: "மின்னஞ்சலை உள்ளிடவும்",
    employer_custom_plan: 'தனிப்பயன் திட்டம்',
    employer_password_change_header: "பாஸ்வேர்டை மாற்று",
    employer_password_change_old_password: "தற்போதைய பாஸ்வேர்ட்",
    employer_password_change_new_password: "புதிய பாஸ்வேர்ட்",
    employer_password_change_change_password_button: "பாஸ்வேர்டை மாற்று",
    employer_registration_user: "பயனரின் பெயர்",
    employer_registration_mobileno: "கைபேசி எண்",
    employer_registration_password: "பாஸ்வேர்ட்",
    employer_registration_otp: "ஒடிபி",
    employer_registration_otpbutton: "ஒடிபி பெறுக",
    employer_registration_checkbox: "நான் விதிமுறைகளையும், நிபந்தனைகளையும் ஒப்புக்கொள்கிறேன்",
    employer_registration_submitbutton: "பதிவுபெறுக",
    employer_forgetpassword_mobileno: "கைபேசி எண்",
    employer_forgetpassword_otp: "ஒடிபி",
    employer_forgetpassword_password: "பாஸ்வேர்ட்",
    employer_forgetpassword_submit: "சமர்ப்பிக்கவும்",
    employer_Setting_heading: "அமைப்புகள்",
    employer_Setting_changepassword: "பாஸ்வேர்டை மாற்று",
    employer_Setting_email: "மின்னஞ்சல் முகவரி மாற்றவும்",
    employer_Setting_Notification: "அறிவிப்பு",
    employer_Setting_Preferedlanguage: "விருப்பமான மொழி",
    employer_Setting_invisiblemode: "கண்ணுக்கு தெரியாத பயன்முறை",
    employer_Notification_invisiblemode: "கண்ணுக்கு தெரியாத பயன்முறை",
    employer_Notification_msg1: "உங்கள் கணக்கை கண்ணுக்கு தெரியாத பயன்முறையில் மாற்ற விரும்புகிறீர்களா ?",
    employer_Notification_msg2: "வேலைநாடுபவர்களால் உங்கள் கணக்கைப் பார்க்க முடியாது",
    employer_Notification_cancel: "ரத்துசெய்",
    employer_exit_cancel: "ரத்துசெய்",
    employer_Notification_submit: "சமர்ப்பிக்கவும்",
    employer_looking_more: "நீங்கள் அதிக எண்ணிக்கையிலான வேலை இடுகைகள் மற்றும் சுயவிவரங்களைத் தேடுகிறீர்களா ?",
    employer_Notification_heading: "அறிவிப்பு",
    employer_Notification_jobs: "வேலை தொடர்பான",
    employer_Notification_news: "செய்தி",
    employer_Notification_events: "நிகழ்வுகள்",
    employer_per_view_header: "சுயவிவரம்",
    employer_per_view_marital: "திருமண நிலை",
    employer_per_view_father_name: "தந்தையின் பெயர்",
    employer_per_view_spouse_name: "கணவன் அல்லது மனைவியின் பெயர்",
    employer_per_view_dob: "பிறந்த தேதி",
    employer_per_view_aadhar: "உதயம்/உத்யோக் ஆதார் எண்",
    employer_per_view_lang: "தெரிந்த மொழிகள்",
    employer_per_view_medical: "அறியப்பட்ட மருத்துவ வரலாறு",
    employer_per_view_differentlyabled: "மாற்றுத்திறனாளி",
    employer_per_contact: "தொடர்பு கொள்வதற்கான தகவல்",
    employer_per_edu: "கல்வி",
    employer_per_school: "பள்ளிப்படிப்பு",
    employer_per_after_school: "பள்ளிப்படிப்புக்குப் பிறகு",
    employer_per_skills: "வேலை பிரிவு,வேலை தலைப்பு & திறன்கள்",
    employer_per_ref: "என்னை பரிந்துரைக்கும் நபர்கள்",
    employer_per_pre: "என் வேலை விருப்பத்தேர்வுகள்",
    employer_Contactus_heading: "எங்களை தொடர்பு கொள்ள",
    employer_Contactus_website: "இணையதள முகவரி ",
    employer_Contactus_emailid: "மின்னஞ்சல் முகவரி",
    employer_Contactus_mobileno: "தொலைபேசி எண்",
    employer_Contactus_subject: "பொருள்",
    employer_Contactus__description: "விளக்கம்",
    employer_Contactus_button: "சமர்ப்பிக்கவும்",
    employer_logout_heading: "வெளியேறு",
    employer_logout_msg: "நீங்கள் நிச்சயமாக வெளியேற விரும்புகிறீர்களா ?",
    employer_logout_cancel: "ரத்துசெய்",
    employer_logout_logout: "வெளியேறு",
    employer_Search_heading: "பொது சுயவிவர தேடல்",
    employer_Recent_Search: "சமீபத்திய தேடல்",
    employer_Placeholder: "இருப்பிடம், வேலை பிரிவு, வேலை தலைப்பு ஆகியவற்றின் அடிப்படையில் தேடுங்கள்",
    employer_Placeholder1: "இருப்பிடம் அடிப்படையில் தேடுங்கள்",
    employer_gn_desc_job_desc: "வேலை விளக்கம்",
    employer_gn_desc_link: "இணைப்பு",
    employer_gn_desc_downloads: "பதிவிறக்கங்கள்",
    employer_gn_no_vacanies: "காலியிடங்களின் எண்ணிக்கை",
    employer_gn_Expiry: "கடைசி தேதி",
    employer_rl_freshers: "ஃப்ரெஷ்ஷர்",
    employer_rl_sortby: "வரிசைப்படுத்து",
    employer_rl_filterby: "வடிகட்டவும்",
    employer_rl_applyfilter: "வடிகட்டு",
    employer_rl_jobtype: "வேலை வகை",
    employer_rl_experience: "அனுபவம்",
    employer_rl_qualification: "கல்வி",
    employer_rl_skills: "திறன்கள்",
    employer_rl_description: "வேலை விளக்கம்",
    employer_description: "வேலை இடுகை நிராகரிப்பை தவிர்க்க, வேலை விளக்கத்தில் தொடர்பு தகவலை உள்ளிட வேண்டாம் ",
    employer_rl_grp: "வேலை குழு",
    employer_rl_maritial: "திருமண நிலை",
    employer_rl_gender: "பாலினம்",
    employer_rl_age: "வயது அளவுகோல்",
    employer_rl_differently: "மாற்றுத்திறனாளி",
    employer_sal_range: "ஊதிய வீதம்",
    employer_rl_any: "ஏதேனும்",
    employer_rl_no: "No",
    employer_r1_to: "முதல்",
    employer_r1_years: "ஆண்டுகள்",
    employer_rl_preferred: "விருப்பமான",
    employer_rl_jobtaluk: "வேலை தாலுகா",
    job_rl_jobtaluk: "வேலை நாடுநரின் வேலை விருப்ப இடம் - தாலுகா",
    employer_candidate_location: "வேலை நாடுநரின் இடம்",
    job_candidate_location: "வேலை நாடுநரின் வீட்டு இருப்பிடம் - மாவட்டம்",
    employer_candidate_taluk: "வேலை நாடுநரின் தாலுகா",
    job_candidate_taluk: "வேலை நாடுநரின் வீட்டு இருப்பிடம் - தாலுகா",
    employer_rl_lastdate: "கடைசி தேதி",
    employer_rl_no_of_opening: "காலியிடங்கள் எண்ணிக்கை",
    employer_rl_work: "வேலை நேரம்",
    employer_rl_lang: "மொழிகள்",
    employer_rl_aboutcompany: "நிறுவனத்தை பற்றி",
    employer_rl_turnover: "ஆண்டு விற்பனை",
    employer_rl_fac: "வழங்கப்படும் அம்சங்கள்",
    employer_rl_activity: "செயல்பாட்டு வகை",
    employer_rl_employee: "பணியாளர் எண்ணிக்கை",
    employer_rl_contact: "தொடர்புக்கு",
    employer_rl_address: "முகவரி",
    employer_rl_do_invitation: "இந்த விண்ணப்பதாரரை ஏற்க விரும்புகிறீர்களா?",
    employer_rl_decline: "மறுக்க",
    employer_rl_accept: "ஏற்றுக்கொள்",
    employer_r1_group: "வேலை குழு",
    employer_rl_Age: "வயது அளவுகோல்",
    employer_rl_school: "பள்ளி",
    employer_jp_repost: "மறுபதிவுசெய்",
    employer_jp_clone: "போலிகை",
    employer_editable: "(திருத்தக்கூடியது)",
    employer_noneditable: "(திருத்த முடியாதது)",
    employer_jp_on: "ஆன்",
    employer_jp_off: "ஆஃப்",
    employer_jp_filter: "வேலை பங்கை வடிகட்டவும்",
    employer_subscription_heading: "சந்தாக்கள்",
    employer_subscription_active: "செயலில் உள்ளது",
    employer_subscription_history: "வரலாறு",
    employer_subscription_subscribe: "சந்தா செலுத்த",
    employer_buy: "இப்போது வாங்க",
    employer_search_results: "பரிந்துரைக்கப்பட்ட முடிவுகள்",
    employer_sub_post: "வேலை இடுகை எண்ணிக்கை",
    employer_sub_vacancy: 'வேலை காலியிடங்களின் எண்ணிக்கை',
    employer_sub_viewed: "காணத்தக்க வேலை சுயவிவரங்களின் எண்ணிக்கை/வேலை இடுகை",
    employer_sub_applied: "விண்ணப்பிக்த்தக்க வேலை சுயவிவரங்களின் எண்ணிக்கை/வேலை இடுகை",
    employer_sub_invited: "அழைக்த்தக்க வேலை சுயவிவரங்களின் எண்ணிக்கை/வேலை இடுகை",
    employer_sub_shortlist: "தேர்ந்தெடுக்த்தக்க வேலை சுயவிவரங்களின் எண்ணிக்கை/வேலை இடுகை",
    employer_sub_total: "மொத்தம்",
    employer_sub_posted: "இடுகையிடப்பட்டது",
    employer_sub_available: "மீதமுள்ளது",
    employer_sub_active: "செயலில் உள்ளது",
    employer_sub_max: "வேலை இடுகை பதிவிட தேதியில் இருந்து செல்லுபடியாகும் நாட்களின் எண்ணிக்கை",
    employer_sub_exp: "காலாவதியாகும் நாள்",
    employer_sub_package_validity: "திட்ட காலக்கெடு",
    employer_sub_choose_subscription: "உங்கள் சந்தாவை தேர்வுசெய்க",
    employer_contact_msg: "தகவல்",
    employer_preference_heading: "என் பணியாளர் விருப்பத்தேர்வுகள்",
    employer_preference_job: "வேலை பிரிவு",
    employer_preference_Role: "வேலை தலைப்பு",
    employer_preference_type: "வேலை வகை",
    employer_preference_time: "சேருவதற்கான கால அவகாசம்",
    employer_preference_to: "முதல்",
    employer_preference_salary: "எதிர்பார்க்கப்படும் மாத சம்பளம்",
    employer_preference_button: "அடுத்து",
    employer_contact_us_head: "எங்களை தொடர்பு கொள்ள",
    employer_contact_us_post: "இடுகைகளின் எண்ணிக்கை",
    employer_contact_us_profile: "சுயவிவரங்களின் எண்ணிக்கை",
    employer_contact_us_vacancies: "காலியிடங்களின் எண்ணிக்கை",
    employer_contact_us_desc: "விளக்கம்",
    employer_contact_us_submit: "சமர்ப்பிக்கவும்",
    employer_abou_com_com: "நிறுவனத்தை பற்றி",
    employer_abou_com_attach: "இணைப்பு",
    employer_comapny_photos: "நிறுவனத்தின் புகைப்படங்கள்",
    employer_abou_com_gallery: "குறிப்பு: அதிகபட்சம் 3 படங்கள் அனுமதிக்கப்படும்",
    employer_post_job_header: "வேலை இடுகையிடவும்",
    employer_post_job_header1: "வேலை இடுகையிடவும்",
    employer_post_job_details: "வேலை விவரங்கள்",
    employer_post_job_company: "கிளை பெயர்",
    employer_post_job_hours: "மணி",
    employer_post_job_minutes: "நிமிடங்கள்",
    employer_Post_job_function: "வேலை பிரிவு",
    employer_post_job_Role: "வேலை தலைப்பு",
    employer_post_job_skills: "திறன்கள்",
    employer_post_job_desc: "விளக்கம்",
    employer_post_job_exp: "அனுபவம்",
    employer_post_job_quali: "தகுதி",
    employer_post_job_jobtype: "வேலை வகை",
    employer_post_job_noofopen: "காலியிடங்கள் எண்ணிக்கை",
    shift_name: 'பகுதிநேர வேலை பெயர்',
    shift_txt: 'பகுதிநேர வேலை',
    start_txt: 'தொடக்கம்',
    end_txt: 'முடிவு',
    shift_name1: 'ஷிப்ட்',
    start_txt1: 'தொடக்க நேரம்',
    end_txt1: 'இறுதி நேரம்',
    shift_error: 'பகுதிநேர வேலை பெயரை தேர்ந்தெடுக்கவும்',
    starthours_error: 'தொடக்க மணி நேரத்தைத் தேர்ந்தெடுக்கவும்',
    startminutes_error: 'தொடக்க நிமிடங்களை தேர்ந்தெடுக்கவும்',
    endhours_error: 'இறுதி மணி நேரத்தைத் தேர்ந்தெடுக்கவும்',
    endminutes_error: 'இறுதி நிமிடங்களை தேர்ந்தெடுக்கவும்',
    fromtimetext_error: 'தொடக்க நேரத்தைத் தேர்ந்தெடுக்கவும்',
    totimetext_error: 'இறுதி நேரத்தைத் தேர்ந்தெடுக்கவும்',
    sametime_error: 'சரியான நேரத்தைத் தேர்ந்தெடுக்கவும்',
    shiftttime_exits: 'நேரம் ஏற்கனவே உள்ளது',
    shiftname_exits: 'பகுதிநேர வேலை பெயர் ஏற்கனவே உள்ளது',
    employer_post_job_work: "வேலை நேரம்",
    add_post_job_work: "வேலை நேரத்தைச் சேர்க்கவும்",
    plaseadd_post_job_work: "தயவு செய்து வேலை நேரத்தைச் சேர்க்கவும்",
    employer_limit_cross_head_jobfunction: "எச்சரிக்கை",
    employer_limit_cross_head_joblocation: "எச்சரிக்கை",
    employer_limit_cross_head_jobfunction_error: "நீங்கள் அதிகபட்சம் தேர்வு செய்யும் எண்ணிக்கை",
    employer_limit_cross_head_joblocation_error: "நீங்கள் அதிகபட்சம் தேர்வு செய்யும் எண்ணிக்கை",
    employer_abused_content: 'உங்கள் கணக்கு தடை செய்யப்பட்டுள்ளது' + '\n' + 'ஜபான்யா நிர்வாகியை தொடர்பு கொள்ளவும்',
    employer_login_invisible: "உங்கள் கணக்கு கண்ணுக்கு தெரியாத பயன்முறையில் உள்ளது.",
    employer_login_invisible_active: "இப்போது மீண்டும் செயல்படுத்த விரும்புகிறீர்களா?",
    employer_login_yes: "ஆம்",
    employer_login_no: "இல்லை",
    employer_accout_reactivate: "கணக்கை மீண்டும் செயல்படுத்து",
    employer_account_otp: "ஒடிபி உங்கள் பதிவு செய்யப்பட்ட மின்னஞ்சலுக்கு அனுப்பப்படுகிறது",
    employer_resend_otp_verify: "சரிபார்க்கவும்",
    employer_otp_sent: "ஓடிபி வெற்றிகரமாக அனுப்பபட்டது",
    employer_otp_message: "தவறான ஓடிபி",
    employer_otp_verified: 'வெற்றிகரமாக சரிபார்க்கப்பட்டது',
    employer_contactus_msg_error: "தகவலை உள்ளிடவும்",
    thank_contactus: 'எங்களை தொடர்பு கொண்டதற்கு நன்றி',
    employer_saved_success: "வெற்றிகரமாக சேமிக்கப்பட்டுள்ளது ",
    employer_deleted_success: "வெற்றிகரமாக அழிக்கப்பட்டது",
    employer_deleted_error: "வேலையளிப்போர் குறைந்தபட்சம் ஒரு கிளையாவது வைத்திருக்க வேண்டும்",
    employer_updated_success: "வெற்றிகரமாக புதுப்பிக்கப்பட்டது",
    employer_already_aproved: "வேலை இடுகை ஏற்கனவே அங்கீகரிக்கப்பட்டுள்ளது",
    employer_repost_success: "வெற்றிகரமாக மறுபதிவு செய்யப்பட்டது",
    employer_clone_success: "வெற்றிகரமாக போலிகை செய்யப்பட்டது",
    employer_abouse_success: "துஷ்பிரயோகம் என வெற்றிகரமாக புகாரளிக்கப்பட்டது",
    employer_login_success: "வெற்றிகரமாக உள்நுழைக",
    employer_abouse_error: "ஏற்கனவே துஷ்பிரயோகம் செய்யப்பட்டுள்ளது",
    employer_invite_success: "வெற்றிகரமாக அழைக்கபட்டுள்ளீர்கள்",
    employer_invite_error: "ஏற்கனவே அழைக்கப்பட்டது",
    employer_alrdy_applied: "ஏற்கனவே விண்ணப்பிக்கப்பட்டது",
    employer_invite_count_exceeds: "அழைப்பு வரம்பு மீறப்பட்டது",
    employer_already_reject_job: "வேலை நாடுநர் இந்த வேலையை நிராகரித்துவிட்டார் ",
    employer_you_reject_this_job: "நீங்கள் இந்த வேலையை நிராகரித்து உள்ளீர்கள்",
    employer_repost: "மறுபதிவுசெய்",
    employer_err_job_function_required: 'வேலை பிரிவை தேர்ந்தெடுக்கவும்',
    employer_err_preference_joblocation_required: 'தயவுசெய்து வேலை இருப்பிடத்தைத் தேர்ந்தெடுக்கவும்',
    employer_err_preference_employementtype_empty: "",
    employer_err_preference_jobrole_required: 'தயவுசெய்து வேலை தலைப்பைத் தேர்ந்தெடுக்கவும்',
    employer_id_psw_incorrect: "செல்லுபடியாகும் மின்னஞ்சல் மற்றும் பாஸ்வேர்டை உள்ளிடவும்",
    employer_waiting_approval: "உங்கள் பதிவு ஒப்புதலுக்காக காத்திருக்கிறது.\n சந்தேகம் இருப்பின் ஜபான்யா தொடர்பு கொள்ளவும்.",
    employer_verification_pending: "மின்னஞ்சல் சரிபார்ப்பு நிலுவையில் உள்ளது.\n உங்கள் மின்னஞ்சல் பெட்டியை சரிபார்க்கவும்.",
    employer_deactivated_acc: "கணக்கு செயலிழக்கப்பட்டது",
    employer_employer_activated: "வெற்றிகரமாக செயல்படுத்தப்பட்டுள்ளது",
    employer_password_old_check: "பழைய பாஸ்வேர்ட் தவறானது",
    employer_psw_changed: 'பாஸ்வேர்ட் வெற்றிகரமாக மாற்றப்பட்டுள்ளது ',
    employer_post_edit_job_header: "வேலை இடுகையை தொகுத்து அமை",
    employer_branch_required: "கிளை பெயரை தேர்ந்தெடுக்கவும்",
    employer_job_function_required: "வேலை பிரிவை தேர்ந்தெடுக்கவும்",
    employer_job_role_required: "வேலை தலைப்பைத் தேர்ந்தெடுக்கவும்",
    employer_skills_error_required: "திறன்களை தேர்ந்தெடுக்கவும்",
    employer_skills_empty: '',
    employer_description_required: "வேலை விளக்கத்தை உள்ளிடவும்",
    employer_education_required: "கல்வி வகையை தேர்ந்தெடுக்கவும்",
    employer_qualification_required: "தகுதியை தேர்ந்தெடுக்கவும்",
    employer_specialization_required: "நிபுணத்துவத்தை தேர்ந்தெடுக்கவும்",
    employer_education_empty: "",
    employer_experience_required: "குறைந்தபட்ச அனுபவத்தை உள்ளிடவும் ",
    employer_experiencefrom_valid: "செல்லுபடியாகும் குறைந்தபட்ச அனுபவத்தை உள்ளிடவும் ",
    employer_experienceTo_required: "அதிகபட்ச அனுபவத்தை உள்ளிடவும்",
    employer_experienceTo_valid: "செல்லுபடியாகும் அதிகபட்ச அனுபவத்தை உள்ளிடவும்",
    employer_experience_fresher_sepcific_required: "அனுபவத்தை தேர்வுசெய்க",
    employer_job_type_required: "வேலை வகையை தேர்ந்தெடுக்கவும்",
    select_job_type: "வேலை வகையை தேர்ந்தெடுக்கவும்",
    employer_noofopening_required: "காலியிடங்களின் எண்ணிக்கையை உள்ளிடவும்",
    employer_worktime_required: "வேலை நேரத்தை உள்ளிடவும்",
    employer_facility_error_required: "வசதியை தேர்ந்தெடுக்கவும்",
    employer_contact_person_required: "தொடர்பு கொள்ள வேண்டிய நபரின் பெயரை உள்ளிடவும்",
    employer_marital_required: "திருமண நிலையை தேர்வுசெய்க",
    employer_age_required: "வயது அளவுகோலை தேர்ந்தெடுக்கவும்",
    employer_gender_required: "பாலினத்தை தேர்வுசெய்க",
    employer_contact_designation_required: "பதவியை உள்ளிடவும்",
    employer_state_required: "மாநிலத்தை தேர்வுசெய்க",
    employer_district_required: "மாவட்டத்தை தேர்வுசெய்க",
    employer_taluk_required: "தாலுக்காவை தேர்வு செய்யவும்",
    employer_contact_email_required: "மின்னஞ்சலை உள்ளிடவும்",
    employer_contact_primary_mob_required: "கைபேசி எண்ணை உள்ளிடவும்",
    employer_contact_secondary_mob_required: "மற்றொரு கைபேசி எண்ணை உள்ளிடவும்",
    employer_contact_address_required: "தொடர்பு முகவரியை உள்ளிடவும்",
    employer_company_required: "நிறுவனம் பற்றிய விளக்கத்தை உள்ளிடவும்",
    employer_turn_over_required: "ஆண்டு விற்பனை தேர்ந்தெடுக்கவும்",
    employer_noof_employee_required: "வேலையாட்களின் எண்ணிக்கையை உள்ளிடவும்",
    employer_industry_required: "தொழிற்துறையை தேர்ந்தெடுக்கவும்",
    employer_employertype_required: "தயவுசெய்து வேலையளிப்போர் தேர்ந்தெடுக்கவும்",
    employer_companytypename_required: "நிறுவன வகை பெயரைத் தேர்ந்தெடுக்கவும்",
    employer_city_required: "டவுன் / நகரத்தின் பெயரை தேர்ந்தெடுக்கவும்",
    employer_taluk_required: "தாலுக்காவை தேர்ந்தெடுக்கவும்",
    employer_applied_on: "விண்ணப்பிக்கப்பட்ட நாள் ",
    employer_invited_on: "அழைக்கப்பட்ட நாள் ",
    employer_accepted_on: "ஏற்றுக்கொள்ளப்பட்ட நாள் ",
    employer_rejected_on: "நிராகரிக்கப்பட்ட நாள் ",
    employer_post_job_factlities: "வழங்கப்படும் வசதிகள்",
    employer_post_job_validate: "காலக்கெடு தேதி",
    employer_post_job_preferences: "என் பணியாளர் விருப்பத்தேர்வுகள்",
    employer_job_details: "வேலை விவரங்கள்",
    employer_post_job_jobgroup: "வேலை குழு",
    employer_post_job_martial: "திருமண நிலை",
    employer_post_job_gender: "பாலினம்",
    employer_post_job_age: "வயது அளவுகோல்",
    employer_post_job_da: "மாற்றுத்திறனாளி",
    employer_post_job_salary: "ஊதிய வீதம் ஒரு மாதத்திற்கு",
    employer_post_job_prefered: "வேலைநாடுநரின் வீட்டு இடம்",
    employer_post_job_prefered1: "வேலைநாடுநரின் வேலை விருப்ப இடம்",
    employer_post_job_hr: "மனிதவள தொடர்பு விவரங்கள்",
    employer_post_job_email: "மின்னஞ்சல் முகவரி",
    employer_post_job_mn: "தொலைபேசி எண்",
    employer_post_job_address: "முகவரி",
    employer_post_job_specific: "குறிப்பிட்ட",
    employer_post_job_within: "ஏதேனும்",
    employer_post_job_contactperson: "தொடர்பு கொள்ள வேண்டிய நபர்",
    employer_post_job_desg: "பதவி",
    employer_branch_head: "கிளை விவரங்கள்",
    employer_branch_branch: "கிளை பெயர்",
    employer_branch_contact: "தொடர்பு கொள்ள வேண்டிய நபர் ",
    employer_branch_state: "மாநிலம்",
    employer_branch_district: "மாவட்டம்",
    employer_branchtype: "Branch Type",
    employer_branch_city: "டவுன் / நகரத்தின் பெயர்",
    employer_branch_taluk: "தாலுகா",
    employer_branch_pincode: "அஞ்சல் குறியீடு",
    employer_branch_address: "நிறுவனத்தின் முகவரி",
    employer_profile_edit: "சுயவிவரங்களை புதுப்பிக்கவும்",
    employer_branch_mobile: "தொலைபேசி எண்",
    employer_branch_telephone: "தொலைபேசி எண்",
    employer_branch_head1: "கிளை விவரங்களை தொகுத்து அமை",
    employer_branch_head2: "கிளை விவரங்களை சேர்க்கவும்",
    employer_abou_com_turn: "ஆண்டு விற்பனை",
    employer_abou_com_No: "வேலையாட்களின் எண்ணிக்கை",
    employer_check_log: "தொடர்பு முகவரி நிறுவனத்தின் பதிவு செய்யப்பட்ட \n முகவரி போன்றது",
    employer_contactus_subject_error: "தலைப்பை தேர்ந்தெடுக்கவும்",
    employer_contactus_post_error: "இடுகைகளின் எண்ணிக்கை உள்ளிடவும்",
    employer_contactus_profile_error: "தயவுசெய்து சுயவிவரங்கள் எண்ணிக்கை உள்ளிடவும்",
    employer_contactus_vacancies_error: "காலியிடங்களின் எண்ணிக்கை உள்ளிடவும்",
    employer_contactus_desc_error: "விளக்கங்களை உள்ளிடவும்",
    employer_username_required: "மின்னஞ்சலை உள்ளிடவும்",
    employer_company_name_errormsg: "நிறுவனத்தின் பெயரை உள்ளிடவும்",
    employer_company_name_errormsg_validate: "நிறுவனத்தின் பெயர் 50 எழுத்துக்களுக்கு குறைவாக இருக்க வேண்டும்",
    employer_company_type_errormsg: "நிறுவனத்தின் வகையை தேர்ந்தெடுக்கவும்",
    employer_prefer_ok: "சரி",
    employer_activity_type_errormsg: "செயல்பாட்டு வகையை தேர்ந்தெடுக்கவும்",
    employer_industry_type_errormsg: "தொழில் வகையைத் தேர்ந்தெடுக்கவும்",
    employer_type_errormsg: "வேலையளிப்போர் வகையைத் தேர்ந்தெடுக்கவும்",
    employer_state_errormsg: "மாநிலத்தை தேர்ந்தெடுக்கவும்",
    employer_district_errormsg: "மாவட்டத்தை தேர்ந்தெடுக்கவும்",
    employer_city_errormsg: "டவுன் / நகரத்தின் பெயரை தேர்ந்தெடுக்கவும்",
    employer_taluk_errormsg: "தாலுக்கை தேர்ந்தெடுக்கவும்",
    employer_registered_email_errormsg: "பதிவுசெய்த மின்னஞ்சலை உள்ளிடவும்",
    employer_valid_email_errormsg: "செல்லுபடியாகும் மின்னஞ்சல் முகவரியை உள்ளிடவும்",
    employer_alreadyregistered_email_errormsg: "மின்னஞ்சல் ஏற்கனவே பதிவுசெய்யப்பட்டுள்ளது",
    employer_passsword_errormsg: "பாஸ்வேர்டை உள்ளிடவும்",
    employer_passsword_length_errormsg: "குறைந்தபட்சம் 6 எழுத்துகள் பாஸ்வேர்டை உள்ளிடவும்",
    employer_pincode_errormsg: "அஞ்சல் குறியீடை உள்ளிடவும்",
    employer_pincode_valid_errormsg: "செல்லுபடியாகும் அஞ்சல் குறியீடை உள்ளிடவும்",
    employer_company_address_errormsg: "நிறுவனத்தின் முகவரியை உள்ளிடவும்",
    employer_mobile_number_errormsg: "கைபேசி எண்ணை உள்ளிடவும்",
    employer_mobileno_exists_errormsg: "கைபேசி எண். ஏற்கனவே இருக்கிறது",
    employer_mobile_numbervalid_errormsg: "செல்லுபடியாகும் கைபேசி எண்ணை உள்ளிடவும்",
    employer_telephone_number_errormsg: "தொலைபேசி எண்ணை உள்ளிடவும்",
    employer_telephone_number_Valid_errormsg: "செல்லுபடியாகும் தொலைபேசி எண்ணை உள்ளிடவும்",
    employer_website_address_errormsg: "வலைத்தள முகவரியை உள்ளிடவும்",
    employer_website_address_errormsg_valid: "செல்லுபடியாகும் வலைத்தளத்தை உள்ளிடவும்",
    employer_contact_person_errormsg: "தொடர்பு கொள்ள வேண்டிய நபரின் பெயரை உள்ளிடவும்",
    employer_designation_errormsg: "பதவியை உள்ளிடவும்",
    employer_knowntype_errormsg: "பெஸ்ட்ஜோப்களைப் பற்றி உங்களுக்கு எப்படித் தெரியும் என்பதைத் தேர்ந்தெடுக்கவும்",
    employer_log_others: "தகவலை வழங்கவும்",
    employer_gstin_errormsg: "ஜிஎஸ்டி எண் எண்ணை உள்ளிடவும்",
    employer_gstin_valid_errormsg: "செல்லுபடியாகும் ஜிஎஸ்டி எண் எண்ணை உள்ளிடவும்",
    employer_alreadygstin_errormsg: "ஜிஎஸ்டி எண் ஏற்கனவே உள்ளது",
    employer_alreadypan_errormsg: "பான் எண் ஏற்கனவே உள்ளது",
    employer_alreadyaadhar_errormsg: "ஆதார் எண் ஏற்கனவே உள்ளது",
    employer_pan_errormsg: "பான் எண்ணை உள்ளிடவும்",
    employer_pan_valid_errormsg: "செல்லுபடியாகும் பான் எண்ணை உள்ளிடவும்",
    employer_aadhar_errormsg: "உதயம்/உத்யோக் ஆதார் எண்ணை உள்ளிடவும்",
    employer_aadhar_prof_errormsg: "ஆதார் எண்ணை உள்ளிடவும்",
    employer_aadhar_valid_errormsg: "செல்லுபடியாகும் உதயம்/உத்யோக் ஆதார் எண்ணை உள்ளிடவும்",
    employer_aadhar_prof_valid_errormsg: "செல்லுபடியாகும் ஆதார் எண்ணை உள்ளிடவும்",
    employer_OTP_valid_errormsg: "சரியான OTP ஐ உள்ளிடவும்",
    employer_facilities_error: "வழங்கப்படும் வசதியை தேர்ந்தெடுக்கவும்",
    employer_password_required: "பாஸ்வேர்டை உள்ளிடவும்",
    employer_emailid_format: "செல்லுபடியாகும் மின்னஞ்சலை உள்ளிடவும்",
    employer_reg_email_error: "பதிவுசெய்த மின்னஞ்சலை உள்ளிடவும்",
    employer_mail_exists: "மின்னஞ்சல் ஏற்கனவே உள்ளது",
    employer_pass_validate1: "பாஸ்வேர்டை உள்ளிடவும்",
    employer_pass_validate5: "பாஸ்வேர்டை உள்ளிடவும்",
    employer_pass_validate1_new: "பாஸ்வேர்டை உள்ளிடவும்",
    employer_pass_validate5_new: "பாஸ்வேர்டை உள்ளிடவும்",
    employer_contact_company_address_errormsg: "முகவரியை உள்ளிடவும்",
    employer_pass_validate7: "தவறான பாஸ்வேர்ட்",
    employer_pass_validate2_new: "பாஸ்வேர்ட் குறைந்தபட்சம் 6 எழுத்துகளாக இருக்க வேண்டும்",
    employer_pwd_changed_success: "பாஸ்வேர்ட் வெற்றிகரமாக மாற்றப்பட்டுள்ளது ",
    employer_Subscribed_Successfully: 'வெற்றிகரமாக சந்தா பெறப்பட்டுள்ளது ',
    user_not_found: 'உங்கள் கணக்கு செயலில் இல்லை',
    netinfo_heading: "தகவல்",
    netinfo_subheading_reachable: "உங்கள் இணைய இணைப்பை சரிபார்க்கவும்",
    netinfo_subheading: "உங்கள் இணைய இணைப்பை சரிபார்க்கவும்",
    netinfo_subheading2: "ஏதோ தவறு நடந்துவிட்டது",
    netinfo_subheading_server: "சேவையகத்தை அடைய முடியவில்லை. தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
    days: 'நாட்கள்',
    Resume: 'தற்குறிப்பு',
    PDF: "பிடிஎப்",
    PDF_Viewer: 'பிடிஎப் பார்வையாளர்',
    Latitude: "அட்சரேகை:",
    Longitude: "தீர்க்கரேகை:",
    Mark: "குறி",
    Invite_Now: 'இப்போது அழைக்கவும்',
    Invite_Now_qus: 'நீங்கள் நிச்சயமாக அழைக்க விரும்புகிறீர்களா?',
    Exact_match: 'கச்சிதமான பொருத்தம்',
    Similar_match: 'ஒத்த பொருத்தம்',
    select_education: 'கல்வி வகையைத் தேர்ந்தெடுக்கவும்',
    Select_Qualification: 'தகுதியைத் தேர்ந்தெடுக்கவும்',
    Select_Specialization: 'சிறப்பு தேர்வு',
    Select_facilities_offered: 'வழங்கப்பட்ட வசதிகளைத் தேர்ந்தெடுக்கவும்',
    Select_district: 'மாவட்டத்தைத் தேர்ந்தெடுக்கவும்',
    Select_taluk: "தாலுக்கைத் தேர்ந்தெடுக்கவும்",
    app_share_employee: "இந்தியாவின் நம்பர் 1 பணியாளர் தேடல் பயன்பாட்டை இப்போது பதிவிறக்கவும்\n",
    app_share_employer: "இந்தியாவின் நம்பர் 1 வேலை தேடல் பயன்பாட்டை இப்போது பதிவிறக்கவும் \n",
    not_auth: "இந்த உள்ளடக்கத்தைக் காண உங்களுக்கு அங்கீகாரம் இல்லை",
    emp_footer_size: 8,
    textfont: 14,
    Pending: 'நிலுவையில் உள்ளது',
    placeholder_jobfunction: 'வேலை பிரிவு மூலம் தேடுங்கள்',
    placeholder_jobrole: 'வேலை தலைப்பு மூலம் தேடுங்கள்',
    placeholder_employmenttype: 'வேலைவாய்ப்பு வகை மூலம் தேடுங்கள்',
    placeholder_district: 'மாவட்ட வாரியாக தேடுங்கள்',
    placeholder_taluk: 'தாலுகா வாரியாகத் தேடுங்கள்',
    placeholder_faclities: 'வழங்கப்படும் வசதிகள் மூலம் தேடுங்கள்',
    placeholder_skills: 'திறன்களால் தேடுங்கள்',
    placeholder_Education: 'கல்வி வகை மூலம் தேடுங்கள்',
    placeholder_Qualification: 'தகுதி மூலம் தேடுங்கள்',
    placeholder_Specialization: 'சிறப்பு மூலம் தேடுங்கள்',
    dismissall_qus: 'நிச்சயமாக அனைத்தையும் நிராகரிக்க விரும்புகிறீர்களா?',
    deleteactiveJobTitle: 'உங்களின் தற்போதைய பணிப் பொறுப்பை நீக்க முயற்சிக்கிறீர்கள். நீக்குவதற்கு முன் உங்களின் தற்போதைய பணிப் பொறுப்பைத் தேர்வு செய்யவும்.',
    deleteJobTitle: 'இந்த அனுபவத்தை நிச்சயமாக நீக்க விரும்புகிறீர்களா?',
    Search_here: 'இங்கே தேடவும்',
    employer_reg_msg: 'எங்களுடன் பதிவு செய்ததற்கு நன்றி.\n  உங்கள் மின்னஞ்சலை சரிபார்க்க இன்பாக்ஸ்/ஸ்பாம் பார்க்கவும்.',
    youtube: 'வலைஒளி',
    facebook: 'முகநூல்',
    telegram: 'தந்தி',
    Facebook_Media_link: "முகநூல் ",
    Instagram_Media_link: "தந்தி ",
    Youtube_Media_link: "வலைஒளி ",
    Social_Media_link: "சமூக ஊடக இணைப்புகள்",
    linkedin_Media_link: "LinkedIn ",
    Twitter_Media_link: "ட்விட்டர் ",
    employer_preference_word: "விருப்பத்தேர்வுகளை தேர்ந்தெடுக்கும்போது, மிகவும் திட்டவட்டமாக இருங்கள், அதன்முலம்தான் உங்களுக்கு கட்சிதமான பொருத்தமுடைய பணியாளர்களின் சுயவிவரங்களைப் பெறுவீர்கள்.",
    employee_preference_word: "விருப்பத்தேர்வுகளை தேர்ந்தெடுக்கும்போது, மிகவும் திட்டவட்டமாக இருங்கள், அதன்முலம்தான் உங்களுக்கு கட்சிதமான வேலை அறிவிப்புகள் மற்றும் வேலை பரிந்துரைகளை பெறுவீர்கள்",
    employer_gallery_word: "பதிவேற்றும் படங்களைத் தேர்ந்தெடுக்கவும்",
    from_age: 'ஆரம்ப வயது வரம்பு',
    to_age: 'இறுதி வயது வரம்பு',
    from_sal: 'சம்பள ஆரம்ப தொகை',
    to_sal: 'சம்பள இறுதி தொகை',
    from_exp: 'அனுபவத்திலிருந்து',
    to_exp: 'அனுபவிக்க',
    Invite: 'அழைக்கவும்',
    aadhar_note: '*உங்கள் சுயவிவரத்தின் நம்பகத்தன்மையை உறுதிப்படுத்த',
    reset_filter: "வடிகட்டியை அழி",
    clear_filter: 'வடிகட்டியை அழி',
    as_per_settings: 'உங்கள் பணியாளர் விருப்பத்தேர்வு அமைப்புகளின்படி',
    upto: 'அதிகபட்சம் ',
    employer_looking: "வலை பயன்பாட்டைத் தேடுகிறீர்களா?",
    employer_visit_portal: "தயவுசெய்து எங்களைப் பார்வையிடவும் ",
    incomplete_profile: "உங்கள் சுயவிவரம் முழுமையடையவில்லை.",
    lead_incomplete_profile_header: "சுயவிவரத்தின் நிலை",
    lead_incomplete_profile: "உங்கள் சுயவிவரம் ",
    lead_incomplete_profile1: " பூர்த்தி செய்துள்ளீர்கள். ",
    lead_incomplete_profile2: " பூர்த்தி செய்து சிறந்த வேலைகளை பெறுங்கள்.",
    lead_incomplete_profile3: " பூர்த்தி செய்யவும் ",
    lead_incomplete_profile4: " பூர்த்தி செய்துள்ளீர்கள். இந்த வேலைக்கு விண்ணப்பிக்க அதை ",
    total_vacancies: 'மொத்த வேலை காலியிடங்கள் : ',
    jobpost_approval_info: 'தயவுசெய்து கவனிக்கவும்: உங்கள் வேலை இடுகை அங்கீகரிக்கப்பட்டு இடுகையிடப்பட்டபின் மாற்றி அமைக்க முடியாது',
    jobpost_tab_search_hint: 'பணியாளர்களின் சுயவிவரங்களை தேட தேடல் ஐகானை கிளிக் செய்யவும்',
    jobpostlist_hint: "உங்கள் பட்டியலிடப்பட்ட வேலை இடுகையை கிளிக் செய்து, தொடர்புடைய பணியாளர்கள் விவரங்களைப் பார்க்கவும்",
    my_pref_cand: 'எனது விருப்பமான பணியாளர்கள்',
    eligible_cand: 'தகுதியான வேட்பாளர்கள்',
    invited_cand: 'அழைக்கப்பட்ட வேட்பாளர்கள்',
    applied_cand: "விண்ணப்பித்த விண்ணப்பதாரர்கள்",
    invited_now: "இப்போது அழைக்கவும்",
    posted_jobs: "மொத்த இடுகையிடப்பட்ட வேலைகள்",
    valid_link: "சரியான இணைப்பை உள்ளிடவும்",
    mobile_not_reg: "மொபைல் எண் பதிவு செய்யப்படவில்லை",
    keep_document_ready: "இந்த ஆவண எண்களைத் தயாராக வைத்திருங்கள் \n #கம்பெனி ஜிஎஸ்டி #கம்பெனி பான் #உதயம்/உத்யோக் ஆதார் \n பதிவு செய்வதற்கு \n JPG/JPEG/PDF வடிவத்தில் வைத்திருக்கவும்",
    incomplete_profile_employer: "அதிக ஊழியர்களை ஈர்க்க உங்கள் சுயவிவரத்தை 100% பூர்த்தி செய்யவும்",
    jobpost_posted_on: "வெளியிடப்பட்ட  நாள்:",
    profilesummary_title: "கிடைக்கும் சுயவிவர சுருக்கம்",
    gst_applicable: "* ஜிஎஸ்டி பொருந்தும்",
    subscription_failed: "சந்தா தோல்வியுற்றது",
    share_gnjob1: "ஜபான்யா - அரசு வேலை அறிவிப்பு",
    share_gnjob2: "முழு விவரங்களையும் காண ஜபான்யா ஆண்ட்ராய்டு செயலியில் உள்நுழைக",
    share_job1: "ஜபான்யா - வேலை அறிவிப்பு",
    share_job2: "முழு விவரங்களையும் காண ஜபான்யா ஆண்ட்ராய்டு செயலியில் உள்நுழைக",
    share_open: "காலியிடங்கள்",
    notification_on: "ஆன்",
    notification_off: "ஆஃப்",
    please_upload_profile: "தயவுசெய்து சுயவிவரப் படத்தை பதிவேற்றவும்",
    subscriptions_buy_notes: "உங்கள் விருப்ப வேலை நாடுநர் பட்டியலில் போதுமான அளவு தகுதியுள்ள போட்டியாளர்களின் விவரங்களை பார்த்த பிறகு சந்தா செலுத்துங்கள்",
    download_text: "பதிவிறக்கம் முடிந்தது",
    full_complete_profile: "உங்கள் சுயவிவரத்தை 100% முடித்த பிறகு",
    full_complete_profile1: "உங்கள் நேர்காணலுக்கான தற்போதைய தேதி மற்றும் நேரத்துடன் ரெஸ்யூமை பதிவிறக்கம் செய்யலாம்",
    total_no_profile: "மொத்த சுயவிவரங்களின் எண்ணிக்கை",
    total_job_list: "மொத்த வேலைகள் எண்ணிக்கை : ",
    exp_year_str: "ஆண்டுகள்",
    exp_year_str1: "ஆண்டு",
    exp_month_str: "மாதங்கள்",
    exp_month_str1: "மாதம்",
    experience_mnt1: "மாதங்கள்",
    experience_years1: "ஆண்டுகள்",
    abuse_txt: 'புகார் செய்',
    currently_pursuing_word: "தற்போது தொடர்கிறது",
    currently_pursuing: "தற்போது பயில்கிறேன்",
    employer_profile_txt: ' உங்கள் சுயவிவரத்தை சுவாரஸ்யமாக்க உங்கள் நிறுவனத்தின் லோகோவை பதிவேற்றவும் !',
    employer_gallery_txt: 'உங்கள் சுயவிவரத்தை சுவாரஸ்யமாக்க உங்கள் நிறுவனத்தின் புகைப்படங்களை  பதிவேற்றவும் !',
    employee_profile_View: 'உங்கள் சுயவிவரத்தை சுவாரஸ்யமாக்க புகைப்படத்தை பதிவேற்றவும் !',
    text_viewed: 'பார்க்கப்பட்டது',
    Match_pro: "பொருந்தும் சுயவிவரங்கள்",
    employer_info_errormsg: "தகவலை வழங்கவும்",
    sslc_first: 'தயவு செய்து எஸ்.எஸ்.எல்.சி சேர்க்கவும்',
    employer_usertype_type_errormsg: "டெலிகாலர்களைத் தேர்ந்தெடுக்கவும்",
    Employee_known_text: "தேர்ந்தெடுக்கவும்",
    Telecallers: "டெலிகாலர்கள்",
    Referal: "பரிந்துரைக்கும் நபர்",
    Rejected_job: "இந்த வேலை நிராகரிக்கப்பட்டது",
    Document_type: "ஆவண வகை",
    Additional_Documents: "கூடுதல் ஆவணங்கள்",
    doument_error: "குறைந்தது மூன்று ஆவணங்களை பதிவேற்றவும்",
    doument_error_one: "Please Upload atleast one document from GSTIN/PAN/Aadhar",
    Document_file: "கோப்பை பதிவேற்றவும்",
    Reference_number: "குறிப்பு எண்",
    District: 'மாவட்டம்',
    Taluk: 'தாலுகா',
    City: "டவுன் / நகரம்",
    File: "விருப்பங்கள்",
    Reference_number_error: "குறிப்பு எண்ணை உள்ளிடவும்",
    aadharAlphaNumeric: 'Enter valid Udyam/Udyog Aadhar number',
    flash_jobs: 'Flash Jobs',
    any_state: " Any State",
    any_district: " Any District",
    filter_any_district: "ஏதேனும்",
    filter_any_taluk: "ஏதேனும்",
    any_taluk: " Any Taluk",
    disclaimer_class: 'தயவுசெய்து எங்கள் ',
    disclaimer_class1: 'மறுப்பு விதியைப் ',
    disclaimer_class2: ' படிக்கவும்',
    disclaimer_class3: 'அந்தந்த அரசு நிறுவனங்களின் ',
    disclaimer_class4: 'இணையதளங்களில் இருந்து பெறப்பட்டது.',
    Reference_number_error_valid: "சரியான குறிப்பு எண்ணை உள்ளிடவும்",
    last_login: "கடைசி உள்நுழைவு",
    employer_hours_required: "நேரத்தைத் தேர்ந்தெடுக்கவும்",
    employer_minutes_required: "நிமிடங்களைத் தேர்ந்தெடுக்கவும்",
    filesize_restriction: "கோப்பு அளவு 2MB க்கும் குறைவாக இருக்க வேண்டும்",
    employee_search: "பொது தேடல்",
    registration_fee: "பதிவு கட்டணம்",
    congratulations: "வாழ்த்துக்கள் !",
    offer_msg1: "நீங்கள் உங்கள் சுயவிவரத்தை பூர்த்தி செய்து",
    offer_msg2: "சதவீதம் தள்ளுபடி கிடைக்க தகுதி பெற்றுள்ளீர்கள்",
    gstpercentage: "*18% ஜிஎஸ்டி கட்டணம் தனி",
    checkout_msg1: "உங்கள் சுயவிவரத்தை பூர்த்தி செய்து",
    checkout_msg2: "சதவீதம் தள்ளுபடி பெற்றிடுங்கள்",
    checkout: "பணம் செலுத்துதல்",
    goback: "பின் செல்ல",
    thankyou: "நன்றி",
    payment_success: "உங்கள் கட்டணம் வெற்றிகரமாக பெறப்பட்டது",
    proceed: "தொடர",
    sorry: "மன்னிக்கவும்",
    payment_failed: "உங்களால் கட்டணம் செலுத்த இயலவில்லை.",
    retry: "மீண்டும் முயற்சிக்கவும்",
    new_user: "புதிய பயனர்",
    registered_user: "முந்தைய பயனர்",
    login: "உள்நுழைய",
    matching: "பொருத்தம்",
    accountant: "எ.கா. கணக்காளர், ஓட்டுனர், டெலிகாலர்",
    searchJobRole: "தேடல் முடிவுகள்",
    SalaryExpected: 'எதிர்பார்க்கப்படும் சம்பளம்',
    Close: 'மூடு',
    jobRole: 'வேலை தலைப்பு',
    ProfileCompletion: 'சுயவிவரத்தை நிறைவு செய்தல்',
    ClickhereMoreSearch: 'மேலும் தேடலுக்கு இங்கே கிளிக் செய்யவும்',
    YouCanselectSkills: 'நீங்கள் 6 திறன்கள் வரை தேர்ந்தெடுக்கலாம்',
    ChooseUrExp: 'உங்கள் அனுபவத்தைத் தேர்வு செய்யவும்',
    chooseUrSkill: 'தயவு செய்து உங்கள் திறமைகளை தேர்வு செய்யவும்',
    YouCanSelectJobRoles: 'நீங்கள் 4 வேலை தலைப்புகள் வரை தேர்ந்தெடுக்கலாம்',
    PleaseSelectAllField: 'அனைத்து விவரங்களையும் தேர்ந்தெடுக்கவும்'
  },
  "hn": {
    file_upload_text: "प्रोफ़ाइल छवि अपलोड करें",
    min_salary: "न्यूनतम आय",
    max_salary: "अधिकतम वेतन",
    welcome_text: "स्वागत",
    welcome_text1: "कृपया अपना प्रोफ़ाइल पूरा करें",
    image_text: "बेहतर दृश्यता के लिए अपना फोटो अपलोड करें",
    applied_jobs: "लागू नौकरियाँ",
    all_jobs: 'सारी नौकरियां',
    reotp: "ओटीपी पुनः भेजें",
    otp_re: "ओटीपी पुनः भेजें",
    sent: "हमने एक OTP भेजा है",
    otp_chg: "कृपया ओटीपी दर्ज करें",
    local_first: "बेहतर वेतन वाली स्थानीय नौकरियाँ खोजें!",
    vali_t: "कृपया एक वैध 10-अंकीय मोबाइल नंबर दर्ज करें",
    recommended_jobs_label: "माई एलिजिबल जॉब्स",
    filter_by: "फ़िल्टर किस्में",
    filter_job_type: "कार्य का प्रकार",
    filter_job_location: "नौकरी करने का स्थान",
    no_recommended_jobs: "कोई अनुशंसित नौकरियाँ नहीं मिलीं",
    no_search_jobs: "इस नौकरी शीर्षक में कोई नौकरी नहीं मिली",
    no_Filter_search_jobs: "कोई नौकरी नहीं मिली",
    no_applied_jobs: "आपने नोट कर लिया है और आवेदन भी कर दिया है",
    candidate_job_type: "कार्य का प्रकार",
    showing: "दिखा रहा",
    job_of: "की नौकरियाँ",
    employee_experience: "आपका अनुभव क्या है?",
    skills_known: "आप इनमें से किसे जानते हैं?",
    ma_ypro: "अपनी प्रोफ़ाइल बनाएं",
    re_pro: "अपना प्रोफ़ाइल पंजीकृत करें",
    cl_era: "स्पष्ट",
    pre_me: "पहले का",
    chose_ji: "अपनी नौकरी का शीर्षक चुनें",
    add_job_title: "अनुभव जोड़ें",
    cur_ex: "अपेक्षित वेतन सीमा",
    wrk_pro: "कार्य अनुभव ",
    edu_trt: "शिक्षा का स्तर",
    load_deng: "और लोड करें",
    off_txt: "वेतन की पेशकश की",
    txt_ski: "आवश्यक योग्यता",
    inform_txt_key: "नौकरी की सूचना",
    expect_ey: "अपेक्षित अधिकतम वेतन",
    expected_key: "SPSITATE न्यूनतम बिक्री",
    findlist_job: "साबुन सूची",
    findjobs_text: "नौकरी ढूंढे",
    jobsummary_tag: "User Friendly & Trusted HR Manager",
    lang_read_text: "पढ़ना",
    lang_write_text: "लिखना",
    lang_speak_text: "बोलना",
    persons_applied_text: " लोगों ने आवेदन किया",
    recommended_jobs_text: "आपकी प्रोफ़ाइल के आधार पर अनुशंसित नौकरियां",
    matching_jobs_text: "आपकी प्राथमिकताओं के आधार पर मेरी मिलती-जुलती नौकरियाँ",
    incomplete_text: "अधूरा",
    rejection_remarks_error: "कृपया अस्वीकृति का कारण दर्ज करें",
    rejection_remarks_text: "खारिज करने का कारण",
    rejection_save_text: "बचाना",
    customercaremsg: "सहायता के लिए, कृपया हमारे ग्राहक सहायता कार्यकारी को कॉल करें",
    customercaremsg1: " (सोमवार - शनिवार / प्रातः 10:00 - सायं 06:00 बजे)",
    calls_made: "कॉल किए गए",
    calls_received: "कॉल प्राप्त",
    employer_job_expired: "नौकरी समाप्त",
    apply_call: "अब कॉल करें",
    apply_viewjob: "नौकरी देखें",
    invite_call: "अब आप इस कर्मचारी को @ कॉल कर सकते हैं",
    download_resume_btntext: "बायोडाटा देखें",
    download_resume_icontext: "अपना बायोडाटा डाउनलोड करें",
    emp_legend: "अनिवार्य",
    checkout_button_text: "आगे बढ़ना",
    registration_fee: "पंजीकरण शुल्क",
    congratulations: "बधाई हो !",
    offer_msg1: "अपनी प्रोफ़ाइल 100% पूर्ण करें और प्राप्त करें",
    offer_msg2: "% बंद",
    gstpercentage: "*जीएसटी 18% लागू",
    checkout_msg1: "अपनी प्रोफ़ाइल पूरी करें",
    checkout_msg2: "&",
    checkout_msg3: "पाना",
    checkout_msg4: "बंद",
    checkout: "अब भुगतान करें",
    goback: "वापस जाओ",
    thankyou: "धन्यवाद",
    payment_success: "आपका भुगतान सफलतापूर्वक प्राप्त हो गया है",
    proceed: "आगे बढ़ना",
    sorry: "क्षमा मांगना",
    payment_failed: "आपका भुगतान विफल हो गया है। कृपया पुनः प्रयास करें।",
    retry: "पुनः प्रयास करें",
    new_user: "नए उपयोगकर्ता",
    registered_user: "पंजीकृत उपयोगकर्ता",
    login: "लॉग इन करें",
    employee_search: "सामान्य खोज",
    filesize_restriction: "फ़ाइल का आकार 2एमबी से कम होना चाहिए",
    last_login: "आखरी लॉगइन",
    disclaimer_class: 'कृपया हमारा पढ़ें',
    disclaimer_class1: 'अस्वीकरण खंड',
    disclaimer_class2: '',
    disclaimer_class3: 'संबंधित की वेबसाइटों से प्राप्त',
    disclaimer_class4: 'सरकारी संगठन',
    disclaimer_class2: '',
    any_state: "कोई भी राज्य",
    any_district: "कोई भी जिला",
    filter_any_district: "कोई भी जिला",
    filter_any_taluk: "कोई तालुक",
    any_taluk: "कोई तालुक",
    best_salary: 'उद्योग में सर्वश्रेष्ठ वेतन',
    flash_jobs: 'फ्लैश जॉब्स',
    flash_jobs_details: 'फ़्लैश जॉब विवरण',
    aadharAlphaNumeric: 'वैध उद्यम/उद्योग आधार संख्या दर्ज करें',
    Document_type: "दस्तावेज़ का प्रकार",
    Additional_Documents: "अतिरिक्त दस्तावेज़",
    doument_error: "कृपया कम से कम तीन दस्तावेज़ अपलोड करें",
    doument_error_one: "कृपया जीएसटीआईएन/पैन/आधार से कम से कम एक दस्तावेज अपलोड करें",
    Document_file: "दस्तावेज अपलोड करें",
    District: 'ज़िला',
    Taluk: 'तालुक',
    City: "शहर / शहर",
    Reference_number: "संदर्भ संख्या",
    File: "विकल्प",
    Reference_number_error: "कृपया संदर्भ संख्या दर्ज करें",
    Reference_number_error_valid: "कृपया मान्य संदर्भ संख्या दर्ज करें",
    Rejected_job: "यह नौकरी अस्वीकार कर दी गई थी",
    Telecallers: 'टेलीकॉलर्स',
    Referal: 'रेफ़रल',
    employer_usertype_type_errormsg: 'कृपया टेलीकॉलर्स का चयन करें',
    Employee_known_text: "कृपया चुने",
    employer_info_errormsg: 'कृपया जानकारी प्रदान करें',
    sslc_first: 'कृपया एसएसएलसी जोड़ें',
    text_viewed: 'देखा',
    Match_pro: "मिलान प्रोफाइल",
    employer_profile_txt: 'अपनी प्रोफ़ाइल को रोचक बनाने के लिए अपनी कंपनी का लोगो अपलोड करें!',
    employer_gallery_txt: 'अपनी प्रोफ़ाइल को रोचक बनाने के लिए अपनी कंपनी की फ़ोटो अपलोड करें!',
    employee_profile_View: 'अपना प्रोफाइल दिलचस्प बनाने के लिए अपना फोटो अपलोड करें!',
    abuse_txt: 'दुरुपयोग होने की सूचना दें',
    currently_pursuing_word: "वर्तमान में पीछा कर रहा है",
    currently_pursuing: "वर्तमान में पीछा कर रहा है",
    total_no_profile: "कुल प्रोफ़ाइल गणना:",
    total_job_list: "कुल प्रोफ़ाइल गणना:",
    exp_year_str: "वर्षों",
    exp_year_str1: "साल",
    exp_month_str: "महीने",
    exp_month_str1: "महीना",
    experience_years1: "वर्षों)",
    experience_mnt1: "महीना",
    full_complete_profile: "अपनी प्रोफ़ाइल 100% पूरी करने के बाद",
    full_complete_profile1: "आप अपने साक्षात्कार के लिए वर्तमान तिथि \ के साथ अपना रिज्यूमे डाउनलोड कर सकते हैं",
    download_text: "डाउनलोड सम्पन्न हुआ",
    subscriptions_buy_notes: " 'माय प्रेफर्ड कैंडिडेट्स' में पर्याप्त मैचिंग प्रोफाइल देखने के बाद कृपया प्लान को सब्सक्राइब करें ",
    please_upload_profile: "कृपया प्रोफ़ाइल चित्र अपलोड करें",
    notification_on: "पर",
    notification_off: "बंद",
    share_gnjob1: "जोबान्या से सरकारी नौकरी अधिसूचना",
    share_gnjob2: "पूरा विवरण देखने के लिए जोबान्या एंड्रॉइड ऐप में लॉग इन करें",
    share_job1: "जोबान्या से नौकरी अधिसूचना",
    share_job2: "पूरा विवरण देखने के लिए जोबान्या एंड्रॉइड ऐप में लॉग इन करें",
    share_open: "उद्घाटन",
    subscription_failed: "सदस्यता विफल हो गई",
    gst_applicable: "* जीएसटी जैसा लागू हो",
    jobpost_posted_on: "प्रकाशित किया गया : ",
    profilesummary_title: "उपलब्ध प्रोफ़ाइल सारांश",
    my_pref_cand: 'मेरे पसंदीदा उम्मीदवार',
    eligible_cand: 'योग्य उम्मीदवार',
    invited_cand: 'आमंत्रित उम्मीदवार',
    applied_cand: "आवेदित उम्मीदवार",
    invited_now: "अभी आमंत्रित करें",
    posted_jobs: "कुल पोस्ट की गई नौकरियां",
    jobpost_approval_info: 'कृपया ध्यान दें: एक बार जॉब पोस्ट स्वीकृत और पोस्ट हो जाने के बाद, एचआर संपर्क विवरण को छोड़कर, जॉब पोस्ट को संपादित नहीं किया जा सकता है',
    jobpost_tab_search_hint: 'खोज आइकन पर क्लिक करके उम्मीदवार की प्रोफ़ाइल खोजें',
    jobpostlist_hint: "संबंधित उम्मीदवार प्रोफाइल देखने के लिए, अपने सूचीबद्ध जॉब पोस्ट पर क्लिक करें",
    total_vacancies: 'कुल नौकरी रिक्तियां:',
    incomplete_profile: "आपका प्रोफ़ाइल अधूरा है।",
    lead_incomplete_profile_header: "प्रोफ़ाइल पूर्णता",
    lead_incomplete_profile: "आपकी प्रोफ़ाइल है",
    lead_incomplete_profile1: "पूरा हुआ। कृपया इसे पूरा करें",
    lead_incomplete_profile2: " अछे नतीजे के लिये।",
    lead_incomplete_profile3: "इस नौकरी के लिए आवेदन करने के लिए।",
    lead_incomplete_profile4: " पुरा होना। भरना",
    keep_document_ready: "इन दस्तावेजों को तैयार रखें \n #कंपनी जीएसटी #कंपनी पैन #उद्यम/उद्योग आधार\n\n पीडीएफ प्रारूप में \n पंजीकरण के लिए",
    incomplete_profile_employer: "अपना प्रोफ़ाइल 100% पूर्ण करके अधिक कर्मचारियों को आकर्षित करें",
    employer_looking: "डेस्कटॉप संस्करण खोज रहे हैं?",
    employer_visit_portal: "कृपया अवश्य पधारिए  ",
    upto: 'तक ',
    as_per_settings: 'आपकी वरीयता सेटिंग्स के अनुसार',
    reset_filter: "फिल्टर रीसेट करें",
    clear_filter: 'स्पष्ट निस्यंदक',
    reset_filter_inicaps: "फिल्टर रीसेट करें",
    clear_filter_inicaps: 'स्पष्ट निस्यंदक',
    aadhar_note: '*साक्षात्कार के दौरान अपने प्रोफाइल की प्रामाणिकता सुनिश्चित करने के लिए',
    from_age: 'उम्र से',
    to_age: 'उम्र होना',
    from_sal: 'वेतन से',
    to_sal: 'वेतन के लिए',
    from_exp: 'अनुभव से',
    to_exp: 'अनुभव करना',
    Social_Media_link: "सोशल मीडिया लिंक",
    linkedin_Media_link: "लिंक्डइन",
    Twitter_Media_link: "ट्विटर ",
    Facebook_Media_link: "फेसबुक ",
    Instagram_Media_link: "इंस्टाग्राम",
    Youtube_Media_link: "यूट्यूब ",
    valid_link: "कृपया वैध लिंक दर्ज करें",
    employer_preference_word: "प्राथमिकताओं का चयन करते समय, बहुत विशिष्ट रहें, ताकि आपको पूरी तरह से मेल खाने वाले कर्मचारी प्रोफ़ाइल प्राप्त हों",
    employee_preference_word: "प्राथमिकताओं का चयन करते समय, बहुत विशिष्ट रहें, ताकि आपको सही नौकरी अधिसूचनाएं और नौकरी की सिफारिशें प्राप्त होंगी",
    employer_gallery_word: "कृपया अपनी कंपनी की इमेज अपलोड करें",
    youtube: 'यूट्यूब',
    facebook: 'फेसबुक',
    telegram: 'टेलीग्राम',
    employer_reg_msg: " आपका पंजीकरण सफलतापूर्वक सबमिट कर दिया गया है। कृपया अपनी पंजीकृत ईमेल आईडी पर भेजे गए ईमेल सत्यापन लिंक पर क्लिक करके अपनी ईमेल आईडी सत्यापित करें। ",
    Search_here: "यहां तलाश करो",
    dismissall_qus: 'क्या आप निश्चित रूप से सभी को खारिज करना चाहते हैं?',
    deleteactiveJobTitle: 'आप अपनी वर्तमान नौकरी भूमिका को हटाने का प्रयास कर रहे हैं। हटाने से पहले कृपया अपनी वर्तमान नौकरी भूमिका चुनें।',
    deleteJobTitle: 'क्या आप वाकई इस अनुभव को हटाना चाहते हैं?',
    placeholder_jobfunction: 'जॉब फ़ंक्शन द्वारा खोजें',
    placeholder_jobrole: 'जॉब रोल द्वारा खोजें',
    placeholder_employmenttype: 'रोजगार प्रकार द्वारा खोजें',
    placeholder_district: 'जिला द्वारा खोजें',
    placeholder_taluk: 'तालुक द्वारा खोजें',
    placeholder_faclities: 'ऑफ़र की गई सुविधाओं के आधार पर खोजें',
    placeholder_skills: 'कौशल द्वारा खोजें',
    placeholder_Education: 'शिक्षा श्रेणी द्वारा खोजें',
    placeholder_Qualification: 'योग्यता द्वारा खोजें',
    placeholder_Specialization: 'विशेषज्ञता द्वारा खोजें',
    textfont: 24,
    emp_footer_size: 16,
    Pending: 'लंबित',
    not_auth: "आप इस सामग्री को देखने के लिए अधिकृत नहीं हैं",
    app_share_employee: "भारत का नंबर 1 कर्मचारी खोज ऐप अभी डाउनलोड करें \n",
    app_share_employer: "भारत का नंबर 1 नौकरी खोजने वाला ऐप अभी डाउनलोड करें \n",
    Select_district: 'जिला चुनें',
    Select_taluk: 'तालुक चुनें',
    select_education: " शिक्षा श्रेणी का चयन करें ",
    Select_facilities_offered: 'प्रस्तावित सुविधाओं का चयन करें',
    Select_Qualification: 'योग्यता चुनें',
    Select_Specialization: 'विशेषज्ञता चुनें',
    Exact_match: 'सटीक मिलान',
    Similar_match: 'समान मिलान',
    Invite: 'आमंत्रित करना',
    Invite_Now: 'अभी आमंत्रित करें',
    Invite_Now_qus: 'क्या आप निश्चित रूप से आमंत्रित करना चाहते हैं?',
    Mark: 'निशान',
    Latitude: "अक्षांश:",
    Longitude: "देशांतर:",
    PDF_Viewer: 'पीडीएफ व्यूअर',
    Resume: " फिर शुरू करना ",
    PDF: "पीडीएफ",
    days: 'दिन',
    search_similar: 'समान प्रोफ़ाइल खोजें',
    atleaseone_proof: 'कृपया कम से कम दो प्रमाण अपलोड करें',
    atleaseone_proof1: 'दस्तावेज अपलोड करें',
    split_Employee: "कर्मचारी",
    employer_Portal: "नियोक्ता पोर्टल",
    split_Employer: 'नियोक्ता',
    update_available: "उपलब्ध अद्यतन",
    update_msg: "एक अपडेट उपलब्ध है। क्या आप निश्चित रूप से अपडेट करना चाहते हैं?",
    force_update: "अभी अद्यतन  ",
    home_update: " सर्वोत्तम परिणाम  के लिए अभी अपडेट करे ",
    Later: 'बाद में',
    male: 'पुरुष',
    female: 'महिला',
    transgender: 'ट्रांसजेंडर',
    netinfo_heading: "जानकारी",
    limit_cross_head_jobfunction: "चेतावनी",
    limit_cross_head_joblocation: "चेतावनी",
    limit_cross_head_jobfunction_error: "आप अधिकतम चुन सकते हैं",
    limit_cross_head_joblocation_error: "आप अधिकतम चुन सकते हैं",
    search_results: "सुझाए गए परिणाम",
    netinfo_heading_server: "सर्वर पहुंच योग्य नहीं",
    netinfo_subheading_server: "सर्वर पहुंच योग्य नहीं है कृपया पुनः प्रयास करें",
    fresher_status: "मैं एक नवसिखुआ हूँ",
    netinfo_subheading: "कृपया अपना नेटवर्क कनेक्शन जांचें",
    netinfo_subheading_reachable: "कृपया अपना नेटवर्क कनेक्शन जांचें",
    schooling_no_record: "कोई रिकॉर्ड नहीं मिला",
    selectlangheadertag: "पसंदीदा भाषा      ",
    experience_error: "कृपया मान्य अनुभव दर्ज करें",
    experience_error1: "कृपया मान्य अनुभव माह दर्ज करें",
    add_schooling: "कृपया पहले स्कूली जानकारी जोड़ें",
    selectedlanguage_heading: "कृपया अपनी भाषा चुनें",
    Select: 'चुनना',
    Select_Skills: 'कौशल चुनें',
    netinfo_subheading2: "कुछ गलत हो गया",
    no_records: "कोई रिकॉर्ड नहीं मिला",
    no_notification: "कोई नई सूचना नहीं मिली",
    sslc_not_eligible: "आप एसएसएलसी जोड़ने के योग्य नहीं हैं",
    hsc_not_eligible: "आप एसएसएलसी जोड़ने के योग्य नहीं हैं",
    exp_del_fresher_heading: "पुष्टिकरण",
    at: "पर",
    since_experience: "तब से",
    exit_cancel: "रद्द करना",
    personal_info_fill_first: "आगे बढ़ने के लिए कृपया अपनी व्यक्तिगत जानकारी पूरी करें",
    not_eligible: "पात्र नहीं है",
    apply_limit: "लागू सीमा पार हो गई",
    alrdy_invited: "पहले से ही आमंत्रित",
    invite_then_reject: "आप पहले ही इस कार्य को अस्वीकार कर चुके हैं",
    apply_then_reject: "आप इस नौकरी के लिए नहीं चुने गए हैं",
    education_exist: "शिक्षा_अस्तित्व",
    exit_ok: "ठीक है",
    any_locations: "कोई भी स्थान",
    any_location: "कोई भी स्थान",
    otp_message: "अमान्य ओटीपी",
    otp_sent: "ओटीपी भेजा",
    err_checkismax: "कृपया मान्य अधिकतम वेतन दर्ज करें",
    langselectnext: "अगला",
    sliderfirsttitle: "जोबान्या में आपका स्वागत है",
    slidersecondtitle: "",
    sliderthirdtitle: "",
    sliderfourtitle: "",
    getstarted: "शुरू हो जाओ",
    lang: "भाषा",
    loginhead: 'कर्मचारी',
    loginsubhead: "जारी रखने के लिए साइन इन करें",
    mobileplaceholder: 'मोबाइल नंबर या उपयोगकर्ता नाम',
    passwordplaceholder: 'पासवर्ड',
    forgetpassword: 'पासवर्ड भूल गए?',
    signinbutton: 'साइन इन करें',
    signupbutton: 'नया उपयोगकर्ता? साइन अप करें',
    forgotsubmit: 'प्रस्तुत करना',
    otptext: 'ओटीपी',
    getotptext: 'ओटीपी प्राप्त करें',
    verifiedotpmessage: "कृपया ओटीपी सत्यापित करें",
    verifiedotptext: "ओटीपी सत्यापित करें",
    home_header_title1: 'काम',
    home_tab_home: 'गृह',
    home_tab_recommended: 'अनुशंसित',
    home_tab_applied: 'लागू',
    home_tab_invitedon: "आमंत्रित",
    home_tab_invited: 'आमंत्रित',
    home_tab_wishlist: 'विशलिस्ट',
    home_jobtype: 'कार्य का प्रकार',
    home_joblocation: 'नौकरी करने का स्थान',
    home_joblocation1: 'जॉब लोकेशन डिस्ट्रिक्ट',
    job_joblocation1: 'उम्मीदवार की नौकरी वरीयता स्थान - जिला',
    home_industry: 'उद्योग',
    home_jobfunction: 'कार्यभार',
    home_govjobs: 'सरकारी नौकरियों',
    home_eligiblejobs: 'मेरी मैचिंग नौकरियाँ',
    home_my: 'मेरा',
    home_flashjobs: 'फ्लैश जॉब्स',
    clear_filter: 'फ़िल्टर साफ़ करें',
    jobsummary_title: 'उपलब्ध नौकरी सारांश',
    jobsummary_privatejobs: 'निजी नौकरियां',
    jobsummary_fresherjobs: 'नवसिखुआ नौकरियाँ',
    jobsummary_appliedjobs: 'माई एप्लाइड जॉब्स',
    jobsummary_eligiblejobs: 'माई एलिजिबल जॉब्स',
    jobsummary_govtjobs: 'सरकारी नौकरी',
    jobsummary_govtjobs: 'सरकारी नौकरी',
    enter: 'प्रवेश करना',
    footer_privatejobs: 'कुल नौकरियाँ',
    footer_flashjobs: 'फ्लैश जॉब्स',
    footer_govtjobs: 'सरकार',
    footer_jobs: 'नौकरियां',
    apply_filter: "आवेदन करना",
    salary_scale: "वेतन पैमाना",
    apply: "लागू",
    invite_accept: "को स्वीकृत",
    invite_decline: "इंकार कर दिया",
    apply_accpt: "को स्वीकृत",
    apply_reject: "अस्वीकृत",
    Posted_on: "प्रकाशित किया गया",
    invitation_accept: "निमंत्रण सफलतापूर्वक स्वीकार किया गया",
    invitation_reject: "निमंत्रण सफलतापूर्वक अस्वीकृत",
    education_plus: "अभी कुछ भी नहीं है! नीचे दिए गए प्लस बटन पर क्लिक करके शिक्षा जोड़ें",
    skills_plus: "अभी कुछ भी नहीं है! नीचे प्लस बटन पर क्लिक करके कौशल जोड़ें",
    no_record_found: "कोई रिकॉर्ड नहीं मिला",
    reference_plus: "अभी कुछ भी नहीं है! नीचे प्लस बटन पर क्लिक करके संदर्भ जोड़ें",
    sidebar_home: "गृह",
    sidebar_profile: "मेरा प्रोफ़ाइल",
    sidebar_resumebuilder: "फिर से शुरू बिल्डर",
    sidebar_news: "समाचार",
    sidebar_events: "आयोजन",
    sidebar_govjobs: "सरकारी नौकरियों",
    sidebar_settings: "समायोजन",
    sidebar_contactus: "संपर्क करें",
    sidebar_share: "शेयर करना",
    sidebar_rating: "गूगल रेटिंग और समीक्षाएं",
    sidebar_logout: "लॉग आउट",
    resume_heading: "फिर से शुरू बिल्डर",
    resume_personalinfo: "व्यक्तिगत जानकारी",
    resume_contactinfo: "संपर्क सूचना",
    resume_education: "शिक्षा",
    resume_skills: "साबुन बंच की नजूबी भूमिका और कौशल",
    resume_exp: "स्पिरेंस",
    resume_ref: "संदर्भ",
    resume_recd: "(अनुशंसित डर /)",
    resume_recd1: "जो लोग मेरी सिफ़ारिश करते हैं)",
    resume_preference: "सूचना की प्राथमिकताएं",
    personal_header: "व्यक्तिगत जानकारी",
    date: "दिनांक",
    personal_fullname: "पूरा नाम",
    personal_fathername: "पिता का नाम",
    personal_spousename: "जीवनसाथी का नाम",
    personal_dob: "जन्म की तारीख",
    personal_gender: "लिंग",
    personal_marital: "वैवाहिक स्थिति",
    personal_aadhar: "आधार न.",
    personal_lg: "ज्ञात भाषाएँ",
    personal_medical: "कोई ज्ञात चिकित्सा इतिहास",
    personal_upload: "रिज्यूमे अपलोड करें",
    personal_save: "बचाना",
    personal_diff: "अलग रूप से सक्षम",
    personal_yes: "हाँ",
    personal_no: "नहीं",
    personal_add: "जोड़ें",
    personal_format: "(डॉक्टर, डॉक्स और पीडीएफ प्रारूप केवल)",
    contact_header: "संपर्क सूचना",
    contact_door: "द्वार संख्या / गली",
    contact_area: "क्षेत्र",
    contact_taluk: 'तालुक',
    contact_city: "शहर / शहर का नाम",
    contact_district: "ज़िला",
    contact_state: "राज्य",
    contact_pincode: "पिन कोड",
    contact_emailid: "ईमेल आईडी",
    contact_mbleno: "व्हाट्सएप के साथ मोबाइल नंबर",
    contact_altmbleno: "वैकल्पिक मोबाइल नंबर",
    contact_save: "बचाना",
    date_of_birth: "जन्म की तारीख",
    work_exp_fresher: "नवसिखुआ",
    work_exp_experienced: "अनुभव",
    address: "स्थान",
    contact_address: "पता",
    edu_header: "शिक्षा",
    edu_schooling: "स्कूली शिक्षा",
    edu_category: "श्रेणी",
    edu_qualification: "योग्यता",
    edu_insti: "संस्थान",
    edu_loc: "स्थान",
    Candidate_location: "नियोक्ता का स्थान",
    edu_mark: "मार्क का प्रतिशत",
    edu_yrofpassing: "उत्तीर्ण होने का वर्ष",
    edu_save: "बचाना",
    percentage_check: "कृपया मान्य प्रतिशत दर्ज करें",
    ref_popup_header: "रिश्ता",
    ref: "संदर्भ",
    ref_name: "नाम",
    ref_desig: "पद का नाम",
    ref_others: "अपने रिश्ते का उल्लेख करें",
    references_candidate_required: "अपने रिश्ते का उल्लेख करें",
    ref_org: "संगठन",
    ref_relationship: "उम्मीदवार से संबंध",
    ref_remarks: "टिप्पणियां",
    ref_save: "बचाना",
    ref_del: "मिटाना",
    ref_update: "अद्यतन",
    per_view_header: "प्रोफ़ाइल",
    per_view_marital: "वैवाहिक स्थिति",
    per_view_father_name: "पिता का नाम",
    per_view_spouse_name: "जीवनसाथी का नाम",
    per_view_dob: "जन्म की तारीख",
    per_view_aadhar: "आधार न.",
    per_view_lang: "ज्ञात भाषाएँ",
    per_view_medical: "कोई ज्ञात चिकित्सा इतिहास",
    per_view_differentlyabled: "अलग रूप से सक्षम",
    per_contact: "संपर्क सूचना",
    per_Details: "व्यक्तिगत विवरण",
    per_edu: "शिक्षा",
    per_school: "स्कूली शिक्षा",
    per_after_school: "स्कूली शिक्षा के बाद",
    per_skills: "जॉब फंक्शन, जॉब रोल और स्किल्स",
    per_ref: "संदर्भ",
    per_ref_recd: "(अनुशंसित द्वारा / (द्वारा सिफारिश))",
    per_pre: "सूचना की प्राथमिकताएं",
    exp_totalexp: "कुल अनुभव",
    exp_add: "अपना अनुभव जोड़ें",
    exp_years: "वर्षों",
    exp_heading: "अनुभव",
    exp_org: "कंपनी का नाम",
    exp_desig: "पद का नाम",
    experience_from_month: "कृपया महीने से चुनें",
    experience_to_month: "कृपया महीने का चयन करें",
    exp_location: "स्थान",
    exp_from: "से",
    exp_to_month: "महीने के लिए",
    exp_from_month: "महीने से",
    exp_to: "प्रति",
    shift_start: 'समय शुरू',
    shift_end: 'अंत समय',
    exp_month: "महीना",
    exp_year: "साल",
    exp_currently: "वर्तमान में यहाँ काम कर रहा हूँ",
    job_currently: "वर्तमान कार्य भूमिका",
    exp_save: "बचाना",
    prefer_ok: "ठीक है",
    jobfunction_currently: "वर्तमान पद",
    experience_yearoverlap: "आपका वर्तमान अनुभव दूसरे अनुभव के साथ ओवरलैप करता है",
    experience_fromyear_required: "कृपया वर्ष दर्ज करें",
    experience_fromyearvalid_required: "कृपया मान्य वर्ष दर्ज करें",
    experience_tomonth_required: "कृपया माह दर्ज करें",
    experience_toyear_required: "कृपया वर्ष दर्ज करें",
    experience_tomonthvalid_required: "कृपया मान्य माह दर्ज करें",
    experience_toyearvalid_required: "कृपया मान्य वर्ष दर्ज करें",
    pre_emptype: "रोजगार के प्रकार",
    pre_timeforjg: "शामिल होने का समय",
    pre_immediate: "तुरंत",
    pre_within: "अंदर",
    pre_days: "दिन",
    pre_day: 'दिन',
    pre_expected: "प्रति माह अपेक्षित वेतन",
    pre_permnth: "प्रति महीने",
    pre_header_popup_type: "रोजगार के प्रकार",
    pre_header_popup_sal: "वेतन",
    pre_to: "प्रति",
    pre_jobrole: "नौकरी भूमिका",
    pre_fun: "कार्यभार",
    skills_heading: "कौशल",
    skills_jobfunction: "कार्यभार",
    skills_Role: "नौकरी भूमिका",
    mobileNo_change_header: "मोबाइल नंबर बदलें",
    Mobileno_oldnum: "पुराना मोबाइल नंबर",
    mobileNo_change_number: "नया मोबाइल नंबर",
    mobileNo_change_otp: "ओटीपी",
    mobileNo_change_change_mobile_number_button: "मोबाइल नंबर बदलें",
    invited: "Invited",
    toast_mobile: "मोबाइल नंबर सफलतापूर्वक अपडेट किया गया",
    login_invisible: "आपका खाता अदृश्य मोड में है।",
    login_invisible_active: "क्या आप अभी पुनः सक्रिय करना चाहते हैं?",
    accout_reactivate: "खाता पुनर्सक्रियन",
    account_otp: "ओटीपी आपके पंजीकृत मोबाइल नंबर पर भेजा गया है",
    resend_otp_account: "ओटीपी प्राप्त करें",
    resend_otp_verify: "सत्यापित करना",
    login_yes: "हाँ",
    login_no: "नहीं",
    noti_head: "अधिसूचना",
    noti_jobs: "नौकरियां",
    noti_news: "समाचार",
    noti_events: "आयोजन",
    events_head: "आयोजन",
    job_description: "विवरण",
    news_head: "समाचार",
    link: "संपर्क",
    filterby: "के द्वारा छनित",
    govt_jobs: "सरकारी नौकरियों",
    noti_button: "सभी खारिज",
    noti_swipe: "(खारिज करने के लिए बाएं स्वाइप करें)",
    password_change_header: "पासवर्ड बदलें",
    password_change_old_password: "पुराना पासवर्ड",
    password_change_new_password: "नया पासवर्ड",
    password_change_change_password_button: "पासवर्ड बदलें",
    toast_password: "पासवर्ड सफलतापूर्वक बदला गया!",
    registration_heading: "कर्मचारी पंजीकरण",
    registration_heading1: "एकमुश्त पंजीकरण शुल्क",
    registration_user: "उपयोगकर्ता का नाम",
    registration_name: "उपयोगकर्ता नाम",
    registered_name_msg: "(नाम, बिना किसी स्थान के कोई भी संख्या)",
    registration_mobileno: "मोबाइल नहीं है",
    registration_password: "पासवर्ड",
    registration_otp: "ओटीपी",
    registration_otpbutton: "ओटीपी प्राप्त करें",
    registration_checkbox: "मैं जोबान्या से सहमत हूं",
    registration_submitbutton: "साइन अप करें",
    signup_button: "साइन अप करें और जारी रखें",
    forgetpassword_mobileno: "मोबाइल नहीं है",
    forgetpassword_otp: "ओटीपी",
    forgetpassword_password: "पासवर्ड",
    forgetpassword_submit: "प्रस्तुत करना",
    Setting_heading: "समायोजन",
    Setting_changepassword: "पासवर्ड बदलें",
    Setting_changemobileno: "मोबाइल नंबर बदलें",
    Setting_Notification: "अधिसूचना",
    Setting_Preferedlanguage: "पसंदीदा भाषा",
    Setting_invisiblemode: "अदृश्य मोड",
    Notification_invisiblemode: "अदृश्य मोड",
    notifi_submit_toast: "सफलतापूर्वक अपडेट किया गया!",
    image_upload_size_msg: "छवि का आकार बहुत बड़ा है",
    image_upload_format_msg: "फ़ाइल '.jpg','.jpeg','.png' फॉर्मेट में होनी चाहिए",
    OTP_Validation: "कृपया 6 अंकों का ओटीपी दर्ज करें",
    Notification_msg1: "क्या आप अपने खाते को अदृश्य मोड में बदलना चाहते हैं?",
    Notification_msg2: "नियोक्ता आपका खाता नहीं देख पाएंगे।",
    Notification_cancel: "रद्द करना",
    Notification_submit: "प्रस्तुत करना",
    Notification_heading: "अधिसूचना",
    Notification_jobs: "नौकरियां",
    Notification_news: "समाचार",
    Notification_events: "आयोजन",
    Contactus_heading: "संपर्क करें",
    Contactus_website: "वेबसाइट",
    Contactus_emailid: "ईमेल आईडी",
    Contactus_mobileno: "मोबाइल नहीं है।",
    Contactus_subject: "विषय",
    Contactus__description: "विवरण",
    Contactus_button: "प्रस्तुत करना",
    Setting_invisiblemode: "अदृश्य मोड",
    logout_heading: "लॉग आउट",
    logout_msg: "क्या आप लॉग आउट करना चाहते हैं ?",
    logout_cancel: "रद्द करना",
    logout_logout: "लॉग आउट",
    Search_heading: "खोज",
    Recent_Search: "हाल की खोज",
    Search_Something: 'कुछ खोजें',
    No_data_Available: 'कोई डेटा मौजूद नहीं',
    Placeholder: "स्थान, उद्योग, नौकरी समारोह, नौकरी की भूमिका से खोजें",
    gn_desc_job_desc: "नौकरी का विवरण",
    toaster_job_title_error: "आप अधिकतम 4 नौकरी शीर्षक का चयन कर सकते हैं",
    toaster_job_title_fresher_error: "आप अधिकतम 1 नौकरी शीर्षक का चयन कर सकते हैं",
    toaster_job_type_error: "आप अधिकतम 4 प्रकार की नौकरी चुन सकते हैं",
    already_Exist: "उपयोगकर्ता नाम पहले से मौजूद",
    gn_desc_link: "संपर्क",
    gn_desc_downloads: "डाउनलोड",
    gn_no_vacanies: "रिक्तियों की संख्या",
    gn_Expiry: "अंतिम तिथी",
    rl_apply_now: "कॉल करें और आवेदन करें",
    rl_apply_now_one: "क्या आप निश्चित रूप से आवेदन करना चाहते हैं?",
    rl_freshers: "फ्रेशर",
    rl_fresher: "फ्रेशर",
    rl_sortby: "इसके अनुसार क्रमबद्ध करें",
    rl_filterby: "के द्वारा छनित",
    rl_applyfilter: "फ़िल्टर लागू करें",
    rl_filter: "फिल्टर लागू करें",
    rl_jobtype: "कार्य का प्रकार",
    rl_experience: "अनुभव",
    rl_qualification: "शिक्षा",
    rl_skills: "कौशल",
    Job: "काम",
    Description: 'विवरण',
    rl_description: "नौकरी का विवरण",
    rl_grp: "नौकरी समूह",
    rl_maritial: "वैवाहिक स्थिति",
    rl_gender: "लिंग",
    rl_age: "आयु मानदंड",
    rl_differently: "अलग रूप से सक्षम",
    rl_any: "कोई",
    rl_no: "नहीं",
    rl_preferred: "पसंदीदा",
    rl_lastdate: "अंतिम तिथी",
    rl_no_of_opening: "उद्घाटनों की संख्या",
    rl_work: "कार्य समय",
    rl_lang: "ज्ञात भाषाएँ",
    max_length_preference: "आप अधिकतम चयन कर सकते हैं",
    rl_aboutcompany: "कम्पनी के बारे में",
    rl_turnover: "पलटें",
    rl_fac: "सुविधाएँ प्रदान की जाती हैं",
    log_industry: "उद्योग क्षेत्र",
    rl_activity: "क्रिया के प्रकार",
    rl_company: "कंपनी के प्रकार",
    rl_employee: "कर्मचारियों की संख्या",
    rl_contact: "संपर्क करना",
    rl_address: "पता",
    r1_email: "ईमेल",
    alternative_mobileno: "वैकल्पिक मोबाइल नंबर",
    not_eligible_text: "आप \n आवेदन करने के योग्य नहीं हैं",
    not_eligible_text_1: "(आपकी फिर से शुरू प्राथमिकताएं मेल नहीं खातीं)",
    rl_apply: "अभी अप्लाई करें",
    rl_do_invitation: "क्या आप इस आमंत्रण को स्वीकार करना चाहते हैं?",
    rl_decline: "पतन",
    accept_qus: 'क्या आप वाकई इस आमंत्रण को स्वीकार करना चाहते हैं?',
    decline_qus: "क्या आप वाकई इस आमंत्रण को अस्वीकार करना चाहते हैं?",
    rl_decline_one: 'क्या आप निश्चित रूप से अस्वीकार करना चाहते हैं?',
    rl_accept: "स्वीकार करना",
    rl_accept_one: 'क्या आप वाकई स्वीकार करना चाहते हैं?',
    r1_group: "नौकरी समूह",
    rl_Age: "आयु मानदंड",
    rl_school: "स्कूल",
    r1_daysleft: " दिन शेष",
    expired: "खत्म हो चुका",
    expired_todat: "आज समाप्त हो रहा है",
    r1_to: "प्रति",
    r1_years: "वर्षों",
    contact_msg: "संदेश",
    assistent_call: 'सहायता कॉल के लिए',
    job_head: "मस्तक",
    job_msg: "क्या आप सुनिश्चित हैं कि आप इस जॉब पोस्ट की रिपोर्ट करना चाहते हैं?",
    job_cancel: "रद्द करना",
    job_report: "प्रतिवेदन",
    edu_aft_schl_heading: "शिक्षा",
    edu_aft_schl_sub: "स्कूली शिक्षा के बाद",
    edu_aft_schl_qualification: "योग्यता",
    edu_aft_schl_specialization: "विशेषज्ञता",
    edu_aft_schl_institution: "संस्थान",
    edu_aft_schl_location: "स्थान",
    edu_aft_schl_mark: "सीजीपीए / प्रतिशत",
    edu_aft_schl_year: "",
    edu_aft_schl_button: "बचाना",
    contact_city: "शहर / शहर का नाम",
    preference_heading: "सूचना की प्राथमिकताएं",
    preference_job: "कार्यभार",
    preference_Role: "नौकरी भूमिका",
    preference_Employement: "रोजगार के प्रकार",
    preference_taluk: "नौकरी तालुक",
    preference_time: "शामिल होने का समय",
    preference_time1: "तुरंत",
    preference_time2: "अंदर",
    preference_day: "दिन",
    preference_to: "प्रति",
    preference_salary: "प्रति माह अपेक्षित वेतन",
    preference_button: "",
    rate_ourapp: "हमारे एपीपी को रेट करें",
    apprate_content: "आप हमारे ऐप अनुभव को कैसे रेट करेंगे?",
    later_btn: "बाद में",
    ratenow_btn: "अब रेट करें",
    err_preferences_min_required: "कृपया न्यूनतम वेतन दर्ज करें",
    add_schooling: "कृपया पहले स्कूली जानकारी जोड़ें",
    err_preferences_max_required: "कृपया अधिकतम वेतन दर्ज करें",
    err_preferences_min_max_values: "कृपया मान्य अधिकतम वेतन दर्ज करें",
    err_preferences_max_min_values: "कृपया वैध न्यूनतम वेतन दर्ज करें",
    err_preferences_days_required: 'कृपया दिन दर्ज करें',
    err_preferences_min: "",
    err_preferences_emptype_required: 'कृपया रोजगार का प्रकार चुनें',
    err_job_function_required: 'कृपया कार्य प्रकार्य चुनें',
    err_preference_jobrole_required: 'कृपया कार्य भूमिका चुनें',
    err_preference_joblocation_required: 'कृपया कार्य स्थान चुनें',
    err_preference_jobtaluk_required: 'कृपया जॉब तालुक चुनें',
    err_preference_employementtype_required: "कृपया रोजगार के प्रकार का चयन करें",
    registered_msg: "पंजीकरण सफलतापूर्वक हो गया है",
    err_preference_employementtype_empty: "",
    err_preference_employementtype: "",
    err_job_function_empty: "",
    err_job_function: '',
    err_preference_joblocation: '',
    preference_type: "कार्य का प्रकार",
    err_preferences_days: "  गलती_वरीयता_दिन_खाली",
    err_preferences_days_empty: '',
    contact_msg: "संदेश",
    nameofuse_validate: '',
    nameofuse_validate1: "उपयोगकर्ता का नाम कम से कम 3 वर्णों का होना चाहिए",
    nameofuse_validate2: 'उपयोगकर्ता का नाम 50 वर्णों से अधिक नहीं हो सकता',
    nameofuse_validate3: 'कृपया नाम दर्ज करें',
    nameofuse_validate5: 'कृपया नाम दर्ज करें',
    nameofuse_validate6: "कृपया मान्य नाम दर्ज करें",
    user_validate1: "उपयोगकर्ता नाम कम से कम 3 वर्णों का होना चाहिए",
    user_validate2: 'उपयोगकर्ता नाम 50 वर्णों से अधिक नहीं हो सकता',
    user_validate3: 'कृपया उपयोगकर्ता नाम दर्ज करें',
    user_validate5: 'कृपया उपयोगकर्ता नाम दर्ज करें',
    user_validate6: "कृपया केवल अल्फ़ा और न्यूमेरिक का उपयोग करें और स्पेस और विशेष वर्णों का उपयोग न करें",
    user_validate7: "उपयोगकर्ता का नाम पहले से मौजूद है",
    mob_validate1: 'कृपया मोबाइल नंबर दर्ज करें',
    mob_validate2: "कृपया मान्य मोबाइल नंबर दर्ज करें",
    mob_validate4: "कृपया मान्य मोबाइल नंबर दर्ज करें",
    mob_validate5: "कृपया मोबाइल नंबर दर्ज करें",
    mob_validate7: "मोबाइल नंबर पहले से मौजूद है",
    pass_validate1: "कृप्या पास्वर्ड भरो",
    pass_validate2: "पासवर्ड कम से कम 6 अक्षरों का होना चाहिए",
    pass_validate5: "कृप्या पास्वर्ड भरो",
    pass_validate7: "गलत पासवर्ड",
    pass_validate1_new: "कृप्या पास्वर्ड भरो",
    pass_validate2_new: "पासवर्ड कम से कम 6 अक्षरों का होना चाहिए",
    pass_validate5_new: "कृप्या पास्वर्ड भरो",
    textValue: "ओटीपी प्राप्त करें",
    textValue1: 'ओटीपी पुनः भेजें',
    check_value: "मैं जोबान्या से सहमत हूं",
    check_value1: " नियम और शर्तें",
    password_message: "कृपया वैध मोबाइल नंबर दर्ज करें",
    password_success: "पासवर्ड सफलतापूर्वक बदला गया!",
    username_required: "कृपया मोबाइल नंबर या उपयोगकर्ता नाम दर्ज करें",
    validate_username: "कृपया मान्य उपयोगकर्ता नाम दर्ज करें",
    password_required: "कृप्या पास्वर्ड भरो",
    password_limit: "पासवर्ड 20 वर्णों से कम का होना चाहिए",
    password_length: "कृपया मान्य लॉगिन क्रेडेंशियल दर्ज करें",
    username_length: "कृपया मान्य लॉगिन क्रेडेंशियल दर्ज करें",
    street_name_required: "कृपया सड़क का नाम दर्ज करें",
    areaname_required: "कृपया क्षेत्र का नाम दर्ज करें",
    cityname_required: "कृपया शहर / शहर का नाम दर्ज करें",
    emailid_required: "कृपया ईमेल आईडी दर्ज करें",
    emailid_already_exits: "ईमेल आईडी पहले ही बंद हो चुकी है",
    emailid_format: "कृपया वैध ईमेल दर्ज़ करें",
    mobileno_required: "कृपया मोबाइल नंबर दर्ज करें।",
    mobileno_max: "कृपया वैध मोबाइल नंबर दर्ज करें",
    same_alter_no: "वैकल्पिक। प्राथमिक नहीं हो सकता। नहीं",
    pincode_required: "कृपया पिनकोड दर्ज करें",
    pincode_max: "कृपया मान्य पिनकोड दर्ज करें",
    state_name_required: "कृपया राज्य का नाम दर्ज करें",
    altmobileno_max: "कृपया मान्य वैकल्पिक मोबाइल नंबर दर्ज करें",
    state_required: 'कृपया राज्य चुनें',
    district_required: 'कृपया जिला चुनें',
    city_required: 'कृपया नगर/शहर का चयन करें',
    reference_name_required: "कृपया नाम दर्ज करें",
    reference_designation_required: "कृपया पदनाम दर्ज करें",
    reference_relationship_required: "कृपया संबंध दर्ज करें",
    reference_organization_required: "कृपया संगठन दर्ज करें",
    reference_emailid_required: "कृपया ईमेल आईडी दर्ज करें",
    reference_emailid_format: "कृपया वैध ईमेल दर्ज़ करें",
    reference_mobileno_required: "कृपया मोबाइल नंबर दर्ज करें",
    reference_mobileno_max: "कृपया मान्य मोबाइल नंबर दर्ज करें",
    reference_mobileno_reference: "आपका संपर्क। कोई संदर्भ नहीं हो सकता। नहीं",
    references_candidate_required: 'कृपया उम्मीदवार चुनें',
    schooling_institution_required: "कृपया संस्थान दर्ज करें",
    schooling_qualification_required: "कृपया योग्यता चुनें",
    after_schooling_institution_required: "कृपया संस्थान दर्ज करें",
    after_schooling_location_required: "कृपया स्थान दर्ज करें",
    after_schooling_mark_required: "कृपया प्रतिशत दर्ज करें",
    after_schooling_category_required: "कृपया श्रेणी चुनें",
    after_schooling_qualification_required: "कृपया योग्यता चुनें",
    after_schooling_year_required: "कृपया उत्तीर्ण होने का वर्ष दर्ज करें",
    after_schooling_year_validate: "कृपया उत्तीर्ण होने का वैध वर्ष दर्ज करें",
    after_schooling_specialization_required: "कृपया विशेषज्ञता चुनें",
    experience_organization_required: "कृपया संगठन दर्ज करें",
    experience_designation_required: "कृपया पदनाम दर्ज करें",
    experience_location_required: "कृपया स्थान दर्ज करें",
    experience_fromyear_required: "कृपया वर्ष दर्ज करें",
    experience_tomonth_required: "कृपया माह दर्ज करें",
    experience_toyear_required: "कृपया वर्ष दर्ज करें",
    experience_years: "वर्षों",
    personalinfo_name_required: "कृपया नाम दर्ज करें ",
    personalinfo_fathername_required: "कृपया पिता का नाम दर्ज करें",
    personalinfo_spousename_required: "कृपया जीवनसाथी का नाम दर्ज करें",
    personalinfo_aadharno_required: "कृपया आधार संख्या दर्ज करें",
    aadhar_exists: "आधार संख्या पहले से मौजूद है",
    personalinfo_aadharno_max: "आधार नंबर 12 अंकों का होना चाहिए",
    personalinfo_lang_required: "कृपया भाषा दर्ज करें",
    personalinfo_ageerror_required: "उम्र कम से कम 18 साल होनी चाहिए",
    personalinfo_resume_required: "",
    personalinfo_gender_required: 'कृपया लिंग चुनें',
    personalinfo_Dob_required: 'कृपया जन्म तिथि चुनें',
    personalinfo_mar_required: 'कृपया वैवाहिक स्थिति का चयन करें',
    preferences_min_required: "कृपया न्यूनतम वेतन दर्ज करें",
    preferences_max_required: "कृपया अधिकतम वेतन दर्ज करें",
    preferences_days_required: 'कृपया दिन दर्ज करें',
    preferences_emptype_required: 'कृपया रोजगार का प्रकार चुनें',
    job_function_required: 'कृपया कार्य प्रकार्य चुनें',
    preference_jobfunction_required: 'कृपया कार्य प्रकार्य चुनें',
    preference_jobrole_required: 'कृपया कार्य भूमिका चुनें',
    preference_joblocation_required: 'कृपया कार्य स्थान का चयन करें',
    skills_required: 'कृपया कौशल चुनें',
    skill_catagory_required: 'कृपया जॉब फंक्शन चुनें',
    skill_sub_catagory_required: 'कृपया कार्य भूमिका चुनें',
    sub: "विषय",
    user_not_found: 'आपका खाता सक्रिय नहीं है',
    abused_content: " आपका खाता सक्रिय नहीं है ",
    invalid_credentials: 'उपयोगकर्ता नाम या पासवर्ड ग़लत है',
    filter_skills: 'कौशल',
    filter_location: 'स्थान',
    filter_jobfunction: 'कार्यभार',
    filter_jobtype: 'कार्य का प्रकार',
    filter_education: 'शिक्षा',
    filter_experience: 'अनुभव',
    filter_industry: 'उद्योग',
    filter_salaryrange: 'वेतन सीमा प्रति माह',
    filter_employertype: 'नियोक्ता प्रकार',
    filter_companytype: 'कंपनी के प्रकार',
    filter_jobgroup: 'नौकरी समूह',
    login_success: "लॉगिन सफलतापूर्वक",
    pwd_changed_success: "पासवर्ड सफलतापूर्वक बदला गया",
    document_download: "दस्तावेज़ डाउनलोड हो रहा है",
    update_total_exp: "कृपया अनुभव के कुल वर्ष दर्ज करें",
    updated_success: "सफलतापूर्वक अपडेट किया गया",
    created_success: "सफलतापूर्वक बनाया गया",
    record_not_found: "रिकॉर्ड नहीं मिला",
    deleted_success: "सफलतापूर्वक मिटाया गया",
    deleted_error: "हटाया नहीं जा सकता",
    deactivate_success: "सफलतापूर्वक निष्क्रिय",
    active_success: "सफलतापूर्वक सक्रिय",
    education_afterschool_year_overlap: "कृपया एक मान्य वर्ष दर्ज करें",
    saved_success: "सफलतापूर्वक बचाया",
    abuse_error: "",
    bellow_sslc_error: "कृपया अपने स्कूली शिक्षा डेटा की जाँच करें",
    already_exist: "पहले से ही मौजूद",
    user_exist: "उपयोगकर्ता का नाम पहले से मौजूद है",
    user_not_exist: "मोबाइल नंबर या उपयोगकर्ता नाम मौजूद नहीं है",
    mobile_no_exist: "मोबाइल नंबर पहले से मौजूद है",
    mobile_not_reg: "मोबाइल नंबर पंजीकृत नहीं है",
    invalid_credentials: "उपयोगकर्ता नाम या पासवर्ड गलत है",
    register_success: "सफलतापूर्वक पंजीकृत",
    contactus_msg_error: "कृपया संदेश दर्ज करें",
    contactus_subject_error: "कृपया विषय का चयन करें",
    applied_success: "सफलतापूर्वक लागू",
    employer_Subscribed_Successfully: 'सफलतापूर्वक सब्सक्राइब किया गया',
    employer_email_changed: 'ई मेल सफलतापूर्वक बदल गया',
    employer_email_exists: 'ई मेल पहले से मौजूद है',
    employer_otp_message_expired: 'एक्सपायर्ड ओटीपी',
    employer_seconds_ago: 'एक सेकंड पहले',
    employer_minutes_ago: 'कुछ मिनट पहले',
    employer_hours_ago: 'घंटो पहले',
    employer_days_ago: ' दिन पहले',
    employer_months_ago: 'महिनो पहले',
    employer_years_ago: 'बहुत साल पहले',
    employer_update_available: "उपलब्ध अद्यतन",
    employer_update_msg: "एक अपडेट उपलब्ध है। क्या आप निश्चित रूप से अपडेट करना चाहते हैं?",
    employer_force_update: "फोर्स अपडेट नाउ",
    employer_any_qualification: "कोई योग्यता",
    employer_any_specl: "कोई विशेषज्ञता",
    employer_any_Degree: "कोई भी शिक्षा श्रेणी",
    employer_since_experience: "तब से",
    employer_selectlangheadertag: "पसंदीदा भाषा     ",
    preferedLocation: "नौकरी पसंदीदा स्थान",
    employer_date: "दिनांक",
    Unlimited: 'असीमित',
    employer_unlimited: '*उल-असीमित',
    employer_unlimited_short: 'उल',
    employer_company_profile: "कंपनी प्रोफाइल",
    employer_no_records: "कोई रिकॉर्ड नहीं मिला",
    employer_no_notification: "कोई नई सूचना नहीं मिली",
    employer_job_accept: "नौकरी आवेदन स्वीकृत",
    employer_job_accept_already: "नौकरी का आवेदन पहले ही स्वीकार किया जा चुका है",
    employer_shortlist_limit_exceed: "शॉर्टलिस्ट की सीमा पार हो गई है",
    employer_job_reject_already: "नौकरी का आवेदन पहले ही खारिज हो चुका है",
    employer_job_reject: "नौकरी आवेदन अस्वीकृत",
    employer_no_jobpost: "कोई नौकरी पोस्ट नहीं मिला",
    employer_no_active_subscription: "कोई सक्रिय सदस्यता नहीं मिली",
    employer_document_download: "दस्तावेज़ डाउनलोड हो रहा है",
    employer_language_required: "कृपया भाषाओं का चयन करें",
    employer_Contact_state_errormsg: "कृपया राज्य का चयन करें",
    employer_selectedlanguage_heading: "कृपया अपनी भाषा चुनें",
    employer_langselectnext: "अगला",
    employer_sliderfirsttitle: "जोबान्या में आपका स्वागत है",
    employer_sliderfirstdesc: "कैरियर प्रेरणा की तलाश में हैं? यहां आप अकादमिक लाइब्रेरियन से लेकर युवा कार्यकर्ता तक सैकड़ों विभिन्न नौकरियों के बारे में पता लगा सकते हैं।",
    employer_slidersecondtitle: "",
    employer_sliderseconddesc: "प्रत्येक भूमिका के लिए विशिष्ट जिम्मेदारियों और नियोक्ताओं के बारे में पता लगाने के लिए हमारे कार्य विवरण ब्राउज़ करें, ताकि आप देख सकें कि क्या शामिल है और कौन आपको नियुक्त कर सकता है।",
    employer_sliderthirdtitle: "",
    employer_sliderthirddesc: "कुछ भूमिकाएं किसी भी डिग्री पृष्ठभूमि के आवेदकों के लिए खुली हैं, जबकि अन्य के लिए स्नातकोत्तर योग्यता की विशिष्ट डिग्री की आवश्यकता होती है।",
    employer_sliderfourtitle: "",
    employer_sliderfourdesc: "संबंधित नौकरी विवरणों के समूहों का पता लगाने के लिए कैरियर क्षेत्र द्वारा फ़िल्टर करें और उस तरह की भूमिका के बारे में अपने विचारों को सुधारें जो आपको सूट करे।",
    employer_getstarted: "शुरू हो जाओ",
    employer_pass_validate2: "पासवर्ड कम से कम 6 अक्षरों का होना चाहिए",
    employer_pass_validate1: "कृप्या पास्वर्ड भरो",
    employer_pass_validate5: "कृप्या पास्वर्ड भरो",
    employer_pass_validate1_new: "कृप्या पास्वर्ड भरो",
    employer_pass_validate5_new: "कृप्या पास्वर्ड भरो",
    employer_contact_company_address_errormsg: "कृपया पता दर्ज करें",
    employer_pass_validate7: "गलत पासवर्ड",
    employer_pass_validate2_new: "पासवर्ड कम से कम 6 अक्षरों का होना चाहिए",
    employer_pwd_changed_success: "पासवर्ड सफलतापूर्वक बदला गया",
    employer_loginhead: 'नियोक्ता',
    employer_textValue: "ओटीपी प्राप्त करें",
    employer_textValue1: "ओटीपी पुनः भेजें",
    employer_loginsubhead: "जारी रखने के लिए साइन इन करें",
    employer_mobileplaceholder: 'मोबाइल नंबर/उपयोगकर्ता नाम',
    employer_passwordplaceholder: 'पासवर्ड',
    employer_forgetpassword: 'पासवर्ड भूल गए?',
    employer_signinbutton: 'साइन इन करें',
    employer_signupbutton: 'नया नियोक्ता? साइन अप करें',
    employer_emailid: "ईमेल आईडी",
    employer_website_required: "कृपया वेबसाइट दर्ज करें",
    employer_phone_num_check: "कृपया मान्य मोबाइल नंबर दर्ज करें",
    employer_valid_salary: "न्यूनतम वेतन न्यूनतम 4 अंकों का होना चाहिए",
    employer_email_required: "कृपया ईमेल दर्ज करें",
    employer_forgotsubmit: 'प्रस्तुत करना',
    employer_otptext: 'ओटीपी',
    employer_err_preferences_min_max_values: "कृपया वैध वेतन दर्ज करें",
    employer_minsal_required: "कृपया न्यूनतम वेतन दर्ज करें",
    employer_maxsal_required: "कृपया अधिकतम वेतन दर्ज करें",
    employer_minage_required: "कृपया न्यूनतम आयु दर्ज करें",
    employer_maxage_required: "कृपया अधिकतम आयु दर्ज करें",
    employer_getotptext: 'ओटीपी प्राप्त करें',
    employer_emailid_format: "कृपया मान्य ईमेल पता दर्ज करें",
    employer_reg_email_error: "कृपया पंजीकृत ईमेल दर्ज करें",
    employer_mail_exists: "ईमेल पहले से मौजूद है",
    employer_prefer_ok: "ठीक है",
    employer_home_header_title: 'शिवा सॉफ्टवेयर सॉल्यूशंस',
    employer_home_header_title1: 'हाय सूर्या',
    employer_home_tab_post: 'माई जॉब पोस्ट',
    employer_home_tab_recommended: "",
    employer_home_tab_applied: 'लागू',
    employer_home_tab_invited: 'आमंत्रित',
    employer_home_tab_wishlist: 'इच्छा सूची',
    employer_home_tab_Shortlisted: "शॉर्टलिस्टेड",
    employer_home_tab_Description: "विवरण",
    employer_home_jobtype: 'कार्य का प्रकार',
    employer_home_industry: 'उद्योग',
    employer_home_jobfunction: 'कार्यभार',
    employer_home_govjobs: 'सरकारी नौकरियों',
    employer_looking_more: "क्या आप अधिक संख्या में जॉब पोस्ट और प्रोफाइल ढूंढ रहे हैं?",
    employer_sidebar_home: "घर",
    employer_sidebar_profile: "कंपनी प्रोफाइल",
    employer_sidebar_subscription: "सदस्यता",
    employer_sidebar_news: "समाचार",
    employer_sidebar_events: "आयोजन",
    employer_sidebar_settings: "समायोजन",
    employer_sidebar_contactus: "संपर्क करें",
    employer_sidebar_share: "शेयर करना",
    join_us: 'हमसे जुड़ें',
    rate_us: 'हमें रेट करें और समीक्षा करें',
    employer_sidebar_rating: "रेटिंग और समीक्षा",
    employer_sidebar_logout: "लॉग आउट",
    employer_resume_heading: "",
    employer_resume_personalinfo: "व्यक्तिगत जानकारी",
    employer_resume_contactinfo: "संपर्क सूचना",
    employer_resume_education: ",शिक्षा",
    education_category: "शिक्षा श्रेणी",
    employer_resume_skills: "कौशल",
    employer_resume_exp: "अनुभव",
    employer_resume_ref: "संदर्भ",
    employer_resume_preference: "सूचना की प्राथमिकताएं",
    employer_personal_header: "व्यक्तिगत जानकारी",
    employer_personal_fullname: "पूरा नाम",
    employer_personal_fathername: "पिता का नाम",
    employer_personal_spousename: "जीवनसाथी का नाम",
    employer_personal_dob: "जन्म की तारीख",
    employer_personal_gender: "लिंग",
    employer_personal_marital: "वैवाहिक स्थिति",
    employer_personal_aadhar: "उद्यम/उद्योग आधार संख्या",
    employer_personal_lg: "ज्ञात भाषाएँ",
    employer_personal_medical: "कोई ज्ञात चिकित्सा इतिहास",
    employer_personal_upload: "रिज्यूमे अपलोड करें",
    employer_personal_save: "बचाना",
    employer_personal_diff: "अलग रूप से सक्षम",
    employer_personal_All: 'सभी',
    employer_personal_yes: "हाँ",
    employer_personal_no: "नहीं",
    employer_personal_include: "शामिल",
    employer_personal_only: "केवल अलग-अलग एबल्ड",
    employer_personal_add: "जोड़ें",
    employer_personal_format: "(डॉक्टर, डॉक्स और पीडीएफ प्रारूप केवल)",
    employer_contact_header: "संपर्क सूचना",
    employer_contact_door: "द्वार संख्या / गली",
    employer_contact_area: "क्षेत्र",
    employer_contact_city: "शहर / शहर का नाम",
    employer_contact_district: "ज़िला",
    employer_contact_state: "राज्य",
    employer_contact_pincode: "पिन कोड",
    employer_contact_emailid: "ईमेल आईडी",
    employer_contact_mbleno: "मोबाइल नहीं है।",
    employer_contact_altmbleno: "वैकल्पिक मोबाइल नं.",
    employer_contact_altmbleno_contact: "वैकल्पिक संपर्क नंबर",
    employer_contact_save: "बचाना",
    employer_address: "स्थान",
    employer_edu_header: "शिक्षा",
    employer_edu_schooling: "स्कूली शिक्षा",
    employer_edu_qualification: "योग्यता",
    employer_edu_insti: "संस्थान",
    employer_edu_loc: "स्थान",
    employer_edu_mark: "मार्क का प्रतिशत",
    employer_edu_yrofpassing: "उत्तीर्ण होने का वर्ष",
    employer_edu_save: "बचाना",
    employer_exit_cancel: "रद्द करना",
    employer_any_show: "कोई",
    employer_ref_popup_header: "रिश्ता",
    employer_ref: "संदर्भ",
    employer_ref_name: "नाम",
    employer_ref_desig: "पद का नाम",
    employer_branch_email: "ईमेल आईडी",
    employer_branchname_required: "कृपया शाखा का नाम दर्ज करें",
    employer_person_name_required: "कृपया संपर्क व्यक्ति का नाम दर्ज करें",
    employer_designation_required: "कृपया पदनाम दर्ज करें",
    employer_pincode_required: "कृपया पिनकोड दर्ज करें",
    employer_pincode_valid: "कृपया मान्य पिनकोड दर्ज करें",
    employer_companyaddress_required: "कृपया कंपनी का पता दर्ज करें",
    employer_companyname_required: "कृपया कंपनी का नाम दर्ज करें",
    employer_mobnum_required: "कृपया मोबाइल नंबर दर्ज करें",
    employer_mobnum_valid: "कृपया वैध मोबाइल नंबर दर्ज करें",
    employer_telenum_required: "कृपया टेलीफोन नंबर दर्ज करें",
    employer_telenum_valid: "कृपया वैध टेलीफोन नंबर दर्ज करें",
    employer_ref_org: "संगठन",
    employer_ref_relationship: "उम्मीदवार से संबंध",
    employer_ref_remarks: "टिप्पणियां",
    // employer_ref_postjob:"Post Job",
    employer_ref_saveasdraft: "ड्राफ्ट के रूप में सेव करें",
    employer_ref_save: "बचाना",
    employer_ref_del: "मिटाना",
    employer_ref_update: "अद्यतन",
    employer_later: 'बाद में',
    employer_per_view_header: "प्रोफ़ाइल",
    employer_per_view_marital: "वैवाहिक स्थिति",
    employer_per_view_father_name: " पिता का नाम",
    employer_per_view_spouse_name: "जीवनसाथी का नाम",
    employer_per_view_dob: "जन्म की तारीख",
    employer_per_view_aadhar: "उद्यम/उद्योग आधार संख्या",
    employer_per_view_lang: "ज्ञात भाषाएँ",
    employer_per_view_medical: "कोई ज्ञात चिकित्सा इतिहास",
    employer_per_view_differentlyabled: "अलग रूप से सक्षम",
    employer_per_contact: "संपर्क सूचना",
    employer_per_edu: "शिक्षा",
    employer_per_school: "स्कूली शिक्षा",
    employer_per_after_school: "स्कूली शिक्षा के बाद",
    employer_per_skills: "जॉब फंक्शन, जॉब रोल और स्किल्स",
    employer_per_ref: "संदर्भ (द्वारा अनुशंसित)",
    employer_per_pre: "अधिसूचना प्राथमिकताएं",
    employer_pr_view_header: "प्रोफ़ाइल",
    employer_Pr_company: "कंपनी के प्रकार",
    employer_pr_emp_type: "नियोक्ता प्रकार",
    employer_pr_industry: "उद्योग क्षेत्र",
    employer_pr_activity: "क्रिया के प्रकार",
    employer_pr_branch: "शाखा विवरण",
    employer_pr_abt_company: "कम्पनी के बारे में",
    employer_pr_pre: "सूचना की प्राथमिकताएं",
    employer_pr_contact: "संपर्क सूचना",
    Employer_referredby: "द्वारा उल्लिखित",
    employer_pr_gn: "",
    employer_pr_gstin1: "जीएसटीआईएन",
    employer_pr_gstin: "जीएसटीआईएन (सभी पेज शामिल करें)",
    employer_pr_pan: "बरतन",
    employer_pr_aadhar: "उद्यम/उद्योग आधार संख्या.",
    employer_pr_aadhar_no: "आधार संख्या",
    documentvalidate: 'कृपया अनुलग्नकों के बिना तीन वैध पंजीकरण संख्याएं प्रदान करें (या) अनुलग्नकों के साथ दो वैध पंजीकरण संख्याएं प्रदान करें',
    employer_pr_facilities: "सुविधाएँ प्रदान की जाती हैं",
    employer_pr_job_func: "कार्यभार",
    employer_pr_job_role: "नौकरी भूमिका",
    employer_pr_Loc: "स्थान",
    employer_pr_turnover: "पलटें",
    employer_pr_no_emp: "कर्मचारियों की संख्या",
    employer_exp_totalexp: "कुल अनुभव",
    employer_exp_years: "वर्षों",
    employer_rl_fresher: "फ्रेशर",
    employer_exp_heading: "अनुभव",
    employer_exp_org: "संगठन ",
    employer_exp_desig: "पद का नाम",
    employer_exp_location: "स्थान",
    employer_exp_from: "से",
    employer_exp_to: "प्रति",
    employer_exp_month: "महीना",
    employer_exp_year: "साल",
    employer_exp_currently: "वर्तमान में यहाँ काम कर रहा हूँ",
    employer_exp_save: "बचाना",
    employer_pre_emptype: "रोजगार के प्रकार",
    employer_pre_timeforjg: "शामिल होने का समय",
    employer_pre_immediate: "तुरंत",
    employer_pre_within: "अंदर",
    employer_pre_days: "दिन",
    employer_pre_expected: "प्रति माह अपेक्षित वेतन",
    employer_pre_permnth: "प्रति महीने",
    employer_pre_header_popup_type: "कर्मचारी का प्रकार",
    employer_pre_header_popup_sal: "वेतन",
    employer_pre_to: "प्रति",
    employer_pre_jobrole: "नौकरी भूमिका",
    employer_pre_fun: "कार्यभार",
    employer_skills_heading: "कौशल",
    employer_skills_jobfunction: "कार्यभार",
    employer_skills_Role: "नौकरी भूमिका",
    employer_mobileNo_change_header: "ईमेल आईडी बदलें",
    employer_mobileNo_change_number: "मोबाइल नहीं है।",
    employer_email_change_current: "वर्तमान ईमेल आईडी",
    employer_email_change_email: "ईमेल आईडी",
    employer_mobileNo_change_otp: "ओटीपी",
    employer_mobileNo_change_change_mobile_number_button: "ईमेल आईडी बदलें",
    employer_noti_head: "अधिसूचना",
    employer_noti_jobs: "नौकरी संबंधित",
    employer_noti_news: "समाचार",
    employer_noti_events: "घटनाक्रम",
    employer_events_head: "घटनाएँ",
    employer_job_description: "विवरण",
    employer_news_head: "समाचार",
    employer_link: "संपर्क",
    employer_filterby: "के द्वारा छनित",
    employer_govt_jobs: "सरकारी नौकरियों",
    employer_noti_button: "सभी खारिज",
    employer_noti_swipe: "(खारिज करने के लिए बाएं स्वाइप करें)",
    employer_password_change_header: "पासवर्ड बदलें",
    employer_custom_plan: 'कस्टम योजना',
    employer_password_change_old_password: "वर्तमान पासवर्ड",
    employer_password_change_new_password: "नया पासवर्ड",
    employer_password_change_change_password_button: "पासवर्ड बदलें",
    employer_registration_user: "उपयोगकर्ता का नाम",
    employer_registration_mobileno: "मोबाइल नहीं है",
    employer_registration_password: "पासवर्ड",
    employer_registration_otp: "ओटीपी",
    employer_registration_otpbutton: "ओटीपी प्राप्त करें",
    employer_registration_checkbox: "मैं जोबान्या से सहमत हूं",
    employer_registration_submitbutton: "साइन अप करें",
    employer_forgetpassword_mobileno: "मोबाइल नहीं है",
    employer_forgetpassword_otp: "ओटीपी",
    employer_forgetpassword_password: "पासवर्ड",
    employer_forgetpassword_submit: "प्रस्तुत करना",
    employer_Setting_heading: "समायोजन",
    employer_Setting_changepassword: "पासवर्ड बदलें",
    employer_Setting_email: "ईमेल आईडी बदलें",
    employer_Setting_Notification: "अधिसूचना",
    employer_Setting_Preferedlanguage: "पसंदीदा भाषा",
    employer_Setting_invisiblemode: "अदृश्य मोड",
    employer_Notification_invisiblemode: "अदृश्य मोड",
    employer_Notification_msg1: "क्या आप वाकई अपने खाते को अदृश्य मोड में बदलना चाहते हैं?",
    employer_Notification_msg2: "कर्मचारी आपका खाता नहीं देख पाएंगे।",
    employer_Notification_cancel: "रद्द करना",
    employer_Notification_submit: "प्रस्तुत करना",
    employer_Notification_heading: "अधिसूचना",
    employer_Notification_jobs: "नौकरी संबंधित",
    employer_Notification_news: "समाचार",
    employer_Notification_events: "आयोजन",
    employer_reg_faci: "सुविधाएँ प्रदान की जाती हैं",
    employer_Contactus_heading: "संपर्क करें",
    employer_Contactus_website: "वेबसाइट",
    employer_Contactus_emailid: "ईमेल आईडी",
    employer_Contactus_mobileno: "मोबाइल नहीं है।",
    employer_Contactus_subject: "विषय",
    employer_Contactus__description: "विवरण",
    employer_Contactus_button: "प्रस्तुत करना",
    employer_logout_heading: "लॉग आउट",
    employer_logout_msg: "क्या आप वाकई लॉगआउट करना चाहते हैं?",
    employer_logout_cancel: "रद्द करना",
    employer_logout_logout: "लॉग आउट",
    employer_Search_heading: "सामान्य प्रोफ़ाइल खोज",
    employer_Recent_Search: "हाल की खोज",
    employer_Placeholder: "स्थान, कार्य प्रकार्य, कार्य भूमिका द्वारा खोजें",
    employer_Placeholder1: "स्थान के आधार पर खोजें",
    employer_gn_desc_job_desc: "नौकरी का विवरण",
    employer_gn_desc_link: "संपर्क",
    employer_gn_desc_downloads: "डाउनलोड",
    employer_gn_no_vacanies: "रिक्तियों की संख्या",
    employer_gn_Expiry: "अंतिम तिथी",
    employer_rl_freshers: " फ्रेशर्स ",
    employer_rl_sortby: "इसके अनुसार क्रमबद्ध करें",
    employer_rl_filterby: "के द्वारा छनित",
    employer_rl_applyfilter: "फ़िल्टर लागू करें",
    employer_rl_jobtype: "कार्य का प्रकार",
    employer_rl_experience: "अनुभव",
    employer_rl_qualification: "शिक्षा",
    employer_rl_skills: "कौशल",
    employer_rl_description: "नौकरी का विवरण",
    employer_description: "नौकरी पोस्ट अस्वीकृति से बचने के लिए कार्य विवरण में संपर्क जानकारी शामिल न करें",
    employer_rl_grp: "नौकरी समूह",
    employer_rl_maritial: "वैवाहिक स्थिति",
    employer_rl_gender: "लिंग",
    employer_rl_age: "आयु मानदंड",
    employer_rl_differently: "अलग रूप से सक्षम",
    employer_sal_range: "प्रति माह वेतन सीमा",
    employer_rl_any: "कोई",
    employer_rl_no: "नहीं",
    employer_r1_to: "प्रति",
    employer_r1_years: "वर्षों",
    employer_rl_preferred: "पसंदीदा",
    employer_rl_jobtaluk: "नौकरी तालुक",
    job_rl_jobtaluk: "उम्मीदवार की नौकरी वरीयता स्थान - तालुक",
    employer_candidate_location: "उम्मीदवार स्थान",
    job_candidate_location: "उम्मीदवार का गृह स्थान - जिला",
    employer_candidate_taluk: "उम्मीदवार तालुक",
    job_candidate_taluk: "उम्मीदवार का गृह स्थान - तालुक",
    employer_rl_lastdate: "अंतिम तिथी",
    employer_rl_no_of_opening: "उद्घाटन की संख्या",
    employer_rl_work: "कार्य समय",
    employer_rl_lang: "भाषाएँ",
    employer_rl_aboutcompany: "कम्पनी के बारे में",
    employer_rl_turnover: "पलटें",
    employer_rl_fac: "सुविधाएँ प्रदान की जाती हैं",
    employer_rl_activity: "क्रिया के प्रकार",
    employer_rl_employee: "कर्मचारी की संख्या",
    employer_rl_contact: "संपर्क करना",
    employer_rl_address: "पता",
    employer_rl_do_invitation: "क्या आप इस आवेदक के आवेदन को स्वीकार करना चाहते हैं?",
    employer_rl_decline: "पतन",
    employer_rl_accept: "स्वीकार करना",
    employer_r1_group: "नौकरी समूह",
    employer_rl_Age: "आयु मानदंड",
    employer_rl_school: "स्कूल",
    employer_jp_repost: "रेपोस्ट",
    employer_jp_clone: "क्लोन",
    employer_editable: "(संपादन योग्य)",
    employer_noneditable: "(गैर-संपादन योग्य)",
    employer_jp_on: "पर",
    employer_jp_off: "बंद",
    employer_jp_filter: "फ़िल्टर बाय जॉब रोल",
    employer_search_results: "सुझाए गए परिणाम",
    employer_log_heading: "नियोक्ता पंजीकरण",
    employer_sliderfirsttitle1: "कंपनी की बुनियादी जानकारी",
    employer_slidersecondtitle2: "एचआर संपर्क जानकारी",
    employer_sliderthirdtitle3: "सरकारी पहचान",
    employer_log_regcomname: "पंजीकृत कंपनी का नाम",
    employer_log_companytype: "कंपनी के प्रकार",
    employer_log_industry: "उद्योग क्षेत्र",
    employer_log_activity: "क्रिया के प्रकार",
    employer_log_employertype: "नियोक्ता प्रकार",
    employer_log_regmail: "पंजीकृत ईमेल आईडी",
    employer_log_password: "पासवर्ड",
    employer_log_state: "राज्य",
    employer_log_district: "ज़िला",
    employer_log_pincode: "पिन कोड",
    employer_log_regaddress: "सड़क/सड़क/क्षेत्र",
    Company_Address_Placeholder: 'दरवाज़ा नंबर, सड़क का नाम, इलाका',
    employer_log_mobile: "मोबाइल नहीं है।",
    employer_log_telephone: "टेलीफ़ोन",
    employer_log_website: "वेबसाइट",
    employer_log_check: "संपर्क पता कंपनी पंजीकृत पते के समान है",
    employer_log_contactperson: "संपर्क व्यक्ति",
    employer_log_designation: "पद",
    employer_log_city: "शहर / शहर का नाम",
    employer_log_taluk: "तालुक",
    employer_log_address: "कंपनी का पता",
    employer_log_mobileno: "मोबाइल नहीं है।",
    employer_log_question: "आप जोबान्या के बारे में कैसे जानते हैं?",
    employer_log_gstin: "कंपनी जीएसटीआईएन",
    employer_log_pan: "कंपनी पैन",
    employer_log_pan_no: "बरतन",
    employer_log_aadhar: "उद्योग/उद्योग आधार प्रमाणपत्र",
    employer_log_aadhar_no: "आधार संख्या",
    employer_company_address: 'पंजीकृत कंपनी का पता',
    employer_log_faci: "सुविधाएं प्रदान की जाती हैं",
    employer_contactinfo_header: "संपर्क जानकारी संपादित करें",
    employer_contactinfo_person: "संपर्क व्यक्ति",
    employer_contactinfo_designation: "पद का नाम",
    employer_contactinfo_address: "कंपनी का पता",
    employer_contactinfo_city: "शहर / शहर का नाम",
    employer_contactinfo_pincode: "पिन कोड",
    employer_contactinfo_mobile: "मोबाइल नहीं है।",
    employer_contactinfo_telephone: "टेलीफ़ोन",
    employer_contactinfo_email: "ईमेल आईडी",
    employer_contactinfo_Website: "वेबसाइट",
    employer_contactinfo_map: "नक्शा स्थान",
    employer_contact_msg: "संदेश",
    employer_job_head: "दुरुपयोग होने की सूचना दें",
    employer_at: "पर",
    employer_job_msg: "क्या आप सुनिश्चित हैं कि आप इस कर्मचारी की रिपोर्ट करना चाहते हैं?",
    employer_job_cancel: "रद्द करना",
    employer_job_report: "प्रतिवेदन",
    employer_edu_aft_schl_specialization: "विशेषज्ञता",
    employer_logo: " कंपनी का लोगो",
    employer_preference_heading: "सूचना की प्राथमिकताएं",
    employer_preference_job: "कार्यभार",
    employer_preference_Role: "नौकरी भूमिका",
    employer_preference_type: "कार्य का प्रकार",
    employer_preference_time: "शामिल होने का समय",
    employer_preference_to: "प्रति",
    employer_preference_salary: "प्रति माह अपेक्षित वेतन",
    employer_preference_button: "अगला",
    employer_contact_us_post: "पदों की संख्या",
    employer_contact_us_head: "संपर्क करें",
    employer_contact_us_profile: "प्रोफाइल की संख्या",
    employer_contact_us_vacancies: "रिक्तियों की संख्या",
    employer_contact_us_desc: "विवरण",
    employer_contact_us_submit: "प्रस्तुत करना",
    employer_subscription_heading: "सदस्यता",
    employer_subscription_active: "सक्रिय",
    employer_subscription_history: "इतिहास",
    employer_subscription_subscribe: "सदस्यता लें",
    employer_buy: "अभी खरीदें",
    employer_sub_post: "नौकरी पदों की संख्या",
    employer_sub_vacancy: " 'नहीं। नौकरी की रिक्तियों / पोस्ट 'की ",
    employer_sub_viewed: "प्रोफाइल की संख्या जो देख सकते हैं / पोस्ट कर सकते हैं",
    employer_sub_applied: "प्रोफाइल की संख्या जो आवेदन / पोस्ट कर सकते हैं",
    employer_sub_invited: "प्रोफाइल की संख्या जिन्हें आमंत्रित / पोस्ट किया जा सकता है",
    employer_sub_shortlist: "प्रोफाइलों की संख्या जिन्हें शॉर्टलाइज्ड / पोस्ट किया जा सकता है",
    employer_sub_total: "कुल",
    employer_sub_posted: "की तैनाती",
    employer_sub_available: "उपलब्ध",
    employer_sub_active: "सक्रिय",
    employer_sub_max: "पोस्टिंग की तिथि से प्रति जॉब पोस्ट की अधिकतम वैधता",
    employer_sub_exp: "पर समय सीमा समाप्त",
    employer_sub_package_validity: "पैकेज की वैधता",
    employer_sub_choose_subscription: "अपनी सदस्यता चुनें",
    employer_forgot_email: "ईमेल आईडी",
    employer_abou_com_com: "कम्पनी के बारे में",
    employer_abou_com_turn: "टर्न ओवर",
    employer_abou_com_No: "कर्मचारियों की संख्या",
    employer_branch_head: "शाखा विवरण",
    employer_branch_branch: "शाखा का नाम",
    employer_branch_contact: "संपर्क व्यक्ति",
    employer_branch_state: "राज्य",
    employer_branch_district: "ज़िला",
    employer_branchtype: "शाखा प्रकार",
    employer_branch_taluk: "तालुक",
    employer_branch_city: "शहर / शहर का नाम",
    employer_branch_pincode: "पिन कोड",
    employer_branch_address: "कंपनी का पता",
    employer_profile_edit: "प्रोफ़ाइल विवरण अपडेट करें",
    employer_branch_mobile: "मोबाइल नहीं है।",
    employer_branch_telephone: "टेलीफ़ोन नंबर।",
    employer_branch_head1: "शाखा विवरण संपादित करें",
    employer_branch_head2: "शाखा विवरण जोड़ें",
    employer_abou_com_attach: "अनुरक्ति",
    employer_comapny_photos: "कंपनी तस्वीरें",
    employer_abou_com_gallery: "ध्यान दें: अधिकतम 3 फ़ोटो की अनुमति है",
    employer_post_job_header: "पोस्ट द जॉब",
    employer_post_job_header1: "एक नौकरी पोस्ट करो",
    employer_post_job_details: "नौकरी विवरण",
    employer_post_job_company: "शाखा का नाम",
    employer_post_job_hours: "घंटे",
    employer_post_job_minutes: "मिनट",
    employer_Post_job_function: "कार्यभार",
    employer_post_job_Role: "नौकरी भूमिका",
    employer_post_job_skills: "कौशल",
    employer_post_job_desc: "विवरण",
    employer_post_job_exp: "अनुभव",
    employer_post_job_quali: "योग्यता",
    employer_post_job_jobtype: "कार्य का प्रकार",
    employer_post_job_noofopen: "नंबर ऑफ ओपनिंग",
    shift_name: 'शिफ्ट नाम',
    shift_txt: 'बदलाव',
    start_txt: 'शुरू',
    end_txt: " समाप्त ",
    shift_name1: 'शिफ्ट नाम',
    start_txt1: 'समय शुरू',
    end_txt1: 'अंत समय',
    shift_error: 'कृपया शिफ्ट का नाम चुनें',
    starthours_error: 'कृपया प्रारंभ घंटे चुनें',
    startminutes_error: 'कृपया प्रारंभ मिनट चुनें',
    endhours_error: 'कृपया समाप्ति समय चुनें',
    endminutes_error: 'कृपया समाप्ति मिनट चुनें',
    fromtimetext_error: "",
    totimetext_error: 'कृपया समाप्ति समय चुनें',
    sametime_error: 'कृपया मान्य समय चुनें',
    shiftttime_exits: 'कृपया मान्य समय चुनें',
    shiftname_exits: 'शिफ्ट नाम पहले से मौजूद है',
    employer_post_job_work: "कार्य समय",
    add_post_job_work: "कार्य समय जोड़ें",
    plaseadd_post_job_work: "कृपया कार्य समय जोड़ें",
    employer_post_job_factlities: "सुविधाएँ प्रदान की जाती हैं",
    employer_post_job_validate: "वैधता तिथि",
    employer_post_job_preferences: "सूचना की प्राथमिकताएं",
    employer_job_details: "नौकरी विवरण",
    employer_post_job_jobgroup: "नौकरी समूह",
    employer_post_job_martial: "वैवाहिक स्थिति",
    employer_post_job_gender: "लिंग",
    employer_post_job_age: "आयु मानदंड",
    employer_post_job_da: "अलग रूप से सक्षम",
    employer_post_job_salary: "प्रति माह वेतन सीमा",
    employer_post_job_prefered: "उम्मीदवार का गृह स्थान",
    employer_post_job_prefered1: "उम्मीदवार की नौकरी वरीयता स्थान",
    employer_post_job_hr: "एचआर संपर्क विवरण",
    employer_post_job_email: "ईमेल आईडी",
    employer_post_job_mn: "मोबाइल नहीं है।",
    employer_post_job_address: "पता",
    employer_post_job_specific: "विशिष्ट",
    employer_post_job_within: "कोई",
    employer_post_job_contactperson: "संपर्क व्यक्ति ",
    employer_post_job_desg: "पद का नाम",
    employer_check_log: "संपर्क जानकारी पंजीकृत \n कार्यालय के समान है",
    employer_contactus_subject_error: "कृपया विषय का चयन करें",
    employer_contactus_post_error: "कृपया पोस्ट की संख्या दर्ज करें",
    employer_contactus_profile_error: "कृपया प्रोफाइल की संख्या दर्ज करें",
    employer_contactus_vacancies_error: "कृपया रिक्तियों की संख्या दर्ज करें",
    employer_contactus_desc_error: "कृपया विवरण दर्ज करें",
    employer_username_required: "कृपया ईमेल आईडी दर्ज करें",
    employer_password_required: "कृप्या पास्वर्ड भरो",
    employer_limit_cross_head_jobfunction: "चेतावनी",
    employer_limit_cross_head_joblocation: "चेतावनी",
    employer_limit_cross_head_jobfunction_error: "आप अधिकतम चुन सकते हैं",
    employer_limit_cross_head_joblocation_error: "आप अधिकतम चुन सकते हैं",
    employer_abused_content: 'आपका खाता ब्लॉक कर दिया गया है' + '\n' + 'कृपया जोबानिया एडमिन से संपर्क करें',
    employer_login_invisible: "आपका खाता अदृश्य मोड में है।",
    employer_login_invisible_active: "क्या आप अभी पुनः सक्रिय करना चाहते हैं?",
    employer_login_yes: "हाँ",
    employer_login_no: " नहीं ",
    employer_accout_reactivate: "खाता पुनर्सक्रियन",
    employer_account_otp: "ओटीपी आपके पंजीकृत ई-मेल पर भेजा गया है",
    employer_resend_otp_verify: "सत्यापित करना",
    employer_otp_sent: "ओटीपी भेजा",
    employer_Invalid_Mail: 'अवैध ईमेल',
    employer_otp_message: "अमान्य ओटीपी",
    employer_otp_verified: 'सत्यापित सफलता',
    employer_contactus_msg_error: "कृपया संदेश दर्ज करें",
    employer_saved_success: "सफलतापूर्वक बचाया",
    thank_contactus: 'हमसे संपर्क करने के लिए धन्यवाद',
    employer_deleted_success: "सफलतापूर्वक मिटाया गया",
    employer_deleted_error: "कम से कम एक शाखा होनी चाहिए",
    employer_updated_success: "सफलतापूर्वक अपडेट किया गया",
    employer_already_aproved: "नौकरी पोस्ट पहले ही स्वीकृत है",
    employer_repost_success: "सफलतापूर्वक दोबारा पोस्ट किया गया",
    employer_clone_success: "सफलतापूर्वक क्लोन किया गया",
    employer_abouse_success: "सफलतापूर्वक दुरुपयोग के रूप में रिपोर्ट किया गया",
    employer_login_success: "सफलतापूर्वक लॉग इन करें",
    employer_abouse_error: "पहले से ही दुर्व्यवहार",
    employer_invite_success: "सफलतापूर्वक आमंत्रित",
    employer_invite_error: "पहले से ही आमंत्रित",
    employer_alrdy_applied: "पहले से ही लागू",
    employer_invite_count_exceeds: "आमंत्रित सीमा पार हो गई है",
    employer_already_reject_job: "कर्मचारी ने इस नौकरी को अस्वीकार कर दिया",
    employer_you_reject_this_job: "आप इस नौकरी को अस्वीकार करते हैं",
    employer_repost: "रेपोस्ट",
    employer_err_job_function_required: 'कृपया कार्य समारोह का चयन करें',
    employer_err_preference_joblocation_required: 'कृपया कार्य स्थान चुनें',
    employer_err_preference_employementtype_empty: "",
    employer_err_preference_jobrole_required: 'कृपया कार्य भूमिका का चयन करें',
    employer_id_psw_incorrect: "कृपया मान्य ईमेल आईडी और पासवर्ड दर्ज करें",
    employer_waiting_approval: "आपका पंजीकरण अनुमोदन के लिए प्रतीक्षा कर रहा है। \nकिसी भी स्पष्टीकरण के लिए कृपया जोबान्या से संपर्क करें।",
    employer_verification_pending: "ईमेल आईडी सत्यापन लंबित है। कृपया अपना मेलबॉक्स जांचें।",
    employer_deactivated_acc: "आपका खाता निष्क्रिय कर दिया गया है",
    employer_employer_activated: "सफलतापूर्वक सक्रिय",
    employer_password_old_check: "सफलतापूर्वक सक्रिय",
    employer_psw_changed: 'पासवर्ड सफलतापूर्वक बदला गया',
    employer_post_edit_job_header: "जॉब पोस्ट संपादित करें",
    employer_branch_required: "कृपया शाखा का नाम चुनें",
    employer_job_function_required: "कृपया कार्य समारोह का चयन करें",
    employer_hours_required: "कृपया घंटे चुनें",
    employer_minutes_required: "कृपया मिनट चुनें",
    employer_job_role_required: "कृपया कार्य भूमिका चुनें",
    employer_skills_error_required: "कृपया कौशल चुनें",
    employer_skills_empty: '',
    employer_description_required: "कृपया नौकरी विवरण दर्ज करें",
    employer_education_required: "कृपया शिक्षा श्रेणी का चयन करें",
    employer_qualification_required: "कृपया योग्यता चुनें",
    employer_specialization_required: "कृपया विशेषज्ञता चुनें",
    employer_education_empty: "",
    employer_experience_required: "कृपया अनुभव से प्रवेश करें",
    employer_experiencefrom_valid: "कृपया अनुभव से मान्य दर्ज करें",
    employer_experienceTo_required: "कृपया अनुभव करने के लिए प्रवेश करें",
    employer_experienceTo_valid: "कृपया अनुभव के लिए मान्य दर्ज करें",
    employer_experience_fresher_sepcific_required: "कृपया अनुभव चुनें",
    employer_job_type_required: "कृपया कार्य प्रकार चुनें",
    select_job_type: "नौकरी का प्रकार चुनें",
    employer_noofopening_required: "कृपया खुलने की संख्या दर्ज करें",
    employer_worktime_required: "कृपया कार्य समय दर्ज करें",
    employer_facility_error_required: "कृपया सुविधा चुनें",
    employer_contact_person_required: "कृपया संपर्क व्यक्ति का नाम दर्ज करें",
    employer_marital_required: "कृपया वैवाहिक स्थिति चुनें",
    employer_age_required: "कृपया आयु मानदंड चुनें",
    employer_gender_required: "कृपया लिंग चुनें",
    employer_contact_designation_required: "कृपया पदनाम दर्ज करें",
    employer_state_required: "कृपया राज्य चुनें",
    employer_district_required: "कृपया जिला चुनें",
    employer_taluk_required: "कृपया तालुक चुनें",
    employer_contact_email_required: "कृपया ईमेल दर्ज करें",
    employer_contact_primary_mob_required: "कृपया मोबाइल नंबर दर्ज करें",
    employer_contact_secondary_mob_required: "कृपया अन्य मोबाइल नंबर दर्ज करें",
    employer_contact_address_required: "कृपया संपर्क पता दर्ज करें",
    employer_company_required: "कृपया कंपनी के बारे में विवरण दर्ज करें",
    employer_turn_over_required: "कृपया टर्नओवर चुनें",
    employer_noof_employee_required: "कृपया कर्मचारियों की संख्या दर्ज करें",
    employer_industry_required: "कृपया उद्योग चुनें",
    employer_employertype_required: "कृपया नियोक्ता प्रकार का चयन करें",
    employer_companytypename_required: "कृपया कंपनी का प्रकार नाम चुनें",
    employer_city_required: "कृपया टाउन / सिटी चुनें",
    employer_taluk_required: "कृपया तालुक का चयन करें",
    employer_applied_on: "पर लागू ",
    employer_invited_on: "आमंत्रित",
    employer_accepted_on: "स्वीकार किया",
    employer_rejected_on: "Rejected on ",
    employer_company_name_errormsg: "कृपया कंपनी का नाम दर्ज करें",
    employer_company_name_errormsg_validate: "कंपनी का नाम 50 वर्णों से कम होना चाहिए",
    employer_company_type_errormsg: "कृपया कंपनी का प्रकार चुनें",
    employer_activity_type_errormsg: "कृपया गतिविधि प्रकार चुनें",
    employer_industry_type_errormsg: "कृपया उद्योग प्रकार चुनें",
    employer_type_errormsg: "कृपया नियोक्ता प्रकार का चयन करें",
    employer_state_errormsg: "कृपया राज्य का चयन करें",
    employer_district_errormsg: "कृपया जिला चुनें",
    employer_city_errormsg: "कृपया टाउन / सिटी चुनें",
    employer_taluk_errormsg: "कृपया तालुक चुनें",
    employer_registered_email_errormsg: "कृपया पंजीकृत ईमेल दर्ज करें",
    employer_valid_email_errormsg: "कृपया मान्य ईमेल आईडी दर्ज करें",
    employer_alreadyregistered_email_errormsg: "ईमेल आईडी पहले से पंजीकृत है",
    employer_passsword_errormsg: "कृप्या पास्वर्ड भरो",
    employer_passsword_length_errormsg: "",
    employer_pincode_errormsg: "कृपया पिनकोड दर्ज करें",
    employer_pincode_valid_errormsg: "कृपया मान्य पिनकोड दर्ज करें",
    employer_company_address_errormsg: "कृपया कंपनी का पता दर्ज करें",
    employer_mobile_number_errormsg: "कृपया मोबाइल नंबर दर्ज करें",
    employer_mobileno_exists_errormsg: "मोबाइल नंबर पहले से मौजूद है",
    employer_mobile_numbervalid_errormsg: "कृपया मान्य मोबाइल नंबर दर्ज करें",
    employer_telephone_number_errormsg: "कृपया टेलीफोन नंबर दर्ज करें",
    employer_telephone_number_Valid_errormsg: "कृपया वैध टेलीफोन नंबर दर्ज करें",
    employer_website_address_errormsg: "कृपया वेबसाइट दर्ज करें",
    employer_website_address_errormsg_valid: "कृपया मान्य वेबसाइट दर्ज करें",
    employer_contact_person_errormsg: "कृपया संपर्क व्यक्ति का नाम दर्ज करें",
    employer_designation_errormsg: "कृपया पदनाम दर्ज करें",
    employer_knowntype_errormsg: "कृपया चयन करें कि आप जोबान्या के बारे में कैसे जानते हैं",
    employer_log_others: "कृपया जानकारी दें",
    employer_gstin_errormsg: "कृपया जीएसटीआईएन संख्या दर्ज करें",
    employer_gstin_valid_errormsg: "कृपया वैध जीएसटीआईएन संख्या दर्ज करें।",
    employer_alreadygstin_errormsg: "जीएसटीआईएन पहले से मौजूद है",
    employer_alreadypan_errormsg: "पैन नंबर पहले से मौजूद है",
    employer_alreadyaadhar_errormsg: "आधार नंबर पहले से मौजूद है",
    employer_pan_errormsg: "कृपया पैन नंबर दर्ज करें",
    employer_pan_valid_errormsg: "कृपया वैध पैन नंबर दर्ज करें।",
    employer_aadhar_errormsg: "कृपया उद्यम/उद्योग आधार संख्या दर्ज करें।",
    employer_aadhar_prof_errormsg: "कृपया आधार नंबर दर्ज करें.",
    employer_aadhar_valid_errormsg: "कृपया मान्य उद्योग/उद्योग आधार संख्या दर्ज करें।",
    employer_aadhar_prof_valid_errormsg: "कृपया वैध आधार नंबर दर्ज करें।",
    employer_OTP_valid_errormsg: "कृपया वैध ओटीपी दर्ज करें।",
    employer_facilities_error: "कृपया सुविधाएं चुनें",
    employer_already_exist: "पहले से ही मौजूद है",
    employer_register_success: "सफलतापूर्वक पंजीकृत",
    employer_register_name: "पंजीकृत नाम",
    employer_filter_skills: 'कौशल',
    employer_filter_location: 'कर्मचारी स्थान',
    employer_filter_taluk: 'कर्मचारी तालुक',
    employer_filter_jobfunction: 'कार्यभार',
    employer_filter_jobtype: 'कार्य का प्रकार',
    employer_filter_education: 'शिक्षा',
    employer_filter_experience: 'अनुभव',
    employer_filter_industry: 'उद्योग',
    employer_filter_salaryrange: 'प्रति माह वेतन सीमा',
    employer_filter_employertype: 'नियोक्ता प्रकार',
    employer_filter_companytype: 'कंपनी के प्रकार',
    employer_filter_jobgroup: 'जॉब ग्रुप',
    employer_filter_jobrole: 'नौकरी भूमिका',
    user_not_found: 'आपका खाता सक्रिय नहीं है',
    netinfo_heading: "जानकारी",
    netinfo_subheading_reachable: "कृपया अपना नेटवर्क कनेक्शन जांचें",
    netinfo_subheading: "कृपया अपना नेटवर्क कनेक्शन जांचें",
    netinfo_subheading2: "कुछ गलत हो गया",
    netinfo_subheading_server: "सर्वर पहुंच योग्य नहीं है कृपया पुनः प्रयास करें",
    matching: "मेल मिलाना",
    accountant: "जैसे. अकाउंटेंट, ड्राइवर, टेलीकॉलर",
    searchJobRole: "खोज का परिणाम",
    SalaryExpected: 'आप कितने वेतन की उमीद करते है',
    Close: 'बंद करना',
    jobRole: 'नौकरी का नाम',
    ProfileCompletion: 'प्रोफ़ाइल समापन',
    ClickhereMoreSearch: 'अधिक खोज के लिए यहां क्लिक करें',
    YouCanselectSkills: 'आप 6 कौशल तक का चयन कर सकते हैं',
    ChooseUrExp: 'कृपया अपना अनुभव चुनें',
    chooseUrSkill: 'कृपया अपना कौशल चुनें',
    YouCanSelectJobRoles: 'आप अधिकतम 4 नौकरी शीर्षक का चयन कर सकते हैं',
    PleaseSelectAllField: 'कृपया सभी विवरण चुनें'
  }
});
export default strings;
import React, { Component } from "react";
import {
  MDBNavbar, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBCollapse, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem,
  MDBHamburgerToggler, MDBIcon
} from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';
import { Link } from "react-router-dom";
import { history } from "../../history"
import axios from "axios";
import { IntlContext } from "../../utility/context/Internationalization"
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap"
import { APIURL, COMMON } from '../../config';
import stringsoflanguages from '../../Stringsoflanguage'
import SweetAlert from 'react-bootstrap-sweetalert';
import { generateRefreshToken } from '../../serviceworker';
let error_log = require('./../../error_log');
class Mobile_menu extends Component {
  constructor() {
    super();
    this.state = {
      enableLogin: 'false',
      collapse1: false,
      collapseID: '',
      ipurl: APIURL,
      employeecode: 0,
      languageCode: 2,
      languageData: [],
      logoutealert: false,
      Rejected: 9
    }
  }
  componentDidMount() {
    this.setState({ enableLogin: localStorage.getItem('enableLogin') })
    try {
      let empcode = localStorage.getItem('employeecode');
      if (empcode == null || empcode == undefined || empcode == 0 || empcode == -1) {
        empcode = -1;
      }
      this.setState({ employeecode: this.state.employeecode = empcode });
      // alert(localStorage.getItem('languagecode_short'))
      let langcode = (localStorage.getItem('languagecode') == null || localStorage.getItem('languagecode') == undefined || localStorage.getItem('languagecode') == 0 || localStorage.getItem('languagecode') == '') ? 2 : localStorage.getItem('languagecode')
      let shortcode = (localStorage.getItem('languagecode_short') == null || localStorage.getItem('languagecode_short') == undefined || localStorage.getItem('languagecode_short') == 0 || localStorage.getItem('languagecode_short') == '') ? 'en' : localStorage.getItem('languagecode_short')
      // alert(shortcode);
      stringsoflanguages.setLanguage(shortcode)
      localStorage.setItem('languagecode', langcode)
      localStorage.setItem('languagecode_short', shortcode)
      this.setState({ languagecode_color: this.state.languagecode_color = 2 })
      if (localStorage.getItem('language_json_result') == null || localStorage.getItem('language_json_result') == 'null' || localStorage.getItem('language_json_result') == undefined || localStorage.getItem('language_json_result') == 'undefined')
        this.fetchLanguageData();
      else {
        let language_list = localStorage.getItem('language_json_result').toString() != 'null' ? JSON.parse(localStorage.getItem('language_json_result')) : {}
        this.bindLanguage(language_list)
      }
    }
    catch (error) {
      error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Mobile_menu", "componentDidMount", 0, error.toString());
    }
  }
  logOut() {
    this.setState({ logoutealert: this.state.logoutealert = true })
  }
  logoutFn(appliedshortstatus) {
    try {
      localStorage.clear();
      sessionStorage.clear();
      this.setState({ enableLogin: 'false', logoutealert: this.state.logoutealert = false })
      history.push('/login');
    } catch (error) {
      error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Mobile_menu", "logoutFn", 0, error.toString());
    }
  }
  fetchLanguageData() {
    try {
      //alert("bindentry")
      var accessToken = localStorage.getItem('accessToken');
      axios({
        method: 'Post',
        url: this.state.ipurl + 'languagebind/?',
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        params: {
          ipaddress: '103.65.1.203'
        },
      })
        .then(async (response) => {
          if (response.data.status == 401) {
            await generateRefreshToken().then((result) => {
              if (result == 1) {
                this.fetchLanguageData();
              }
              else {
                // alert('Unauthorized')
              }
            })
          }
          else {
            if (response.data) {
              localStorage.setItem("language_json_result", JSON.stringify(response.data))
              this.bindLanguage(response.data);
            }
          }
        })
        .catch((error) => {
        });
    } catch (error) {
      error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Mobile_menu", "fetchLanguageData", 0, error.toString());
    }
  }
  bindLanguage(response) {
    try {
      if (response.hasOwnProperty('language_json_result')) {
        let languageList = response.language_json_result.languagelist;
        this.setState({ languageData: this.state.languageData = languageList });
      }
    } catch (error) {
      error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Mobile_menu", "bindLanguage", 0, error.toString());
    }
  }
  handleLanguageChange = (event) => {
    try {
      const selectedLanguageCode = event.target.value;
      this.setState({ languageCode: selectedLanguageCode });
    } catch (error) {
      error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Mobile_menu", "handleLanguageChange", 0, error.toString());
    }
  };
  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({ collapseID: (prevState.collapseID !== collapseID ? collapseID : '') }));
  }
  toggleSingleCollapse = collapseId => {
    this.setState({
      ...this.state,
      [collapseId]: !this.state[collapseId]
    });
  }
  render() {
    return (
      <Router>
        <MDBNavbar>
          <MDBHamburgerToggler id="power-off" onClick={() => this.toggleSingleCollapse('collapse1')} /> 
          <MDBCollapse isOpen={this.state.collapse1} navbar>
            {this.state.employeecode > 0 ?
              <MDBNavbarNav left>
                <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownItem nav caret onClick={() => history.push('/Job_list')}>{stringsoflanguages.employer_sidebar_home}</MDBDropdownItem>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownItem nav caret onClick={() => history.push('/Candidate_details')}>{stringsoflanguages.per_view_header}</MDBDropdownItem>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownItem nav caret onClick={() => this.logOut()}>{stringsoflanguages.sidebar_logout}</MDBDropdownItem>
                  </MDBDropdown>
                </MDBNavItem>
              </MDBNavbarNav>
              :
              null 
            }
          </MDBCollapse> 
        </MDBNavbar>
        <SweetAlert
          style={{ width: 'auto' }}
          title={<div style={{ fontSize: '15px', fontWeight: '400' }}>{stringsoflanguages.logout_msg}</div>}
          show={this.state.logoutealert}
          showCancel
          reverseButtons
          confirmBtnText={<div style={{ fontSize: '13px', fontWeight: '400' }}>{stringsoflanguages.employer_personal_yes}</div>}
          cancelBtnText={<div style={{ fontSize: '13px', fontWeight: '400', textDecoration: 'none' }}>{stringsoflanguages.employer_personal_no}</div>}
          onConfirm={() => {
            this.logoutFn(this.state.Rejected)
          }}
          cancelBtnCssClass="btn-cancelClr"
          confirmBtnCssClass="btn-dangerclr"
          onCancel={() => {
            this.setState({ logoutealert: this.state.logoutealert = false })
          }}
        >
        </SweetAlert>
      </Router>
    );
  }
}
export default Mobile_menu;
import React, { Component } from 'react'
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap"
import Menu from './Menu';
import Mobile_menu from './Mobile_menu';
import Logo from './Logo';
import axios from "axios";
import { APIURL, COMMON, checkIsLoggedIn } from '../../config';
import Header_search from './Header_search'
import { ChevronDown } from "react-feather"
import stringsoflanguages from '../../Stringsoflanguage';
import { history } from "../../history";
import { IntlContext } from "../../utility/context/Internationalization";
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Job_role_filter } from '../../pages/job_role_filter';
import { generateRefreshToken } from '../../serviceworker';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Redirect } from 'react-router-dom';
import { MoreVertical, User, LogOut, Home } from 'react-feather'
import LoadingOverlay from 'react-loading-overlay'
import IdleTimer from "react-idle-timer";
let error_log = require('./../../error_log');
export class Header extends Component {
  constructor() {
    super();
    this.state = {
      enableLogin: 'false',
      employee_pwa_video: "",
      ipurl: APIURL,
      leadtype: 0,
      ipaddress: '',
      languageCode: 2,
      languageData: [],
      jobrole_val: '',
      enableJobSearch: 'false',
      selectedLanguage: "",
      Rejected: 9,
      logoutealert: false,
      employeeName: '',
      showSearch: false,
      isActive: false,
      apptypecode: COMMON.APP_TYPE_CODE,
      Timer: 30 * 60 * 1000
    }
  }
  logOut() {
    this.setState({ logoutealert: this.state.logoutealert = true })
  }
  SearchFunction() {
    try {
      localStorage.setItem('setJobSearch', "true")
      const currentRoute = window.location.pathname
      if (currentRoute == '/job_list') {
        window.location.reload();
      }
      else {
        history.push('/job_list');
      }
    } catch (error) {
      error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Header", "SearchFunction", 0, error.toString());
    }
  }
  logOut() {
    this.setState({ logoutealert: this.state.logoutealert = true })
  }
  closeModal = () => {
    try {
      this.setState({ showModal: this.state.showModal = false });
      const currentRoute = window.location.pathname
      if (currentRoute == '/job_list') {
        window.location.reload();
      }
      else {
        history.push('/job_list');
      }
    } catch (error) {
      error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Header", "closeModal", 0, error.toString());
    }
  }
  logoutFn(appliedshortstatus) {
    try {
      localStorage.clear();
      sessionStorage.clear();
      this.setState({ enableLogin: 'false', logoutealert: this.state.logoutealert = false })
      history.push('/login');
    } catch (error) {
      error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Header", "logoutFn", 0, error.toString());
    }
  }
  openJobSearchModel = (message) => {
    this.setState({
      showModal: true,
      modalMessage: message,
    });
  };
  componentDidMount() {
    try {
      let loginCheck = checkIsLoggedIn();
      if (loginCheck) {
      } else {
        localStorage.clear();
        sessionStorage.clear();
        history.push('/login');
      }
      let employee_pwa_video = localStorage.getItem('employee_pwa_video');
      this.setState({ employee_pwa_video: this.state.employee_pwa_video = (employee_pwa_video != null && employee_pwa_video != undefined && employee_pwa_video != "undefined") ? employee_pwa_video : "" });
      let leadtype = localStorage.getItem('leadtype');
      this.setState({ leadtype: this.state.leadtype = (leadtype != null && leadtype != undefined && leadtype != "undefined") ? leadtype : 1 });
      this.setState({ ipaddress: this.state.ipaddress = localStorage.getItem('ipaddress') });
      stringsoflanguages.setLanguage(localStorage.getItem('languagecode_short'));
      let langcode = (localStorage.getItem('languagecode') == null || localStorage.getItem('languagecode') == undefined || localStorage.getItem('languagecode') == 0 || localStorage.getItem('languagecode') == '') ? 2 : localStorage.getItem('languagecode')
      let shortcode = (localStorage.getItem('languagecode_short') == null || localStorage.getItem('languagecode_short') == undefined || localStorage.getItem('languagecode_short') == 0 || localStorage.getItem('languagecode_short') == '') ? 'en' : localStorage.getItem('languagecode_short')
      stringsoflanguages.setLanguage(shortcode)
      localStorage.setItem('languagecode', langcode)
      localStorage.setItem('languagecode_short', shortcode)
      this.setState({ selectedLanguage: this.state.selectedLanguage = langcode })
      this.setState({ languagecode_color: this.state.languagecode_color = 2 })
      window.addEventListener('scroll', this.isSticky);
      this.setState({ enableLogin: localStorage.getItem('enableLogin') })
      this.setState({ employeeName: localStorage.getItem('employeeName') })
      this.setState({ enableJobSearch: localStorage.getItem('setJobSearch') })
      //session timeout default timer taken from backend
      if (localStorage.getItem("webExpireTime") && Number(localStorage.getItem("webExpireTime")))
        this.setState({ Timer: this.state.Timer = Number(localStorage.getItem("webExpireTime")) });
      if (localStorage.getItem('employeecode') && localStorage.getItem('isLanguageChanged') == "true")
        this.saveLanguageDetails();
      if (localStorage.getItem('language_json_result') == null || localStorage.getItem('language_json_result') == 'null' || localStorage.getItem('language_json_result') == undefined || localStorage.getItem('language_json_result') == 'undefined')
        this.fetchLanguageData();
      else {
        let language_list = localStorage.getItem('language_json_result').toString() != 'null' ? JSON.parse(localStorage.getItem('language_json_result')) : {}
        this.bindLanguage(language_list)
      }
      if (localStorage.getItem('languageFilter') == null || localStorage.getItem('languageFilter') == 'null' || localStorage.getItem('languageFilter') == undefined || localStorage.getItem('languageFilter') == 'undefined')
        this.call_job_function();
      if (leadtype == 0) {
        if (window.location.href.includes("/job_list") || window.location.href.includes("/Job_list"))
          this.setState({ showSearch: this.state.showSearch = true })
      }
    } catch (error) {
      error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Header", "componentDidMount", 0, error.toString());
    }
  }
  saveLanguageDetails() {
    try {
      var accessToken = localStorage.getItem('accessToken');
      axios({
        method: 'Post',
        url: this.state.ipurl + 'notification/tokenentry?',
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        params: {
          ipaddress: this.state.ipaddress,
          deviceid: this.state.ipaddress,
          apptypecode: this.state.apptypecode || 1,
          usercode: localStorage.getItem('employeecode') && Number(localStorage.getItem('employeecode')) ? Number(localStorage.getItem('employeecode')) : 0
        },
        data: {
          devicetoken: "",
          languagecode: localStorage.getItem('languagecode') && localStorage.getItem('languagecode') != 'null' && Number(localStorage.getItem('languagecode')) ? Number(localStorage.getItem('languagecode')) : COMMON.default_language_code
        }
      })
        .then(async response => {
          if (response.data.status == 401) {
            await generateRefreshToken().then((result) => {
              if (result == 1) {
                this.call_job_function();
              }
              else {
                // alert('Unauthorized')
              }
            })
          }
          else {
            localStorage.removeItem("isLanguageChanged")
          }
        }).catch((error) => {
          localStorage.removeItem("isLanguageChanged")
          error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Header", "saveLanguageDetails", 0, error.toString());
        });
    } catch (error) {
      error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Header", "saveLanguageDetails", 0, error.toString());
    }
  }
  call_job_function() {
    var accessToken = localStorage.getItem('accessToken');
    try {
      window.fbq('track', 'Website', { fb_ad_function: "call_job_function", fb_ad_type: "Job list", fb_ad_loginuser: COMMON.employee_name });
      this.setState({ isActive: this.state.isActive = true })
      axios({
        method: 'Post',
        url: this.state.ipurl + 'employee/filterbind/',
        crossdomain: true,
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        params: {
          deviceip: this.state.ipaddress,
          type: 0,
          languagecode: this.state.selectedLanguage,
          employeecode: -1,
        }
      })
        .then(async response => {
          if (response.data.status == 401) {
            await generateRefreshToken().then((result) => {
              if (result == 1) {
                this.call_job_function();
              }
              else {
                // alert('Unauthorized')
              }
            })
          }
          else {
            this.setState({ isActive: this.state.isActive = false })
            if (response.data.filter_json_result.varstatuscode === 4) {
              var skillList = response.data.filter_json_result.skill_list || [];
              localStorage.setItem('filter_skill_list', JSON.stringify(skillList))
              var jobfunctionlist = response.data.filter_json_result.jobfunctionlist || [];
              localStorage.setItem('filter_job_function_list', JSON.stringify(jobfunctionlist))
              var genderlist = response.data.filter_json_result.genderlist || [];
              localStorage.setItem('filter_gender_list', JSON.stringify(genderlist));
              var state_list = response.data.filter_json_result.state_list || [];
              localStorage.setItem('filter_state_list', JSON.stringify(state_list));
              var district_list = response.data.filter_json_result.district_list || [];
              localStorage.setItem('filter_district_list', JSON.stringify(district_list));
              var jobrolelist = response.data.filter_json_result.jobrolelist || [];
              localStorage.setItem('filter_jobrole_list', JSON.stringify(jobrolelist));
              var profilecategoryresult = response.data.filter_json_result.profilecategoryresult || [];
              localStorage.setItem('filter_profile_category', JSON.stringify(profilecategoryresult));
              var experiencelist = response.data.filter_json_result.experiencelist || [];
              localStorage.setItem('filter_experience_list', JSON.stringify(experiencelist));
              var jobtypelist = response.data.filter_json_result.jobtypelist || [];
              localStorage.setItem('filter_jobtypelist', JSON.stringify(jobtypelist));
              var schoolinglist = response.data.filter_json_result.schoolinglist || [];
              localStorage.setItem('filter_schooling_list', JSON.stringify(schoolinglist));
              var educationcategorylist = response.data.filter_json_result.educationcategorylist || [];
              localStorage.setItem('filter_education_category', JSON.stringify(educationcategorylist));
              var maritalstatuslist = response.data.filter_json_result.maritalstatuslist || [];
              localStorage.setItem('filter_maritalstatus_list', JSON.stringify(maritalstatuslist));
              localStorage.setItem('languageFilter', this.state.selectedLanguage)
            } else if (response.data.filter_json_result.varstatuscode === 7) {
              localStorage.clear();
            }
          }
        })
        .catch(error => {
        });
    } catch (error) {
      this.setState({ isActive: this.state.isActive = false })
      error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Header", "call_job_function", 0, error.toString());
    }
  }
  fetchLanguageData() {
    try {
      var accessToken = localStorage.getItem('accessToken');
      axios({
        method: 'Post',
        url: this.state.ipurl + 'languagebind/?',
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        params: {
          ipaddress: this.state.ipaddress,
        },
      })
        .then(async (response) => {
          if (response.data.status == 401) {
            await generateRefreshToken().then((result) => {
              if (result == 1) {
                this.fetchLanguageData();
              }
              else {
                // alert('Unauthorized')
              }
            })
          }
          else {
            if (response.data) {
              localStorage.setItem("language_json_result", JSON.stringify(response.data))
              this.bindLanguage(response.data);
            }
          }
        })
        .catch((error) => {
        });
    } catch (error) {
      error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Header", "fetchLanguageData", 0, error.toString());
    }
  }
  bindLanguage(response) {
    try {
      if (response.hasOwnProperty('language_json_result')) {
        let languageList = response.language_json_result.languagelist;
        this.setState({ languageData: this.state.languageData = languageList });
      }
    } catch (error) {
      error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Header", "bindLanguage", 0, error.toString());
    }
  }
  handleLanguageChange = (event) => {
    try {
      const selectedLanguageCode = event.target.value;
      this.setState({ languageCode: selectedLanguageCode });
    } catch (error) {
      error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Header", "handleLanguageChange", 0, error.toString());
    }
  };
  componentWillUnmount() {
    window.removeEventListener('scroll', this.isSticky);
  }
  isSticky = (e) => {
    try {
      const header = document.querySelector('header');
      const scrollTop = window.scrollY;
      scrollTop >= 250 ? header.classList.add('is-Sticky') : header.classList.remove('is-Sticky');
    } catch (error) {
      error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Header", "isSticky", 0, error.toString());
    }
  };
  onIdle = () => {
    try {
      toast.error("Your session has expired", {
        duration: 5000,
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo-bar'
      });
      localStorage.clear();
      sessionStorage.clear();
      setTimeout(() => {
        history.push('/login');
      }, 4000)
    } catch (error) {
      error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Header", "onIdle", 0, error.toString());
    }
  }
  onchange = () => {
    localStorage.setItem("IdleTimeOut", (new Date().getTime() + this.state.Timer).toString())
  }
  render() {
    return (
      <header id="masthead" className="header ttm-header-style-01">
        <IdleTimer
          onIdle={this.onIdle}
          onAction={this.onchange}
          timeout={this.state.Timer}
        />
        <SweetAlert style={{ width: '24%' }}
          title={<div style={{ fontSize: '15px', fontWeight: '400' }}>{stringsoflanguages.logout_msg}</div>}
          show={this.state.logoutealert}
          showCancel
          reverseButtons
          confirmBtnText={<div style={{ fontSize: '13px', fontWeight: '400' }}>{stringsoflanguages.employer_personal_yes}</div>}
          cancelBtnText={<div style={{ fontSize: '13px', fontWeight: '400', textDecoration: 'none' }}>{stringsoflanguages.employer_personal_no}</div>}
          onConfirm={() => {
            this.logoutFn(this.state.Rejected)
          }}
          cancelBtnCssClass="btn-cancelClr"
          confirmBtnCssClass="btn-dangerclr"
          onCancel={() => {
            this.setState({ logoutealert: this.state.logoutealert = false })
          }}
        >
        </SweetAlert>
        <LoadingOverlay
          active={this.state.isActive}
          spinner={
            <img src="images/svg/loading.gif" className="dz-img rounded-circle" alt="" />}
        ></LoadingOverlay>
        {/* {this.state.leadtype == 0 ? */}
        <div id="site-header-menu" className="site-header-menu ttm-bgcolor-white">
          <div className="site-header-menu-inner ttm-stickable-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  {/* site-navigation */}
                  <div className="site-navigation d-flex align-items-center justify-content-between">
                    {/* site-branding */}
                    <div className="site-brandings">
                      <Logo />
                      {window.innerWidth > 850 && this.state.showSearch && this.state.leadtype == 0 && (<Job_role_filter />)}
                    </div>
                    {/* site-branding end */}
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                          <div className="border-box-block" style={{ padding: '0px', marginRight: '0px' }}>
                            <div className=" d-flex align-items-center justify-content-between">
                              <Menu />
                              <Modal isOpen={this.state.showModal} className='JobList_Popup'>
                                <ModalHeader style={{ borderColor: 'white', justifyContent: 'flex-end', textAlign: 'flex-end', alignContent: 'flex-end', alignSelf: 'flex-end', alignItems: 'flex-end', paddingBottom: '1px' }} toggle={this.closeModal} >
                                </ModalHeader>
                                <ModalBody style={{
                                  marginTop: '-4rem',
                                  marginBottom: '2rem'
                                }}>
                                </ModalBody>
                              </Modal>
                              <Modal className='col-lg-12' isOpen={this.state.playvideo} style={{ width: 'auto', height: 'auto' }}>
                                <ModalHeader style={{ borderColor: 'white', padding: 2, margin: 2, justifyContent: 'flex-end', textAlign: 'flex-end', alignContent: 'flex-end', alignSelf: 'flex-end', alignItems: 'flex-end' }} onClick={e => { this.setState({ playvideo: this.state.playvideo = false }) }} >
                                  <a>X</a>
                                </ModalHeader>
                                <ModalBody style={{ textAlign: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                  <iframe
                                    width='300px'
                                    height={window.innerWidth > 850 ? '550px' : '400px'}
                                    src={this.state.employee_pwa_video}
                                    title="Instruction"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen
                                  ></iframe>
                                </ModalBody>
                              </Modal>
                              {/* menu end */}
                              <div className='mt-1'>
                                <i className="fab fa-youtube" onClick={e => { this.setState({ playvideo: this.state.playvideo = true }) }} style={{ fontSize: "20px", color: 'red' }}></i>
                              </div>
                              <div className="header_extra ml-auto d-flex align-items-center">
                                {/* <Header_search/> */}
                                <div className="header_social">
                                  <span className="ttm-icon_element-onlytxt ttm-icon_element-color-skincolor"><i className="fas fa-phone-square-alt"></i> </span><span>+91 93843 78052
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {this.state.languageData.length > 0 ?
                            <IntlContext.Consumer>
                              {context => {
                                return (
                                  <div className="dropdown d-inline-block select-lang" style={{ marginRight: "20px" }}>
                                    <UncontrolledDropdown>
                                      <DropdownToggle color="flat-light" caret>
                                        <span style={{ color: "grey", fontSize: "10px", textTransform: 'none' }}>   {this.state.languageData?.length > 0 ? this.state.languageData?.filter(t => t.shorttype == localStorage.getItem('languagecode_short')).map(e => { return e.languagename }) : ""}</span>
                                      </DropdownToggle>
                                      <DropdownMenu style={{ minWidth: "6rem" }}>
                                        {this.state.languageData ? this.state.languageData.map((i, index) => {
                                          return (
                                            <DropdownItem style={{ padding: '0px 13px' }}
                                              tag="a"
                                              onClick={e => {
                                                if (!this.state.selectedLanguage || this.state.selectedLanguage != i.languagecode) {
                                                  this.setState({
                                                    selectedLanguage: (this.state.selectedLanguage = i.languagecode)
                                                  })
                                                  stringsoflanguages.setLanguage('ta')
                                                  localStorage.setItem('languagecode', i.languagecode)
                                                  localStorage.setItem('languagecode_short', i.shorttype)
                                                  if (localStorage.getItem('employeecode'))
                                                    localStorage.setItem("isLanguageChanged", 'true');
                                                  localStorage.removeItem('languageFilter')
                                                  window.location.reload();
                                                }
                                              }}
                                            >
                                              <span className='fs-12'>{i.languagename}</span>
                                            </DropdownItem>
                                          )
                                        }) : ""}
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                )
                              }}
                            </IntlContext.Consumer>
                            : null}
                          {this.state.enableLogin === 'true' ? <div className="border-box-block mobile-menu" style={{ padding: '0px 0px 0px 0px' }}>
                            <div className=" d-flex align-items-center justify-content-between">
                              <div className="mobile-menu"><Mobile_menu /></div>
                            </div></div> : null}
                          {this.state.enableLogin === 'true' && (
                            <div className="header_btn" >
                              <UncontrolledDropdown>
                                <DropdownToggle className='icon-btn hide-arrow' color='transparent' size='md' caret>
                                  <i className="fas fa-bars" style={{ fontSize: "20px" }}></i>
                                </DropdownToggle>
                                <DropdownMenu style={{ padding: "1px", fontSize: '14px', minWidth: "auto" }}>
                                  <DropdownItem onClick={e => { e.preventDefault(); history.push('/Job_list') }} style={{ borderBottom: "1px solid #f3efef" }}>
                                    <Home className='me-50' size={15} /> <span className='align-middle padd-lf12'>{stringsoflanguages.employer_sidebar_home}</span>
                                  </DropdownItem>
                                  <DropdownItem onClick={e => { e.preventDefault(); history.push('/Candidate_details') }} style={{ borderBottom: "1px solid #f3efef" }}>
                                    <User className='me-50' size={15} /> <span className='align-middle padd-lf12'>{stringsoflanguages.per_view_header}</span>
                                  </DropdownItem>
                                  <DropdownItem onClick={e => { e.preventDefault(); this.logOut(); }}>
                                    <LogOut className='me-50' size={15} /> <span className='align-middle padd-lf12'>{stringsoflanguages.sidebar_logout}</span>
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>{/* site-navigation end */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* site-header-menu end */}
      </header>
    )
  }
}
export default Header;
import React, { Component, useState } from "react";
import Header from "../components/layout/Header";
import PageHeader from "../components/layout/PageHeader";
import { EmployeePortalFooter } from "../components/layout/employeePortalFooter";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axios from "axios";
import Select from "react-select";
import { APIURL, COMMON } from "../config";
import Checkbox from "../components/@vuexy/checkbox/CheckboxesVuexy";
import stringsoflanguages from '../Stringsoflanguage'
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify";
import { history } from "../history";
import "react-toastify/dist/ReactToastify.css"
import LoadingOverlay from 'react-loading-overlay'
import { Redirect } from 'react-router-dom';
import avatar1 from '../assets/img/gender/Female.jpg';
import avatar2 from '../assets/img/gender/Male.jpg';
import avatar3 from '../assets/img/gender/Others.jpg';
import uploadicon from '../assets/img/icons/upload.png';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Label,
  CustomInput,
  FormGroup,
} from "reactstrap";
import { ChipSet, Chip } from "@material/react-chips";
import "@material/react-chips/index.scss";
import { generateRefreshToken } from "../serviceworker";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { grey } from "@mui/material/colors";
import Cropper from 'react-easy-crop'
import getCroppedImg from './cropImage'
let error_log = require("../error_log");
export class CreateProfile extends Component {
  constructor() {
    super();
    this.state = {
      uploadfilename: '',
      imageSrc: '',
      isupload: false,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1,
      croppedAreaPixels: {},
      showimageupload: false,
      currentworkflag: false,
      currentworkjobrolecode: 0,
      currentstatus: 1,
      otherworkstatus: 0,
      is_auth: true,
      ipurl: APIURL,
      languagecode: 0,
      empname: "",
      minsalary: COMMON.minsalary,
      salary: "",
      leadtype: 0,
      dateofbirth: "",
      maxDateofbirth: new Date("01-01-" + (new Date().getFullYear() - 18)),
      username: "",
      password: "",
      ipaddress: '',
      employeecode: "",
      txtempname_invalid: false,
      errorempname: "Please enter name of the user",
      txtusername_invalid: false,
      errorusername: "Please enter user name",
      txtmobnum_invalid: false,
      errormobnum: "Please enter mobile no.",
      txtpassword_invalid: false,
      errorpassword: "Please enter password",
      txtknowntype_invalid: false,
      errorknowntype: "Please how do you know about JOBANYA",
      varstatuscode: "",
      txtotp_invalid: false,
      errorotp: "Please enter OTP",
      iagree: "",
      isActive: false,
      genderChips: [],
      txtJobFunction: "",
      txtJobRole: "",
      selectedcurrentjob: '',
      educationChips: [],
      experienceChips: [],
      workexperienceChips: [],
      selectedCheckbox: null,
      apptypecode: COMMON.APP_TYPE_CODE,
      selectedGender: [],
      selectedMarital: [],
      selectedEducation: [],
      selectedJobType: [],
      selectedExperience: [],
      selectedSkills: [],
      selectedWorkExperience: [],
      selectedlanguage: [],
      selected_knowntype: "0",
      knowntypelist: [],
      dpdistrict: [],
      dpjobfunction: [],
      dpFilterdJobFunction: [],
      selectedJobFunction: [],
      showJobRole: false,
      dpjobrole: [],
      jobfunctionrole: [],
      allJobroleArray: [],
      filteredJobfunctionrole: [],
      selectedJobRoles: [],
      selectedRoleSkilsArray: [],
      selectJobRoleArray: [],
      showJobFunctionDiv: false,
      showSkillsDiv: false,
      showProfileDiv: true,
      selectedJobRoleIndex: 0,
      skillList: [],
      filteredJobType: [],
      districtValue: "0",
      candidateDistrictValue: "0",
      stateValue: 0,
      candidateStateValue: 0,
      showLocation: "",
      showjobFunction: "",
      showjobFunctionCode: "",
      showCandidateLocation: "",
      nextBtnTxt: "Next",
      maritalStatusList: [],
      dpbindlanguage: [],
    };
    this.setState({
      maxDateofbirth: this.state.maxDateofbirth = (new Date().getFullYear() - 18) + "-01-01"
    })
  }
  drawImage = () => {
    const { croppedAreaPixels } = this.state;
    this.ctx.drawImage(this.image, croppedAreaPixels.x, croppedAreaPixels.y, croppedAreaPixels.width, croppedAreaPixels.height, 0, 0, croppedAreaPixels.width, croppedAreaPixels.height);
    this.setState({ candidateImage: this.state.candidateImage = this.state.image })
  }
  onCropChange = crop => {
    this.setState({ crop: this.state.crop = crop })
  }
  onZoomChange = zoom => {
    this.setState({ zoom: this.state.zoom = zoom })
  }
  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedAreaPixels
    })
  }
  showCroppedImage = async () => {
    this.setState({ isActive: this.state.isActive = true })
    try {
      const croppedImage = await getCroppedImg(
        this.state.imageSrc,
        this.state.croppedAreaPixels,
        0,
        0
      )
      this.setState({ candidateImage: this.state.candidateImage = croppedImage })
      this.createImage();
    } catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "showCroppedImage", 0, error.toString());
    }
  }
  componentDidMount() {
    try {
      if (!localStorage.getItem("mobileNumber") || localStorage.getItem("mobileNumber") == null) {
        this.setState({ is_auth: this.state.is_auth = false });
      }
      let empcode = localStorage.getItem('employeecode');
      let leadtype = localStorage.getItem('leadtype');
      if (empcode == null || empcode == undefined || empcode == 0 || empcode == '') {
        empcode = -1;
      }
      this.setState({ employeecode: this.state.employeecode = empcode });
      if (leadtype == null || leadtype == undefined || leadtype == '') {
        leadtype = 1;
      }
      this.setState({ leadtype: this.state.leadtype = leadtype });
      localStorage.getItem('ipaddress')
      this.setState({ ipaddress: this.state.ipaddress = localStorage.getItem('ipaddress') });
      stringsoflanguages.setLanguage(localStorage.getItem('languagecode_short'));
      this.setState({ languagecode: this.state.languagecode = localStorage.getItem('languagecode') });
      this.setState({
        workexperienceChips: (this.state.workexperienceChips = [
          {
            id: "1",
            value: stringsoflanguages.work_exp_fresher || "Fresher",
          },
          {
            id: "0",
            value: stringsoflanguages.work_exp_experienced || "Experienced",
          }])
      })
      if (localStorage.getItem('languageFilter') == null || localStorage.getItem('languageFilter') == 'null' || localStorage.getItem('languageFilter') == undefined || localStorage.getItem('languageFilter') == 'undefined') {
        this.checkFilterBind()
      } else this.call_job_function()
    } catch (error) {
      error_log.ERRORLOG(
        -1,
        COMMON.BUILD_NO,
        "Register",
        "componentDidMount",
        0,
        error.toString()
      );
    }
    this.languageBind()
  }
  async createImage() {
    try {
      this.setState({ isActive: this.state.isActive = true })
      const croppedImage = await getCroppedImg(
        this.state.imageSrc,
        this.state.croppedAreaPixels,
        0,
        0
      )
      let binary = atob(croppedImage.split(',')[1])
      let array = []
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }
      let blobData = new Blob([new Uint8Array(array)], { type: 'image/jpeg' })
      var varfilename = this.state.uploadfilename;
      var cur_time = new Date().getTime();
      var file_name = 'profile_pic_' + this.state.employeecode + '_' + cur_time + '.' + varfilename.split('.').pop()
      var data_body = {
        "bucketName": process.env.REACT_APP_PROFILE_IMAGE_UPLOAD,
        "fileName": process.env.REACT_APP_PROFILE_IMAGE_FOLDER_UPLOAD + '/' + file_name
      }
      var accessToken = localStorage.getItem('accessToken');
      axios({
        method: 'Post',
        url: this.state.ipurl + 'getLamdaUrlDetails',
        crossdomain: true,
        data: data_body,
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
      })
        .then(async (URLResponse) => {
          if (URLResponse.data.status == 401) {
            await generateRefreshToken().then((result) => {
              if (result == 1) {
                this.createImage()
              }
              else {
                // alert('Unauthorized')
              }
            })
          }
          else {
            this.setState({ isActive: this.state.isActive = false })
            const result = await fetch(URLResponse.data?.urlresponse?.uploadURL, {
              method: 'PUT',
              body: blobData
            }).then(resp => {
              const uploadUrl = resp.url.split('?');
              if (uploadUrl[0]) {
                let dataparams = {
                  employeecode: this.state.employeecode,
                  ipaddress: this.state.ipaddress,
                  imageurl: uploadUrl[0]
                }
                this.setState({ isActive: this.state.isActive = true })
                axios({
                  method: "Post",
                  url: this.state.ipurl + "employee/imagesavevialambda/",
                  crossdomain: true,
                  params: dataparams,
                  headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json'
                  },
                })
                  .then(async (response) => {
                    this.setState({ isActive: this.state.isActive = false })
                    if (response.data.status == 401) {
                      await generateRefreshToken().then((result) => {
                        if (result == 1) {
                          this.createImage()
                        }
                        else {
                          // alert('Unauthorized')
                        }
                      })
                    }
                    else {
                      var result = response.data.employee_json_result.varstatuscode;
                      if (result === 2) {
                        toast.success(stringsoflanguages.employer_updated_success, {
                          position: toast.POSITION.TOP_RIGHT,
                          className: 'toast-success-container toast-success-container-after'
                        });
                        this.setState({ showimageupload: this.state.showimageupload = false });
                        this.setState({ isupload: this.state.isupload = false });
                        history.push('/job_list');
                        // this.loadProfileData()
                      }
                      else {
                        if (response.data.employee_json_result['responsestring'])
                          toast.error(stringsoflanguages.image_upload_size_msg, {
                            position: toast.POSITION.TOP_RIGHT,
                            className: 'foo-bar'
                          });
                        this.setState({ isActive: (this.state.isActive = false) });
                      }
                    }
                  })
                  .catch((error) => {
                    this.setState({ isActive: (this.state.isActive = false) });
                    error_log.ERRORLOG(
                      this.state.usercode,
                      this.state.version,
                      "Candidate_details",
                      "getUploadedProfileImage",
                      0,
                      error.toString()
                    );
                  });
              }
            }).catch((error) => {
              this.setState({ isActive: (this.state.isActive = false) });
              error_log.ERRORLOG(this.state.usercode, this.state.version, "ProfileCreate", "createImage", 0, error.toString());
            });
          }
        }).catch((error) => {
          this.setState({ isActive: this.state.isActive = false });
          error_log.ERRORLOG(this.state.usercode, this.state.version, "ProfileCreate", "createImage", 0, error.toString());
        });
    } catch (error) {
      this.setState({ isActive: this.state.isActive = false });
      error_log.ERRORLOG(this.state.usercode, this.state.version, "ProfileCreate", "createImage", 0, error.toString());
    }
  }
  closeimageModal() {
    this.setState({ showimageupload: this.state.showimageupload = false });
    history.push('/job_list');
  }
  getUploadedProfileImage = async e => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        this.setState({ uploadfilename: this.state.uploadfilename = e.target.files[0].name })
        const imageDataUrl = await this.readFile(e.target.files[0])
        this.setState({
          imageSrc: imageDataUrl,
          crop: { x: 0, y: 0 },
          zoom: 1,
        })
        this.setState({ isupload: this.state.isupload = true })
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "getUploadedProfileImage", 0, error.toString());
    }
  }
  readFile(file) {
    try {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
      })
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "readFile", 0, error.toString());
    }
  }
  languageBind() {
    var langCode = "1";
    if (localStorage.getItem('languagecode') != null && localStorage.getItem('languagecode') != 'null' && localStorage.getItem('languagecode') != undefined && localStorage.getItem('languagecode') != 'undefined') {
      langCode = localStorage.getItem('languagecode');
    }
    var accessToken = localStorage.getItem('accessToken');
    axios({
      method: 'Post',
      url: this.state.ipurl + 'employee/personalinfoload/',
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
      },
      crossdomain: true,
      params: {
        deviceip: localStorage.getItem('ipaddress'),
        languagecode: langCode,
        usercode: 5,
        employeecode: 0,
      }
    })
      .then(async response => {
        this.setState({ isActive: this.state.isActive = false })
        if (response.data.status == 401) {
          await generateRefreshToken().then((result) => {
            if (result == 1) {
              this.languageBind();
            }
            else {
              // alert('Unauthorized')
            }
          })
        }
        else {
          if (response.data.employee_json_result.varstatuscode === 4) {
            //Language
            let varlanguageOptions = response.data.employee_json_result.languagelist
            let languageOptions = [];
            if (varlanguageOptions && varlanguageOptions.length > 0) {
              this.state.Languagelist = varlanguageOptions;
              let languagecode = localStorage.getItem('languagecode');
              varlanguageOptions.forEach(lg => {
                let lg_displayname = lg.languagename;
                if (lg.displayname && lg.displayname.length > 0) {
                  let dis_name = lg.displayname.find(d => d.code === Number(languagecode));
                  if (dis_name && dis_name.name) {
                    lg_displayname = dis_name.name;
                  }
                }
                languageOptions.push({ languagecode: lg.languagecode, languagename: lg_displayname, status: lg.status, knowntype: lg.knowntype, displayname: lg.displayname })
              });
            }
            if (this.state.dpbindlanguage.length == 0) {
              this.setState({ dpbindlanguage: this.state.dpbindlanguage = languageOptions })
            }
          }
        }
      })
      .catch(error => {
        this.setState({ isActive: this.state.isActive = false })
        error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "languageBind", 0, error.toString());
      });
  }
  checkFilterBind() {
    try {
      if (localStorage.getItem('languageFilter') != null && localStorage.getItem('languageFilter') != 'null' && localStorage.getItem('languageFilter') != undefined && localStorage.getItem('languageFilter') != 'undefined') {
        this.call_job_function()
      } else {
        setTimeout(() => {
          this.checkFilterBind()
        }, 500)
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "checkFilterBind", 0, error.toString());
    }
  }
  scrollToElement() {
    var elem = document.getElementById('profileScreen');
    var x = 0;
    var y = 0;
    while (elem != null) {
      x += elem.offsetLeft;
      y += elem.offsetTop;
      elem = elem.offsetParent;
    }
    window.scrollTo(x, y);
  }
  handleJobRoleNext(index, item) {
    try {
      if (this.state.selectedWorkExperience[0] == '1') {
        this.setState({ currentworkflag: this.state.currentworkflag = true })
        this.setState({ currentworkjobrolecode: this.state.currentworkjobrolecode = this.state.selectedJobRoles[0].jobrolecode })
      }
      if (item['selectedExperience'] == null || item['selectedExperience'].length == 0) {
        toast.error("Please update  your experience", {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo-bar'
        });
        return false
      } else if (item['selectedSkills'] == null || item['selectedSkills'].length == 0) {
        toast.error("Please update  your skills", {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo-bar'
        });
        return false
      }
      else {
        if (
          (index + 1) <
          this.state.selectedJobRoles.length
        ) {
          this.setState({
            selectedJobRoleIndex:
              (this.state.selectedJobRoleIndex =
                index + 1),
          });
          this.saveSelectedJobRoles();
          if (this.state.selectedJobRoles[this.state.selectedJobRoleIndex] && this.state.selectedJobRoles[this.state.selectedJobRoleIndex]['selectedSkills']) {
            this.setState({
              selectedRoleSkilsArray: (this.state.selectedRoleSkilsArray = this.state.selectedJobRoles[this.state.selectedJobRoleIndex]['selectedSkills'])
            })
          } else {
            this.setState({
              selectedRoleSkilsArray: (this.state.selectedRoleSkilsArray = [])
            })
          }
        } else if (this.state.currentworkflag == false) {
          toast.error("Please choose current Jobrole", {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo-bar'
          });
          return false
        } else if ((index + 1) == this.state.selectedJobRoles.length) {
          this.registerEmployee()
        }
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "handleJobRoleNext", 0, error.toString());
    }
  }
  registerEmployee() {
    try {
      var schoolingCode = 0;
      var afterSchoolingCode = 0;
      var schoolingArr = [];
      var afterSchoolingArr = [];
      var totalexp = 0;
      var experienceArr = [];
      var jobRoleArr = [];
      var skillsArr = [];
      var jobTypeArr = [];
      var jobFunctionRoleArr = [];
      var accessToken = localStorage.getItem('accessToken');
      if (this.state.selectedEducation.length > 0) {
        var educationCode = this.state.selectedEducation[0]
        var getEducationJson = this.state.educationChips.filter(e => e.educationcategorycode == educationCode);
        if (getEducationJson.length > 0) {
          if (getEducationJson[0]['typecode'] == 1) {
            schoolingCode = getEducationJson[0]['educationcategorycode']
            schoolingArr = [{ "qualificationcode": schoolingCode, "schoolingcode": 1 }]
          }
          else {
            afterSchoolingCode = getEducationJson[0]['educationcategorycode']
            afterSchoolingArr = [
              { "afterschoolingcode": 1, "educationcategorycode": afterSchoolingCode }
            ]
          }
        }
      }
      if (this.state.selectedJobType.length > 0) {
        var jobType = this.state.selectedJobType
        for (var i = 0; i < jobType.length; i++) {
          jobTypeArr.push({
            employementtypecode: jobType[i]
          })
        }
      }
      const selectedDate = this.state.dateofbirth;
      const millisecondsFormat = new Date(selectedDate).getTime();
      if (this.state.selectedJobRoles.length > 0) {
        var selJobRoles = this.state.selectedJobRoles;
        for (var i = 0; i < selJobRoles.length; i++) {
          var selExp = selJobRoles[i]['selectedExperience'] != null && selJobRoles[i]['selectedExperience'].length > 0 ? selJobRoles[i]['selectedExperience'][0] : 0;
          if (selExp) {
            var expJson = this.state.experienceChips.filter(ex => ex.experiencecode == selExp);
            var expflag = selJobRoles.filter(e => e.jobrolecode)
            var current = expflag[i].jobrolecode == this.state.currentworkjobrolecode ? this.state.currentstatus : this.state.otherworkstatus
            if (expJson.length > 0) {
              if (expJson[0]['value'] > 0)
                totalexp = totalexp + expJson[0]['value'];
              var skillArr = [];
              if (selJobRoles && selJobRoles[i].selectedSkills && selJobRoles[i].selectedSkills.length > 0) {
                for (var j = 0; j < selJobRoles[i].selectedSkills.length; j++) {
                  var data = { "skillcode": selJobRoles[i].selectedSkills[j] }
                  skillArr.push(data)
                }
              }
              var exp = { "experiencecode": i + 1, "jobfunctioncode": selJobRoles[i]['jobfunctioncode'], "jobrolecode": selJobRoles[i]['jobrolecode'], "designationname": selJobRoles[i]['jobrolename'], "expid": expJson[0]['experiencecode'], "currentworkstatus": current, "skills": skillArr }
              experienceArr.push(exp);
            }
          }
          if (selJobRoles[i]['jobfunctioncode'])
            jobFunctionRoleArr.push({ "jobfunctioncode": selJobRoles[i]['jobfunctioncode'] })
          var jobRoleJs = { "jobrolecode": selJobRoles[i]['jobrolecode'] };
          jobRoleArr.push(jobRoleJs);
          var selSkills = selJobRoles[i]['selectedSkills']
          if (selSkills.length > 0) {
            for (var j = 0; j < selSkills.length; j++) {
              var skillJs = selJobRoles[i]['skills'].filter(e => e.skillcode == selSkills[j]);
              if (skillJs.length > 0) {
                skillJs[0]['currentjobfunction'] = (skillJs[0]['jobrolecode'] == this.state.currentworkjobrolecode) ? this.state.currentstatus : this.state.otherworkstatus;
                skillsArr.push(skillJs[0]);
              }
            }
          }
        }
      }
      var employeeProfileJson = {
        "employeename": this.state.empname,
        "registervia": 1,
        "username": localStorage.getItem('mobileNumber'),
        "mobileno": localStorage.getItem('mobileNumber'),
        "password": "",
        "dateofbirth": millisecondsFormat,
        "gender": this.state.selectedGender.length > 0 ? this.state.selectedGender[0] : -1,
        "maritalstatus": this.state.selectedMarital.length > 0 ? this.state.selectedMarital[0] : -1,
        "languageknown": this.state.dpbindlanguage,
        "schoolingqualificationcode": schoolingCode,
        "afterschoolingcode": afterSchoolingCode,
        "fresherstatus": this.state.selectedWorkExperience.length > 0 && Number(this.state.selectedWorkExperience[0]) ? Number(this.state.selectedWorkExperience[0]) : 0,
        "totalexp": totalexp,
        "schooling": schoolingArr,
        "afterschooling": afterSchoolingArr,
        "statecode": this.state.candidateStateValue,
        "districtcode": this.state.candidateDistrictValue,
        "jobtype": this.state.selectedJobType,
        "preferredlanguagecode": Number(this.state.languagecode) || COMMON.default_language_code,
        "preferences": {
          "isanystate": this.state.districtValue == -1 ? "true" : "false",
          "isanydistrict": this.state.districtValue == -1 ? "true" : "false",
          "statecode": this.state.stateValue,
          "maxsalary": Number(this.state.salary),
          "minsalary": Number(this.state.minsalary),
          "location": this.state.districtValue == -1 ? [] : [{ "locationcode": this.state.districtValue }],
          "jobfunction": jobFunctionRoleArr, //
          "jobrole": jobRoleArr,
          "employementtype": jobTypeArr
        },
        "skills": skillsArr,
        "experienceinfo": experienceArr,
        "leadcode": this.state.employeecode
      }
      this.setState({ isActive: this.state.isActive = true })
      try {
        axios({
          method: 'Post',
          url: this.state.ipurl + 'employeeportal/register/?',
          headers: {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
          },
          params: {
            ipaddress: this.state.ipaddress,
          },
          data: employeeProfileJson,
        }).then(async response => {
          this.setState({ isActive: this.state.isActive = false })
          if (response.data.status == 401) {
            await generateRefreshToken().then((result) => {
              if (result == 1) {
                this.registerEmployee();
              }
              else {
                // alert('Unauthorized')
              }
            })
          }
          else {
            if (response.data.hasOwnProperty('employee_json_result')) {
              this.state.varstatuscode = response.data.employee_json_result.hasOwnProperty('varstatuscode') ? response.data.employee_json_result.varstatuscode : 13;
              if (this.state.varstatuscode == 13) {
                let empcode = response.data.employee_json_result.employeecode;
                if (empcode > 0) {
                  localStorage.setItem('enableLogin', "true");
                  if (response.data.employee_json_result.accessToken)
                    localStorage.setItem('accessToken', response.data.employee_json_result.accessToken);
                  if (response.data.employee_json_result.refreshToken)
                    localStorage.setItem('refreshToken', response.data.employee_json_result.refreshToken);
                  localStorage.setItem('employeeName', this.state.empname);
                  localStorage.setItem('employeecode', empcode);
                  this.setState({ employeecode: this.state.employeecode = empcode })
                  localStorage.setItem('leadtype', 0);
                  toast.success(stringsoflanguages.registered_msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-success-container toast-success-container-after'
                  });
                  this.setState({ showimageupload: this.state.showimageupload = true })
                } else {
                  toast.error(response.data.employee_json_result.responsestring, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo-bar'
                  });
                }
              }
              else {
                toast.error(stringsoflanguages.already_Exist, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: 'foo-bar'
                });
              }
            }
          }
        })
      }
      catch (error) {
        this.setState({ isActive: this.state.isActive = false })
        error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "registerEmployee", 0, error.toString());
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "registerEmployee", 0, error.toString());
    }
  }
  goToProfile(e) {
    try {
      e.preventDefault();
      this.setState({
        showProfileDiv:
          (this.state.showProfileDiv = true),
      });
      this.setState({
        showJobFunctionDiv:
          (this.state.showJobFunctionDiv = false),
      });
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "goToProfile", 0, error.toString());
    }
  }
  goToSkill(e, varfromflag) {
    try {
      if (varfromflag == 0) {
        e.preventDefault();
        const experienceval = this.state.selectedWorkExperience[0] == '1' ? COMMON.fresherjobarray : this.state.selectedWorkExperience[0] == '0' ? COMMON.experiencejobarray : ''
        const toaster = this.state.selectedWorkExperience[0] == '1' ? stringsoflanguages.toaster_job_title_fresher_error : this.state.selectedWorkExperience[0] == '0' ? stringsoflanguages.toaster_job_title_error : ''
        if (this.state.selectJobRoleArray.length <= experienceval) {
        }
        else {
          toast.error(toaster, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo-bar'
          });
          return false;
        }
      }
      if (this.state.selectJobRoleArray.length > 0) {
        this.setState({
          showJobFunctionDiv:
            this.state.showJobFunctionDiv = false,
        });
        if (this.state.selectedJobRoles.length > 0) {
          let SelectedJobRolesArray = this.state.selectJobRoleArray
          for (var i = 0; i < SelectedJobRolesArray.length; i++) {
            if (!this.state.selectedJobRoles.find((ele => ele.jobrolecode == SelectedJobRolesArray[i]))) {
              let jobRoleFind = this.state.dpjobrole.find(ele => ele.jobrolecode == SelectedJobRolesArray[i])
              if (jobRoleFind) {
                let currentRoles = this.state.selectedJobRoles;
                currentRoles.push(jobRoleFind)
                this.setState({
                  selectedJobRoles: (this.state.selectedJobRoles = currentRoles)
                })
              }
            }
          }
          let result = [];
          for (var j = 0; j < this.state.selectedJobRoles.length; j++) {
            if (this.state.selectJobRoleArray.find((ele => ele == this.state.selectedJobRoles[j].jobrolecode))) {
              result.push(this.state.selectedJobRoles[j])
            }
          }
          this.setState({
            selectedJobRoles: (this.state.selectedJobRoles = result)
          })
        } else {
          let SelectedRoles = this.state.selectJobRoleArray.map(e => {
            let jobRoleFind = this.state.dpjobrole.find(ele => ele.jobrolecode == e)
            return e = jobRoleFind;
          })
          this.setState({
            selectedJobRoles: (this.state.selectedJobRoles = SelectedRoles)
          })
        }
        if (this.state.selectedJobRoles.length > 0) {
          this.setState({
            selectedJobRoleIndex: (this.state.selectedJobRoleIndex = 0),
          });
          this.state.selectedJobRoles.map(e => {
            var skills = this.state.skillList.filter((el) => el.jobfunctioncode == e.jobfunctioncode && el.jobrolecode == e.jobrolecode)
            return e['skills'] = skills;
          })
        }
        if (this.state.selectedJobRoles && this.state.selectedJobRoles.length > 0 && this.state.selectedJobRoles[0]['selectedSkills'] && this.state.selectedJobRoles[0]['selectedSkills'].length > 0) {
          this.setState({ selectedRoleSkilsArray: this.state.selectedRoleSkilsArray = this.state.selectedJobRoles[0]['selectedSkills'] });
        } else {
          this.setState({ selectedRoleSkilsArray: this.state.selectedRoleSkilsArray = [] });
        }
        if (varfromflag == 0) {
          this.saveSelectedJobRoles();
        }
      }
      else {
        toast.error(stringsoflanguages.err_preference_jobrole_required, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo-bar'
        });
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "goToSkill", 0, error.toString());
    }
  }
  saveSelectedJobRoles = () => {
    try {
      var schoolingCode = 0;
      var afterSchoolingCode = 0;
      var schoolingArr = [];
      var afterSchoolingArr = [];
      var totalexp = 0;
      var experienceArr = [];
      // var skillArr = [];
      var jobRoleArr = [];
      var skillsArr = [];
      var jobTypeArr = [];
      var jobFunctionRoleArr = [];
      if (this.state.selectedJobRoles.length > 0) {
        var selJobRoles = this.state.selectedJobRoles;
        for (var i = 0; i < selJobRoles.length; i++) {
          var selExp = selJobRoles[i]['selectedExperience'] != null && selJobRoles[i]['selectedExperience'].length > 0 ? selJobRoles[i]['selectedExperience'][0] : 0;
          if (selExp) {
            var expJson = this.state.experienceChips.filter(ex => ex.experiencecode == selExp);
            var expflag = selJobRoles.filter(e => e.jobrolecode)
            var current = expflag[i].jobrolecode == this.state.currentworkjobrolecode ? this.state.currentstatus : this.state.otherworkstatus
            if (expJson.length > 0) {
              if (expJson[0]['value'] > 0)
                totalexp = totalexp + expJson[0]['value'];
              var skillArr = [];
              if (selJobRoles && selJobRoles[i].selectedSkills && selJobRoles[i].selectedSkills.length > 0) {
                for (var j = 0; j < selJobRoles[i].selectedSkills.length; j++) {
                  var data = { "skillcode": selJobRoles[i].selectedSkills[j] }
                  skillArr.push(data)
                }
              }
              var exp = { "experiencecode": i + 1, "jobfunctioncode": selJobRoles[i]['jobfunctioncode'], "jobrolecode": selJobRoles[i]['jobrolecode'], "designationname": selJobRoles[i]['jobrolename'], "expid": expJson[0]['experiencecode'], "currentworkstatus": current, "skills": skillArr }
              experienceArr.push(exp);
            }
          }
          else {
            var exp = { "experiencecode": i + 1, "jobfunctioncode": selJobRoles[i]['jobfunctioncode'], "jobrolecode": selJobRoles[i]['jobrolecode'], "designationname": selJobRoles[i]['jobrolename'] }
            experienceArr.push(exp);
          }
          if (selJobRoles[i]['jobfunctioncode'])
            jobFunctionRoleArr.push({ "jobfunctioncode": selJobRoles[i]['jobfunctioncode'] })
          var jobRoleJs = { "jobrolecode": selJobRoles[i]['jobrolecode'] };
          jobRoleArr.push(jobRoleJs);
          if (selJobRoles[i]['selectedSkills']) {
            var selSkills = selJobRoles[i]['selectedSkills']
            if (selSkills.length > 0) {
              for (var j = 0; j < selSkills.length; j++) {
                var skillJs = selJobRoles[i]['skills'].filter(e => e.skillcode == selSkills[j]);
                if (skillJs.length > 0) {
                  skillJs[0]['currentjobfunction'] = (skillJs[0]['jobrolecode'] == this.state.currentworkjobrolecode) ? this.state.currentstatus : this.state.otherworkstatus;
                  skillsArr.push(skillJs[0]);
                }
              }
            }
          }
        }
      }
      var employeeProfileJson = {
        "skills": skillsArr,
        "experienceinfo": experienceArr
      }
      var accessToken = localStorage.getItem('accessToken');
      this.setState({ isActive: this.state.isActive = true })
      try {
        axios({
          method: 'Post',
          url: this.state.ipurl + 'employeeportal/jobfunctionupdate/?',
          params: {
            ipaddress: this.state.ipaddress,
            employeecode: localStorage.getItem('employeecode')
          },
          headers: {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
          },
          data: employeeProfileJson,
        }).then(async response => {
          if (response.data.status == 401) {
            await generateRefreshToken().then((result) => {
              if (result == 1) {
                this.saveSelectedJobRoles();
              }
              else {
                // alert('Unauthorized')
              }
            })
          }
          else {
            this.setState({ isActive: this.state.isActive = false })
            if (response.data.hasOwnProperty('employee_json_result')) {
              this.state.varstatuscode = response.data.employee_json_result.hasOwnProperty('varstatuscode') ? response.data.employee_json_result.varstatuscode : 13;
              if (this.state.varstatuscode == 2) {
                toast.success(stringsoflanguages.saved_success, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: 'toast-success-container toast-success-container-after'
                });
                this.setState({ showSkillsDiv: this.state.showSkillsDiv = true });
              }
              else {
                toast.error(response.data.employee_json_result.responsestring, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: 'foo-bar'
                });
              }
            }
          }
        })
      }
      catch (e) {
        this.setState({ isActive: this.state.isActive = false })
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "saveSelectedJobRoles", 0, error.toString());
    }
  }
  setJobRoles(event) {
    try {
      event.preventDefault();
      if (this.state.selectedJobRoles.length > 0) {
        this.setState({
          showJobRole: (this.state.showJobRole = false),
        });
        this.setState({
          showJobFunctionDiv: (this.state.showJobFunctionDiv = false),
        });
        if (this.state.selectedJobRoles.length > 0) {
          this.setState({
            selectedJobRoleIndex: (this.state.selectedJobRoleIndex = 0),
          });
          this.state.selectedJobRoles.map(e => {
            var skills = this.state.skillList.filter((el) => el.jobfunctioncode == e.jobfunctioncode && el.jobrolecode == e.jobrolecode)
            return e['skills'] = skills;
          })
        }
        this.setState({ showSkillsDiv: (this.state.showSkillsDiv = true) });
      }
      else {
        this.setState({
          showJobRole: (this.state.showJobRole = true),
        });
        toast.error(stringsoflanguages.err_preference_jobrole_required, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo-bar'
        });
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "setJobRoles", 0, error.toString());
    }
  }
  filterJobRole(jobfuncionids) {
    try {
      if (jobfuncionids.length > 0 && jobfuncionids.length <= 4) {
        this.setState({
          selectedJobRoles:
            (this.state.selectedJobRoles =
              jobfuncionids),
        })
      }
      if (jobfuncionids.length > 4) {
        toast.error(stringsoflanguages.toaster_job_title_error, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo-bar'
        });
        return false;
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "filterJobRole", 0, error.toString());
    }
  }
  filterJobFunction(searchValue) {
    try {
      this.setState({ txtJobFunction: this.state.txtJobFunction = searchValue })
      if (searchValue && searchValue != "" && searchValue.length >= 3) {
        // var filteredJobFunctionName = this.state.dpjobrole.filter(e=>e.jobrolename.toLowerCase().includes(searchValue.toLowerCase()));
        var filteredJobFunctionName = this.state.dpjobfunction.filter(e => e.jobfunctionname.toLowerCase().startsWith(searchValue.toLowerCase()));
        this.setState({ dpFilterdJobFunction: this.state.dpFilterdJobFunction = filteredJobFunctionName });
      }
      else {
        this.setState({ dpFilterdJobFunction: this.state.dpFilterdJobFunction = this.state.dpjobfunction });
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "filterJobFunction", 0, error.toString());
    }
  }
  searchJobRole(searchValue) {
    try {
      this.setState({ txtJobRole: this.state.txtJobRole = searchValue })
      if (searchValue != "") {
        var filteredJobRole = this.state.jobfunctionrole.filter(e => e.jobrolename.toLowerCase().includes(searchValue.toLowerCase()));
        this.setState({ filteredJobfunctionrole: this.state.filteredJobfunctionrole = filteredJobRole })
      }
      else {
        this.setState({ filteredJobfunctionrole: this.state.filteredJobfunctionrole = this.state.jobfunctionrole })
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "searchJobRole", 0, error.toString());
    }
  }
  selectJobRoleExperience(item, selectedChipIds) {
    try {
      this.state.selectedJobRoles.map(e => {
        if (e.jobrolecode == item.jobrolecode) {
          return e['selectedExperience'] = selectedChipIds
        }
      })
      this.setState({
        selectedJobRoles:
          this.state.selectedJobRoles
      })
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "selectJobRoleExperience", 0, error.toString());
    }
  }
  selectJobRoleSkills(item, selectedChipIds) {
    try {
      if (selectedChipIds && selectedChipIds.length > 6) {
        toast.error(stringsoflanguages.YouCanselectSkills, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo-bar'
        });
        return false;
      } else {
        this.state.selectedJobRoles.map(e => {
          if (e.jobrolecode == item.jobrolecode) {
            return e['selectedSkills'] = selectedChipIds
          }
        })
        this.setState({
          selectedJobRoles:
            this.state.selectedJobRoles
        })
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "selectJobRoleSkills", 0, error.toString());
    }
  }
  //Bind job function
  call_job_function() {
    try {
      var jobfunctionlist = localStorage.getItem("filter_job_function_list") != null ? JSON.parse(localStorage.getItem("filter_job_function_list")) : [];
      this.state.dpjobfunction = jobfunctionlist;
      var genderlist = localStorage.getItem("filter_gender_list") != null ? JSON.parse(localStorage.getItem("filter_gender_list")) : [];
      this.state.genderChips = genderlist;
      var educationcategorylist = localStorage.getItem("filter_education_category") != null ? JSON.parse(localStorage.getItem("filter_education_category")) : [];
      this.state.educationChips = educationcategorylist;
      var experiencelist = localStorage.getItem("filter_experience_list") != null ? JSON.parse(localStorage.getItem("filter_experience_list")) : [];
      this.state.experienceChips = experiencelist;
      var district_list = localStorage.getItem("filter_district_list") != null ? JSON.parse(localStorage.getItem("filter_district_list")) : [];
      this.state.dpdistrict = district_list;
      var skill_list = localStorage.getItem("filter_skill_list") != null ? JSON.parse(localStorage.getItem("filter_skill_list")) : [];
      this.state.skillList = skill_list;
      var jobrole_list = localStorage.getItem("filter_jobrole_list") != null ? JSON.parse(localStorage.getItem("filter_jobrole_list")) : [];
      this.state.allJobroleArray = jobrole_list;
      var job_type = localStorage.getItem("filter_jobtypelist") != null ? JSON.parse(localStorage.getItem("filter_jobtypelist")) : [];
      this.state.filteredJobType = job_type;
      this.state.dpjobrole = localStorage.getItem("filter_jobrole_list") != null ? JSON.parse(localStorage.getItem("filter_jobrole_list")) : [];
      this.setState({ dpjobrole: this.state.dpjobrole });
      this.state.maritalStatusList = localStorage.getItem("filter_maritalstatus_list") != null ? JSON.parse(localStorage.getItem("filter_maritalstatus_list")) : [];
      this.setState({ maritalStatusList: this.state.maritalStatusList });
      this.setState({ dpjobfunction: this.state.dpjobfunction });
      this.setState({ genderChips: this.state.genderChips });
      this.setState({ educationChips: this.state.educationChips });
      this.setState({ experienceChips: this.state.experienceChips });
      this.setState({ dpdistrict: this.state.dpdistrict });
      this.setState({ skillList: this.state.skillList });
      this.setState({ allJobroleArray: this.state.allJobroleArray });
      this.setState({ filteredJobType: this.state.filteredJobType });
      if (this.state.leadtype == 1) {
        this.getEmployeeDetails();
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "call_job_function", 0, error.toString());
    }
  }
  //Bind job function
  call_job_role_function() {
    try {
      var accessToken = localStorage.getItem('accessToken');
      window.fbq("track", "Website", {
        fb_ad_function: "call_job_role_function",
        fb_ad_type: "Job list",
        fb_ad_loginuser: COMMON.employee_name,
      });
      this.setState({ isActive: this.state.isActive = true })
      axios({
        method: "Post",
        url: this.state.ipurl + "employeeportal/filterbind",
        crossdomain: true,
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        },
        params: {
          deviceip: this.state.deviceip,
          type: 0,
          languagecode: 0,
          employeecode: this.state.employeecode,
        },
      })
        .then(async response => {
          if (response.data.status == 401) {
            await generateRefreshToken().then((result) => {
              if (result == 1) {
                this.call_job_role_function();
              }
              else {
                // alert('Unauthorized')
              }
            })
          }
          else {
            this.setState({ isActive: this.state.isActive = false })
            if (response.data.filter_json_result.varstatuscode === 4) {
              this.state.dpjobrole = response.data.filter_json_result.jobrolelist;
              let languagebasebind = this.state.dpjobrole.filter(el => el.languagecode == (this.state.languagecode || COMMON.default_language_code))
              this.setState({ dpFilterdJobFunction: this.state.dpjobrole });
              this.setState({ dpjobrole: this.state.dpjobrole });
            } else if (response.data.filter_json_result.varstatuscode === 7) {
            } else {
            }
          }
        })
        .catch((error) => {
          this.setState({ isActive: this.state.isActive = false })
          error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "call_job_role_function", 0, error.toString());
        });
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "call_job_role_function", 0, error.toString());
    }
  }
  GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }
  job_role_click = (event, jobrolecode) => {
    try {
      let check = event.target.checked;
      let value = event.target.value;
      let index = this.state.jobRoleArray.findIndex(el => el.jobrolecode == jobrolecode);
      if (index != -1) {
        this.state.jobRoleArray[index]['status'] = check
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.employeecode, this.state.version, "Search_Profiles", "jfunctionone", 0, error.toString());
    }
  }
  bind_job_role(item, mjfun_rowdata, idfun = 1) {
    try {
      this.state.jobRoleArray = mjfun_rowdata;
      if (this.state.jobRoleArray && this.state.jobRoleArray.length > 0) {
        return this.state.jobRoleArray.map((jfun, index) => {
          const { jobrolecode, status, jobrolename } = jfun
          return (
            <div className="cookies-checkbox mt-15" >
              <div className="d-flex flex-row justify-content-start">
                <Checkbox className="d-flex flex-row justify-content-start"
                  id="jobfunction"
                  icon=""
                  label={stringsoflanguages.employer_exp_currently}
                  checked={status}
                  onChange={(e) => {
                    this.job_role_click(e, jobrolecode)
                  }}
                />
              </div>
            </div>
          )
        })
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "bind_jobfunction", 0, error.toString());
    }
  }
  selectJobRole(e, isCreate = false) {
    try {
      if (!isCreate) {
        this.setState({
          selectJobRoleArray:
            (this.state.selectJobRoleArray =
              this.state.selectJobRoleArray.filter(el => el != e))
        })
      }
      const experienceval = this.state.selectedWorkExperience[0] == '1' ? COMMON.fresherjobarray : this.state.selectedWorkExperience[0] == '0' ? COMMON.experiencejobarray : ''
      const toaster = this.state.selectedWorkExperience[0] == '1' ? stringsoflanguages.toaster_job_title_fresher_error : this.state.selectedWorkExperience[0] == '0' ? stringsoflanguages.toaster_job_title_error : ''
      if (this.state.selectJobRoleArray.length < experienceval && isCreate) {
        if (this.state.dpjobrole && this.state.dpjobrole.findIndex(f => f['jobrolecode'] == e) == -1) {
          let jobTitle = this.state.allJobroleArray.find(f => f['jobrolecode'] == e);
          if (jobTitle && jobTitle['jobrolecode']) {
            this.state.dpjobrole.push(jobTitle);
            this.setState({
              dpjobrole: this.state.dpjobrole
            })
          }
        }
        let jobRoleArray = this.state.selectJobRoleArray
        jobRoleArray.push(e)
        this.setState({
          selectJobRoleArray:
            (this.state.selectJobRoleArray = jobRoleArray)
        })
      } else if (isCreate) {
        toast.error(toaster, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo-bar'
        });
        return false;
      } else {
        return false;
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "selectJobRole", 0, error.toString());
    }
  }
  check_inputfield_empty() {
    try {
      this.setState({
        txtempname_invalid: (this.state.txtempname_invalid = false),
        txtknowntype_invalid: (this.state.txtknowntype_invalid = false),
      });
      if (
        this.state.empname == "" ||
        this.state.empname == null ||
        this.state.empname == undefined
      ) {
        this.setState({
          txtempname_invalid: (this.state.txtempname_invalid = true),
        });
        return;
      }
      if (this.state.selected_knowntype == "0") {
        this.setState({
          txtknowntype_invalid: (this.state.txtknowntype_invalid = true),
        });
        return;
      }
      this.user_register();
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "check_inputfield_empty", 0, error.toString());
    }
  }
  inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      let next = elmnt.target.tabIndex - 2;
      if (next > 5) {
        elmnt.target.form.elements[next - 1].focus();
      }
    } else {
      let next = elmnt.target.tabIndex;
      if (next < 12) {
        elmnt.target.form.elements[next - 1].focus();
      }
    }
  };
  handleNameChange = (e) => {
    const inputValue = e.target.value;
    if (/^[A-Za-z. \s]*$/.test(inputValue)) {
      this.setState({
        empname: inputValue,
      });
    }
  };
  handledistrictchange = (e) => {
    const inputValue = e.target.value;
    this.setState({
      districtValue: inputValue,
    });
  }
  handleSalaryChange = (e) => {
    const inputValue = e.target.value;
    if (/^[0-9]*$/.test(inputValue)) {
      this.setState({
        salary: inputValue,
      });
    }
  };
  number_only = (event) => {
    const regex = /^[0-9]*$/;
    const input = event.target.value + event.key;
    if (!regex.test(input) || input.length > 5) {
      event.preventDefault();
    }
  };
  handleMinSalaryChange = (e) => {
    const inputValue = e.target.value;
    if (/^[0-9]*$/.test(inputValue)) {
      this.setState({
        minsalary: inputValue,
      });
    }
  };
  handleNextButtonClick = (e) => {
    try {
      e.preventDefault();
      const selectedDate = this.state.dateofbirth;
      const millisecondsFormat = new Date(selectedDate).getTime();
      const isGenderArrayValid = Array.isArray(this.state.selectedGender) && this.state.selectedGender.length > 0;
      const isEducationArrayValid = Array.isArray(this.state.selectedEducation) && this.state.selectedEducation.length > 0;
      const isDistrictValid = this.state.districtValue !== "0";
      const isDOBValid = millisecondsFormat != null && millisecondsFormat != undefined && millisecondsFormat != "undefined" && millisecondsFormat != "NaN" && millisecondsFormat != NaN && millisecondsFormat > 0;
      const iscandiDistrictValid = this.state.candidateDistrictValue !== "0";
      const islanguageArrayvalid = Array.isArray(this.state.selectedlanguage) && this.state.selectedlanguage.length > 0;
      const isWorkExperienceArrayValid = Array.isArray(this.state.selectedWorkExperience) && this.state.selectedWorkExperience.length > 0;
      const isJobTypeArrayValid = Array.isArray(this.state.selectedJobType) && this.state.selectedJobType.length > 0;
      if (!this.state.empname || !isGenderArrayValid || !isEducationArrayValid || !isDistrictValid || !isWorkExperienceArrayValid || !this.state.salary || !this.state.minsalary || !isJobTypeArrayValid || !iscandiDistrictValid || !islanguageArrayvalid || !isDOBValid) {
        toast.error(stringsoflanguages.PleaseSelectAllField, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo-bar'
        });
      } else {
        this.savePersonalInfo();
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "handleNextButtonClick", 0, error.toString());
    }
  };
  savePersonalInfo = () => {
    try {
      var schoolingCode = 0;
      var afterSchoolingCode = 0;
      var schoolingArr = [];
      var afterSchoolingArr = [];
      var totalexp = 0;
      var experienceArr = [];
      var jobRoleArr = [];
      var skillsArr = [];
      var jobTypeArr = [];
      var jobFunctionRoleArr = [];
      if (this.state.selectedEducation.length > 0) {
        var educationCode = this.state.selectedEducation[0]
        var getEducationJson = this.state.educationChips.filter(e => e.educationcategorycode == educationCode);
        if (getEducationJson.length > 0) {
          if (getEducationJson[0]['typecode'] == 1) {
            schoolingCode = getEducationJson[0]['educationcategorycode']
            schoolingArr = [{ "qualificationcode": schoolingCode, "schoolingcode": 1 }]
          }
          else {
            afterSchoolingCode = getEducationJson[0]['educationcategorycode']
            afterSchoolingArr = [
              { "afterschoolingcode": 1, "educationcategorycode": afterSchoolingCode }
            ]
          }
        }
      }
      if (this.state.selectedJobType.length > 0) {
        var jobType = this.state.selectedJobType
        for (var i = 0; i < jobType.length; i++) {
          jobTypeArr.push({
            employementtypecode: jobType[i]
          })
        }
      }
      const selectedDate = this.state.dateofbirth;
      const millisecondsFormat = new Date(selectedDate).getTime();
      var employeeProfileJson = {
        "employeename": this.state.empname,
        "mobileno": localStorage.getItem("employeemobilenumber"),
        "password": "",
        "dateofbirth": millisecondsFormat,
        "gender": this.state.selectedGender.length > 0 ? this.state.selectedGender[0] : -1,
        "maritalstatus": this.state.selectedMarital.length > 0 ? this.state.selectedMarital[0] : -1,
        "languageknown": this.state.dpbindlanguage,
        "schoolingqualificationcode": schoolingCode,
        "afterschoolingcode": afterSchoolingCode,
        "fresherstatus": this.state.selectedWorkExperience.length > 0 && Number(this.state.selectedWorkExperience[0]) ? Number(this.state.selectedWorkExperience[0]) : 0,
        "totalexp": totalexp,
        "schooling": schoolingArr,
        "afterschooling": afterSchoolingArr,
        "statecode": this.state.candidateStateValue,
        "districtcode": this.state.candidateDistrictValue,
        "jobtype": this.state.selectedJobType,
        "preferredlanguagecode": Number(this.state.languagecode) || COMMON.default_language_code,
        "preferences": {
          "isanystate": this.state.districtValue == -1 ? "true" : "false",
          "isanydistrict": this.state.districtValue == -1 ? "true" : "false",
          "statecode": this.state.stateValue,
          "maxsalary": Number(this.state.salary),
          "minsalary": Number(this.state.minsalary),
          "location": this.state.districtValue == -1 ? [] : [{ "locationcode": this.state.districtValue }],
          "employementtype": jobTypeArr
        }
      }
      var accessToken = localStorage.getItem('accessToken');
      this.setState({ isActive: this.state.isActive = true })
      try {
        axios({
          method: 'Post',
          url: this.state.ipurl + 'employeeportal/personalinfoupdate/?',
          params: {
            ipaddress: this.state.ipaddress,
            employeecode: localStorage.getItem('employeecode')
          },
          headers: {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
          },
          data: employeeProfileJson,
        }).then(async response => {
          if (response.data.status == 401) {
            await generateRefreshToken().then((result) => {
              if (result == 1) {
                this.savePersonalInfo();
              }
              else {
                // alert('Unauthorized')
              }
            })
          }
          else {
            this.setState({ isActive: this.state.isActive = false })
            if (response.data.hasOwnProperty('employee_json_result')) {
              this.state.varstatuscode = response.data.employee_json_result.hasOwnProperty('varstatuscode') ? response.data.employee_json_result.varstatuscode : 13;
              if (this.state.varstatuscode == 2) {
                localStorage.setItem('employeeName', this.state.empname);
                toast.success(stringsoflanguages.saved_success, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: 'toast-success-container toast-success-container-after'
                });
                this.setState({
                  showProfileDiv: false,
                  showJobFunctionDiv: true,
                });
              }
              else {
                toast.error(response.data.employee_json_result.responsestring, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: 'foo-bar'
                });
              }
            }
          }
          // }
        })
      }
      catch (e) {
        this.setState({ isActive: this.state.isActive = false })
      }
    }
    catch (error) {
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "savePersonalInfo", 0, error.toString());
    }
  }
  getEmployeeDetails() {
    try {
      try {
        this.setState({
          isActive: (this.state.isActive = true)
        })
        var accessToken = localStorage.getItem('accessToken');
        axios({
          method: 'Post',
          url: this.state.ipurl + 'employee/portalprofileload/?',
          params: {
            employeecode: this.state.employeecode,
            deviceip: this.state.ipaddress,
            languagecode: this.state.languagecode,
            isleadtype: this.state.leadtype
          },
          headers: {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
          },
        })
          .then(async (response) => {
            if (response.data.status == 401) {
              await generateRefreshToken().then((result) => {
                if (result == 1) {
                  this.getEmployeeDetails();
                }
                else {
                  // alert('Unauthorized')
                }
              })
            }
            else {
              this.setState({
                isActive: (this.state.isActive = false)
              })
              if (response.data.hasOwnProperty('employee_json_result')) {
                const { employee_json_result } = response.data;
                if (employee_json_result.varstatuscode === 4) {
                  this.setState({ selectedJobRoles: [] });
                  const regVia = employee_json_result.personalinfo.registervia;
                  const fullName = employee_json_result.personalinfo.employeefullname;
                  this.setState({ empname: this.state.empname = fullName });
                  const dateOfBirthMillis = employee_json_result.personalinfo.dateofbirth;
                  const dateOfBirth = dateOfBirthMillis == '' ? '' : new Date(dateOfBirthMillis).toLocaleString().split('T')[0];
                  const date_Birth = new Date(dateOfBirth)
                  this.setState({ dateofbirth: this.state.dateofbirth = date_Birth });
                  const mobileno = employee_json_result.contactinfo.mobileno;
                  this.setState({ contact: mobileno });
                  //experience
                  const totalexperience = employee_json_result.totalexperience;
                  this.setState({ experience: totalexperience });
                  //fresherstatus
                  var experienceval = [];
                  const fresherstatus = employee_json_result.fresherstatus;
                  this.setState({
                    frestatus: fresherstatus
                  });
                  experienceval.push(fresherstatus)
                  this.setState({ selectedWorkExperience: experienceval });
                  const skillsList = employee_json_result.skilllist;
                  const experienceDetails = employee_json_result && employee_json_result.experience;
                  //education Level
                  let Schooling = employee_json_result.schooling;
                  let AfterSchooling = employee_json_result.afterschooling;
                  const educationStatus = AfterSchooling.length > 0 ? AfterSchooling[0].qualificationcode : Schooling.length > 0 ? Schooling[0].qualificationcode : [];
                  this.setState({
                    education: educationStatus
                  });
                  const selectedEducationObj = this.state.educationChips.find((chip) => chip.educationcategorycode === this.state.education);
                  this.setState({ selectedEducation: selectedEducationObj ? [selectedEducationObj.educationcategorycode] : [] })
                  //Total percentage
                  const totalPer = employee_json_result.profilestatus && employee_json_result.profilestatus.totalpercentage;
                  this.setState({ totalPercentage: totalPer });
                  //salary 
                  const maxsalary = employee_json_result.preferences == undefined ? '' : employee_json_result.preferences.maxsalary;
                  this.setState({ salary: maxsalary });
                  //job Type 
                  const emptypelist = employee_json_result.preferences == undefined ? '' : employee_json_result.preferences.emptypelist;
                  let job_type = emptypelist.flatMap(el => el.employementtypecode);
                  this.setState({ selectedJobType: job_type });
                  //marital status
                  const maritalstatus = employee_json_result.personalinfo.maritalstatus;
                  this.setState({ status: maritalstatus });
                  //locations
                  const locationname = employee_json_result.contactinfo && employee_json_result.contactinfo.districtname;
                  const locationID = employee_json_result.preferences.locationlist.length > 0 ? employee_json_result.preferences.locationlist[0].locationcode : '';
                  const statename = employee_json_result.contactinfo && employee_json_result.contactinfo.statename;
                  //Candidate locations
                  const candidLocationname = employee_json_result.contactinfo ? employee_json_result.contactinfo.districtcode : '';
                  const candidStatename = employee_json_result.contactinfo.statecode;
                  const candidDistrictname = employee_json_result.contactinfo.districtcode;
                  this.setState({ showCandidateLocation: candidLocationname + '-' + candidStatename, candidateDistrictValue: candidDistrictname });
                  //State
                  const stateVal = employee_json_result.preferences.statecode;
                  this.setState({ statecode: stateVal, stateValue: stateVal });
                  const locationVal = locationname == undefined && statename == undefined ? '-' : locationID.toString() + '-' + stateVal.toString();
                  this.setState({ showLocation: locationVal, locationID: locationID });
                  this.setState({ districtValue: Number(this.state.locationID) });
                  const jobTypeVal = employee_json_result.preferences && employee_json_result.preferences.emptypelist;
                  var jobArray = [];
                  for (let emp = 0; emp < jobTypeVal.length; emp++) {
                    jobArray.push(jobTypeVal[emp].employementtypename)
                  }
                  this.setState({ jobTypeArray: jobArray });
                  //gender
                  const gendername = employee_json_result.personalinfo.gendername;
                  this.setState({ gender: gendername, genderID: employee_json_result.personalinfo.gender });
                  var selGender = [];
                  selGender.push(employee_json_result.personalinfo.gender);
                  this.setState({
                    selectedGender: selGender
                  })
                  const maritalname = employee_json_result.personalinfo.maritalstatus;
                  this.setState({ maritalname: maritalname, maritalID: employee_json_result.personalinfo.maritalstatuscode })
                  var selMarital = [];
                  selMarital.push(employee_json_result.personalinfo.maritalstatuscode)
                  this.setState({
                    selectedMarital: selMarital
                  })
                  if (employee_json_result.personalinfo.languagesknown == null || employee_json_result.personalinfo.languagesknown == undefined && employee_json_result.personalinfo.languagesknown == "undefined" && employee_json_result.personalinfo.languagesknown.length == 0) {
                    this.languageBind();
                  }
                  else {
                    this.setState({ dpbindlanguage: this.state.dpbindlanguage = employee_json_result.personalinfo.languagesknown });
                    this.setState({ selectedlanguage: this.state.selectedlanguage = employee_json_result.personalinfo.editlanguage })
                  }
                  const experienceData = employee_json_result.experience || [];
                  this.setState({
                    experience: experienceData,
                    designation: experienceData.map((exp) => exp.designationname).join(', '),
                  });
                  var skillexistflag = 0, currentexpflag = 0, currentexpcount = 0
                  if (this.state.experience != null && this.state.experience.length > 0 && this.state.experience[0].jobfunctioncode != null && this.state.experience[0].jobfunctioncode != undefined && this.state.experience[0].jobfunctioncode != "undefined" && this.state.experience[0].jobfunctioncode != 0) {
                    let valueArrr = this.state.experience[0].jobfunctioncode
                    this.setState({ showjobFunction: this.state.showjobFunction = valueArrr })
                    if (this.state.dpjobrole.find(el => el.jobfunctioncode == valueArrr))
                      this.setState({
                        dpFilterdJobFunction: this.state.dpFilterdJobFunction = this.state.dpjobrole.filter(el => el.jobfunctioncode == valueArrr) || [],
                        selectJobRoleArray: this.state.selectJobRoleArray = [],
                        selectedRoleSkilsArray: this.state.selectedRoleSkilsArray = []
                      });
                    for (let i = 0; i < this.state.experience.length; i++) {
                      this.selectJobRole(this.state.experience[i].jobrolecode, this.state.selectJobRoleArray.findIndex(f => f == this.state.experience[i].jobrolecode) > -1 ? false : true)
                      let SelectedRoles = this.state.selectJobRoleArray.map(e => {
                        let jobRoleFind = this.state.dpjobrole.find(ele => ele.jobrolecode == e)
                        return e = jobRoleFind;
                      })
                      this.setState({
                        selectedJobRoles: (this.state.selectedJobRoles = SelectedRoles)
                      })
                      if (this.state.experience[i].skills != null && this.state.experience[i].skills != undefined && this.state.experience[i].skills != "undefined" && this.state.experience[i].skills.length > 0) {
                        skillexistflag = skillexistflag + 1;
                      }
                      else {
                        if (currentexpcount == 0) {
                          currentexpcount = currentexpcount + 1;
                          currentexpflag = i;
                        }
                      }
                    }
                  }
                  const selectedDate = this.state.dateofbirth;
                  const millisecondsFormat = new Date(selectedDate).getTime();
                  if (skillexistflag > 0) {
                    for (let i = 0; i < this.state.experience.length; i++) {
                      if (this.state.experience[i].skills != null && this.state.experience[i].skills != undefined && this.state.experience[i].skills != "undefined" && this.state.experience[i].skills.length > 0) {
                        if (this.state.experience[i].jobrolecode == this.state.selectedJobRoles[i].jobrolecode) {
                          var skillArr = [];
                          for (let j = 0; j < this.state.experience[i].skills.length; j++) {
                            skillArr.push(this.state.experience[i].skills[j].skillcode);
                          }
                          this.state.selectedJobRoles[i].selectedSkills = skillArr;
                          this.state.selectedJobRoles[i].selectedExperience = [this.state.experience[i].expid];
                          this.selectJobRoleSkills(this.state.experience[i], skillArr)
                        }
                      }
                    }
                    this.goToSkill(0, 1)
                    this.setState({
                      showProfileDiv: false,
                      showJobFunctionDiv: false,
                    });
                    this.setState({
                      selectedRoleSkilsArray: (this.state.selectedJobRoles[currentexpflag].selectedSkills != null && this.state.selectedJobRoles[currentexpflag].selectedSkills != undefined && this.state.selectedJobRoles[currentexpflag].selectedSkills != "undefinde" && this.state.selectedJobRoles[currentexpflag].selectedSkills.length > 0) ? this.state.selectedJobRoles[currentexpflag].selectedSkills : []
                    })
                    this.setState({ showSkillsDiv: this.state.showSkillsDiv = true });
                    this.setState({
                      selectedJobRoleIndex: (this.state.selectedJobRoleIndex = currentexpflag),
                    });
                  }
                  else if (skillexistflag == 0 && this.state.selectedJobRoles != null && this.state.selectedJobRoles != undefined && this.state.selectedJobRoles != "undefined" && this.state.selectedJobRoles.length > 0) {
                    this.goToSkill(0, 1)
                    this.setState({
                      showProfileDiv: false,
                      showJobFunctionDiv: false,
                    });
                    this.setState({ showSkillsDiv: this.state.showSkillsDiv = true });
                    this.setState({
                      selectedJobRoleIndex: (this.state.selectedJobRoleIndex = 0),
                    });
                  }
                  else if (this.state.empname != "" && millisecondsFormat != null && millisecondsFormat != undefined && millisecondsFormat != "undefined" && millisecondsFormat != "NaN" && millisecondsFormat != NaN && millisecondsFormat > 0) {
                    this.setState({
                      showProfileDiv: false,
                      showJobFunctionDiv: true,
                    });
                  }
                } else if (employee_json_result.varstatuscode === 7) {
                  localStorage.clear();
                } else {
                  // Handle other status codes or errors
                }
              }
            }
          })
          .catch((error) => {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "getEmployeeDetails", 0, error.toString());
          });
      } catch (error) {
        this.setState({
          isActive: (this.state.isActive = false)
        })
        error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "getEmployeeDetails", 0, error.toString());
      }
    }
    catch (error) {
      this.setState({ isActive: (this.state.isActive = false) });
      error_log.ERRORLOG(this.state.usercode, this.state.version, "Profile_create", "getEmployeeDetails", 0, error.toString());
    }
  }
  handledateofbirth = (event) => {
    const selectedDate = event.target.value;
    this.setState({ dateofbirth: selectedDate });
  };
  convertToMilliseconds = () => {
    const selectedDate = this.state.dateofbirth;
    const millisecondsFormat = new Date(selectedDate).getTime();
  };
  render() {
    return (
      <div className="site-main">
        {!this.state.is_auth && <Redirect push to="/login" />}
        <ToastContainer autoClose={10000} toastStyle={{ backgroundColor: "#ed9d3b", color: 'white' }} progressStyle={{ background: 'white' }} />
        <Header />
        <div className="form-section clearfix">
          <div className="container">
          </div>
        </div>
        <LoadingOverlay
          active={this.state.isActive}
          spinner={
            <img src="images/svg/loading.gif" className="dz-img rounded-circle" alt="" />}
        ></LoadingOverlay>
        <div className="ttm-row sidebar job-sidebar clearfix" id="profileScreen">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg border rounded p-40 p-lg-20 mt_70 mt-20">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" style={{ textAlign: 'center' }}>
                    <label style={{ color: '#ff8d00' }}>{stringsoflanguages.welcome_text} {this.state.empname == null || this.state.empname == undefined || this.state.empname == 'undefined' || this.state.empname == '' ? 'User' : this.state.empname}. {stringsoflanguages.welcome_text1}</label>
                  </div>
                  {this.state.showProfileDiv == true ? (
                    <div className="layer-content" style={{ marginTop: window.innerWidth < 768 ? '10%' : 0 }}>
                      <div className="mb-20">
                        <h6>{stringsoflanguages.re_pro}</h6>
                      </div>
                      <div className="ttm-tabs ttm-tab-style-02">
                        <div className="content-tab">
                          {/* <TabPanel> */}
                          <form className="login_form wrap-form">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="col-lg-12">
                                  <FormGroup>
                                    <label>{stringsoflanguages.ref_name}*</label>
                                    <label>
                                      <i className="ti ti-user"></i>
                                      <input
                                        type="text"
                                        id="txtempname"
                                        placeholder={stringsoflanguages.registration_user}
                                        autoComplete="off"
                                        tabIndex="1"
                                        value={this.state.empname}
                                        onChange={this.handleNameChange}
                                        onBlur={this.checkInputFieldEmpty}
                                      />
                                    </label>
                                  </FormGroup>
                                </div>
                                <div className="col-lg-12">
                                  <FormGroup>
                                    <label>{stringsoflanguages.rl_gender}*</label>
                                    <ChipSet
                                      choice
                                      handleSelect={(selectedChipIds) =>
                                        this.setState({
                                          selectedGender:
                                            (this.state.selectedGender =
                                              selectedChipIds),
                                        })
                                      }
                                    >
                                      {this.state.genderChips.map((chip) => (
                                        <Chip
                                          className={
                                            this.state.selectedGender[0] ==
                                              chip.gendercode
                                              ? "selectedChipBg"
                                              : "chipBg"
                                          }
                                          id={chip.gendercode}
                                          key={chip.gendercode} // The chip's key cannot be its index, because its index may change.
                                          label={chip.gendername}
                                        />
                                      ))}
                                    </ChipSet>
                                  </FormGroup>
                                </div>
                                <div className="col-lg-12">
                                  <FormGroup>
                                    <label>{stringsoflanguages.rl_maritial}*</label>
                                    <ChipSet
                                      choice
                                      handleSelect={(selectedChipIds) =>
                                        this.setState({
                                          selectedMarital:
                                            (this.state.selectedMarital =
                                              selectedChipIds),
                                        })
                                      }
                                    >
                                      {this.state.maritalStatusList.map((chip) => (
                                        <Chip
                                          className={
                                            this.state.selectedMarital[0] ==
                                              chip.maritalcode
                                              ? "selectedChipBg"
                                              : "chipBg"
                                          }
                                          id={chip.maritalcode}
                                          key={chip.maritalcode} // The chip's key cannot be its index, because its index may change.
                                          label={chip.maritalname}
                                        />
                                      ))}
                                    </ChipSet>
                                  </FormGroup>
                                </div>
                                <div className="col-lg-12">
                                  <FormGroup>
                                    <label>{stringsoflanguages.edu_trt}*</label>
                                    <ChipSet
                                      choice
                                      handleSelect={(selectedChipIds) =>
                                        this.setState({
                                          selectedEducation:
                                            (this.state.selectedEducation =
                                              selectedChipIds),
                                        })
                                      }
                                    >
                                      {this.state.educationChips.map((chip) => (
                                        <Chip
                                          className={
                                            this.state.selectedEducation[0] ==
                                              chip.educationcategorycode
                                              ? "selectedChipBg"
                                              : "chipBg"
                                          }
                                          id={chip.educationcategorycode}
                                          key={chip.educationcategorycode} // The chip's key cannot be its index, because its index may change.
                                          label={chip.educationcategoryname}
                                        />
                                      ))}
                                    </ChipSet>
                                  </FormGroup>
                                </div>
                                <div className="col-lg-12">
                                  <FormGroup>
                                    <label>{stringsoflanguages.candidate_job_type}*</label>
                                    <ChipSet
                                      choice
                                    >
                                      {this.state.filteredJobType.map((chip) => (
                                        <Chip
                                          className={
                                            this.state.selectedJobType.findIndex(f => f == chip.jobtypecode) > -1
                                              ? "selectedChipBg"
                                              : "chipBg"
                                          }
                                          onClick={() => {
                                            if (this.state.selectedJobType && this.state.selectedJobType.length == 4 && this.state.selectedJobType.findIndex(f => f == chip.jobtypecode) == -1) {
                                              toast.error(stringsoflanguages.toaster_job_type_error, {
                                                position: toast.POSITION.TOP_RIGHT,
                                                className: 'foo-bar'
                                              });
                                              return;
                                            } else if (!this.state.selectedJobType || this.state.selectedJobType.findIndex(f => f == chip.jobtypecode) == -1) {
                                              this.state.selectedJobType.push(chip.jobtypecode)
                                              this.setState({
                                                selectedJobType: this.state.selectedJobType
                                              })
                                            } else {
                                              this.setState({
                                                selectedJobType: this.state.selectedJobType = this.state.selectedJobType.filter(f => f != chip.jobtypecode)
                                              })
                                            }
                                          }}
                                          id={chip.jobtypecode}
                                          key={chip.jobtypecode} // The chip's key cannot be its index, because its index may change.
                                          label={chip.jobtypename}
                                        />
                                      ))}
                                    </ChipSet>
                                  </FormGroup>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="col-lg-12">
                                  <label>{stringsoflanguages.Candidate_location}*</label>
                                  <label>
                                    <i className="ti ti-location-pin"></i>
                                    <select
                                      tabIndex="2"
                                      value={this.state.showCandidateLocation}
                                      onChange={(e) => {
                                        if (e.target.value != "0") {
                                          var valueArrr = e.target.value.split("-");
                                          if (valueArrr.length > 0) {
                                            var stateCode = valueArrr[1];
                                            var districtCode = valueArrr[0];
                                            this.setState({ candidateDistrictValue: this.state.candidateDistrictValue = Number(districtCode) })
                                            this.setState({ candidateStateValue: this.state.candidateStateValue = Number(stateCode) })
                                            this.setState({ showCandidateLocation: this.state.showCandidateLocation = districtCode.toString() + "-" + stateCode.toString() })
                                          }
                                        }
                                      }}
                                      onBlur={() =>
                                        this.check_inputfield_empty()
                                      }
                                    >
                                      <option value="0">{stringsoflanguages.Employee_known_text}</option>
                                      {this.state.dpdistrict.map((o) => (
                                        <option
                                          key={o.districtcode}
                                          value={o.districtcode.toString() + "-" + o.statecode.toString()}
                                        >
                                          {o.districtname}
                                        </option>
                                      ))}
                                    </select>
                                  </label>
                                </div>
                                <div className="col-lg-12">
                                  <label>{stringsoflanguages.preferedLocation}*</label>
                                  <label>
                                    <i className="ti ti-location-pin"></i>
                                    <select
                                      tabIndex="3"
                                      value={this.state.showLocation}
                                      onChange={(e) => {
                                        if (e.target.value == "-1") {
                                          var valueArrr = e.target.value.split("-");
                                          if (valueArrr.length > 0) {
                                            var stateCode = valueArrr[1];
                                            var districtCode = valueArrr[0];
                                            this.setState({ districtValue: this.state.districtValue = Number(e.target.value) })
                                            this.setState({ stateValue: this.state.stateValue = Number(stateCode) })
                                            this.setState({ showLocation: this.state.showLocation = districtCode.toString() + "-" + stateCode.toString() })
                                          }
                                        }
                                        else if (e.target.value != "0") {
                                          var valueArrr = e.target.value.split("-");
                                          if (valueArrr.length > 0) {
                                            var stateCode = valueArrr[1];
                                            var districtCode = valueArrr[0];
                                            this.setState({ districtValue: this.state.districtValue = Number(districtCode) })
                                            this.setState({ stateValue: this.state.stateValue = Number(stateCode) })
                                            this.setState({ showLocation: this.state.showLocation = districtCode.toString() + "-" + stateCode.toString() })
                                          }
                                        } else {
                                          this.setState({ districtValue: this.state.districtValue = "0" })
                                          this.setState({ stateValue: this.state.stateValue = 0 })
                                          this.setState({ showLocation: this.state.showLocation = "" })
                                        }
                                      }}
                                      onBlur={() =>
                                        this.check_inputfield_empty()
                                      }
                                    >
                                      <option value="0">{stringsoflanguages.Employee_known_text}</option>
                                      {this.state.dpdistrict.map((o) => (
                                        <option
                                          key={o.districtcode}
                                          value={o.districtcode.toString() + "-" + o.statecode.toString()}
                                        >
                                          {o.districtname}
                                        </option>
                                      ))}
                                      <option value="-1">{stringsoflanguages.any_location}</option>
                                    </select>
                                  </label>
                                </div>
                                <div className="col-lg-6">
                                  <div className="col-lg-12">
                                    <FormGroup>
                                      <label>{stringsoflanguages.date_of_birth}*</label>
                                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker stmaxDateofbirthyle={{ background: 'none' }}
                                          className='form-control datepick'
                                          onChange={(e) => {
                                            this.setState({ dateofbirth: e.$d })
                                          }
                                          }
                                          value={dayjs(this.state.dateofbirth)}
                                          defaultValue={dayjs(this.state.dateofbirth)}
                                          maxDate={dayjs(this.state.maxDateofbirth)}
                                          format="DD/MM/YYYY"
                                        />
                                      </LocalizationProvider>
                                    </FormGroup>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <FormGroup>
                                    <label>{stringsoflanguages.wrk_pro}*</label>
                                    <ChipSet
                                      choice
                                      handleSelect={(selectedChipIds) =>
                                        this.setState({
                                          selectedWorkExperience:
                                            (this.state.selectedWorkExperience =
                                              selectedChipIds),
                                        })
                                      }
                                    >
                                      {this.state.workexperienceChips.map(
                                        (chip) => (
                                          <Chip
                                            className={
                                              this.state
                                                .selectedWorkExperience[0] ==
                                                chip.id
                                                ? "selectedChipBg"
                                                : "chipBg"
                                            }
                                            id={chip.id}
                                            key={chip.id} // The chip's key cannot be its index, because its index may change.
                                            label={chip.value}
                                          />
                                        )
                                      )}
                                    </ChipSet>
                                  </FormGroup>
                                </div>
                                <div className="col-lg-12">
                                  <FormGroup>
                                    <label>{stringsoflanguages.personal_lg}</label>
                                  </FormGroup>
                                  {this.state.dpbindlanguage.map((lang) => (
                                    <FormGroup style={{ display: 'flex' }} className="col-lg-12 cls-lang-frm">
                                      <label className="col-lg-4 cls-lang-lbl col-md-12">{lang.languagename}</label>
                                      <ChipSet className="col-lg-8 col-md-12"
                                        choice
                                        handleSelect={(selectedChipIds) => {
                                          this.setState({
                                            selectedlanguage:
                                              (this.state.selectedlanguage =
                                                selectedChipIds),
                                          })
                                          lang.status = 1
                                          lang.knowntype.map(e => {
                                            if (selectedChipIds.includes(e.knownto)) {
                                              if (lang.knowntype.findIndex(f => ((f.status == 0) && (f.knownto == this.state.selectedlanguage))) != -1) {
                                                return e.status = 1;
                                              }
                                              else if (lang.knowntype.findIndex(f => ((f.status == 1) && (f.knownto == this.state.selectedlanguage))) != -1) {
                                                return e.status = 0;
                                              }
                                            }
                                          })
                                          return lang;
                                        }
                                        }
                                      >
                                        {lang.knowntype.map(
                                          (chip) => (
                                            <Chip
                                              className={
                                                chip.status == 1
                                                  ? "selectedChipBg"
                                                  : "chipBg"
                                              }
                                              id={chip.knownto}
                                              key={chip.knownto} // The chip's key cannot be its index, because its index may change.
                                              label={chip.knownto == 'read' ? stringsoflanguages.lang_read_text : chip.knownto == 'write' ? stringsoflanguages.lang_write_text : chip.knownto == 'speak' ? stringsoflanguages.lang_speak_text : ''}
                                            />
                                          )
                                        )}
                                      </ChipSet>
                                    </FormGroup>
                                  ))}
                                </div>
                                <div className="col-lg-12">
                                  <FormGroup>
                                    <label>{stringsoflanguages.cur_ex}*</label>
                                    <label>
                                      <div className="row">
                                        <div className="col-lg-4">
                                          <label className="col-lg-2">{stringsoflanguages.min_salary}</label>
                                          <input
                                            type="number"
                                            max="5"
                                            id="txtminsal"
                                            placeholder={stringsoflanguages.min_salary}
                                            autoComplete="off"
                                            tabIndex="5"
                                            onKeyPress={(e) => this.number_only(e)}
                                            className="clsInput"
                                            value={this.state.minsalary}
                                            onChange={this.handleMinSalaryChange}
                                            onBlur={this.checkInputFieldEmpty}
                                          /></div>
                                        <div className="col-lg-4">
                                          <label className="col-lg-2">{stringsoflanguages.max_salary}</label>
                                          <input
                                            type="number"
                                            id="txtmaxsal"
                                            placeholder={stringsoflanguages.max_salary}
                                            autoComplete="off"
                                            tabIndex="5"
                                            maxLength={5}
                                            size={5}
                                            onKeyPress={(e) => this.number_only(e)}
                                            className="clsInput"
                                            value={this.state.salary}
                                            onChange={this.handleSalaryChange}
                                            onBlur={this.checkInputFieldEmpty}
                                          /></div>
                                      </div>
                                    </label>
                                  </FormGroup>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.showJobFunctionDiv == true ? (
                    <div className="layer-content login_form wrap-form" style={{ marginTop: window.innerWidth < 768 ? '10%' : 0 }}>
                      <div className="mb-20">
                        <h6>{stringsoflanguages.chose_ji}* </h6>
                      </div>
                      <div className="col-lg-6">
                        <label>{stringsoflanguages.employer_home_jobfunction}*</label>
                        <label>
                          <i className="ti ti-search"></i>
                          <select
                            tabIndex="2"
                            value={this.state.showjobFunction}
                            onChange={(e) => {
                              if (e.target.value != "0") {
                                var valueArrr = e.target.value;
                                this.setState({ showjobFunction: this.state.showjobFunction = Number(valueArrr) })
                                if (this.state.dpjobrole.find(el => el.jobfunctioncode == valueArrr))
                                  this.setState({
                                    dpFilterdJobFunction: this.state.dpFilterdJobFunction = this.state.dpjobrole.filter(el => el.jobfunctioncode == valueArrr) || [],
                                    selectJobRoleArray: this.state.selectJobRoleArray = [],
                                    selectedRoleSkilsArray: this.state.selectedRoleSkilsArray = []
                                  });
                              }
                            }}
                            onBlur={() =>
                              this.check_inputfield_empty()
                            }
                          >
                            <option value="0">{stringsoflanguages.Employee_known_text}</option>
                            {this.state.dpjobfunction.map((o) => (
                              <option
                                key={o.jobfunctioncode}
                                value={o.jobfunctioncode}
                              >
                                {o.jobfunctionname}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                      <div className="ttm-tabs ttm-tab-style-02">
                        <div className="content-tab">
                          {/* <TabPanel> */}
                          <form className="login_form wrap-form">
                            <div className="row">
                              {this.state.dpFilterdJobFunction && this.state.dpFilterdJobFunction.length > 0 ? <div className="col-lg-12">
                                <label>{stringsoflanguages.pre_jobrole}*</label>
                                <FormGroup>
                                  <ChipSet
                                    choice
                                  >
                                    {this.state.dpFilterdJobFunction.map((chip) => (
                                      <Chip
                                        className={
                                          this.state.selectJobRoleArray.findIndex(f => f == chip.jobrolecode) > -1
                                            ? "selectedChipBg"
                                            : "chipBg"
                                        }
                                        leadingIcon={
                                          <img src={chip.imageurl}></img>
                                        }
                                        onClick={() => this.selectJobRole(chip.jobrolecode, this.state.selectJobRoleArray.findIndex(f => f == chip.jobrolecode) > -1 ? false : true)}
                                        id={chip.jobrolecode}
                                        key={chip.jobrolecode}
                                        label={chip.jobrolename}
                                      />
                                    ))}
                                  </ChipSet>
                                </FormGroup>
                              </div> : <></>
                              }
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.selectedJobRoles.length > 0 &&
                    this.state.showSkillsDiv == true &&
                    this.state.selectedJobRoles.map((item, index) => (
                      <div className="layer-content" style={{ marginTop: window.innerWidth < 768 ? '10%' : 0 }}>
                        {this.state.selectedJobRoleIndex == index ? (
                          <div>
                            <div className="mb-20">
                              <h6>
                                {item.jobrolename}
                              </h6>
                              {this.state.selectedWorkExperience[0] == '1' ? null :
                                <Checkbox className="d-flex flex-row justify-content-start"
                                  id="jobfunction"
                                  icon=""
                                  label={stringsoflanguages.job_currently}
                                  checked={(this.state.currentworkflag == true && this.state.currentworkjobrolecode == item.jobrolecode) || (this.state.selectedWorkExperience[0] == '1')}
                                  disabled={this.state.currentworkjobrolecode != item.jobrolecode && this.state.currentworkflag == true}
                                  onChange={(e) => {
                                    this.setState({ currentworkflag: this.state.currentworkflag = !this.state.currentworkflag })
                                    this.setState({ currentworkjobrolecode: this.state.currentworkjobrolecode = item.jobrolecode })
                                  }}
                                />
                              }
                            </div>
                            <div className="ttm-tabs ttm-tab-style-02">
                              <div className="content-tab">
                                {/* <TabPanel> */}
                                <form className="login_form wrap-form">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <FormGroup>
                                        <label>{stringsoflanguages.employee_experience}</label>
                                        <ChipSet
                                          choice
                                          handleSelect={(selectedChipIds) => {
                                            this.selectJobRoleExperience(item, selectedChipIds)
                                          }
                                          }
                                        >
                                          {this.state.experienceChips.map(
                                            (chip) => (
                                              <Chip
                                                className={
                                                  item['selectedExperience'] ? item['selectedExperience'][0] ==
                                                    chip.experiencecode
                                                    ? "selectedChipBg"
                                                    : "chipBg" : "chipBg"
                                                }
                                                id={chip.experiencecode}
                                                key={chip.experiencecode} // The chip's key cannot be its index, because its index may change.
                                                label={chip.experiencename}
                                              />
                                            )
                                          )}
                                        </ChipSet>
                                      </FormGroup>
                                    </div>
                                    <div className="col-lg-12">
                                      <FormGroup>
                                        <label>
                                          {stringsoflanguages.skills_known}
                                        </label>
                                        <ChipSet
                                          choice
                                        >
                                          {item.skills.map(
                                            (chip) => (
                                              <Chip
                                                className={
                                                  item['selectedSkills'] ? item['selectedSkills'].findIndex(f => f == chip.skillcode) > -1 ?
                                                    "selectedChipBg"
                                                    : "chipBg" : "chipBg"
                                                }
                                                onClick={() => {
                                                  if (this.state.selectedRoleSkilsArray && this.state.selectedRoleSkilsArray.length == 6 && item['selectedSkills'].findIndex(f => f == chip.skillcode) == -1) {
                                                    toast.error(stringsoflanguages.YouCanselectSkills, {
                                                      position: toast.POSITION.TOP_RIGHT,
                                                      className: 'foo-bar'
                                                    });
                                                    return;
                                                  } else if (!item['selectedSkills'] || item['selectedSkills'].findIndex(f => f == chip.skillcode) == -1) {
                                                    this.state.selectedRoleSkilsArray.push(chip.skillcode)
                                                    this.setState({
                                                      selectedRoleSkilsArray: this.state.selectedRoleSkilsArray
                                                    })
                                                  } else {
                                                    this.setState({
                                                      selectedRoleSkilsArray: this.state.selectedRoleSkilsArray = item['selectedSkills'].filter(f => f != chip.skillcode)
                                                    })
                                                  }
                                                  this.selectJobRoleSkills(item, this.state.selectedRoleSkilsArray)
                                                }}
                                                id={chip.skillcode.toString() + item.jobrolecode.toString()}
                                                key={chip.skillcode.toString() + item.jobrolecode.toString()}
                                                label={chip.skillname}
                                              />
                                            )
                                          )}
                                        </ChipSet>
                                      </FormGroup>
                                    </div>
                                    <div
                                      className="col-lg-12"
                                      style={{ textAlign: "center" }}
                                    >
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault()
                                          if (window.innerWidth <= 768)
                                            this.scrollToElement()
                                          if (index >= 1) {
                                            this.setState({
                                              selectedJobRoleIndex:
                                                (this.state.selectedJobRoleIndex =
                                                  index - 1),
                                            });
                                            if (this.state.selectedJobRoles[this.state.selectedJobRoleIndex] && this.state.selectedJobRoles[this.state.selectedJobRoleIndex]['selectedSkills']) {
                                              this.setState({
                                                selectedRoleSkilsArray: (this.state.selectedRoleSkilsArray = this.state.selectedJobRoles[this.state.selectedJobRoleIndex]['selectedSkills'])
                                              })
                                            } else {
                                              this.setState({
                                                selectedRoleSkilsArray: (this.state.selectedRoleSkilsArray = [])
                                              })
                                            }
                                          }
                                          else {
                                            this.setState({
                                              selectedJobRoleIndex:
                                                (this.state.selectedJobRoleIndex =
                                                  -1),
                                            });
                                            this.setState({
                                              showSkillsDiv:
                                                (this.state.showSkillsDiv = false),
                                            });
                                            this.setState({
                                              showJobFunctionDiv:
                                                (this.state.showJobFunctionDiv = true),
                                            });
                                          }
                                        }}
                                        className="btnprevious"
                                      >
                                        {" "}
                                        {stringsoflanguages.pre_me}
                                      </button>
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault()
                                          if (window.innerWidth <= 768)
                                            this.scrollToElement()
                                          this.setState({
                                            selectedRoleSkilsArray: (this.state.selectedRoleSkilsArray = [])
                                          });
                                          this.handleJobRoleNext(index, item)
                                        }}
                                        style={{ width: "auto", minWidth: "100px" }}
                                        className="btnnext"
                                      >
                                        {(index + 1) ==
                                          this.state.selectedJobRoles.length ? stringsoflanguages.forgotsubmit : stringsoflanguages.langselectnext}
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="row stickyButton">
              {this.state.showProfileDiv == true ?
                <div
                  className="col-lg-12">
                  <button tabIndex="6"
                    onClick={(e) => {
                      if (window.innerWidth <= 768)
                        this.scrollToElement()
                      this.handleNextButtonClick(e)
                    }}
                    className="btnnext" >{stringsoflanguages.langselectnext}</button>
                </div> : null}
              {this.state.showJobFunctionDiv == true ?
                <div
                  className="col-lg-12">
                  <button
                    onClick={(e) => {
                      if (window.innerWidth <= 768)
                        this.scrollToElement()
                      this.goToProfile(e)
                    }} className="btnprevious">
                    {stringsoflanguages.pre_me}
                  </button>
                  <button onClick={(e) => {
                    if (window.innerWidth <= 768)
                      this.scrollToElement()
                    //  this.setJobRoles(e)
                    this.goToSkill(e, 0)
                  }} className="btnnext"> {stringsoflanguages.langselectnext}</button>
                </div>
                : null}
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.isupload == true} className='JobList_Popup' style={{ width: '100%', height: '100%' }}>
          <ModalHeader style={{ width: "100%", display: 'block', height: '10%' }}>
            <div className='row'>
              <div className={window.innerWidth > 850 ? 'col-11' : 'col-11'} style={{ display: 'inline-block' }}>
                <h6 style={window.innerWidth > 850 ? { width: "95%", flex: 50 } : { width: "50%", flex: 50, display: 'inline-block' }}>{stringsoflanguages.file_upload_text}
                </h6>
              </div>
              <div className='col-1' style={{ marginLeft: -20 }}>
                <div >
                  <a className="close_btn" style={{ cursor: 'pointer' }} onClick={() => { this.setState({ isupload: this.state.isupload = false }) }}>
                    <i className="ti ti-close"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-10'>
              </div>
              <div className='col-lg-2'>
                <button className='apply-button ttm-btn ttm-btn-size-md ttm-btn-shape-rounded applyBtn ttm-btn-style-fill ttm-btn-color-skincolor jobDetail'
                  onClick={this.showCroppedImage}
                  variant="contained"
                  color="primary"
                  style={{ alignContent: 'flex-end', alignItems: 'flex-end', alignSelf: 'flex-end', textAlign: 'center', width: '50%', display: 'inline-block' }}
                // classes={{ root: classes.cropButton }}
                >
                  {stringsoflanguages.rejection_save_text}
                </button>
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
              <div style={{ width: '100%', height: '400px', position: 'relative' }}>
                <div>
                  <Cropper
                    image={this.state.imageSrc}
                    crop={this.state.crop}
                    zoom={this.state.zoom}
                    aspect={this.state.aspect}
                    onCropChange={this.onCropChange}
                    onCropComplete={this.onCropComplete}
                    onZoomChange={this.onZoomChange}
                  /></div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          className="JobList_Popup"
          style={{ width: "350px!important" }}
          backdrop="static"
          id="jobrole"
          isOpen={this.state.showJobRole}
        >
          <ModalHeader
            style={{ borderColor: "white" }}
            className="profile-modal-color bg-mycolortext"
          >
            <div className="row" style={{ textAlign: 'end' }}>
              <a
                className="close_btn"
                onClick={() => {
                  if (this.state.selectedJobRoles.length == 0) {
                    this.setState({
                      selectedJobFunction: this.state.selectedJobFunction = [],
                    })
                  }
                  this.setState({
                    showJobRole: (this.state.showJobRole = false),
                  });
                }
                }
              >
                <i class="ti ti-close"></i>
              </a>
            </div>
            <div className="row">
              <div className="col-lg-11 login_form wrap-form">
                <FormGroup>
                  <label>
                    <i className="ti ti-search"></i>
                    <input
                      type="text"
                      id="txtempname"
                      placeholder="Search your job role..."
                      autoComplete="off"
                      tabIndex="1"
                      value={this.state.txtJobRole}
                      onChange={(e) => {
                        this.searchJobRole(e.target.value)
                      }}
                    />
                    {" "}
                  </label>
                </FormGroup>
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <form className="login_form wrap-form">
              <div className="row">
                <div className="row">
                  <div className="col-lg-1"></div>
                  <div
                    className="col-lg-11"
                    style={{ maxHeight: "250px", overflowY: "scroll" }}
                  >
                    {this.state.filteredJobfunctionrole.map((elem) => (
                      <Checkbox
                        className="d-flex flex-row justify-content-start"
                        id={elem.jobrolecode}
                        icon=""
                        style={{ color: '#000' }}
                        label={elem.jobrolename}
                        checked={this.state.selectedJobRoles.findIndex(e => e.jobrolecode == elem.jobrolecode) > -1 ? true : false}
                        onChange={(e) => {
                          this.selectJobRole(e, elem);
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div className="col-lg-12" style={{ textAlign: "center" }}>
                  <button
                    onClick={(event) => {
                      this.setState({
                        showJobRole: (this.state.showJobRole = false),
                      });
                      this.setJobRoles(event);
                    }}
                    className="btnnext"
                  >
                    {" "}
                    Select
                  </button>
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>
        <Modal
          className="JobList_Popup"
          style={{ width: "100px!important" }}
          backdrop="static"
          id="jobrole"
          isOpen={this.state.showimageupload}
        >
          <ModalHeader
            style={{ display: 'none' }}
          >
          </ModalHeader>
          <ModalBody>
            <div className="mb-20 d-flex dialogTitle bg-theme-GreyColor" >
              <h6 style={window.innerWidth > 850 ? { width: "95%" } : { width: "100%" }}>{stringsoflanguages.image_text}</h6>
              <div>  <a className="close_btn" style={{ cursor: 'pointer' }} onClick={() => this.closeimageModal()}>
                <i className="ti ti-close"></i>
              </a> </div>
            </div>
            <div className="candidate-img" style={{ cursor: 'pointer', textAlign: "center", alignContent: "center", alignItems: "center", alignSelf: "center" }}>
              <label
                className=""
                style={{ cursor: 'pointer', textAlign: "center", alignContent: "center", alignItems: "center", alignSelf: "center" }}
                htmlFor="imgorganization_logo"
                color="primary" onChange={this.getUploadedProfileImage}>
                <i className="fa fa-camera upload_camera_style dz-img fa-10x" hidden aria-hidden="true"></i>
                <input
                  type="file"
                  id="imgorganization_logo" hidden
                />
                <img src={uploadicon} />
              </label>
            </div>
          </ModalBody>
        </Modal>
      </div >
    );
  }
}
export default CreateProfile;

import React, { Suspense, lazy } from "react"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './css/vendor/flaticon.css';
import './css/vendor/fontawesome.min.css';
import './css/vendor/themify-icons.css';
import { Router, Switch, Route } from "react-router-dom"
import { history } from "./history"
import { connect } from "react-redux"
// import { ContextLayout } from "./utility/context/Layout"
import Home from './pages/Home';
import Home2 from './pages/Home2';
import Home3 from './pages/Home3';
import About_us from './pages/About_us';
import Terms_conditions from './pages/terms-conditions';
import Privacy_policy from './pages/privacy-policy';
import Disclaimer_clause from './pages/disclaimer_clause';
import Services from './pages/Services';
import Services_details from './pages/Services_details';
import Blog_classic from './pages/Blog_classic';
import Blog_grid from './pages/Blog_grid';
import Blog_details from './pages/Blog_details';
import Contact from './pages/Contact';
import Contact_02 from './pages/Contact_02';
import Job_list from './pages/Job_list';
import Job_role_filter from './pages/job_role_filter'
import Job_details from './pages/Job_details';
import Carrer_01 from './pages/Carrer_01';
import Error_404 from './pages/Error_404';
import Error_405 from './pages/Error_405';
import Candidate_list from './pages/Candidate_list';
import Candidate_details from './pages/Candidate_details';
import Login from './pages/Login';
import CreateProfile from "./pages/Profile_create";
import ScrollToTop from './components/layout/Gotop';
// import {
//   BrowserRouter
// } from "react-router-dom";
const RouteConfig = ({ component: Component, fullLayout, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return (
        <ScrollToTop>
          {context => {
            let LayoutTag =
              fullLayout === true
                ? context.fullLayout
                : context.state.activeLayout === "horizontal"
                  ? context.horizontalLayout
                  : context.VerticalLayout
            return (
              <LayoutTag {...props} permission={props.user}>
                <Suspense fallback={''}>
                  <Component {...props} />
                </Suspense>
              </LayoutTag>
            )
          }}
        </ScrollToTop>
      )
    }}
  />
)
const mapStateToProps = state => {
  return {
    user: state.auth.login.userRole
  }
}
const AppRoute = connect(mapStateToProps)(RouteConfig)
class AppRouter extends React.Component {
  render() {
    return (
      // Set the directory path if you are deploying in sub-folder
      // <BrowserRouter>
      <Router history={history}>
        <Switch>
          <Route exact path={'/'} component={Login} />
          <Route exact path={'/Home2'} component={Home2} />
          <Route exact path={'/Home3'} component={Home3} />
          <Route exact path={'/About_us'} component={About_us} />
          <Route exact path={'/terms-conditions'} component={Terms_conditions} />
          <Route exact path={'/privacy-policy'} component={Privacy_policy} />
          <Route exact path={'/disclaimer_clause'} component={Disclaimer_clause} />
          <Route exact path={'/Services'} component={Services} />
          <Route exact path={'/Services_details'} component={Services_details} />
          <Route exact path={'/Blog_classic'} component={Blog_classic} />
          <Route exact path={'/Blog_grid'} component={Blog_grid} />
          <Route exact path={'/Blog_details'} component={Blog_details} />
          <Route exact path={'/Contact_us'} component={Contact} />
          <Route exact path={'/Contact_02'} component={Contact_02} />
          <Route exact path={'/Job_list'} component={Job_list} />
          <Route exact path={'/Job_role_filter'} component={Job_role_filter} />
          <Route exact path={'/Job_details'} component={Job_details} />
          <Route exact path={'/Carrer_01'} component={Carrer_01} />
          <Route exact path={'/Error_404'} component={Error_404} />
          <Route exact path={'/Error_405'} component={Error_405} />
          <Route exact path={'/Candidate_list'} component={Candidate_list} />
          <Route exact path={'/Candidate_details'} component={Candidate_details} />
          <Route exact path={'/Login'} component={Login} />
          <Route exact path={'/CreateProfile'} component={CreateProfile} />
        </Switch>
      </Router>
      // </BrowserRouter>
    )
  }
}
export default AppRouter
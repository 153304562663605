import React, { Component } from 'react';
import Header from '../components/layout/Header';
import Checkbox from '../components/@vuexy/checkbox/CheckboxesVuexy';
import axios from "axios";
import stringsoflanguages from '../Stringsoflanguage';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {
    Card,
    CardBody,
    Media,
    Col,
    Row,
    Label,
    FormGroup,
    Button,
    TabContent,
    TabPane,
    Form,
    Input,
    Collapse,
    CardHeader,
    CardTitle,
    CustomInput,
    Badge,
    UncontrolledCollapse
} from "reactstrap"
import Select from "react-select"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import LoadingOverlay from 'react-loading-overlay'
import { APIURL, COMMON } from '../config'
import { history } from "../history"
import { getAllByPlaceholderText } from '@testing-library/react';
import { useLocalStorage } from 'react-use';
import { generateRefreshToken } from '../serviceworker';
let error_log = require('../error_log');
export class Job_role_filter extends Component {
    constructor() {
        super();
        this.state = {
            employermobileno: '',
            fromSalary: '',
            toSalary: '',
            showModal: false,
            modalMessage: '',
            showCallHRModal: false,
            name: "React",
            ipurl: APIURL,
            rowData: [],
            dpjobfunction: [],
            jobrole_full_list: [],
            currentItems: [],
            pageCount: 0,
            itemOffset: 0,
            itemsPerPage: 10,
            items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
            activePage: 15,
            skip: 0,
            limit: 5,
            jobfunctioncodeval: [],
            locationcodeval: [],
            jobrolecodeval: [],
            typecode: '',
            jobnav: '',
            languagecode: 0,
            search_val: '',
            common_array_duplicate: [],
            common_array: '',
            recent_array_duplicate: [],
            closeicon_hidden: true,
            show_hidden: true,
            search_array_hidden: true,
            profile_location: [],
            profile_taluk: [],
            profile_jobfunction: [],
            profile_jobrole: [],
            skillcodeval: [],
            isSearchclick: false,
            filterprofile: [],
            jobalertdays: '',
            location_val: '',
            jobrole_val: '',
            jobfunction_val: '',
            searchflag: '',
            jobfunctionresult: false,
            jobroleresult: false,
            enableSearch: false,
            locationresult: false,
            showjobfunction: false,
            showskills: false,
            skill_full_list: [],
            dpgender: [],
            profile_gender: [],
            jobfunctionarray: [],
            skillarray: [],
            dpgender: [],
            jobfunctioncode_api: [],
            joblocationcode_api: [],
            dpdistrict: [],
            dpbindstate: [],
            experienceval: 0,
            jobtype: 0,
            ipaddress: '',
            isActive: false,
            jobtypecodeval: [],
            employeecode: -1,
            districtarray: [],
            selectedItemIndex: 0,
            jobfunction_val: '',
            newSelectedJobRoleIndex: 0,
            items: [], // Populate with your items
            selectedJobRoleIndex: 0,
            setJobRoleIndex: 0,
            jobrole_val: '',
            jobRoleArray: [],
            selectedLocationIndex: 0,
            location_val: '',
            activeTab: 1,
            jobtypedata: [{ jobtypecode: 1, jobtypename: "Private Jobs" }, { jobtypecode: 2, jobtypename: "Freshers Jobs" }, { jobtypecode: 3, jobtypename: "Flash Jobs" }]
        };
        this.onChangeValue = this.onChangeValue.bind(this);
        this.onChangeJobfunction = this.onChangeJobfunction.bind(this);
        this.inputRef = React.createRef();
        this.inputRoleRef = React.createRef();
        this.inputLocationRef = React.createRef();
        this.setState({
            jobrole_val: this.state.jobrole_val = localStorage.getItem('getCurrentJobSearch') == 'null' ? '' : localStorage.getItem('getCurrentJobSearch'),
        })
        if (localStorage.getItem('getCurrentJobSearch') != null && localStorage.getItem('getCurrentJobSearch') != '' && localStorage.getItem('getCurrentJobSearch') != 'null') {
            this.setState({
                enableSearch: this.state.enableSearch = true,
            })
        }
        else {
            this.setState({
                enableSearch: this.state.enableSearch = false,
            })
        }
    }
    handleGlobalKeyDown = (e) => {
        try {
            if (document.activeElement === this.inputRef.current) {
                if (e.key === 'Tab') {
                    e.preventDefault();
                } else if (e.key === 'ArrowDown') {
                    e.preventDefault();
                    if (this.state.selectedItemIndex < this.state.common_array_duplicate.length - 1) {
                        const newSelectedItemIndex = this.state.selectedItemIndex + 1;
                        const commonArrayDuplicate = [...this.state.common_array_duplicate];
                        commonArrayDuplicate[this.state.selectedItemIndex].hover = false;
                        commonArrayDuplicate[newSelectedItemIndex].hover = true;
                        this.setState({
                            common_array_duplicate: commonArrayDuplicate,
                            selectedItemIndex: newSelectedItemIndex,
                        });
                    }
                } else if (e.key === 'ArrowUp') {
                    e.preventDefault();
                    // Handle Up Arrow key
                    if (this.state.selectedItemIndex > 0) {
                        const newSelectedItemIndex = this.state.selectedItemIndex - 1;
                        const commonArrayDuplicate = [...this.state.common_array_duplicate];
                        commonArrayDuplicate[this.state.selectedItemIndex].hover = false;
                        commonArrayDuplicate[newSelectedItemIndex].hover = true;
                        this.setState({
                            common_array_duplicate: commonArrayDuplicate,
                            selectedItemIndex: newSelectedItemIndex,
                        });
                    }
                } else if (e.key === 'Enter') {
                    if (this.state.selectedItemIndex >= 0) {
                        const selectedItem = this.state.common_array_duplicate[this.state.selectedItemIndex];
                        this.setState({
                            jobfunction_val: selectedItem.name,
                            selectedItemIndex: 0, // Reset selection
                        }, () => {
                            this.inputRef.current.value = selectedItem.name;
                        });
                        this.recentSearchClick(selectedItem)
                    }
                }
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "job_role_filter", "handleGlobalKeyDown", 0, error.toString());
        }
    };
    handleJobRoleKeyDown = (e) => {
        try {
            if (document.activeElement === this.inputRoleRef.current) {
                if (e.key === 'Tab') {
                    e.preventDefault();
                } else if (e.key === 'ArrowDown') {
                    e.preventDefault();
                    // Handle Down Arrow key
                    if (this.state.selectedJobRoleIndex < this.state.common_array_duplicate.length - 1) {
                        this.state.newSelectedJobRoleIndex = this.state.selectedJobRoleIndex;
                        if (this.state.setJobRoleIndex == 0) {
                            this.state.newSelectedJobRoleIndex = 0
                            this.setState({
                                setJobRoleIndex: 1
                            });
                        } else {
                            this.state.newSelectedJobRoleIndex = this.state.selectedJobRoleIndex + 1;
                        }
                        const commonArrayDuplicate = [...this.state.common_array_duplicate];
                        commonArrayDuplicate[this.state.selectedJobRoleIndex].hover = false;
                        commonArrayDuplicate[this.state.newSelectedJobRoleIndex].hover = true;
                        this.setState({
                            common_array_duplicate: commonArrayDuplicate,
                            selectedJobRoleIndex: this.state.newSelectedJobRoleIndex,
                        });
                    }
                } else if (e.key === 'ArrowUp') {
                    e.preventDefault();
                    // Handle Up Arrow key
                    if (this.state.selectedJobRoleIndex > 0) {
                        const newSelectedJobRoleIndex = this.state.selectedJobRoleIndex - 1;
                        const commonArrayDuplicate = [...this.state.common_array_duplicate];
                        commonArrayDuplicate[this.state.selectedJobRoleIndex].hover = false;
                        commonArrayDuplicate[newSelectedJobRoleIndex].hover = true;
                        this.setState({
                            common_array_duplicate: commonArrayDuplicate,
                            selectedJobRoleIndex: newSelectedJobRoleIndex,
                        });
                    }
                } else if (e.key === 'Enter') {
                    if (this.state.selectedJobRoleIndex >= 0) {
                        const selectedJobRole = this.state.common_array_duplicate[this.state.selectedJobRoleIndex];
                        this.setState({
                            jobrole_val: selectedJobRole.name,
                            selectedJobRoleIndex: 0, // Reset selection
                        }, () => {
                            this.inputRoleRef.current.value = selectedJobRole.name;
                            this.recentSearchClick(selectedJobRole);
                            this.get_job_list();
                        });
                    }
                }
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "job_role_filter", "handleJobRoleKeyDown", 0, error.toString());
        }
        // Check if the focused element is the input field
    };
    handleLocationKeyDown = (e) => {
        try {
            if (document.activeElement === this.inputLocationRef.current) {
                if (e.key === 'Tab') {
                    e.preventDefault();
                } else if (e.key === 'ArrowDown') {
                    e.preventDefault();
                    // Handle Down Arrow key
                    if (this.state.selectedLocationIndex < this.state.common_array_duplicate.length - 1) {
                        const newSelectedLocationIndex = this.state.selectedLocationIndex + 1;
                        const commonArrayDuplicate = [...this.state.common_array_duplicate];
                        commonArrayDuplicate[this.state.selectedLocationIndex].hover = false;
                        commonArrayDuplicate[newSelectedLocationIndex].hover = true;
                        this.setState({
                            common_array_duplicate: commonArrayDuplicate,
                            selectedLocationIndex: newSelectedLocationIndex,
                        });
                    }
                } else if (e.key === 'ArrowUp') {
                    e.preventDefault();
                    // Handle Up Arrow key
                    if (this.state.selectedLocationIndex > 0) {
                        const newSelectedLocationIndex = this.state.selectedLocationIndex - 1;
                        const commonArrayDuplicate = [...this.state.common_array_duplicate];
                        commonArrayDuplicate[this.state.selectedLocationIndex].hover = false;
                        commonArrayDuplicate[newSelectedLocationIndex].hover = true;
                        this.setState({
                            common_array_duplicate: commonArrayDuplicate,
                            selectedLocationIndex: newSelectedLocationIndex,
                        });
                    }
                } else if (e.key === 'Enter') {
                    if (this.state.selectedLocationIndex >= 0) {
                        const selectedLocation = this.state.common_array_duplicate[this.state.selectedLocationIndex];
                        this.setState({
                            location_val: selectedLocation.name,
                            selectedLocationIndex: 0, // Reset selection
                        }, () => {
                            this.inputLocationRef.current.value = selectedLocation.name;
                            this.recentSearchClick(selectedLocation)
                        });
                    }
                }
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "job_role_filter", "handleLocationKeyDown", 0, error.toString());
        }
        // Check if the focused element is the input field
    };
    searchJobRole(searchValue) {
        try {
            this.setState({ txtJobRole: this.state.txtJobRole = searchValue })
            if (searchValue != "") {
                var filteredJobRole = this.state.jobrolelist
                    .filter(e => e.jobrolename.toLowerCase().startsWith(searchValue.toLowerCase()));
                this.setState({ filteredJobfunctionrole: this.state.filteredJobfunctionrole = filteredJobRole })
            }
            else {
                this.setState({ filteredJobfunctionrole: this.state.filteredJobfunctionrole = this.state.jobfunctionrole })
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "job_role_filter", "searchJobRole", 0, error.toString());
        }
    }
    componentDidMount() {
        try {
            let empcode = localStorage.getItem('employeecode');
            if (empcode == null || empcode == undefined || empcode == 0 || empcode == -1) {
                empcode = -1;
            }
            this.setState({ employeecode: this.state.employeecode = empcode });
            localStorage.getItem('ipaddress')
            this.setState({ ipaddress: this.state.ipaddress = localStorage.getItem('ipaddress') });
            stringsoflanguages.setLanguage(localStorage.getItem('languagecode_short'));
            this.setState({ languagecode: this.state.languagecode = localStorage.getItem('languagecode') });
            let query = new URLSearchParams(history.location.search)
            let jobfunctioncode = (query.get('jobfunctioncodeval'))
            let jobrolecode = (query.get('jobrolecodeval'))
            let locationcodeval = (query.get('locationcodeval'))
            let jobfunctionname = (query.get('jobfunctionname'))
            let jobrolename = (query.get('jobrolename'))
            let locationname = (query.get('locationname'))
            let jobnav = (query.get('jobnav'))
            let searchflag = (query.get('searchflag'))
            let jobtype = (query.get('jobtype'))
            let jfunarr = []
            let jlocarr = []
            let jrolearr = []
            this.setState({
                searchflag: this.state.searchflag = searchflag
            })
            if (jobfunctioncode != '' && jobfunctioncode != null) {
                jfunarr.push(parseInt(jobfunctioncode))
                if (this.state.searchflag == 2) {
                    this.setState({ jobfunction_val: this.state.jobfunction_val = jobfunctionname })
                }
            }
            if (jobrolecode != '' && jobrolecode != null) {
                jrolearr.push(parseInt(jobrolecode))
                if (this.state.searchflag == 2) {
                    this.setState({ jobrole_val: this.state.jobrole_val = jobrolename })
                }
            }
            if (locationcodeval != '' && locationcodeval != null) {
                jlocarr.push(parseInt(locationcodeval))
                if (this.state.searchflag == 2) {
                    this.setState({ location_val: this.state.location_val = locationname })
                }
            }
            if (jobnav != '' && jobnav != null) {
                this.setState({ jobnav: this.state.jobnav = jobnav })
            }
            if (jobtype == 2) {
                this.setState({ experienceval: this.state.experienceval = [0] })
            }
            this.setState({
                jobfunctioncodeval: this.state.jobfunctioncodeval = jfunarr,
                jobfunctioncode_api: this.state.jobfunctioncode_api = [],
                jobfunctioncode_api: this.state.jobfunctioncode_api = Array.from(new Set(this.state.jobfunctioncodeval.concat(this.state.profile_jobfunction))),
                jobrolecodeval: this.state.jobrolecodeval = jrolearr,
                locationcodeval: this.state.locationcodeval = jlocarr,
                joblocationcode_api: this.state.joblocationcode_api = [],
                joblocationcode_api: this.state.joblocationcode_api = Array.from(new Set(this.state.locationcodeval.concat(this.state.profile_location))),
                jobtype: this.state.jobtype = jobtype
            })
            this.searchprofile_load()
            this.call_job_role()
        }
        catch (error) {
            error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Job_role_list", "componentDidMount", 0, error.toString());
        }
    }
    indian_standard_amount(amt) {
        try {
            var x = parseInt(amt);
            x = x.toString();
            var lastThree = x.substring(x.length - 3);
            var otherNumbers = x.substring(0, x.length - 3);
            if (otherNumbers != '')
                lastThree = ',' + lastThree;
            var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
            return res;
        }
        catch (error) {
            error_log.ERRORLOG(-1, COMMON.BUILD_NO, "Job_role_list", "indian_standard_amount", 0, error.toString());
        }
    }
    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
    }
    findclick() {
        try {
            this.setState({ searchflag: this.state.searchflag = '' })
            if (this.state.jobfunction_val == '') {
                this.setState({ jobfunctioncodeval: this.state.jobfunctioncodeval = [] })
            }
            if (this.state.jobrole_val == '') {
                this.setState({ jobrolecodeval: this.state.jobrolecodeval = [] })
            }
            if (this.state.location_val == '') {
                this.setState({ locationcodeval: this.state.locationcodeval = [] })
            }
            this.job_list()
        }
        catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "job_role_filter", "findclick", 0, error.toString());
        }
    }
    openCallHRModal = (employermobileno) => {
        this.setState({ employermobileno: this.state.employermobileno = employermobileno });
        this.setState({
            showCallHRModal: true,
        });
    };
    openModal = (message) => {
        this.setState({
            showModal: true,
            modalMessage: message,
        });
    }
    closeModal = () => {
        window.location.reload();
        this.setState({ showModal: this.state.showModal = false });
    }
    applynow(jobcode, employercode, employermobileno) {
        var accessToken = localStorage.getItem('accessToken');
        try {
            this.setState({ employermobileno: this.state.employermobileno = employermobileno });
            axios({
                method: 'Post',
                url: this.state.ipurl + 'jobs/jobapply/?',
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json'
                },
                params: {
                    employeecode: this.state.employeecode,
                    languagecode: 2,
                    ipaddress: this.state.ipaddress,
                    jobcode: jobcode,
                    employercode: employercode,
                },
            })
                .then(async response => {
                    if (response.data.status == 401) {
                        await generateRefreshToken().then((result) => {
                            if (result == 1) {
                                this.applynow();
                            }
                            else {
                                // alert('Unauthorized')
                            }
                        })
                    }
                    else {
                        if (response.data.hasOwnProperty('job_json_result')) {
                            this.state.varstatuscode = response.data.job_json_result.hasOwnProperty('varstatuscode') ? response.data.job_json_result.varstatuscode : 0;
                            let modalMessage = '';
                            if (this.state.varstatuscode === 27) {
                                const message = ` Job applied successfully!`;
                                this.openModal(message);
                            } else if (this.state.varstatuscode === 31) {
                                toast("not_eligible", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            } else if (this.state.varstatuscode === 77) {
                                toast("apply_limit", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            } else if (this.state.varstatuscode === 83) {
                                toast("alrdy_invited", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            } else if (this.state.varstatuscode === 62) {
                                toast("invite_then_reject", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            } else if (this.state.varstatuscode === 59) {
                                toast("apply_then_reject", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            } else if (this.state.varstatuscode === 7) {
                                toast("user_not_found", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            } else {
                                toast(this.state.responsestring, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar'
                                });
                            }
                            if (modalMessage) {
                                this.openModal(modalMessage);
                            }
                        }
                    }
                })
                .catch((error) => {
                    error_log.ERRORLOG(this.state.usercode, this.state.version, "job_role_filter", "applynow", 0, error.toString());
                });
        } catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "job_role_filter", "applynow", 0, error.toString());
        }
    }
    job_list() {
        var accessToken = localStorage.getItem('accessToken');
        window.fbq('track', 'Website', { fb_ad_function: "job_list", fb_ad_type: "Job list", fb_ad_loginuser: COMMON.employee_name });
        this.setState({ isActive: this.state.isActive = true })
        try {
            this.setState({ typecode: this.state.typecode = 6 })
            localStorage.removeItem("jobnavication")
            var varurl = '';
            if (this.state.employeecode > 0) {
                varurl = this.state.ipurl + 'jobs/recommended'
            }
            else {
                varurl = this.state.ipurl + 'jobs/jobslist'
            }
            axios({
                method: 'Post',
                url: varurl,
                crossdomain: true,
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json'
                },
                params: {
                    employeecode: this.state.employeecode,
                    ipaddress: this.state.ipaddress,
                    languagecode: this.state.languagecode,
                    type: this.state.typecode,
                    skip: this.state.skip,
                    limit: this.state.limit
                },
                data: {
                    skillcode: this.state.skillcodeval,
                    locationcode: this.state.joblocationcode_api, jobfunctioncode: this.state.jobfunctioncode_api, jobrolecode: this.state.jobrolecodeval, jobtypecode: [], schoolqualcode: [], afterschoolcatecode: [], anydegree: "true", anyqualification: "true", anyspec: "true", afterschoolqualcode: [], afterschoolspeccode: [], experiencecode: this.state.experienceval, industrycode: [], employertypecode: [], companytypecode: [], maritalcode: [], gendercode: this.state.profile_gender, differentlyabled: -1, salaryfrom: this.state.fromSalary, salaryto: this.state.toSalary, agefrom: 0, ageto: 0, anyage: "true"
                }
            })
                .then(async response => {
                    if (response.data.status == 401) {
                        await generateRefreshToken().then((result) => {
                            if (result == 1) {
                                this.job_list();
                            }
                            else {
                                // alert('Unauthorized')
                            }
                        })
                    }
                    else {
                        this.setState({ isActive: this.state.isActive = false })
                        if (this.state.typecode == 6) {
                            if (response.data.job_list_json_result.varstatuscode === 4) {
                                this.setState({ jobalertdays: this.state.jobalertdays = response.data.job_list_json_result.jobalertdays })
                                // let flash_job_list = []
                                let flash_job_list = response.data.job_list_json_result.hasOwnProperty('flash_job_list') ? response.data.job_list_json_result.flash_job_list : []
                                let recommended_list = response.data.job_list_json_result.hasOwnProperty('job_list') ? response.data.job_list_json_result.job_list : []
                                this.setState({ flash_job_list: this.state.flash_job_list = flash_job_list });
                                for (let i = 0; i < recommended_list.length; i++) {
                                    recommended_list[i].flash = 0;
                                    if (recommended_list[i].hasOwnProperty('locationname'))
                                        recommended_list[i].locationname.sort();
                                }
                                for (let i = 0; i < flash_job_list.length; i++) {
                                    flash_job_list[i].flash = 1;
                                    if (flash_job_list[i].hasOwnProperty('joblocationname'))
                                        flash_job_list[i].joblocationname.sort();
                                }
                                let arr = [];
                                let flashlist = flash_job_list;
                                if ((recommended_list && recommended_list.length > 0) && (flash_job_list && flash_job_list.length > 0)) {
                                    for (let i = 0; i < recommended_list.length; i++) {
                                        if (i != 0 && i % 4 == 0) {
                                            if (flashlist && flashlist.length > 0) {
                                                arr.push(flashlist[0]);
                                                flashlist.shift();
                                            }
                                            arr.push(recommended_list[i]);
                                        } else {
                                            arr.push(recommended_list[i]);
                                        }
                                    }
                                    if (flashlist && flashlist.length > 0) {
                                        flashlist.forEach(element => {
                                            arr.push(element);
                                        });
                                    }
                                } else {
                                    if ((recommended_list && recommended_list.length > 0)) {
                                        arr = recommended_list;
                                    }
                                    if ((flash_job_list && flash_job_list.length > 0)) {
                                        arr = flash_job_list;
                                    }
                                }
                                this.setState({ recommended_list: this.state.recommended_list = arr });
                                let rowData = []
                                if (this.state.jobtype == 1) {
                                    rowData = response.data.job_list_json_result.job_list
                                }
                                else if (this.state.jobtype == 3) {
                                    rowData = response.data.job_list_json_result.flash_job_list
                                }
                                else {
                                    rowData = arr;
                                }
                                JSON.stringify(rowData);
                                this.setState({ rowData: this.state.rowData = rowData });
                                this.page(this.state.itemOffset, this.state.itemsPerPage)
                            }
                            else if (response.data.job_list_json_result.varstatuscode === 6) {
                                this.setState({
                                    rowData: this.state.rowData = [],
                                    currentItems: this.currentItems = []
                                });
                                this.page(this.state.itemOffset, this.state.itemsPerPage)
                            }
                        }
                        else {
                            if (response.data.job_list_json_result.varstatuscode === 4) {
                                let flash_job_list = response.data.job_list_json_result.hasOwnProperty('flash_job_list') ? response.data.job_list_json_result.flash_job_list : []
                                let recommended_list = response.data.job_list_json_result.hasOwnProperty('job_list_count') ? response.data.job_list_json_result.job_list_count : []
                                this.setState({ flash_job_list: this.state.flash_job_list = flash_job_list });
                                for (let i = 0; i < recommended_list.length; i++) {
                                    recommended_list[i].flash = 0;
                                    if (recommended_list[i].hasOwnProperty('locationname'))
                                        recommended_list[i].locationname.sort();
                                }
                                for (let i = 0; i < flash_job_list.length; i++) {
                                    flash_job_list[i].flash = 1;
                                    if (flash_job_list[i].hasOwnProperty('joblocationname'))
                                        flash_job_list[i].joblocationname.sort();
                                }
                                let arr = [];
                                let flashlist = flash_job_list;
                                if ((recommended_list && recommended_list.length > 0) && (flash_job_list && flash_job_list.length > 0)) {
                                    for (let i = 0; i < recommended_list.length; i++) {
                                        if (i != 0 && i % 4 == 0) {
                                            if (flashlist && flashlist.length > 0) {
                                                arr.push(flashlist[0]);
                                                flashlist.shift();
                                            }
                                            arr.push(recommended_list[i]);
                                        } else {
                                            arr.push(recommended_list[i]);
                                        }
                                    }
                                    if (flashlist && flashlist.length > 0) {
                                        flashlist.forEach(element => {
                                            arr.push(element);
                                        });
                                    }
                                } else {
                                    if ((recommended_list && recommended_list.length > 0)) {
                                        arr = recommended_list;
                                    }
                                    if ((flash_job_list && flash_job_list.length > 0)) {
                                        arr = flash_job_list;
                                    }
                                }
                                this.setState({ recommended_list: this.state.recommended_list = arr });
                                let rowData = arr
                                JSON.stringify(rowData);
                                this.setState({ rowData: this.state.rowData = rowData });
                                this.page(this.state.itemOffset, this.state.itemsPerPage)
                            }
                            else if (response.data.job_list_json_result.varstatuscode === 6) {
                                this.setState({
                                    rowData: this.state.rowData = [],
                                    currentItems: this.currentItems = []
                                });
                                this.page(this.state.itemOffset, this.state.itemsPerPage)
                            }
                        }
                    }
                })
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "onclicksearch_btn", 0, error.toString());
        }
    }
    get_job_list() {
        if (this.state.jobrolecodeval.length === 0) {
            return
        }
        localStorage.setItem('getcurrentRole', this.state.jobrolecodeval)
        const currentRoute = window.location.pathname
        if (currentRoute == '/job_list') {
            window.location.reload();
        }
        else {
            history.push('/job_list');
        }
    }
    applied_job_list() {
        var accessToken = localStorage.getItem('accessToken');
        window.fbq('track', 'Website', { fb_ad_function: "job_list", fb_ad_type: "Job list", fb_ad_loginuser: COMMON.employee_name });
        this.setState({ isActive: this.state.isActive = true })
        try {
            this.setState({ typecode: this.state.typecode = 6 })
            localStorage.removeItem("jobnavication")
            axios({
                method: 'Post',
                url: this.state.ipurl + '/jobs/applied',
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json'
                },
                params: {
                    deviceip: this.state.ipaddress,
                    employeecode: this.state.employeecode,
                    sortbycode: 0,
                    languagecode: this.state.languagecode,
                },
                data: this.state.bind_filter_array
            }).then(async response => {
                if (response.data.status == 401) {
                    await generateRefreshToken().then((result) => {
                        if (result == 1) {
                            this.applied_job_list();
                        }
                        else {
                            // alert('Unauthorized')
                        }
                    })
                }
                else {
                    this.setState({ isActive: this.state.isActive = false })
                    if (this.state.typecode == 6) {
                        if (response.data.job_list_json_result.varstatuscode === 4) {
                            this.setState({ jobalertdays: this.state.jobalertdays = response.data.job_list_json_result.jobalertdays })
                            let flash_job_list = response.data.job_list_json_result.hasOwnProperty('flash_job_list') ? response.data.job_list_json_result.flash_job_list : []
                            let recommended_list = response.data.job_list_json_result.hasOwnProperty('job_list') ? response.data.job_list_json_result.job_list : []
                            this.setState({ flash_job_list: this.state.flash_job_list = flash_job_list });
                            for (let i = 0; i < recommended_list.length; i++) {
                                recommended_list[i].flash = 0;
                                if (recommended_list[i].hasOwnProperty('locationname'))
                                    recommended_list[i].locationname.sort();
                            }
                            for (let i = 0; i < flash_job_list.length; i++) {
                                flash_job_list[i].flash = 1;
                                if (flash_job_list[i].hasOwnProperty('joblocationname'))
                                    flash_job_list[i].joblocationname.sort();
                            }
                            let arr = [];
                            let flashlist = flash_job_list;
                            if ((recommended_list && recommended_list.length > 0) && (flash_job_list && flash_job_list.length > 0)) {
                                for (let i = 0; i < recommended_list.length; i++) {
                                    if (i != 0 && i % 4 == 0) {
                                        if (flashlist && flashlist.length > 0) {
                                            arr.push(flashlist[0]);
                                            flashlist.shift();
                                        }
                                        arr.push(recommended_list[i]);
                                    } else {
                                        arr.push(recommended_list[i]);
                                    }
                                }
                                if (flashlist && flashlist.length > 0) {
                                    flashlist.forEach(element => {
                                        arr.push(element);
                                    });
                                }
                            } else {
                                if ((recommended_list && recommended_list.length > 0)) {
                                    arr = recommended_list;
                                }
                                if ((flash_job_list && flash_job_list.length > 0)) {
                                    arr = flash_job_list;
                                }
                            }
                            this.setState({ recommended_list: this.state.recommended_list = arr });
                            let rowData = []
                            if (this.state.jobtype == 1) {
                                rowData = response.data.job_list_json_result.job_list
                            }
                            else if (this.state.jobtype == 3) {
                                rowData = response.data.job_list_json_result.flash_job_list
                            }
                            else {
                                rowData = arr;
                            }
                            JSON.stringify(rowData);
                            this.setState({ rowData: this.state.rowData = rowData });
                            this.page(this.state.itemOffset, this.state.itemsPerPage)
                        }
                        else if (response.data.job_list_json_result.varstatuscode === 6) {
                            this.setState({
                                rowData: this.state.rowData = [],
                                currentItems: this.currentItems = []
                            });
                            this.page(this.state.itemOffset, this.state.itemsPerPage)
                        }
                    }
                    else {
                        if (response.data.job_list_json_result.varstatuscode === 4) {
                            let flash_job_list = response.data.job_list_json_result.hasOwnProperty('flash_job_list') ? response.data.job_list_json_result.flash_job_list : []
                            let recommended_list = response.data.job_list_json_result.hasOwnProperty('job_list_count') ? response.data.job_list_json_result.job_list_count : []
                            this.setState({ flash_job_list: this.state.flash_job_list = flash_job_list });
                            for (let i = 0; i < recommended_list.length; i++) {
                                recommended_list[i].flash = 0;
                                if (recommended_list[i].hasOwnProperty('locationname'))
                                    recommended_list[i].locationname.sort();
                            }
                            for (let i = 0; i < flash_job_list.length; i++) {
                                flash_job_list[i].flash = 1;
                                if (flash_job_list[i].hasOwnProperty('joblocationname'))
                                    flash_job_list[i].joblocationname.sort();
                            }
                            let arr = [];
                            let flashlist = flash_job_list;
                            if ((recommended_list && recommended_list.length > 0) && (flash_job_list && flash_job_list.length > 0)) {
                                for (let i = 0; i < recommended_list.length; i++) {
                                    if (i != 0 && i % 4 == 0) {
                                        if (flashlist && flashlist.length > 0) {
                                            arr.push(flashlist[0]);
                                            flashlist.shift();
                                        }
                                        arr.push(recommended_list[i]);
                                    } else {
                                        arr.push(recommended_list[i]);
                                    }
                                }
                                if (flashlist && flashlist.length > 0) {
                                    flashlist.forEach(element => {
                                        arr.push(element);
                                    });
                                }
                            } else {
                                if ((recommended_list && recommended_list.length > 0)) {
                                    arr = recommended_list;
                                }
                                if ((flash_job_list && flash_job_list.length > 0)) {
                                    arr = flash_job_list;
                                }
                            }
                            this.setState({ recommended_list: this.state.recommended_list = arr });
                            let rowData = arr
                            JSON.stringify(rowData);
                            this.setState({ rowData: this.state.rowData = rowData });
                            this.page(this.state.itemOffset, this.state.itemsPerPage)
                        }
                        else if (response.data.job_list_json_result.varstatuscode === 6) {
                            this.setState({
                                rowData: this.state.rowData = [],
                                currentItems: this.currentItems = []
                            });
                            this.page(this.state.itemOffset, this.state.itemsPerPage)
                        }
                    }
                }
            })
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "onclicksearch_btn", 0, error.toString());
        }
    }
    //Bind job function 
    call_job_function() {
        try {
            var accessToken = localStorage.getItem('accessToken');
            window.fbq('track', 'Website', { fb_ad_function: "call_job_function", fb_ad_type: "Job list", fb_ad_loginuser: COMMON.employee_name });
            if (this.state.dpjobfunction.length == 0) {
                this.setState({ isSmallLoaderJobFunctionActive: this.state.isSmallLoaderJobFunctionActive = true })
                axios({
                    method: 'Post',
                    url: this.state.ipurl + 'employee/filterbind/',
                    crossdomain: true,
                    headers: {
                        'Authorization': 'Bearer ' + accessToken,
                        'Content-Type': 'application/json'
                    },
                    params: {
                        deviceip: '123.45.45',
                        type: 3,
                        languagecode: this.state.languagecode,
                        employeecode: this.state.employeecode
                    }
                })
                    .then(async response => {
                        if (response.data.status == 401) {
                            await generateRefreshToken().then((result) => {
                                if (result == 1) {
                                    this.call_job_function();
                                }
                                else {
                                    // alert('Unauthorized')
                                }
                            })
                        }
                        else {
                            this.setState({ isSmallLoaderJobFunctionActive: this.state.isSmallLoaderJobFunctionActive = false })
                            if (response.data.jobfunction_filter_json_result.varstatuscode === 4) {
                                //jobfunction
                                var mjfun_rowdata = response.data.jobfunction_filter_json_result.jobfunctionlist.filter(e => e.languagecode == this.state.languagecode);
                                mjfun_rowdata.sort(this.GetSortOrder('jobfunctionname'))
                                mjfun_rowdata.sort();
                                this.state.dpjobfunction = response.data.jobfunction_filter_json_result.jobfunctionlist.filter(e => e.languagecode == this.state.languagecode);
                                JSON.stringify(this.state.dpjobfunction);
                                this.setState({ dpjobfunction: this.state.dpjobfunction });
                                this.bind_jobfunction(mjfun_rowdata, 1)
                            }
                            else if (response.data.jobfunction_filter_json_result.varstatuscode === 7) {
                                localStorage.clear()
                            }
                            else {
                                this.setState({ isSmallLoaderJobFunctionActive: this.state.isSmallLoaderJobFunctionActive = false })
                            }
                        }
                    })
                    .catch(error => {
                        error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "bind_filter_array", 0, error.toString());
                    });
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "job_role_filter", "call_job_function", 0, error.toString());
        }
    }
    //Bind job skill 
    call_job_skill() {
        try {
            var accessToken = localStorage.getItem('accessToken');
            window.fbq('track', 'Website', { fb_ad_function: "call_job_skill", fb_ad_type: "Job list", fb_ad_loginuser: COMMON.employee_name });
            if (this.state.skill_full_list.length == 0) {
                axios({
                    method: 'Post',
                    url: this.state.ipurl + 'employee/filterbind/',
                    crossdomain: true,
                    headers: {
                        'Authorization': 'Bearer ' + accessToken,
                        'Content-Type': 'application/json'
                    },
                    params: {
                        deviceip: '123.45.45',
                        type: 1,
                        languagecode: this.state.languagecode,
                        employeecode: this.state.employeecode,
                    }
                })
                    .then(async response => {
                        if (response.data.status == 401) {
                            await generateRefreshToken().then((result) => {
                                if (result == 1) {
                                    this.call_job_skill();
                                }
                                else {
                                    // alert('Unauthorized')
                                }
                            })
                        }
                        else {
                            if (response.data.skill_filter_json_result.varstatuscode === 4) {
                                //job skill
                                var mjskill_rowdata, flag_arr = []
                                var skilllist = response.data.skill_filter_json_result.skill_list.filter(e => e.languagecode == this.state.languagecode);
                                this.setState({ skill_full_list: this.state.skill_full_list = skilllist })
                                //remove duplicate
                                skilllist.map(e => {
                                    if (flag_arr.length > 0) {
                                        let filter = flag_arr.some(q => q.skillcode === e.skillcode);
                                        if (!filter)
                                            flag_arr.push(e)
                                    } else {
                                        flag_arr.push(e)
                                    }
                                })
                                mjskill_rowdata = flag_arr
                                this.bind_jobskill(mjskill_rowdata, 3)
                                mjskill_rowdata.sort(this.GetSortOrder('skillname'))
                                if (mjskill_rowdata) {
                                    mjskill_rowdata.forEach(row => {
                                        if (this.state.skillcodeval.includes(row.skillcode)) {
                                            row["status"] = true;
                                        }
                                        else {
                                            row["status"] = false;
                                        }
                                    });
                                }
                            }
                            else if (response.data.skill_filter_json_result.varstatuscode === 7) {
                                localStorage.clear()
                            }
                            else {
                                this.setState({ isSmallLoaderJobSkillActive: this.state.isSmallLoaderJobSkillActive = false })
                            }
                        }
                    })
                    .catch(error => {
                        this.setState({ isSmallLoaderJobSkillActive: this.state.isSmallLoaderJobSkillActive = false })
                        error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "bind_filter_array", 0, error.toString());
                    });
            } else {
                if (this.state.profile_jobrole.length > 0) {
                    this.state.skill_full_list = this.state.skill_full_list.filter(e => this.state.profile_jobrole.includes(e.jobrolecode));
                }
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "job_role_filter", "call_job_skill", 0, error.toString());
        }
    }
    //jobfunction
    bind_jobfunction(mjfun_rowdata, idfun) {
        try {
            this.state.jobfunctionarray = mjfun_rowdata;
            if (this.state.jobfunctionarray && this.state.jobfunctionarray.length > 0) {
                return this.state.jobfunctionarray.map((jfun, index) => {
                    const { jobfunctioncode, status, jobfunctionname } = jfun
                    return (
                        <div className="cookies-checkbox mt-15">
                            <div className="d-flex flex-row justify-content-start">
                                <Checkbox className="d-flex flex-row justify-content-start"
                                    id="jobfunction"
                                    icon=""
                                    label={jobfunctionname}
                                    checked={jfun.checked}
                                    onChange={(e) => {
                                        this.jfunctionclick(e, jobfunctioncode)
                                    }}
                                />
                            </div>
                        </div>
                    )
                })
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "bind_jobfunction", 0, error.toString());
        }
    }
    jfunctionclick = (event, jobfunctioncode) => {
        try {
            let check = event.target.checked;
            let value = event.target.value;
            this.state.jobfunctionarray.forEach(mjfunctions => {
                if (mjfunctions.jobfunctioncode === Number(jobfunctioncode)) {
                    mjfunctions.status = !mjfunctions.status;
                    if (mjfunctions.status === true) {
                        this.state.profile_jobfunction.push(mjfunctions.jobfunctioncode)
                        this.setState({ profile_jobfunction: this.state.profile_jobfunction });
                    } else {
                        let i = this.state.profile_jobfunction.indexOf(mjfunctions.jobfunctioncode);
                        if (i > -1) {
                            this.state.profile_jobfunction.splice(i, 1)
                        }
                        this.setState({ profile_jobfunction: this.state.profile_jobfunction });
                    }
                }
            });
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employeecode, this.state.version, "Search_Profiles", "jfunctionone", 0, error.toString());
        }
    }
    //job skills
    bind_jobskill(mjskill_rowdata, idskill) {
        try {
            this.state.skillarray = mjskill_rowdata;
            if (this.state.skillarray && this.state.skillarray.length > 0) {
                return this.state.skillarray.map((idskill, index) => {
                    const { skillcode, jobrolecode, status, jobfunctioncode, skillname } = idskill
                    return (
                        <div className="cookies-checkbox mt-15">
                            <div className="d-flex flex-row justify-content-start">
                                <Checkbox className="d-flex flex-row justify-content-start"
                                    id="jobskill"
                                    icon=""
                                    label={skillname}
                                    checked={idskill.checked}
                                    onChange={(e) => {
                                        this.jskillone(e, skillcode)
                                    }}
                                />
                            </div>
                        </div>
                    )
                })
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "bind_jobskill", 0, error.toString());
        }
    }
    //onchange skills
    jskillone = (event, skillcode) => {
        try {
            let check = event.target.checked;
            let value = event.target.value;
            this.state.skillarray.forEach(mskills => {
                if (mskills.skillcode === Number(skillcode)) {
                    mskills.status = !mskills.status;
                    if (mskills.status === true) {
                        this.state.skillcodeval.push(mskills.skillcode)
                        this.setState({ skillcodeval: this.state.skillcodeval });
                    } else {
                        let i = this.state.skillcodeval.indexOf(mskills.skillcode);
                        if (i > -1) {
                            this.state.skillcodeval.splice(i, 1)
                        }
                        this.setState({ skillcodeval: this.state.skillcodeval });
                    }
                }
            });
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "jskillone", 0, error.toString());
        }
    }
    //Bind Gender
    call_gender() {
        try {
            var accessToken = localStorage.getItem('accessToken');
            window.fbq('track', 'Website', { fb_ad_function: "call_gender", fb_ad_type: "Job list", fb_ad_loginuser: COMMON.employee_name });
            if (this.state.dpgender.length == 0) {
                this.setState({ isSmallLoaderGenderActive: this.state.isSmallLoaderGenderActive = true });
                axios({
                    method: 'Post',
                    url: this.state.ipurl + 'employee/filterbind/',
                    crossdomain: true,
                    headers: {
                        'Authorization': 'Bearer ' + accessToken,
                        'Content-Type': 'application/json'
                    },
                    params: {
                        deviceip: '123.45.45',
                        type: 10,
                        languagecode: this.state.languagecode,
                        employeecode: this.state.employeecode,
                    }
                })
                    .then(async response => {
                        if (response.data.status == 401) {
                            await generateRefreshToken().then((result) => {
                                if (result == 1) {
                                    this.call_gender();
                                }
                                else {
                                    // alert('Unauthorized')
                                }
                            })
                        }
                        else {
                            if (response.data.jobgroup_filter_json_result.varstatuscode === 4) {
                                //gender status
                                var mjgender_rowdata = response.data.jobgroup_filter_json_result.genderlist.filter(e => e.languagecode == this.state.languagecode)
                                this.setState({ dpgender: this.state.dpgender = mjgender_rowdata });
                                this.setState({ jobgenderarr: this.state.jobgenderarr = mjgender_rowdata })
                                this.bind_genderstatus(mjgender_rowdata, 8)
                            }
                            else if (response.data.jobgroup_filter_json_result.varstatuscode === 7) {
                                localStorage.clear()
                            } else {
                                this.setState({ isSmallLoaderGenderActive: this.state.isSmallLoaderGenderActive = false })
                            }
                        }
                    })
                    .catch(error => {
                        this.setState({ isSmallLoaderGenderActive: this.state.isSmallLoaderGenderActive = false })
                        error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "bind_filter_array", 0, error.toString());
                    });
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "job_role_filter", "call_gender", 0, error.toString());
        }
    }
    //gender status
    //gender
    bind_genderstatus(mjgender_rowdata, idgender) {
        try {
            this.state.jobgenderarr = mjgender_rowdata;
            if (this.state.jobgenderarr && this.state.jobgenderarr.length > 0) {
                return this.state.jobgenderarr.map((idgender, index) => {
                    const { gendercode, gendername, status } = idgender
                    return (
                        <div className="cookies-checkbox mt-15">
                            <div className="d-flex flex-row justify-content-start">
                                <Checkbox className="d-flex flex-row justify-content-start"
                                    id="jobfunction"
                                    icon=""
                                    label={gendername}
                                    checked={idgender.checked}
                                    onChange={(e) => {
                                        this.jobgenderone(e, gendercode)
                                    }}
                                />
                            </div>
                        </div>
                    )
                })
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Job_list", "bind_genderstatus", 0, error.toString());
        }
    }
    //onchanges gender
    jobgenderone = (event, gendercode) => {
        try {
            let check = event.target.checked;
            let value = event.target.value;
            this.state.jobgenderarr.forEach(mjgender => {
                if (mjgender.gendercode === Number(gendercode)) {
                    mjgender.status = !mjgender.status;
                    if (mjgender.status === true) {
                        this.state.profile_gender.push(mjgender.gendercode);
                        this.setState({ profile_gender: this.state.profile_gender });
                    }
                    else {
                        let i = this.state.profile_gender.indexOf(mjgender.gendercode);
                        if (i > -1) {
                            this.state.profile_gender.splice(i, 1)
                        }
                        this.setState({ profile_gender: this.state.profile_gender });
                    }
                }
            });
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "job_list", "jobgenderone", 0, error.toString());
        }
    }
    //Bind job location 
    call_job_location() {
        try {
            var accessToken = localStorage.getItem('accessToken');
            window.fbq('track', 'Website', { fb_ad_function: "call_job_location", fb_ad_type: "Job list", fb_ad_loginuser: COMMON.employee_name });
            if (this.state.dpdistrict.length == 0 || this.state.dpbindstate.length == 0) {
                axios({
                    method: 'Post',
                    url: this.state.ipurl + 'employee/filterbind/',
                    crossdomain: true,
                    headers: {
                        'Authorization': 'Bearer ' + accessToken,
                        'Content-Type': 'application/json'
                    },
                    params: {
                        deviceip: '123.45.45',
                        type: 2,
                        languagecode: this.state.languagecode,
                        employeecode: this.state.employeecode
                    }
                })
                    .then(async response => {
                        if (response.data.status == 401) {
                            await generateRefreshToken().then((result) => {
                                if (result == 1) {
                                    this.call_job_location();
                                }
                                else {
                                    // alert('Unauthorized')
                                }
                            })
                        }
                        else {
                            this.setState({ isSmallLoaderActive: this.state.isSmallLoaderActive = false })
                            if (response.data.location_filter_json_result.varstatuscode === 4) {
                                //Bind state name in select dropdown
                                var sortfilter_ary = response.data.location_filter_json_result.state_list.filter(e => e.languagecode == this.state.languagecode)
                                sortfilter_ary.sort(this.GetSortOrder("statename"))
                                this.state.dpbindstate = sortfilter_ary.map(sortfilter_ary => {
                                    return { value: sortfilter_ary.statecode, label: sortfilter_ary.statename }
                                });
                                JSON.stringify(this.state.dpbindstate);
                                this.setState({ dpbindstate: this.state.dpbindstate });
                                //  Bind job location
                                var mlocat_rowdata = response.data.location_filter_json_result.district_list.filter(e => e.languagecode == this.state.languagecode);
                                mlocat_rowdata.sort(this.GetSortOrder('districtname'))
                                this.state.dpdistrict = response.data.location_filter_json_result.district_list.filter(e => e.languagecode == this.state.languagecode);
                                JSON.stringify(this.state.dpdistrict);
                                this.setState({ dpdistrict: this.state.dpdistrict });
                                this.setState({ districtarray: this.state.districtarray = mlocat_rowdata });
                                this.bind_location(mlocat_rowdata, 0)
                            }
                            else if (response.data.location_filter_json_result.varstatuscode === 7) {
                                localStorage.clear()
                            }
                            else {
                                this.setState({ isSmallLoaderActive: this.state.isSmallLoaderActive = false })
                            }
                        }
                    })
                    .catch(error => {
                        this.setState({ isSmallLoaderActive: this.state.isSmallLoaderActive = false })
                        error_log.ERRORLOG(this.state.employercode, this.state.version, "job_list", "bind_filter_array", 0, error.toString());
                    });
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "job_role_filter", "call_job_location", 0, error.toString());
        }
    }
    //job location
    bind_location(mlocat_rowdata, jloc) {
        try {
            if (mlocat_rowdata && mlocat_rowdata.length > 0) {
                this.state.districtarray = mlocat_rowdata;
                if (this.state.districtarray && this.state.districtarray.length > 0) {
                    return this.state.districtarray.map((jloc, index) => {
                        const { districtcode, statecode, status, districtname } = jloc;
                        return (
                            <div className="cookies-checkbox mt-15">
                                <div className="d-flex flex-row justify-content-start">
                                    <Checkbox className="d-flex flex-row justify-content-start"
                                        id="jobfunction"
                                        icon=""
                                        label={districtname}
                                        checked={jloc.checked}
                                        onChange={(e) => {
                                            this.locationone(e, districtcode)
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    })
                }
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "bind_location", 0, error.toString());
        }
    }
    //onchange location
    locationone = (event, districtcode) => {
        try {
            let check = event.target.checked;
            let value = event.target.value;
            this.state.districtarray.forEach(mlocation => {
                if (mlocation.districtcode === Number(districtcode)) {
                    mlocation.status = !mlocation.status;
                    if (mlocation.status === true) {
                        this.state.profile_location.push(mlocation.districtcode);
                        this.setState({ profile_location: this.state.profile_location });
                    }
                    else {
                        let i = this.state.profile_location.indexOf(mlocation.districtcode);
                        if (i > -1) {
                            this.state.profile_location.splice(i, 1)
                        }
                        this.setState({ profile_location: this.state.profile_location });
                    }
                }
            });
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "locationone", 0, error.toString());
        }
    }
    // jobtype
    bind_jobtype() {
        try {
            if (this.state.jobtypedata && this.state.jobtypedata.length > 0) {
                return this.state.jobtypedata.map((jtype, index) => {
                    const { jobtypename, jobtypecode } = jtype;
                    return (
                        <div className="cookies-checkbox mt-15">
                            <div className="d-flex flex-row justify-content-start">
                                <Checkbox className="d-flex flex-row justify-content-start"
                                    id="jobtype"
                                    icon=""
                                    label={jobtypename}
                                    checked={jtype.checked}
                                    onChange={(e) => {
                                        this.jobtypeone(e, jobtypecode)
                                    }}
                                />
                            </div>
                        </div>
                    )
                })
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "bind_location", 0, error.toString());
        }
    }
    //onchange jobtype
    jobtypeone = (event, jobtypecode) => {
        try {
            var gendiv = document.getElementById("genid");
            let check = event.target.checked;
            let value = event.target.value;
            this.state.jobtypedata.forEach(jobtype => {
                if (jobtype.jobtypecode === Number(jobtypecode)) {
                    jobtype.checked = check
                } else {
                    jobtype.checked = false
                }
            });
            this.setState({
                jobtypedata: this.state.jobtypedata = this.state.jobtypedata,
            })
            if (jobtypecode == 1) {
                gendiv.style.display = "block";
                this.setState({
                    jobtype: this.state.jobtype = jobtypecode
                })
            }
            else if (jobtypecode == 3) {
                gendiv.style.display = "none";
                this.setState({
                    profile_gender: this.state.profile_gender = [],
                    jobtype: this.state.jobtype = jobtypecode
                })
            }
            else {
                gendiv.style.display = "block";
                this.setState({
                    jobtype: this.state.jobtype = jobtypecode,
                    experienceval: this.state.experienceval = [0]
                })
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employeecode, this.state.version, "job-list", "jobtypeone", 0, error.toString());
        }
    }
    applyfilter() {
        this.setState({
            jobfunctioncode_api: this.state.jobfunctioncode_api = [],
            jobfunctioncode_api: this.state.jobfunctioncode_api = Array.from(new Set(this.state.jobfunctioncodeval.concat(this.state.profile_jobfunction))),
            joblocationcode_api: this.state.joblocationcode_api = [],
            joblocationcode_api: this.state.joblocationcode_api = Array.from(new Set(this.state.locationcodeval.concat(this.state.profile_location)))
        })
        this.job_list()
    }
    loadMore() {
        this.setState({
            limit: this.state.limit = this.state.limit + 5
        })
        this.job_list()
    }
    //Bind job role 
    call_job_role() {
        try {
            var accessToken = localStorage.getItem('accessToken');
            window.fbq('track', 'Website', { fb_ad_function: "call_job_role", fb_ad_type: "Job list", fb_ad_loginuser: COMMON.employee_name });
            if (this.state.jobrole_full_list.length == 0) {
                this.setState({ isSmallLoaderJobRoleActive: this.state.isSmallLoaderJobRoleActive = true })
                axios({
                    method: 'Post',
                    url: this.state.ipurl + 'employee/filterbind/',
                    crossdomain: true,
                    headers: {
                        'Authorization': 'Bearer ' + accessToken,
                        'Content-Type': 'application/json'
                    },
                    params: {
                        deviceip: '123.45.45',
                        type: 11,
                        languagecode: this.state.languagecode,
                        employeecode: this.state.employeecode,
                    }
                })
                    .then(async response => {
                        if (response.data.status == 401) {
                            await generateRefreshToken().then((result) => {
                                if (result == 1) {
                                    this.call_job_role();
                                }
                                else {
                                    // alert('Unauthorized')
                                }
                            })
                        }
                        else {
                            this.setState({ isSmallLoaderJobRoleActive: this.state.isSmallLoaderJobRoleActive = false })
                            if (response.data.jobrole_filter_json_result.varstatuscode === 4) {
                                //jobrole
                                var mjrole_rowdata = response.data.jobrole_filter_json_result.jobrolelist.filter(e => e.languagecode == Number(localStorage.getItem('languagecode')));
                                mjrole_rowdata.sort(this.GetSortOrder('jobrolename'))
                                mjrole_rowdata.sort();
                                if (mjrole_rowdata) {
                                    mjrole_rowdata.forEach(row => {
                                        if (this.state.profile_jobrole.includes(row.jobrolecode)) {
                                            row["status"] = true;
                                        }
                                        else {
                                            row["status"] = false;
                                        }
                                    });
                                }
                                if (this.state.profile_jobfunction.length > 0) {
                                    mjrole_rowdata = mjrole_rowdata.filter(e => this.state.profile_jobfunction.includes(e.jobfunctioncode));
                                }
                                this.setState({ jobrole_full_list: this.state.jobrole_full_list = mjrole_rowdata })
                                //Bind job role in checkbox
                                var bind_collapseItems = [
                                    {
                                        id: 2,
                                        title: "Job Role",
                                        icon: <i className='fa fa-chevron-down collapse-icon'></i>,
                                        content: this.bind_jobrole(mjrole_rowdata, 2)
                                    },
                                ];
                                this.setState({ collapseItems: this.state.collapseItems = bind_collapseItems })
                                this.setState({ collapseItems: this.state.collapseItems })
                            }
                            else if (response.data.jobrole_filter_json_result.varstatuscode === 7) {
                                localStorage.clear()
                            }
                            else {
                                this.setState({ isSmallLoaderJobRoleActive: this.state.isSmallLoaderJobRoleActive = false })
                            }
                        }
                    })
                    .catch(error => {
                        error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "bind_filter_array", 0, error.toString());
                    });
            } else {
                if (this.state.profile_jobfunction.length > 0) {
                    this.state.jobrole_full_list = this.state.profile_jobfunction.filter(e => this.state.jobrole_full_list.includes(e.jobfunctioncode));
                }
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "job_role_filter", "call_job_role", 0, error.toString());
        }
    }
    GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }
    onChangeValue(event) {
    }
    onChangeJobfunction(event) {
        this.setState({ jobfunctioncodeval: this.state.jobfunctioncodeval = [] })
        this.state.jobfunctioncodeval.push(Number(event.target.value))
        this.job_list()
    }
    jobpost_list_view() {
        try {
            return this.state.rowData.map((jobpost, index) => {
                const { employername, flash, companyname, statuscode, registeredname, contactdetai1ls, jobcode, jobfunctionname, jobid, viewedcount, dialedcount, pushedcount, experience, salaryrange, isbestsalary, joblocationname, jobrolename, imageurl, createddate, updateddate, profileurl, daysleft, employercode, employermobileno } = jobpost
                return (
                    <div className="col-lg-12 col-md-12" key={index}>
                        {/* featured-imagebox */}
                        <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                            <div className="featured-thumbnail">
                                <img src="images/joblist_logo.jpg" />
                                {/* <img src={profileurl}/> */}
                                {flash == 1 ?
                                    <div className="required-tag">Flash</div>
                                    :
                                    ''
                                }
                            </div>
                            <div className="featured-content">
                                <div className="job-title">
                                    <div className="job-heading">
                                        <h3><a>{jobrolename}</a></h3>
                                    </div>
                                    <div className="job-time">
                                        <span style={{ backgroundColor: 'transparent', color: 'red' }}>{daysleft} days left</span>
                                    </div>
                                </div>
                                <div className="featured-details">
                                    <div className="job-meta">
                                        {flash == 1 ?
                                            <div>
                                                <span><i className="fa fa-building"></i>{companyname}</span><br />
                                            </div>
                                            :
                                            <div>
                                                <span><i className="fa fa-building"></i>{employername}</span><br />
                                            </div>
                                        }
                                        <span><i className="fa fa-map-marker-alt"></i>{joblocationname}</span><br />
                                        <span><span><i className="fa fa-briefcase"></i>{experience[0].isfresher == true || experience[0].isfresher == 'true' ?
                                            <span>Fresher</span>
                                            : ''}
                                            {experience[0].from >= 0 && experience[0].to > 0 ?
                                                <span>{experience[0].from} to {experience[0].to} Years</span>
                                                : ''
                                            }
                                            {experience[0].isfresher == "false" && experience[0].from == 0 && experience[0].to == 0 ?
                                                <span>-</span>
                                                : ''
                                            }</span></span><br />
                                    </div>
                                </div>
                            </div>
                        </div>{/* featured-imagebox end */}
                    </div >
                )
            })
        }
        catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "FlashJobsList", "jobpost_list_view", 0, error.toString());
        }
    }
    bind_pageslist() {
        try {
            let pages = [1, 2, 3, 4, 5, 6, 7]
            if (pages && pages.length > 0) {
                return pages.map((page, index) => {
                    return (
                        index > 2 ?
                            <a className="page-nav-link">...</a>
                            :
                            <a className="page-nav-link ">{page}</a>
                    )
                })
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "bind_jobfunction", 0, error.toString());
        }
    }
    page(offset, itemsPerPage) {
        this.setState({ itemOffset: this.state.itemOffset = offset })
        var endOffset = this.state.itemOffset + itemsPerPage;
        var count = this.state.rowData.slice(this.state.itemOffset, endOffset)
        this.setState({ currentItems: this.state.currentItems = count })
        this.setState({ pageCount: this.state.pageCount = Math.ceil(this.state.rowData.length / itemsPerPage) })
    }
    handlePageClick = (event) => {
        var newOffset = (event.selected * this.state.itemsPerPage) % this.state.rowData.length;
        this.setState({ itemOffset: this.state.itemOffset = newOffset })
        this.page(newOffset, this.state.itemsPerPage)
    }
    joblist = (currentItems) => {
        try {
            return this.state.currentItems.map((jobpost, index) => {
                const { employername, flash, companyname, statuscode, registeredname, contactdetai1ls, jobcode, jobfunctionname, jobid, viewedcount, dialedcount, pushedcount, experience, salaryrange, isbestsalary, joblocationname, jobrolename, imageurl, createddate, updateddate, profileurl, daysleft, employercode, employermobileno, appliedstatus, applieddate } = jobpost
                return (
                    <div className="col-lg-12 col-md-12" key={index}>
                        {/* featured-imagebox */}
                        <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                            <div className="featured-thumbnail" style={{ textAlign: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center', verticalAlign: 'top' }}>
                                {profileurl ?
                                    <img src={profileurl} />
                                    :
                                    <img src="images/joblist_logo.jpg" />
                                }
                                {flash == 1 ?
                                    <div className="required-tag">Flash Job</div>
                                    :
                                    ''
                                }
                                <div className="button-container">
                                    {daysleft <= this.state.jobalertdays && appliedstatus == 0 ?
                                        //  <span  style={{ backgroundColor:'transparent',color:'red' }}>{daysleft} days left</span>
                                        null
                                        : daysleft == 0 ?
                                            <span className="danger-color">Expiring Today</span>
                                            :
                                            null
                                    }
                                    {this.state.employeecode > 0 && appliedstatus == 0 ? (
                                        <button className="apply-button jobDetail ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" onClick={() => this.applynow(jobcode, employercode, employermobileno)}>
                                            apply now</button>
                                    ) : appliedstatus == 11 ?
                                        <button className="apply-button jobDetail ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" style={{ backgroundColor: 'green', borderColor: 'transparent' }} onClick={() => this.openCallHRModal(employermobileno)}>
                                            Call HR</button>
                                        : null}
                                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                        {daysleft > this.state.jobalertdays && (
                                            <span style={{ backgroundColor: 'transparent', color: 'red' }}>{daysleft} days left</span>
                                        )}
                                    </div>
                                    <Modal isOpen={this.state.showModal} className='JobList_Popup'>
                                        <ModalHeader style={{ borderColor: 'white', justifyContent: 'flex-end', textAlign: 'flex-end', alignContent: 'flex-end', alignSelf: 'flex-end', alignItems: 'flex-end' }} toggle={this.closeModal} >
                                        </ModalHeader>
                                        <ModalBody>
                                            <div className="content-tab">
                                                <div className="text-center" style={{ marginTop: "-5px", textAlign: 'center', alignContent: 'center', alignSelf: 'center', alignItems: 'center' }}>
                                                    <center>
                                                        <img src="images/footer-logo.png" alt="Your Logo" />
                                                        <p style={{ fontSize: 12, color: 'black' }}>User Friendly & Trusted HR Manager</p></center>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: 'green',
                                                fontSize: '48px'
                                            }}>
                                                {/* <span><img src="images/svg/applied.gif" className="dz-img rounded-circle cls-load" alt="" /></span> */}
                                            </div>
                                            <p style={{
                                                textAlign: 'center',
                                                fontSize: '18px',
                                                fontWeight: 'bold',
                                            }}>{this.state.modalMessage}</p>
                                            <p style={{ textAlign: 'center' }}>Now you can call this employer @ <b><a href={'tel:' + this.state.employermobileno} >
                                                {this.state.employermobileno}</a></b></p>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Button color="green" onClick={this.closeModal}>OK</Button>
                                            </div>
                                        </ModalBody>
                                    </Modal>
                                </div>
                            </div>
                            <Modal isOpen={this.state.showCallHRModal} className='JobList_Popup'>
                                <ModalHeader style={{ borderColor: 'white', justifyContent: 'flex-end', textAlign: 'flex-end', alignContent: 'flex-end', alignSelf: 'flex-end', alignItems: 'flex-end' }} toggle={this.closeModal} >
                                </ModalHeader>
                                <ModalBody>
                                    <div className="content-tab">
                                        <div className="text-center" style={{ marginTop: "-5px", textAlign: 'center', alignContent: 'center', alignSelf: 'center', alignItems: 'center' }}>
                                            <center>
                                                <img src="images/footer-logo.png" alt="Your Logo" />
                                                <p style={{ fontSize: 12, color: 'black' }}>User Friendly & Trusted HR Manager</p></center>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: 'green',
                                        fontSize: '48px'
                                    }}>
                                        <span><img src="images/svg/phone-call.png" className="dz-img rounded-circle cls-load" alt="" /></span>
                                    </div>
                                    <p style={{
                                        textAlign: 'center',
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                    }}>{this.state.modalMessage}</p>
                                    <p style={{ textAlign: 'center' }}>Now you can call this employer @ <b>{this.state.employermobileno}</b></p>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button color="green" onClick={this.closeModal}>OK</Button>
                                    </div>
                                </ModalBody>
                            </Modal>
                            <div className="featured-content">
                                <div className="job-title">
                                    <div className="job-heading">
                                        {flash == 1 ?
                                            <h5><a href={'/Flashjobs_details?jobcode=' + jobcode}>{jobrolename}</a></h5>
                                            :
                                            <h5><a href={'/Job_details?jobcode=' + jobcode}>{jobrolename}</a></h5>
                                        }
                                    </div>
                                </div>
                                <div className="featured-details">
                                    <div className="job-meta">
                                        {flash == 1 ?
                                            <div>
                                                <span><i className="fa fa-building"></i>{companyname}</span><br />
                                            </div>
                                            :
                                            <div>
                                                <span><i className="fa fa-building"></i>{employername}</span><br />
                                            </div>
                                        }
                                        <span><i className="fa fa-map-marker-alt"></i>{joblocationname}</span><br />
                                        <span><span><i className="fa fa-briefcase"></i>{experience[0].isfresher == true || experience[0].isfresher == 'true' ?
                                            <span>Fresher</span>
                                            : ''}
                                            {experience[0].from >= 0 && experience[0].to > 0 ?
                                                <span>{experience[0].from} to {experience[0].to} Years</span>
                                                : ''
                                            }
                                            {experience[0].isfresher == "false" && experience[0].from == 0 && experience[0].to == 0 ?
                                                <span>-</span>
                                                : ''
                                            }</span></span><br />
                                        {isbestsalary == 1 ?
                                            <div>
                                                <span><i className="fas fa-rupee-sign"></i>Best Salary in the Industry</span>
                                            </div>
                                            :
                                            salaryrange.min != '' & salaryrange.max != '' ?
                                                <div>
                                                    <span><i className="fas fa-rupee-sign"></i>{this.indian_standard_amount(salaryrange.min)} to {this.indian_standard_amount(salaryrange.max)}</span>
                                                </div>
                                                :
                                                <div>
                                                    <span><i className="fas fa-rupee-sign"></i>-</span>
                                                </div>
                                        }
                                    </div>
                                    <div className="job-time">
                                    </div>
                                </div>
                            </div>
                        </div>{/* featured-imagebox end */}
                    </div >
                )
            })
        }
        catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "FlashJobsList", "jobpost_list_view", 0, error.toString());
        }
    }
    navigation(jobcode, flash) {
        localStorage.setItem('jobcode', jobcode);
        localStorage.setItem('flash', flash);
    }
    filterlistchanges(flag) {
        try {
            if (flag == 'jobfunction') {
                this.setState({ showjobfunction: this.state.showjobfunction = !this.state.showjobfunction });
                this.setState({ showskills: this.state.showskills = false });
                this.call_job_function(3);
            }
            if (flag == 'skills') {
                this.setState({ showskills: this.state.showskills = !this.state.showskills });
                this.setState({ showjobfunction: this.state.showjobfunction = false });
                this.call_job_function(1);
            }
        } catch (error) {
        }
    }
    //search load
    searchprofile_load() {
        try {
            var accessToken = localStorage.getItem('accessToken');
            window.fbq('track', 'Website', { fb_ad_function: "searchprofile_load", fb_ad_type: "Job list", fb_ad_loginuser: COMMON.employee_name });
            this.setState({ isActive: this.state.isActive = true })
            axios({
                method: 'Post',
                url: this.state.ipurl + 'search/load/',
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json'
                },
                params: {
                    languagecode: this.state.languagecode,
                    employeecode: this.state.employeecode,
                    ipaddress: '103.154.203.22',
                }
            })
                .then(async response => {
                    if (response.data.status == 401) {
                        await generateRefreshToken().then((result) => {
                            if (result == 1) {
                                this.searchprofile_load();
                            }
                            else {
                                // alert('Unauthorized')
                            }
                        })
                    }
                    else {
                        this.setState({ isActive: this.state.isActive = false })
                        if (response.data.search_json_result.varstatuscode === 4) {
                            //load location
                            var load_location_response = response.data.search_json_result.locationlist;
                            var dpbind_loadlocation = load_location_response.map(load_location_response => {
                                return { value: load_location_response.districtcode, label: load_location_response.districtname }
                            })
                            JSON.stringify(dpbind_loadlocation)
                            this.setState({ dpbind_loadlocation });
                            //load jobfunction
                            var load_jobfunction_response = response.data.search_json_result.jobfunctionlist;
                            var dpbind_loadjobfunction = load_jobfunction_response.map(load_jobfunction_response => {
                                return { value: load_jobfunction_response.jobfunctioncode, label: load_jobfunction_response.jobfunctionname }
                            })
                            JSON.stringify(dpbind_loadjobfunction)
                            this.setState({ dpbind_loadjobfunction });
                            //load jobfunction
                            var load_jobrole_response = response.data.search_json_result.jobrolelist;
                            var dpbind_loadjobrole = load_jobrole_response.map(load_jobrole_response => {
                                return { value: load_jobrole_response.jobrolecode, label: load_jobrole_response.jobrolename }
                            })
                            JSON.stringify(dpbind_loadjobrole)
                            this.setState({ dpbind_loadjobrole });
                            this.setState({ locationlist: this.state.locationlist = response.data.search_json_result.hasOwnProperty('locationlist') ? response.data.search_json_result.locationlist : [] })
                            this.setState({ jobrolelist: this.state.jobrolelist = response.data.search_json_result.hasOwnProperty('jobrolelist') ? response.data.search_json_result.jobrolelist : [] })
                            this.setState({ jobfunctionlist: this.state.jobfunctionlist = response.data.search_json_result.hasOwnProperty('jobfunctionlist') ? response.data.search_json_result.jobfunctionlist : [] })
                            this.setState({ recentsearch: this.state.recentsearch = response.data.search_json_result.hasOwnProperty('recentsearch') ? response.data.search_json_result.recentsearch : [] })
                            let common_list = [], final_list = [];
                            for (let i = 0; i < this.state.recentsearch.recentlocationlist.length; i++) {
                                let filter = common_list.some(e => e.label === this.state.recentsearch.recentlocationlist[i].locationname && e.value === this.state.recentsearch.recentlocationlist[i].locationcode);
                                if (!filter) {
                                    final_list.push({ 'name': this.state.recentsearch.recentlocationlist[i].locationname, "languagecode": this.state.recentsearch.recentlocationlist[i].languagecode, 'id': this.state.recentsearch.recentlocationlist[i].locationcode, 'status': 1, 'status_name': 'Location', 'searchtype': 1, hover: false });
                                }
                            }
                            for (let i = 0; i < this.state.recentsearch.recentjobfunctionlist.length; i++) {
                                let filter = common_list.some(e => e.label === this.state.recentsearch.recentjobfunctionlist[i].jobfunctionname && e.value === this.state.recentsearch.recentjobfunctionlist[i].jobfunctioncode);
                                if (!filter) {
                                    final_list.push({ 'name': this.state.recentsearch.recentjobfunctionlist[i].jobfunctionname, 'languagecode': this.state.recentsearch.recentjobfunctionlist[i].languagecode, 'id': this.state.recentsearch.recentjobfunctionlist[i].jobfunctioncode, 'status': 2, 'status_name': 'Job Function', 'searchtype': 3, hover: false });
                                }
                            }
                            for (let i = 0; i < this.state.recentsearch.recentjobrolelist.length; i++) {
                                let filter = common_list.some(e => e.label === this.state.recentsearch.recentjobrolelist[i].jobrolename && e.value === this.state.recentsearch.recentjobrolelist[i].jobrolecode);
                                if (!filter) {
                                    final_list.push({ 'name': this.state.recentsearch.recentjobrolelist[i].jobrolename, 'languagecode': this.state.recentsearch.recentjobrolelist[i].languagecode, 'id': this.state.recentsearch.recentjobrolelist[i].jobrolecode, 'status': 3, 'status_name': 'Job Role', 'searchtype': 2, hover: false });
                                }
                            }
                            for (let i = 0; i < this.state.jobrolelist.length; i++) {
                                let filter = common_list.some(e => e.label === this.state.jobrolelist[i].jobrolename && e.value === this.state.jobrolelist[i].jobrolecode);
                                if (!filter) {
                                    common_list.push({ 'name': this.state.jobrolelist[i].jobrolename, 'languagecode': this.state.jobrolelist[i].languagecode, 'id': this.state.jobrolelist[i].jobrolecode, 'status': 2, 'status_name': 'Job Role', 'searchtype': 2, hover: false });
                                }
                            }
                            this.setState({ common_array: this.state.common_array = common_list });
                            this.setState({ common_array_duplicate: this.state.common_array_duplicate = common_list });
                            this.setState({ recent_array: this.state.recent_array = final_list });
                            this.setState({ recent_array_duplicate: this.state.recent_array_duplicate = final_list });
                        }
                        else if (response.data.search_json_result.varstatuscode === 7) {
                            localStorage.clear()
                        }
                        else {
                            this.setState({ isActive: this.state.isActive = false })
                        }
                    }
                })
                .catch(error => {
                    this.setState({ isActive: this.state.isActive = false })
                    error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "searchprofile_load", 0, error.toString());
                });
        }
        catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "job_role_filter", "searchprofile_load", 0, error.toString());
        }
    }
    recentSearchClick(item) {
        try {
            this.setState({ show_hidden: this.state.show_hidden = true })
            this.setState({ search_array_hidden: this.state.search_array_hidden = true })
            this.setState({ jobnav: this.state.jobnav = 1 })
            if (Object.keys(item).length > 0) {
                if (item.searchtype === 1) {
                    this.setState({ locationcodeval: this.state.locationcodeval = [] })
                    this.state.locationcodeval.push(item.id)
                    this.setState({
                        joblocationcode_api: this.state.joblocationcode_api = [],
                        joblocationcode_api: this.state.joblocationcode_api = Array.from(new Set(this.state.locationcodeval.concat(this.state.profile_location)))
                    })
                    this.setState({
                        searchtype: this.state.searchtype = 1,
                        location_val: this.state.location_val = item.name
                    })
                }
                else if (item.searchtype === 2) {
                    this.setState({ jobrolecodeval: this.state.jobrolecodeval = [] })
                    this.state.jobrolecodeval.push(item.id)
                    this.setState({
                        searchtype: this.state.searchtype = 2,
                        jobrole_val: this.state.jobrole_val = item.name
                    })
                    localStorage.setItem('getCurrentJobSearch', item.name)
                }
                else {
                    this.setState({ jobfunctioncodeval: this.state.jobfunctioncodeval = [] })
                    this.state.jobfunctioncodeval.push(item.id)
                    this.setState({
                        jobfunctioncode_api: this.state.jobfunctioncode_api = [],
                        jobfunctioncode_api: this.state.jobfunctioncode_api = Array.from(new Set(this.state.jobfunctioncodeval.concat(this.state.profile_jobfunction)))
                    })
                    this.setState({
                        searchtype: this.state.searchtype = 3,
                        jobfunction_val: this.state.jobfunction_val = item.name
                    })
                }
            }
            this.setState({ common_array_duplicate: this.state.common_array_duplicate = this.state.common_array })
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "recentSearchClick", 0, error.toString());
        }
    }
    getSearchValues(val, flag) {
        try {
            if (flag == 2) {
                if (val == "") {
                    this.setState({
                        enableSearch: this.state.enableSearch = false
                    })
                    localStorage.setItem('getCurrentJobSearch', '')
                }
                this.setState({
                    jobrole_val: this.state.jobrole_val = val,
                    jobroleresult: this.state.jobroleresult = true,
                    locationresult: this.state.locationresult = false,
                    jobfunctionresult: this.state.jobfunctionresult = false,
                    enableSearch: this.state.enableSearch = true,
                    jobrolecodeval: this.state.jobrolecodeval && this.state.jobrolecodeval.length > 0 ? this.state.jobrolecodeval[0].jobrolecode : []
                })
            }
            if (this.state.languagecode == 2) {
                if (flag == 2) {
                    this.setState({ jobrole_val: this.state.jobrole_val = val })
                }
                if (val.length === 0) {
                    this.setState({ common_array_duplicate: this.state.common_array_duplicate = this.state.common_array })
                    this.setState({ recent_array_duplicate: this.state.recent_array_duplicate = this.state.recent_array })
                    this.setState({ closeicon_hidden: this.state.closeicon_hidden = true })
                    this.setState({ show_hidden: this.state.show_hidden = true })
                    this.setState({ search_array_hidden: this.state.search_array_hidden = true })
                }
                else if (val.length <= 2) {
                    this.setState({
                        jobroleresult: this.state.jobroleresult == false
                    })
                } else if (val.length >= 3) {
                    this.setState({ closeicon_hidden: this.state.closeicon_hidden = false })
                    this.setState({ show_hidden: this.state.show_hidden = false })
                    this.setState({ search_array_hidden: this.state.search_array_hidden = false })
                    let filteredData = this.state.common_array.filter(x => String(x.name.toLowerCase()).startsWith(val.toLowerCase()) && x.searchtype == flag);
                    this.setState({ common_array_duplicate: this.state.common_array_duplicate = filteredData })
                    let recentfilteredData = this.state.recent_array.filter(x => String(x.name.toLowerCase()).includes(val.toLowerCase()));
                    this.setState({ recent_array_duplicate: this.state.recent_array_duplicate = recentfilteredData })
                }
            } else {
                let languagecode = Number(localStorage.getItem('languagecode'))
                if (val.length === 0) {
                    this.setState({ common_array_duplicate: this.state.common_array_duplicate = this.state.common_array })
                    this.setState({ recent_array_duplicate: this.state.recent_array_duplicate = this.state.recent_array })
                    this.setState({ closeicon_hidden: this.state.closeicon_hidden = true })
                    this.setState({ show_hidden: this.state.show_hidden = true })
                    this.setState({ search_array_hidden: this.state.search_array_hidden = true })
                }
                else if (val.length <= 2) {
                    this.setState({
                        jobroleresult: this.state.jobroleresult == false
                    })
                } else if (val.length >= 3) {
                    this.setState({ closeicon_hidden: this.state.closeicon_hidden = false })
                    this.setState({ show_hidden: this.state.show_hidden = false })
                    this.setState({ search_array_hidden: this.state.search_array_hidden = false })
                    let filteredData = this.state.common_array.filter(x => String(x.name.toLowerCase()).startsWith(val.toLowerCase()) && x.searchtype == flag);
                    let arr_duplicate = [];
                    for (let i = 0; i < filteredData.length; i++) {
                        let res = this.state.common_array.filter(item => (item.id == filteredData[i].id && filteredData[i].code == item.code && item.languagecode == languagecode))
                        if (res == '' || res == null || res == undefined) {
                            arr_duplicate.push(filteredData[i])
                        }
                        else {
                            arr_duplicate.push(res[0])
                        }
                    }
                    this.setState({ common_array_duplicate: this.state.common_array_duplicate = arr_duplicate })
                    let recentfilteredData = this.state.recent_array.filter(x => String(x.name.toLowerCase()).includes(val.toLowerCase()) && x.searchtype == flag);
                    let arr1_duplicate = [];
                    for (let i = 0; i < recentfilteredData.length; i++) {
                        let res = this.state.recent_array.filter(item => (item.id == recentfilteredData[i].id && recentfilteredData[i].code == item.code && item.languagecode == languagecode))
                        if (res == '' || res == null || res == undefined) {
                            arr1_duplicate.push(recentfilteredData[i])
                        }
                        else {
                            arr1_duplicate.push(res[0])
                        }
                    }
                    this.setState({ recent_array_duplicate: this.state.recent_array_duplicate = arr1_duplicate })
                }
            }
        } catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "getSearchValues", 0, error.toString());
        }
    }
    job_loading = (index) => {
        this.setState({
            activeTab: (this.state.activeTab = index)
        })
        if (index === "1") {
            this.job_list()
        }
        if (index === "2") {
            this.applied_job_list()
        }
    }
    search_view() {
        try {
            if (this.state.common_array_duplicate && this.state.common_array_duplicate.length > 0) {
                return this.state.common_array_duplicate.map((recent, index) => {
                    const { name, status_name, hover } = recent
                    return (
                        <div key={index} >
                            <div md="11" style={{ marginLeft: "2%", padding: '5px 19px 0px 19px' }}>
                                <div
                                    onClick={() => this.recentSearchClick(recent)}
                                    onMouseOver={() => this.someHandler_one(recent)}
                                    onMouseLeave={() => this.someOtherHandler_one(recent)}
                                    style={{
                                        backgroundColor: 'white', height: '32px',
                                        ...(hover && { backgroundColor: '#efe9e9', height: '30px', padding: '4px 0px 0px 0px' }),
                                    }}
                                    className="notify-margin" >
                                    {/* <i className="fa fa-search" aria-hidden="true"></i> */}
                                    <span className="cursor-pointer dropdown" onClick={() => this.recentSearchClick(recent)} > {name}</span><span className="span_pointer"></span>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "search_view", 0, error.toString());
        }
    }
    someHandler = (recent) => {
        try {
            recent.hover = true
            this.setState({ recent_array_duplicate: this.state.recent_array_duplicate })
            this.setState({ hover: this.state.hover = true })
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "someHandler_one", 0, error.toString());
        }
    }
    someOtherHandler = (recent) => {
        try {
            recent.hover = false
            this.setState({ recent_array_duplicate: this.state.recent_array_duplicate })
            this.setState({ hover: this.state.hover = false })
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "someHandler_one", 0, error.toString());
        }
    }
    someHandler_one = (recent) => {
        try {
            recent.hover = true
            this.setState({ common_array_duplicate: this.state.common_array_duplicate })
            this.setState({ hover: this.state.hover = true })
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "someHandler_one", 0, error.toString());
        }
    }
    someOtherHandler_one = (recent) => {
        try {
            recent.hover = false
            this.setState({ common_array_duplicate: this.state.common_array_duplicate })
            this.setState({ hover: this.state.hover = false })
        }
        catch (error) {
            error_log.ERRORLOG(this.state.employercode, this.state.version, "Search_Profiles", "someHandler_one", 0, error.toString());
        }
    }
    searchclear(flag) {
        try {
            if (flag == 1) {
                this.setState({
                    location_val: this.state.location_val = '',
                    locationcodeval: this.state.locationcodeval = []
                })
            }
            else if (flag == 2) {
                if (this.state.jobrole_val != "") {
                    this.setState({
                        jobrole_val: this.state.jobrole_val = '',
                        jobrolecodeval: this.state.jobrolecodeval = [],
                        enableSearch: this.state.enableSearch = false,
                        jobroleresult: this.state.jobroleresult = false,
                        selectedJobRoleIndex: this.state.selectedJobRoleIndex = 0
                    })
                    if (localStorage.getItem('getCurrentJobSearch') != null && localStorage.getItem('getCurrentJobSearch') != '' && localStorage.getItem('getCurrentJobSearch') != 'null') {
                        localStorage.setItem('getCurrentJobSearch', 'null')
                        localStorage.setItem('getcurrentRole', 0)
                        const currentRoute = window.location.pathname
                        if (currentRoute == '/job_list') {
                            window.location.reload();
                        }
                        else {
                            history.push('/job_list');
                        }
                    }
                }
            }
            else {
                this.setState({
                    jobfunction_val: this.state.jobfunction_val = '',
                    jobfunctioncodeval: this.state.jobfunctioncodeval = []
                })
                this.setState({
                    jobfunctioncode_api: this.state.jobfunctioncode_api = [],
                    jobfunctioncode_api: this.state.jobfunctioncode_api = this.state.profile_jobfunction
                })
            }
        }
        catch (error) {
            error_log.ERRORLOG(this.state.usercode, this.state.version, "job_role_filter", "searchclear", 0, error.toString());
        }
    }
    handleInputChange = (e) => {
        this.setState({ fromSalary: e.target.value });
    };
    handleOtherInputChange = (e) => {
        this.setState({ toSalary: e.target.value });
    };
    handleMaxValueBlur = () => {
        const { toSalary, fromSalary } = this.state;
        if (parseInt(toSalary, 10) < parseInt(fromSalary, 10)) {
            toast("Maximum salary must be greater than or equal to the minimum salary.", {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo-bar'
            });
        }
    };
    render() {
        const { fromSalary, toSalary } = this.state;
        return (
            <div className="site-main mbl_width">
                <ToastContainer autoClose={10000} toastStyle={{ backgroundColor: "#ed9d3b", color: 'white' }} progressStyle={{ background: 'white' }} />
                <div className="ttm-row sidebar job-sidebar clearfix" >
                    <div className="border_bottom container" >
                        {/* row */}
                        <div className="ttm-tabs ttm-tab-style-01">
                            <div className="react-tabs" data-tabs="true">
                                <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg border  borderNone job_search_width rounded p-lg-20 mt-lg-50 mt-lg-10" style={{ padding: "28px" }}>
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                                        <div className="layer-content">
                                            <div className="search_Form wrap-form">
                                                <label className='mrg_lft'>
                                                    <i className="ti ti-search cursor search_align" style={{ fontSize: '15px', marginTop: '4px', paddingLeft: '7px' }}></i>
                                                    <input type="text"
                                                        id="txtjobrole"
                                                        className="input-serch input_border inputID"
                                                        name="txtjobrole"
                                                        value={this.state.jobrole_val}
                                                        onClick={(e) => this.get_job_list()}
                                                        onChange={(e) => this.getSearchValues(e.target.value, 2)}
                                                        autoComplete="off"
                                                        placeholder={stringsoflanguages.accountant}
                                                        ref={this.inputRoleRef}
                                                        onKeyDown={(e) => this.handleJobRoleKeyDown(e)}
                                                    />
                                                    {this.state.enableSearch == true ?
                                                        <i className="ti ti-close searchclear search_left" style={{ cursor: 'pointer' }} onClick={e => { this.searchclear(2) }}></i>
                                                        : ''
                                                    }
                                                    {this.state.jobroleresult == true ?
                                                        <div className="cls-searchprofile cls_scroll_search">
                                                            <div hidden={this.state.search_array_hidden}>
                                                                <div style={{ marginTop: "10px" }}>
                                                                    {this.search_view()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ''
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div></div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Job_role_filter;
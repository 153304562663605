import axios from "axios"
import { APIURL } from "./config";
import { history } from './history';
let error_log = require('./error_log');
export const generateRefreshToken = async () => {
  var resp;
  try {
    var refreshToken = localStorage.getItem('refreshToken');
    var ipurl = APIURL
    await axios({
      method: 'Post',
      url: ipurl + 'employer/refreshToken/',
      crossdomain: true,
      params: {
        token: refreshToken,
      }
    })
      .then(async response => {
        if(response.data && response.data['tokenReult'] && response.data['tokenReult']['status'] == 498)
        {
          resp = 0;
          localStorage.clear();
          setTimeout(() => {
            history.push('/');
          }, 1000);
        }
        else {
          if (response.data.hasOwnProperty('tokenReult')) {
            if (response.data.tokenReult.hasOwnProperty('accessToken')) {
              localStorage.setItem('accessToken', response.data.tokenReult.accessToken)
              localStorage.setItem('refreshToken', response.data.tokenReult.refreshToken)
              resp = 1
            }
          }
        }
      })
      .catch(error => {
         error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "joinus", 0, error.toString());
      });
  } catch (error) {
    error_log.ERRORLOG(this.state.usercode, this.state.version, "NavbarUser", "joinus", 0, error.toString());
  }
  return resp
}
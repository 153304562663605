import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';

export class Contact extends Component {
    render() {

        return (
            <div className="site-main">
                <Header />

                {/* PageHeader */}
                <PageHeader
                    title="Contact us"
                    breadcrumb="Contact Us"
                />
                {/* PageHeader end */}


                {/* contact-section */}
                <section className="ttm-row contact-section bg-theme-GreyColor clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* section-title */}
                                <div className="section-title title-style-center_text">
                                    <div className="title-header">
                                        {/* <h3>Contact us</h3> */}
                                        <h2 className="title">Get In Touch With Us</h2>
                                    </div>
                                </div>{/* section-title end */}
                            </div>
                        </div>
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="pr-30 pr-md-0 mt-15">
                                    <div className="ttm_single_image-wrapper mb-20">
                                        <img className="img-fluid w-100" src="images/GetInTouchWithUs.jpg" alt="GetInTouchWithUs" />
                                    </div>
                                    <p className="mb-30">We are here for you. Contact us for any quires and more information about Jobanya today. We would love to help you....</p>

                                    
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="ttm-col-bgcolor-yes ttm-bg bg-theme-WhiteColor p-40 p-lg-30 mt-15 mt-lg-30">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                    <div className="layer-content">
                                    <div className="d-flex align-items-center">
                                        <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-sm 
                                    ttm-icon_element-style-round mb-0">
                                            <i className="fa fa-phone"></i>
                                        </div>
                                        <div className="pl-15">
                                            <h6 className="mb-5">Call us Anytime</h6>
                                            <p className="featured-desc mb-0">+91 93843 78052</p>
                                            <p className="featured-desc mb-0">+91 96299 38054</p>
                                        </div>
                                    </div>

                                    <div className="ttm-horizontal_sep width-100 mt-20 mb-20"></div>

                                    <div className="d-flex align-items-center">
                                        <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-sm 
                                    ttm-icon_element-style-round mb-0">
                                            <i className="fa fa-globe"></i>
                                        </div>
                                        <div className="pl-15">
                                            <h6 className="mb-5">Send an Email</h6>
                                            <p className="featured-desc mb-0">support@jobanya.com</p>
                                        </div>
                                    </div>

                                    <div className="ttm-horizontal_sep width-100 mt-20 mb-20"></div>

                                    <div className="d-flex align-items-center">
                                        <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-sm 
                                    ttm-icon_element-style-round mb-0">
                                            <i className="ti ti-home"></i>
                                        </div>
                                        <div className="pl-15">
                                            <h6 className="mb-5">Visit our Office</h6>
                                            <p className="featured-desc mb-0">VinMeen Infotech <br /> 20, Spencers Compound,<br /> 13th Cross Road, Thiruvalluvar Salai, <br />Dindigul – 624003, Tamil Nadu</p>
                                        </div>
                                    </div>{/*  featured-icon-box end */}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-6">
                                <div className="ttm-col-bgcolor-yes ttm-bg bg-theme-WhiteColor p-40 p-lg-30 mt-15 mt-lg-30">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                    <div className="layer-content">
                                        <form id="contact_form" className="contact_form wrap-form">
                                            <div className="row ttm-boxes-spacing-15px">
                                                <div className="col-md-12 ttm-box-col-wrapper">
                                                    <label>
                                                        <input name="name" type="text" placeholder="Your Name" required="required" />
                                                    </label>
                                                </div>
                                                <div className="col-md-6 ttm-box-col-wrapper">
                                                    <label>
                                                        <input name="email" type="text" placeholder="Your Email" required="required" />
                                                    </label>
                                                </div>
                                                <div className="col-md-6 ttm-box-col-wrapper">
                                                    <label>
                                                        <input name="subject" type="text" placeholder="Subject" required="required" />
                                                    </label>
                                                </div>
                                                <div className="col-md-12 ttm-box-col-wrapper">
                                                    <label>
                                                        <input name="phone" type="text" placeholder="Phone Number" required="required" />
                                                    </label>
                                                </div>
                                                <div className="col-md-12 ttm-box-col-wrapper">
                                                    <label>
                                                        <textarea name="message" rows="9" placeholder="Message" required="required"></textarea>
                                                    </label>
                                                </div>
                                            </div>
                                            <button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100" type="submit">send a message!</button>
                                        </form>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
                {/* contact-section end */}


                {/* google_map */}
                <div id="google_map" className="google_map">
                    <div className="map_container clearfix">
                        <div id="map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15698.646955454496!2d77.977885!3d10.368908!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x43d30c7cde2fedf3!2sVinMeen%20Infotech!5e0!3m2!1sen!2sus!4v1660819915719!5m2!1sen!2sus" width="100%" height="450"></iframe>
                        </div>
                    </div>
                </div>
                {/* google_map end */}

                <Footer />

            </div>
        )
    }
}


export default Contact;
import React from 'react';

const PageHeader = ({title,content,breadcrumb,className}) => {
    let image_url = title == 'About Us' ? 'url(images/About-us.jpg)' : title == 'job list' ? 'url(images/Job-list.jpg)' : 'url(images/Contact-Us.jpg)'
    return (
        <div className="page-header-area bg-img" style={{backgroundImage: image_url }}>
           <div className="page-header-area-inner">
                 <div className="container">
                     <div className="row">
                         <div className="col-lg-12 text-center">
                             <div className="page-header-content-inner">
                                <div className="page-header-content">
                                    
                                    <div>{content}</div>
                                    <div className="breadcrumb-wrapper">
                                        
                                            {/* <span><a href={'/'} title="Homepage"><i className="ti ti-home" />&nbsp;&nbsp;Home</a></span>
                                            <span className="bread-sep">&nbsp;/&nbsp;</span>                                        
                                            {breadcrumb} */}
                                    </div>
                                    <div className={className}></div>
                                </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
    );
};

export default PageHeader;